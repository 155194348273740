<template>
  <section v-if="a_categoria">
    <div class="container-stockcar margin-row" v-if="$route.params.categoria != 'proseries'">
        <b-row class="titulo-stock">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h1 class="titulo">{{ a_categoria.nomeCategoria }}</h1>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>
        <b-container>
          <div v-if="$route.params.categoria == 'formula-4' && $i18n.locale == 'en'">
            <div class="txt-stockcar">
              <p>Fernando Julianelli, Vicar 's CEO, and Gianfranco De Bellis, president of Tatuus, official F4 cars factory and licenced by FIA.  
              The new championship will be supervised by the CBA - Brazilian automobilistic confederation that his president Giovanni Guerra wrote and developed 
              the brazilian championship with FIA, an important step before marketing, logistic and sponsorship plans.</p>
            </div>

            <div class="txt-stockcar">
              <p>‘’The F4 championship was created by FIA to be the young drivers first step after Kart competitions and 
              towards F1 and needs a championship that gives him more learning and knowledge, while at the same time developing the 
              drivers in important professional sports situations, says Julianelli”.</p>
            </div>
          </div>
          
          <div v-else class="txt-stockcar">
            <div v-html="a_categoria.texto"></div>
          </div>
        </b-container>
    </div>

    <div v-else class="proseries">
        <b-row class="titulo-stock">
          <b-col class="titulo-centralizado">
            <h1 class="titulo">A stock car pro series</h1>
          </b-col>
        </b-row>

        <b-container class="bv-row valores-container box-historia" data-anima="bottom">
          <div class="conteudo-historia">
            <h2 class="desk">NOSSA<br/> HISTÓRIA</h2>
            <p>A Stock Car foi criada pela Associação Brasileira de Revendedores Chevrolet e teve como inspiração a americana Nascar. 
              A primeira corrida aconteceu no dia 22 de abril de 1979, no autódromo de Tarumã, em Viamão-RS. A prova inaugural 
              contou com a participação de dez carros, que possuíam a mesma concepção mecânica dos veículos de passeio, sem os revestimentos internos.
            </p>
            <h2 class="mob">NOSSA<br/> HISTÓRIA</h2>
          </div>
        </b-container>

        <b-container class="container-linha">
          <div class="linhadotempo">
            <h3>linha do tempo</h3>
          </div>
          <div class="conteudo-tempo">
            <div>
              <h3>DÉCADA DE 1970</h3>
              <p><strong>O COMEÇO DE TUDO </strong><br><br>
                Guiando um Opala 250S, o carioca João Carlos Palhares, o “Capeta”, cravou a primeira pole position da história. 
                Mas foi o paulista Affonso Giaffone Júnior quem venceu a corrida inaugural da Stock Car. Ao final de 14 provas, no entanto, 
                o grande campeão foi Paulo Gomes, com vantagem de apenas três pontos para o goiano Alencar Júnior.</p>

                <h3>DÉCADA DE 1990</h3>
                <p><strong>A DÉCADA DE INGO </strong><br><br>
                  Investindo forte no esporte a motor brasileiro a partir de 1990, a General Motors lançou a Fórmula Chevrolet e também promoveu 
                  uma alteração nas carenagens dos Opala. Outra inovação foi a adoção, a partir de 1994, do Omega como carro base da Stock Car, 
                  fato que gerou comoção tanto pela aposentadoria do Opala quanto pela renovação da categoria. Até hoje o Opala possui uma legião 
                  de admiradores em todo o país, muito por conta da sua história na Stock Car. <br>Entre 1990 e 2000, os campeonatos foram dominados por 
                  Ingo Hoffman, que conquistou oito títulos no período, sendo seis consecutivos. De 1991 a 1993, Hoffmann teve como parceiro o 
                  cascavelense Ângelo Giombelli, formando a única dupla que conquistou um tricampeonato na categoria. Apenas Paulo Gomes (em 1995) 
                  e Chico Serra (em 1999 e 2000) interromperam a hegemonia do Alemão naquela época.</p>

                  <h3>DÉCADA DE 2010</h3>
                  <p><strong>SUCESSO DE PÚBLICO E GRID ESTRELADO </strong><br><br>
                    O sucesso da Stock Car nos autódromos e a exposição na TV começaram a atrair pilotos com muito êxito nas principais categorias 
                    do automobilismo mundial, como Rubens Barrichello, que conquistou o título já na sua segunda temporada na categoria, em 2014.<br>
                    A combinação de grandes nomes internacionais com eventos fortes – a exemplo da Corrida do Milhão, Corrida de Duplas e 
                    Super Final – consolidou a categoria como uma das mais importantes do mundo. Prova disso foi a classificação da Stock entre 
                    as cinco mais equilibradas categorias de turismo do automobilismo internacional, resultado de um regulamento técnico que 
                    sempre buscou priorizar a competitividade. <br>Coroando a ótima fase, em 2019 a Stock Car comemorou 40 anos de existência, 
                    sendo a mais longeva categoria do esporte a motor brasileiro.</p>
            </div>

            <div class="div-linha mob">
              <img src="@/assets/img/linha-mob.png"  alt="Imagem de linha do tempo" class="linha-tempo" />
            </div>

            <div style="margin-top: 30px;">
              <h3>DÉCADA DE 1980</h3>
              <p><strong>EVOLUÇÃO E CONSOLIDAÇÃO </strong><br><br>
                A década de 1980 para a Stock Car foi um período marcado por evolução e consolidação da categoria no país. 
                Os carros ficaram mais velozes, e itens de segurança foram agregados. Logo, a categoria caiu no gosto popular: a média de 
                público nas corridas chegou a 50 mil pessoas.<br> Um fato importante aconteceu em 1982, quando a Stock Car realizou sua primeira 
                corrida de uma competição nacional no exterior — em caráter extraoficial —, no autódromo do Estoril, em Portugal, também com 
                vitória de Paulo Gomes.<br> A década transcorreu com grid cheios, e a Stock se firmou como referência nacional, sempre reunindo os 
                pilotos mais profissionais e competitivos do país. No final do período, mais precisamente em 1989, teria início a hegemonia de 
                Ingo Hoffmann, que conquistaria seis títulos consecutivos.</p>

                <h3>DÉCADA DE 2000</h3>
                <p><strong>MULTIMARCAS, STOCK CAR ABRE SÉCULO COM INOVAÇÕES</strong><br><br>
                  Em 2000, pela primeira vez a Stock Car deixa de usar um carro de rua adaptado para corridas e lança um projeto baseado em um 
                  chassi tubular específico para competição fabricado pela JL, empresa do ex-piloto Zeca Giaffone, campeão de 1987. Em 2001, a Vicar, 
                  organizadora do evento até hoje, passa a gerenciar a competição.<br> A partir de 2003, os carros passam a usar motores de oito cilindros, 
                  importados dos Estados Unidos. A categoria também se torna multimarcas com a entrada de outras montadoras, como Mitsubishi, Peugeot 
                  e Volkswagen, além da Chevrolet, parceira da Stock desde o início. Aos poucos são adotados equipamentos sofisticados para a época, 
                  como a telemetria, que deu às equipes mais informações e controle sobre o comportamento do carro.<br> A década teve como um dos pontos 
                  altos o grande número de pilotos, com 40 carros no grid em 2005. No mesmo ano, também foi realizada uma corrida inédita em Buenos Aires. 
                  Três anos depois foi criada a Corrida do Milhão, iniciativa inovadora no esporte do continente que deu o prêmio de US$ 1 milhão ao 
                  vencedor, Valdeno Brito Filho, que triunfou no saudoso Autódromo de Jacarepaguá. No ano seguinte, a Stock estreou nos circuitos de rua, 
                  com uma prova em Salvador, que terminou com a vitória de Cacá Bueno, um dos principais nomes da década, com três dos seus cinco 
                  títulos conquistados naquele período.</p>
 
            </div>
          </div>


          <div class="decada">
              <div class="linhadotempo">
                <h3>DÉCADA DE 2020</h3>

                <p><strong>UMA NOVA VICAR. UMA NOVA STOCK CAR.</strong><br><br>
                O grid deste período é um dos mais competitivos da história, com a presença constante de pilotos com passagem pela Fórmula 1 
                dividindo curvas com novos astros do esporte.<br> <br>A temporada 2020 da Stock Car marcou o início de uma nova fase para a principal 
                categoria da América do Sul, que passou a contar com carros mais semelhantes aos vistos nas ruas. Ainda equipadas com chassis 
                tubulares, as máquinas agora utilizam os monoblocos dos carros de rua, aproximando o visual dos carros daquele que o consumidor 
                encontra nas concessionárias.<br> <br> A Chevrolet, que disputa as provas com o Cruze desde 2016, ganhou a companhia da Toyota e seu Corolla, 
                que deixou as corridas ainda mais disputadas e entregou um incrível duelo de montadoras. Fora das pistas, em meio a um período 
                de muita dificuldade em razão da pandemia, iniciada em 2020, a Vicar Promoções Desportivas foi adquirida pelo Grupo Veloci, 
                controlado por Lincoln Oliveira Silva que, por sua vez, nomeou Fernando Julianelli como CEO da empresa que promove e organiza 
                a competição. <br> <br>Em constante processo de transformação, a Stock Car coroou nos últimos anos campeões dos mais variados perfis, 
                desde ícones das pistas como Ricardo Maurício (2020) e Rubens Barrichello (2022), como o jovem Gabriel Casagrande, que faturou 
                os títulos de 2021 e 2023. Neste ano também foram definidas as diretrizes da nova geração de carros da categoria, que vai estrear 
                a 'revolução SUV' em 2025, resultado de um projeto ambicioso que vai trazer o que há de melhor em tecnologia e conectividade, em 
                caráter vanguardista que promete abrir novos tempos para o automobilismo brasileiro.</p>
              </div>
          </div>

          <a href="https://www.youtube.com/watch?v=51W6hWSQuKc&ab_channel=StockCar" target="_blank">
            <img src="@/assets/img/video.png"  alt="Imagem de video" class="img-video" />
          </a>

        </b-container>

        <b-container class="box-historia box-trofeu" data-anima="bottom">
          <div class="conteudo-trofeu">
            <img src="@/assets/img/trofeu.png"  alt="Imagem de um trofeu" class="trofeu"/>
            <div class="txt-trofeu">
              <h3>STOCK CAR <br><strong>EM NúMEROS*</strong></h3>
              <p><span>610</span> CORRIDAS JÁ REALIZADAS</p>
              <div class="numero-trofeu"> 
                <p><span>19</span> CAMPEÕES</p>
                <p><span>77</span> VENCEDORES</p>
              </div>

              <div class="numero-trofeu">
                <p><span>19</span> CIRCUITOS**</p>
                <p><span>05</span> MONTADORAS</p>
              </div>

              <div class="numero-trofeu">
                <p><span>414</span> PILOTOS</p>
              </div>

              <div>
                <p class="info-trofeu">* números registrados até o fim da temporada 2023 <br>
                ** somente pistas que receberam etapas oficiais da Stock Car</p>
              </div>
            </div>
          </div>
        </b-container>

    </div>
  </section>
</template>

<script>
import { mapCategorias } from "@/helpers.js";

export default {
  name: "ACategoria",
  data() {
    return {
      a_categoria: null,
    };
  },
  methods: {
    fetchCategoria(id_categoria) {
      this.loading = true;
      this.categoria = null;

      this.$store
        .dispatch("getCategoria", id_categoria)
        .then((resp) => {
          this.a_categoria = resp.categoria;
          this.alterBg();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    alterBg() {
      setTimeout(() => {
        const bg = document.querySelector(".container-stockcar");
        bg.style.backgroundImage = `url('${this.a_categoria.imgBackgroud}')`;
      }, 100);
    },
  },
  mounted() {
    this.a_categoria = this.$store.state.categoria
      ? this.$store.state.categoria
      : sessionStorage.categoria
      ? JSON.parse(sessionStorage.categoria)
      : "";

    if (this.a_categoria) {
      this.alterBg();
    } else {
      this.fetchCategoria(
        mapCategorias[this.$route.params.categoria || "proseries"]
      );
    }
  },
};
</script>
<style scoped>

.proseries h1 {
  font-family: "Uniwars-W00-SemiBold";
  text-transform: uppercase;
  font-size: 36px;
}

.proseries .titulo-stock {
  padding-top: 150px;
  padding-bottom: 20px;
  margin-bottom: 50px;
}

.box-historia {
  background: #000000 linear-gradient(90deg, #2B262A00 0%, #2B262A 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 40px 100px;
}

.box-historia p {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0px;
  width: 50%;
}

.box-historia h2 {
  color: #EABB4E;
  font-size: 30px;
  font-family: "Uniwars-W00-SemiBold";
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: end;
}

.conteudo-historia {
  display: flex;
}

.linhadotempo {
  color: #EABB4E;
  font-size: 30px;
  font-family: "Uniwars-W00-SemiBold";
  text-align: center;
  margin-top: 50px;
}

.conteudo-tempo {
  display: flex;
  gap: 100px;
}

.conteudo-tempo h3 {
  color: #EABB4E;
  font-size: 24px;
  font-family: "Uniwars-W00-SemiBold";
  margin-top: 50px;
}

.conteudo-tempo p {
  color: #FFFFFF;
}

.decada {
  margin-top: 70px;
}

.decada p {
  color: #FFFFFF;
  font-size: 18px;
  font-family: "MetronicPro-Light";
  font-weight: 200px;
  text-align: left;
}

.img-video {
    width: 70%;
    text-align: center;
    margin: 50px auto;
    display: block;
}

.linha-tempo {
  width: 26px;
  margin-top: 50px;
}

.linhadotempo h3 {
  margin-bottom: 50px;
}

.trofeu {
  width: 368px;
  height: 100%;
}

.box-trofeu {
  background: transparent linear-gradient(180deg, #2B262A 0%, #2B262A00 100%) 0% 0% no-repeat padding-box !important;
  padding: 40px 60px;
}

.conteudo-trofeu {
  display: flex;
  gap: 60px;
}

.conteudo-trofeu h3 {
  color: #EABB4E;
  font-size: 41px;
  font-family: "Uniwars-W00-Light";
  margin-bottom: 30px;
}

.conteudo-trofeu strong {
  font-family: "Uniwars-W00-SemiBold";
}

.conteudo-trofeu p {
  padding-bottom: 10px;
  border-bottom: 1px solid #706E6B;
  font-family: "MetronicPro-SemiBold";
  color: #706E6B;
  font-size: 24px;
  margin-bottom: 20px;
  width: 56%;
}

.txt-trofeu {
  width: 100%;
}

.conteudo-trofeu span {
  font-family: "MetronicPro-Regular" !important;
  color: #FFFFFF;
}

.info-trofeu {
  color: #706E6B !important;
  font-size: 15px !important;
  font-family: "MetronicPro-Regular" !important;
  border-bottom: none !important;
  margin-top: 20px;
}

.numero-trofeu {
  display: flex;
  gap: 20px;
}

.numero-trofeu p {
  width: 27%;
}

@media (max-width: 990px) {

  .img-video {
    width: 100%;
  }

  .proseries h1 {
    font-size: 30px;
  }
  .trofeu {
    width: 100%;
  }

  .conteudo-trofeu, .numero-trofeu {
    display: block;
  }

  .conteudo-trofeu h3 {
    font-size: 30px;
    margin-top: 30px;
  }

  .rodape {
    margin-top: 20px;
  }

  .linhadotempo {
    text-align: left;
  }

  .proseries { 
    padding-bottom: 0px;
  }

  .conteudo-trofeu p {
    font-size: 19px;
  }

  .mob {
    display: none !important;
  }

  .conteudo-tempo {
    display: block;
  }

  .box-historia {
    padding: 30px;
  }

  .conteudo-historia {
    display: block;
  }

  .box-historia h2, .box-historia p {
    width: 100% !important;
  }

  .box-historia h2 {
    justify-content: start;
  }
  .proseries .titulo-stock {
    padding-bottom: 0px;
    margin-bottom: 20px;
  }

  .container-linha {
    padding: 0px 30px;
  }

  .proseries {
    padding-bottom: 0px !important;
  }

}

@media (min-width: 991px) {
  .desk {
    display: none !important;
  }
}

@media (min-width: 991px) and (max-width: 1399px){
  .conteudo-trofeu p {
      font-size: 19px;
  }

  .info-trofeu {
    font-size: 14px !important;
  }
}

/*----------------- A STOCK CAR ------------------*/
.proseries {
  background-image: url("@/assets/img/Astock.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 100vh;
  padding-bottom: 200px;
  background-blend-mode: overlay;
  background-color: rgb(0 0 0 / 30%);
}

</style>