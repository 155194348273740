export default function calculaIdade(dataNasc) {
  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();
  const anoNascParts = dataNasc.split('/');
  const anoNasc = +anoNascParts[2];
  const mesNasc = +anoNascParts[1];
  const diaNasc = +anoNascParts[0];
  let idade = anoAtual - anoNasc;
  if (diaNasc > 31 || mesNasc > 12 || anoNasc > anoAtual || idade > 110) {
    return false
  }
  const mesAtual = dataAtual.getMonth() + 1;
  if (mesAtual < mesNasc) idade--;
  else {
    if (mesAtual == mesNasc) {
      if (new Date().getDate() < diaNasc) {
        idade--;
      }
    }
  }
  //  return idade;
  // return idade < 18 ? false : true
  return idade
}

