<template>
  <div class="grid">
    <div class="toggle-pesquisa" v-if="!finalizada">
       
        <b-collapse id="my-collapse">
          <b-card v-if="!inicio">
            <div v-if="telaUm">
              <h1>Quer ganhar prêmios? </h1>
              <p> Responda a pesquisa a seguir para destravar <strong>benefícios exclusivos.</strong></p>
              <b-button type="button" class="comecar" @click="irTelaDois">Continuar</b-button>
            </div>

            <div v-else>
              <p>Queremos te conhecer melhor e trazer conteúdos personalizados.<br/>Lembre-se, você pode parar e retomar quando quiser.<br/>Sua participação é muito importante!</p>
               <b-button type="button" class="comecar" v-on:click="iniciarPesquisa()">Começar</b-button>
            </div>  
          </b-card>

          <b-card v-else class="respostas">
              <div class="box-pesquisa" v-if="!pesquisa.faseEncerrada || proximo">
                <p class="txt-pergunta">
                 {{ pesquisa.pergunta.descricao }}      
                </p>

                <div class="alerta" v-if="alerta">
                  <p>Selecione pelo menos uma opção antes de continuar.</p>
                </div>

                <ul v-if="!pesquisa.pergunta.multiplaResposta" class="pesquisaEnriquecimento">
                  <li v-for="resposta in pesquisa.pergunta.respostas" :key="resposta.idPesquisaPerguntaResposta" @click.prevent="onSubmit(resposta)">
                    <input
                      type="radio"
                      :id="resposta.idPesquisaPerguntaResposta"
                      :value="resposta.idPesquisaPerguntaResposta"
                      name="radio_resposta" />
                    <label :for="resposta.idPesquisaPerguntaResposta">{{ resposta.descricao }}</label> 
                  </li>
                </ul>

                <ul v-else class="pesquisaEnriquecimento">
                  <li v-for="resposta in pesquisa.pergunta.respostas" :key="resposta.idPesquisaPerguntaResposta">
                    <input
                      type="checkbox"
                      :id="resposta.idPesquisaPerguntaResposta"
                      :value="resposta.idPesquisaPerguntaResposta"
                      name="checkbox_resposta" 
                      @change="onCheckboxChange"/>
                    <label :for="resposta.idPesquisaPerguntaResposta">{{ resposta.descricao }}</label> 
                  </li>
                  <button type="button" class="continuar" @click.prevent="onSubmit(respostasSelecionadas)">CONTINUAR </button>
                </ul>
              </div>

              <div v-else>
                  <div v-if="pesquisa.fase == '0'">
                    <p>Agradecemos por participar da nossa pesquisa! Continue respondendo e finalize as fases para ganhar prêmios incríveis!</p>
                    <b-button type="button" class="comecar" v-on:click="proximaPergunta()">CONTINUAR</b-button>
                  </div>

                  <div v-if="pesquisa.fase == '1'">
                    <p>Parabéns! Você completou a 1ª volta da nossa corrida. Enviamos um cupom de desconto para o seu e-mail, 
                      válido para utilização na loja Stock Store! Aproveite!</p>
                      <b-button type="button" class="comecar aproveitar" onclick="window.open('https://www.stockstoreoficial.com.br/', '_blank')">APROVEITE</b-button>
                      <b-button type="button" class="comecar" v-on:click="proximaPergunta()">CONTINUAR</b-button>
                  </div>

                  <div v-if="pesquisa.fase == '2'">
                    <p>Parabéns! Você completou a 2ª volta da nossa corrida e ganhou um voucher de desconto na Auto Service! 
                      Aguarde o contato da nossa equipe para utilização.</p>
                    <b-button type="button" class="comecar" v-on:click="proximaPergunta()">CONTINUAR</b-button>
                  </div>

                  <div v-if="pesquisa.fase == '3'">
                    <p>Parabéns! Você completou a 3ª volta da nossa corrida e ganhou um par de ingressos corridas Stock Car em SP! 
                      Aguarde o contato da nossa equipe para utilização.</p>
                    <b-button type="button" class="comecar" v-on:click="proximaPergunta()">CONTINUAR</b-button>
                  </div>

                  <div v-if="pesquisa.fase == '4'">
                    <p>Parabéns! Você completou a 4ª volta da nossa corrida e ganhou um par de ingressos para a final Stock Car em SP! 
                      Aguarde o contato da nossa equipe para utilização.</p>
                  </div>

              </div>
          </b-card>
        </b-collapse>

         <b-button v-b-toggle.my-collapse class="btn-toggle">
           <img src="@/assets/img/stockinho.png" alt="Imagem do carrinho da Stock Car" class="carrinho" />
        </b-button>
    </div>

  </div>
</template>

<script>
import { api } from "@/services.js";

export default {
  name: "PesquisaEnriquecimento",
  data() {
    return {
      proximo: false,
      pesquisa_concluida: false,
      inicio: false,
      loading: false,
      pesquisa:[],
      respostasSelecionadas: [],
      finalizada: false,
      alerta: false,
      telaUm: true,
      telaDois: false,
      telaTres: false,
      telaQuatro: false,
      telaCinco: false
    };
  },
  methods: {
    irTelaDois() {
      this.telaUm = false;
      this.telaDois = true;
    },

    irTelaTres() {
      this.telaUm = false;
      this.telaDois = false;
      this.telaTres = true;
    },

    irTelaQuatro() {
      this.telaUm = false;
      this.telaDois = false;
      this.telaTres = false;
      this.telaQuatro = true;
    },

    irTelaCinco() {
      this.telaUm = false;
      this.telaDois = false;
      this.telaTres = false;
      this.telaQuatro = false;
      this.telaCinco = true;
    },

    iniciarPesquisa(){
      this.inicio = true;
      localStorage.iniciou_pesquisa = true;
    },

    proximaPergunta() {
      this.fetchPesquisa(true);
    },

    onCheckboxChange(event) {
      const respostaId = event.target.value;

      if (event.target.checked) {
        this.respostasSelecionadas.push(respostaId);
        
      } else {
        this.respostasSelecionadas = this.respostasSelecionadas.filter(id => id !== respostaId);
      }
    },

    fetchPesquisa(proximo = false) {
      this.loading = true;
      api
        .get('PesquisaEnriquecimento/')
        .then((resp) => {
          this.pesquisa = resp.data;
          this.inicio  = localStorage.iniciou_pesquisa ? true :  false;
          this.proximo = proximo;
        })
        .catch((error) => {
          console.log(error);
          this.finalizada = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSubmit(resposta) {

     if (this.pesquisa.pergunta.multiplaResposta && this.respostasSelecionadas.length === 0) {
      this.alerta = true;
    } else {
      this.loading = true;
      this.alerta = false;

      let data;

      if (this.pesquisa.pergunta.multiplaResposta) {
        data = {
          idPesquisa: this.pesquisa.idPesquisa,
          idPesquisaPergunta: this.pesquisa.pergunta.idPesquisaPergunta,
          idRespostas: this.respostasSelecionadas
        };
      } else {
        data = {
          idPesquisa: this.pesquisa.idPesquisa,
          idPesquisaPergunta: resposta.idPesquisaPergunta,
          idRespostas: [resposta.idPesquisaPerguntaResposta],
        };
      }

      api
        .post(`PesquisaEnriquecimento`, data)
        .then((resp) => {
          this.respostasSelecionadas = [];
          this.pesquisa = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }

      
    },

  },
  mounted() {
    this.fetchPesquisa();
  },
};
</script>

<style scoped>

.carrinho {
  width: 75px;
}

.grid p {
  font-family: "MetronicPro-Bold";
  font-size: 18px;
  margin-bottom: 0px;
  border-left: 1px solid black;
  padding-left: 15px;
}

.perguntas {
  padding: 30px;
  background-color: #eabb4d;
  margin-bottom: 20px;
}

.pesquisa {
  margin-top: 50px;
  margin-bottom: 50px;
}

.pesquisa img {
  width: 600px;
  margin: 0 auto;
  display: flex;
}

.pesquisa h3 {
  font-family: "Uniwars-W00-Heavy-Italick";
  color: white;
  font-size: 32px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: -30px;
}

.pesquisa p {
  color: white;
  font-size: 16px;
  text-align: center;
  width: 550px;
  margin: 0 auto;
  margin-top: 40px;
}

ul.ks-cboxtags {
  list-style: none;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
}
ul.ks-cboxtags li {
  display: inline;
}
ul.ks-cboxtags li label {
  word-break: break-word;
  display: inline-block;
  background: transparent linear-gradient(180deg, #0f0f0f00 0%, #5e5e5e80 100%)
    0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  color: #adadad;
  border-radius: 6px;
  white-space: normal;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtags li label {
  padding: 5px 10px;
  cursor: pointer;
  width: 160px;
  text-align: center;
  display: grid;
  align-items: center;
  min-height: 90px !important;
  white-space: break-spaces;
  line-height: 15px;
  background: linear-gradient(
    0deg,
    rgba(69, 69, 69, 0.8183648459383753) 0,
    rgba(0, 0, 0, 0.8295693277310925) 50%
  );
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label,
ul.ks-cboxtags li input[type="radio"]:checked + label {
  border: 1px solid #eabb4d;
  background: #eabb4d;
  color: black;
  transition: all 0.2s;
  opacity: 1;
}

ul.ks-cboxtags li input[type="checkbox"] {
  display: absolute;
}
ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.btn {
  font-size: 16px;
  padding: 15px 30px;
  background-color: transparent;
  border-radius: 6px;
  text-decoration: none;
  border: 1px solid;
  text-align: center;
  color: var(--primary-color);
  transition: 0.3s;
  width: 160px;
  font-family: "Uniwars-W00-Heavy-Italick";
}
.btn:disabled {
  color: #666666;
}
.btn:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.btn-seguir {
  display: flex;
  justify-content: center;
  text-align: center;
}

.resp-radio .custom-control-label {
  display: inline-block;
  background: transparent linear-gradient(180deg, #0f0f0f00 0%, #5e5e5e80 100%)
    0% 0% no-repeat padding-box !important;
  border: 1px solid #707070 !important;
  color: #adadad !important;
  border-radius: 6px !important;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

.resp-radio input {
  position: absolute;
  opacity: 0;
}

@media (max-width: 989px) {

  .pesquisa img,
  .pesquisa p {
    width: 100%;
  }

  .btn-seguir,
  ul.ks-cboxtags {
    justify-content: center;
  }

  .toggle-pesquisa, .toggle-pesquisa .card-body {
    width: 100% !important;
    padding: 0px 20px;
    left: 0px;
  }

  .btn-toggle {
    font-size: 16px !important;
    width: 100px !important;
  }

  .toggle-pesquisa {
    bottom: 0px !important;
  }

  .toggle-pesquisa h1 {
    margin-top: 20px;
  }

  .comecar {
    margin-bottom: 20px;
  }

  .toggle-pesquisa p {
    margin-top: 20px;
  }
}
@media (min-width: 991px) {
  .toggle-pesquisa {
    right: 100px;
  }
}

.loading {
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-pesquisa {
  margin: 40px 0 100px;
}

.toggle-pesquisa {
  bottom: 10px;
  z-index: 9999999;
  position: fixed;
  width: 300px;
}

.toggle-pesquisa .card-body {
  background-color: #E6C156;
  border-top: 1px solid black;
  width: 300px;
  padding: 20px 20px 40px 20px;
  min-height: 350px;
}

.toggle-pesquisa .card {
  border: none;
  max-height: 70vh;
  overflow: auto;
  overflow-x: hidden;
  border-radius: 20px !important;
  background-color: #e6c156;
}

.toggle-pesquisa h1 {
  font-family: "MetronicPro-Bold" !important;
  color: #2B262A;
  font-size: 20px;
  text-transform: uppercase;
}

.toggle-pesquisa p {
  font-family: "MetronicPro-Regular";
  color: #2B262A;
  font-size: 20px;
  border-left:none;
  padding-left: 0px;
  margin-bottom: 70px;
}

.btn-toggle {
  border: none !important;
  padding: 10px 10px;
  width: 100px;
  height: 100px;
  border-radius: 50% !important;
  background-color: #E6C156 !important;
  color: black !important;
  font-family: "Uniwars-W00-SemiBold" !important;
  font-size: 15px;
  position: relative;
  top: -60px;
  right: calc(100px - 100%);
  z-index: 999999;
}

.comecar {
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    padding: 10px 0px;
    width: 100%;
    color: black;
    font-family: "MetronicPro-Regular";
}

.comecar:hover {
  color: #E6C156;
  background-color: black;
}

.continuar {
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    padding: 10px 0px;
    width: 100%;
    color: black;
    font-family: "MetronicPro-Regular";
    background-color: transparent;
    margin-top: 30px;
    margin-bottom: 30px;
}

.continuar:hover {
  color: #E6C156;
  background-color: black;
}

.txt-pergunta {
  margin-bottom: 10px !important;
}
.respostas {
  font-family: "MetronicPro-Regular";
  color: #000000;
  font-size: 16px;
}

.pesquisaEnriquecimento {
  padding-left: 0px;
}

.pesquisaEnriquecimento li {
  list-style-type: none !important;
  margin-bottom: 10px;
  display: flex;
  padding: 3px 0px;
}

.pesquisaEnriquecimento label {
  /*word-break: break-all;*/
  word-wrap: break-word;
  width: 250px;
  padding-left: 10px;
}

.aproveitar {
  margin-bottom: 20px;
}

.alerta p {
  color: #eb0000;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 15px;
}

</style>