<template>
  <div
    data-anima="top"
    v-if="notificacao?.mensagem"
    class="container-notificacao"
  >
    <b-alert
      :show="dismissCountDown"
      fade
      :variant="notificacao?.variant"
      @dismiss-count-down="countDownChanged"
    >
      <div class="content-alert">
        <div class="msg">{{ notificacao?.mensagem }}</div>
        <!-- <div>{{ dismissCountDown }} segundos...</div> -->
        <a href="#" @click.prevent="dismissCountDown = 0"> × </a>
      </div>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "Notificacao",
  props: {
    notificacao: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dismissCountDown: 10,
    };
  },
  watch: {
    dismissCountDown(v) {
      if (v < 1) {
        this.$emit("atualizaNotificacao", 0);
      }
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.dismissCountDown = this.notificacao.count_timer;
    });
  },
};
</script>

<style scoped>
.container-notificacao {
  z-index: 99999 !important;
  margin: 15px auto;
}
.content-alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-alert a {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  /* background: transparent; */
  border: none;
  font-size: 25px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  text-decoration: none;
  margin-top: -5px;
  color: initial;
  opacity: 0.5;
}
.content-alert a:hover {
  opacity: 1;
}
.alert {
  /* border-radius: 0 !important; */
  margin: 0 !important;
  z-index: 99999 !important;
}
.content-alert .msg {
  font-weight: 600;
}

@media (max-width: 989px) {
  .content-alert {
    /* flex-direction: column;
    align-items: flex-start; */
    gap: 10px;
  }
  .content-alert .msg {
    font-size: 15px;
  }
}
</style>