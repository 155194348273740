<template>
  <section>
    <b-container v-if="!loading" data-anima="top">
      <div v-if="sections.primeira && (noticia_principal || noticias.length)">
        <b-row class="box-noticia">

          <!-------------- NOTICIA PRINCIPAL HOME ----------------->
          <b-col cols="12" lg="6" v-if="$route.name != 'Novidades'">
            <div class="d-flex titulo-box-noticias">
              <h3>ÚLTIMOS DESTAQUES</h3>

            </div>

            <a :href="banners[0].link">
              <h2>{{ banners[0].titulo }}</h2>
              <img alt="Imagem da notícia" class="img-noticia img-grande" :src="banners[0].url" loading="lazy"
                width="100%" />

            </a>

          </b-col>

          <!-------------- NOTICIA PRINCIPAL NOVIDADES ----------------->
          <b-col cols="12" lg="12" v-else>

            <div class="d-flex box-destaque-novidade">

              <router-link :to="`/stockcar/${$route.params.categoria || 'proseries'}/noticia/${noticia_principal.id
                }`">

                <img loading="lazy" alt="Imagem da notícia" class="img-noticia novidade-destaque" :src="noticia_principal.imagemNoticia ||
                  noticia_principal.backgroundImagem
                  " />
              </router-link>
              <div class="titulo-box-noticias">
                <div>
                  <h3>ÚLTIMA NOTÍCIA</h3>
                  <p style="color: var(--text-color)">
                    {{
                      noticia_principal.dataPublicacao
                        ? noticia_principal.dataPublicacao.split(" ")[0]
                        : ""
                    }}
                  </p>
                </div>

                <h2>{{ noticia_principal.titulo }}</h2>

              </div>

            </div>

          </b-col>

          <!-------------- NOTICIAS LATERAIS HOME ----------------->
          <b-col v-if="$route.name != 'Novidades'" cols="12" lg="6" class="coluna-noticia">
            <a v-for="item in banners.slice(1)" :key="item.id" :href="item.link">

              <div class="d-flex noticias-direita">
                <img alt="imagem da notícia" class="img-noticia img-lateral" :src="item.url" loading="lazy"
                  width="235" />

                <div class="info-noticias-laterais">
                  <h2>{{ item.titulo }}</h2>
                </div>
              </div>

            </a>

            <div class="link-router" v-if="
              $route.params.categoria == 'formula-4' &&
              $route.name != 'Novidades' &&
              $route.name != 'DetalhePiloto'
            ">
              <router-link :to="`/stockcar/${$route.params.categoria}/novidade`" class="btn-stock">{{
                $t("button.saiba_mais") }}</router-link>
            </div>

            <div class="link-router" v-else-if="$route.name != 'Novidades' && $route.name != 'DetalhePiloto'">
              <router-link :to="`/stockcar/${$route.params.categoria}/novidade`" class="btn-stock">Todas as
                notícias</router-link>
            </div>
          </b-col>

          <!-------------- NOTICIAS LATERAIS NOVIDADES ----------------->
          <b-col v-else cols="12" lg="6" class="coluna-noticia" v-for="item in noticias" :key="item.id">
            <router-link :to="`/stockcar/${$route.params.categoria || 'proseries'
              }/noticia/${item.id}`">

              <div class="d-flex noticias-direita img-novidades">
                <img alt="imagem da notícia" class="img-noticia" :src="item.imagemNoticia || item.imagemGaleria"
                  loading="lazy" />

                <div>
                  <p>
                    {{
                      item.dataPublicacao ? item.dataPublicacao.split(" ")[0] : ""
                    }}
                  </p>

                  <h2>{{ item.titulo }}</h2>
                </div>
              </div>

            </router-link>

            <div class="link-router" v-if="
              $route.params.categoria == 'formula-4' &&
              $route.name != 'Novidades' &&
              $route.name != 'DetalhePiloto'
            ">
              <router-link :to="`/stockcar/${$route.params.categoria}/novidade`" class="btn-stock">{{
                $t("button.saiba_mais") }}</router-link>
            </div>

            <div class="link-router" v-else-if="$route.name != 'Novidades' && $route.name != 'DetalhePiloto'">
              <router-link :to="`/stockcar/${$route.params.categoria}/novidade`" class="btn-stock">Todas as
                notícias</router-link>
            </div>
          </b-col>
        </b-row>
      </div>



      <div class="link-router" v-if="$route.name == 'Novidades' && !disabledBtn">
        <a v-if="$route.params.categoria == 'formula-4'" href="#" class="btn-stock carregar" name="carregar-mais"
          @click.prevent="getProximaPagina">{{ $t("button.carregar-mais") }}</a>
        <a v-else href="#" class="btn-stock carregar" name="carregar-mais" @click.prevent="getProximaPagina">Carregar
          mais</a>
      </div>

      <Notificacao :notificacao="notificacao_infos" v-if="notificacao_infos.count_timer"
        @atualizaNotificacao="notificacao_infos.count_timer = 0" />
    </b-container>

    <b-container v-if="loading" data-anima="bottom">
      <div>
        <b-row class="box-noticia">
          <b-col cols="12" lg="7" class="box-img">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
          <b-col cols="12" lg="5" class="box-texto">
            <div class="linha-noticia"></div>
            <h2><b-skeleton animation="wave" width="70%"></b-skeleton></h2>
            <p><b-skeleton animation="wave" width="20%"></b-skeleton></p>
            <p>
              <b-skeleton animation="wave" width="40%"></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="80%"></b-skeleton>
            </p>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-row class="boxes-noticias">
          <b-col cols="12" lg="4" class="coluna-noticia" data-anima="bottom">
            <p><b-skeleton animation="wave" width="20%"></b-skeleton></p>
            <b-skeleton-img></b-skeleton-img>
            <h2><b-skeleton animation="wave" width="70%"></b-skeleton></h2>
          </b-col>
          <b-col cols="12" lg="4" class="coluna-noticia" data-anima="bottom">
            <p><b-skeleton animation="wave" width="20%"></b-skeleton></p>
            <b-skeleton-img></b-skeleton-img>
            <h2><b-skeleton animation="wave" width="70%"></b-skeleton></h2>
          </b-col>
          <b-col cols="12" lg="4" class="coluna-noticia" data-anima="bottom">
            <p><b-skeleton animation="wave" width="20%"></b-skeleton></p>
            <b-skeleton-img></b-skeleton-img>
            <h2><b-skeleton animation="wave" width="70%"></b-skeleton></h2>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";
import { EventBus } from "@/main.js";

export default {
  name: "Noticias",
  data() {
    return {
      banners: [],
      disabledBtn: false,
      paginacao: {
        tamanhoPagina: 10,
        numeroPagina: 0,
      },
      categoriaPesquisa: "",
      loading: false,
      noticias: [],
      noticia_principal: null,
      noticia_quatro: null,
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
    };
  },
  props: {
    sections: {
      type: Object,
      default: () => { },
    },
    noticias_piloto: {
      type: Array,
      default: () => [],
    },
    pesquisa: {
      type: String,
      default: "",
    },
    ano_selecionado: {
      type: [String][Number],
      default: new Date().getFullYear().toString(),
    },
  },
  filters: {
    dateFormat(date) {
      return new Date(date).toLocaleDateString();
    },
  },

  methods: {

    fetchBanners(categoria_id) {
      this.loading = true;
      api
        .get(`/Banner/categoria/${categoria_id}`)
        .then((resp) => {
          this.slide = 0;
          this.banners = resp.data.banners;
          console.log(this.banners);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    quebraTexto(texto) {
      if (this.$route.params.categoria == "formula-4") {
        return (
          texto.substr(0, 200) +
          `... <br> <br> <p>${this.$t("button.ver-mais")}</p>`
        );
      } else {
        return texto.substr(0, 200) + "... <br> <br> Ver mais";
      }
    },
    getProximaPagina() {
      let page = {
        tamanhoPagina: 12,
        numeroPagina: this.paginacao.numeroPagina + 1,
      };

      this.fetchNoticias(
        mapCategorias[this.$route.params.categoria || "proseries"],
        page
      );
    },
    checkRoute(categoria) {
      let page = {
        tamanhoPagina: 10,
        numeroPagina: 1,
      };
      switch (this.$route.name) {
        case "home": {
          page = {
            tamanhoPagina: 5,
            numeroPagina: 1,
          };
          break;
        }
        case "Novidades":
        case "DetalhePiloto": {
          page = {
            tamanhoPagina: 12,
            numeroPagina: 1,
          };
          break;
        }
      }
      this.noticias = [];
      this.fetchNoticias(mapCategorias[categoria || "proseries"], page);
    },
    fetchNoticias(id_categoria, page) {
      this.categoriaPesquisa = id_categoria;
      if (id_categoria) {
        const ano_filtro =
          this.ano_selecionado && this.$route.name == "Novidades"
            ? `&ano=${this.ano_selecionado}`
            : "";
        if (!this.noticias_piloto.length) {
          const query = `?tamanhoPagina=${page.tamanhoPagina}&numeroPagina=${page.numeroPagina}&busca=${this.pesquisa}${ano_filtro}`;
          this.loading = true;
          api
            .get(`/Noticias/categoria/${id_categoria}${query}`)
            .then((resp) => {
              if (resp.data.resultado.length) {
                this.disabledBtn = false;
                resp.data.resultado.forEach((item) => {
                  this.noticias.push(item);
                });
                const noticias_array = JSON.parse(JSON.stringify(this.noticias[0]));

                this.noticia_principal = noticias_array;
                if (this.$route.name == "Home") {
                  this.noticia_quatro = this.noticias[4];

                  this.noticias = this.noticias.filter((item, index) => {
                    if (index < 4) {
                      return {
                        ...item,
                      };
                    }
                  });
                }

                if (this.noticias.length >= 1) {
                  this.noticias.splice(0, 1);
                }

                this.paginacao = {
                  tamanhoPagina: resp.data.tamanhoPagina,
                  numeroPagina: resp.data.numeroPagina,
                };
                this.$emit("verificaNoticia", this.noticias.length);
              } else {
                this.notificacao_infos = {
                  variant: "primary",
                  mensagem:
                    "Nenhuma noticia encontrada, por favor faça outra pesquisa. ",
                  count_timer: 10000,
                };
                this.disabledBtn = true;
              }

              if (resp.data.totalPaginas <= 1) {
                this.disabledBtn = true;
              }
            })
            .catch(() => {
              this.notificacao_infos = {
                variant: "primary",
                mensagem:
                  "Nenhuma noticia encontrada, por favor faça outra pesquisa.",
                count_timer: 10000,
              };
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.noticias = this.noticias_piloto;
          this.noticia_principal = this.noticias[0];
          if (this.noticias.length >= 1) {
            this.noticias.splice(0, 1);
          }
        }
      }
    },
  },

  watch: {
    pesquisa: function () {
      // watch it
      let page = {
        tamanhoPagina: 10,
        numeroPagina: 1,
      };
      let id_categoria = this.categoriaPesquisa;
      this.noticias = [];
      this.fetchNoticias(id_categoria, page);
    },
    ano_selecionado() {
      let page = {
        tamanhoPagina: 10,
        numeroPagina: 1,
      };
      this.noticias = [];
      this.fetchNoticias(this.categoriaPesquisa, page);
    },
  },

  mounted() {
    this.checkRoute(this.$route.params.categoria);
    this.fetchBanners(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
  },

  created() {
    EventBus.$on("updateRouter", (data) => {
      this.checkRoute(data);
    });
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}

.info-noticias-laterais {
  display: grid;
  align-items: baseline;
}

.box-conferir {
  display: flex;
  margin-top: 20px;
  gap: 15px;
  align-items: center;
}

.box-conferir-lateral {
  display: flex;
  margin-top: 20px;
  gap: 15px;
  align-items: center;
}

.box-conferir-lateral img {
  margin-top: 5px !important;
  width: 20px !important;
  border: none !important;
  height: auto !important;
}

.box-conferir img {
  margin-top: -7px !important;
  width: 22px !important;
  border: none !important;
}

.conferir-lateral {
  color: var(--title-color) !important;
  font-size: 20px !important;
  text-decoration: underline !important;
  margin-bottom: 0px !important;
}

.conferir {
  color: var(--title-color) !important;
  font-size: 28px !important;
  text-decoration: underline !important;
}

.container-novidades .boxes-noticias .coluna-noticia {
  border-right: none;
  border-left: none;
}

.container-novidades .titulo-novidade {
  text-align: center;
  padding-top: 80px;
}

.img-noticia {
  width: 100%;
}

.box-img {
  padding-right: 0px !important;
  padding-left: 0px !important;
  align-items: center;
  display: flex;
}

.box-texto {
  padding: 40px !important;
  background-color: var(--primary-color);
}

.titulo-box-noticias {
  align-items: baseline;
  gap: 30px;
  margin-bottom: 10px;
}

.titulo-box-noticias p {
  margin-bottom: 0px;
  color: #707070 !important;
  font-size: 17px !important;
}

.box-noticia img {
  border: 1px solid var(--primary-color);
}

.box-destaque-novidade {
  gap: 20px;
}

.novidade-destaque {
  width: 500px;
  height: 300px !important;
  object-fit: cover !important;

}

.img-novidades img {
  height: 200px;
  width: 300px;
}

.box-noticia {
  padding-top: 40px;
  padding-bottom: 0px;
}

.box-noticia h3 {
  margin-bottom: 0px;
  color: #CFCFCF;
  font-size: 18px;
}

.titulo-f4 {
  color: #000 !important;
}

.box-noticia h2 {
  font-size: 28px;
  color: var(--primary-color);
  ;
}

.box-texto h2 {
  margin-bottom: 40px;
  font-family: "MetronicPro-Bold";
  font-size: 30px;
  line-height: 36px;
  color: var(--text-color);
}

.box-texto a,
.box-texto a:hover {
  color: var(--text-color);
}

.boxes-noticias {
  padding-top: 30px;
  padding-bottom: 30px;
}

.boxes-noticias p {
  font: normal normal 300 17px/25px MetronicPro-Light;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 30px;
}

.boxes-noticias h2 {
  font: normal normal bold 22px/26px MetronicPro-Bold;
  letter-spacing: 0px;
  margin: 17px 0;
  color: var(--primary-color);
}

.boxes-noticias a,
.boxes-noticias a:hover {
  color: var(--primary-color);
}

.boxes-noticias img {
  margin-bottom: 10px;
  border: 1px solid var(--primary-color);
  min-height: 150px;
  min-width: 100%;
}

.noticias-direita {
  gap: 20px;
  margin-bottom: 30px !important;
}

.noticias-direita p {
  margin-bottom: 30px;
  color: #707070;
  font-size: 17px;
}

.noticias-direita h2 {
  font-size: 22px;
}

.boxes-noticias .coluna-noticia {
  padding-right: 50px;
  border-right: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  padding-left: 50px;
}

.coluna-noticia.col-lg-6.col-12 {
  padding-top: 40px !important;
}

.img-lateral {
  width: 235px !important;
  height: 157px !important;
  height: auto;
  object-fit: cover;
}

.link-router {
  display: flex;
  margin-top: 40px;
}

.img-grande {
  height: 560px !important;
  height: 100%;
  object-fit: cover;
}

.carregar {
  margin: 0 auto !important;
}

.box-destaque-novidade h2 {
  margin-top: 30px;
}

@media (max-width: 990px) {

  .box-destaque-novidade {
    display: block !important;
  }

  .box-destaque-novidade h2 {
    margin-bottom: 40px !important;
    margin-top: 10px;
  }


  .novidade-destaque {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    margin-bottom: 20px !important;
  }

  .titulo-box-noticias {
    justify-content: space-between !important;
  }

  .box-noticia .col-lg-12.col-12 {
    padding: 0px !important;
  }

  .coluna-noticia img {
    width: 188px;
  }

  .box-conferir-lateral img {
    margin-top: -2px !important;
  }

  .img-lateral {
    width: 200px !important;
  }

  .box-conferir {
    display: flex;
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .noticias-direita p {
    margin-bottom: 10px !important;
  }

  .img-grande {
    height: auto !important;
    margin-bottom: 20px;
  }

  .box-noticia h2 {
    line-height: 24px;
    font-size: 22px;
  }

  .noticias-direita h2 {
    font-size: 18px !important;
    line-height: 21px !important;
  }

  .noticias .col-lg-6.col-12,
  .noticias .container,
  .bv-row.noticias.container {
    padding: 0px !important;
  }

  .box-noticia {
    padding-top: 0px;
  }

  .btn-stock {
    padding: 10px 30px !important;
    font-size: 20px;
    margin: 0 auto;
  }
}
</style>
