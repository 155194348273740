<template>
  <section>
    <div class="container-stockcar margin-row overlay-pagina" v-if="item">
      <b-row class="titulo-stock">
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 class="titulo">{{ item.titulo }}</h1>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>

      <b-container>
        <div class="txt-stockcar">
          <div v-html="item.texto"></div>
        </div>
      </b-container>
    </div>
  </section>
</template>

<script>
import { mapCategorias } from "@/helpers.js";

export default {
  name: "PoliticaPrivacidade",

  data() {
    return {
      loading: false,
      item: null,
    };
  },
  methods: {
    fetchFooter(id_categoria) {
      this.loading = true;
      this.item = null;

      this.$store
        .dispatch("getFooter", id_categoria)
        .then((resp) => {
          this.item = resp.itemsRodape;
          this.item = this.item.find(
            (i) => i.pagina == "Políticas de Privacidade"
          );
          this.alterBg();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    alterBg() {
      setTimeout(() => {
        const bg = document.querySelector(".container-stockcar");
        bg.style.backgroundImage = `url('${this.item.backgroudImagem}')`;
      }, 100);
    },
  },
  mounted() {
    this.item = this.$store.state.footer
      ? this.$store.state.footer
      : sessionStorage.footer
      ? JSON.parse(sessionStorage.footer)
      : "";

    if (this.item) {
      this.item = this.item.find((i) => i.pagina == "Políticas de Privacidade");
      this.alterBg();
    } else {
      this.fetchFooter(
        mapCategorias[this.$route.params.categoria || "proseries"]
      );
    }
  },
};
</script>

<style scoped>
</style>