<template>
  <div>
      <TodasEquipes />
  </div>
</template>

<script>
import TodasEquipes from '@/components/TodasEquipes.vue';
export default {
  components: {
    TodasEquipes
  }
};
</script>

