<template>
  <div class="bg-recuperacao-senha">
  
    <b-container v-if="!loading && !success">
      <b-row>
        <b-col>
          <h4 class="heavy-italic">ATUALIZAÇÃO DE SENHA</h4>
          <!-- <div v-if="msgErro.show" class="msgErro">
            {{ msgErro.msg }}
          </div> -->


          <Notificacao
            :notificacao="notificacao_infos"
            v-if="notificacao_infos.count_timer"
            @atualizaNotificacao="notificacao_infos.count_timer = 0"
          />

          <b-form @submit.prevent="resetPassword" class="form-senha">
            <b-form-group class="form-redefinir">
              <div class="senha-item">
                <b-form-input
                  type="password"
                  id="oldPassword"
                  aria-describedby="password-help-block"
                  placeholder="Senha atual"
                  required
                  v-model="form.oldPassword"
                  v-on:input="verificarInput"
                >
                </b-form-input>
                <img
                  src="@/assets/icons/eye.svg"
                  alt="Mostrar senha"
                  class="icon-eye"
                  @click="mostraSenha('oldPassword', 'text')"
                  v-if="!show_password.oldPassword"
                />
                <img
                  v-else
                  src="@/assets/icons/eye-off.svg"
                  alt="Ocultar senha"
                  class="icon-eye"
                  @click="mostraSenha('oldPassword', 'password')"
                />
              </div>

              <div class="senha-item">
                <b-form-input
                  type="password"
                  id="senha"
                  aria-describedby="password-help-block"
                  placeholder="Nova senha"
                  required
                  v-model="form.newPassword"
                  v-on:input="verificarInput"
                >
                </b-form-input>
                <img
                  src="@/assets/icons/eye.svg"
                  alt="Mostrar senha"
                  class="icon-eye"
                  @click="mostraSenha('newPassword', 'text')"
                  v-if="!show_password.newPassword"
                />
                <img
                  v-else
                  src="@/assets/icons/eye-off.svg"
                  alt="Ocultar senha"
                  class="icon-eye"
                  @click="mostraSenha('newPassword', 'password')"
                />
              </div>
              <div class="senha-item">
                <b-form-input
                  id="senhaConfirma"
                  :class="{
                    error:
                      form.newPassword != form.confirmNewPassword ||
                      errors.password,
                  }"
                  type="password"
                  placeholder="Confirme a nova senha"
                  required
                  v-model="form.confirmNewPassword"
                  onpaste="return false"
                  ondrop="return false"
                  autocomplete="senhaConfirma-"
                ></b-form-input>
                <img
                  src="@/assets/icons/eye.svg"
                  alt="Mostrar senha"
                  class="icon-eye"
                  @click="mostraSenha('confirmNewPassword', 'text')"
                  v-if="!show_password.confirmNewPassword"
                />
                <img
                  v-else
                  src="@/assets/icons/eye-off.svg"
                  alt="Ocultar senha"
                  class="icon-eye"
                  @click="mostraSenha('confirmNewPassword', 'password')"
                />
              </div>

              <b-button
                type="submit"
                class="redefinir btn"
                :disabled="
                  form.newPassword != form.confirmNewPassword ||
                  !requisitoSenha ||
                  !form.oldPassword
                "
                >REDEFINIR</b-button
              >

              <span
                v-if="
                  form.oldPassword &&
                  form.newPassword != form.confirmNewPassword
                "
                class="feedback"
              >
                - Ambos campos de senha devem ser iguais</span
              >
              <span
                v-if="form.oldPassword && form.newPassword && !requisitoSenha"
                class="feedback"
              >
                - Senhas não atendem os requisitos obrigatórios</span
              >
            </b-form-group>
          </b-form>
        </b-col>
        <div class="validacao-senha">
          <PasswordValidation
            :password="form.newPassword"
            @senhaOk="validationSenha"
          />
        </div>
      </b-row>
    </b-container>

    <b-container v-if="!loading && success">
      <b-row>
        <b-col>
          <h4>Senha redefinida com sucesso!</h4>
          <p>
            Acabamos de redefinir a sua senha. <br />Clique no botão abaixo para
            refazer o login
          </p>
          <a
            href="#"
            @click.prevent="deslogar"
            style="text-decoration: none; color: inherit"
            ><b-button type="submit" class="login btn"
              >REFAZER LOGIN</b-button
            ></a
          >
        </b-col>
      </b-row>
    </b-container>

    <div v-if="loading" class="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import PasswordValidation from "./PasswordValidation.vue";

export default {
  name: "RedefinirSenha",
  components: {
    PasswordValidation,
  },
  data() {
    return {
      loading: false,
      requisitoSenha: false,
      msgErro: {
        show: false,
        msg: "",
      },
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
      success: false,
      form: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      errors: {
        senha: false,
      },
      show_password: {
        oldPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      },
    };
  },
  methods: {
    mostraSenha(el, type) {
      this.show_password[el] = !this.show_password[el];
      document.getElementById(el).setAttribute("type", type);
    },
    deslogar() {
      this.$store.dispatch("deslogarUsuario");
      this.$router.push("/stockclub/login");
    },
    resetPassword() {
      this.msgErro = {
        show: false,
        msg: "",
      };
      this.loading = true;

      api
        .post("/Registro/atualizar-senha", this.form)
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.deslogar();
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          this.notificacao_infos = {
            variant: "danger",
            mensagem: error.response
              ? error.response.data
              : "Algo deu errado. Verifique se a senha atual está correta e tente novamente",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validationSenha(data) {
      this.requisitoSenha = data;
    },

    verificarInput() {
      if (this.password === "") {
        this.requisitoSenha = false;
      }
    },
  },
};
</script>

<style scoped>
.bg-recuperacao-senha h4 {
  font-size: 25px;
  line-height: 27px;
  color: var(--title-color);
  margin-bottom: 50px;
  text-align: center;
  width: 600px;
  margin: 0 auto;
  margin-top: 100px;
}

.heavy-italic {
  font-family: "Uniwars-W00-Heavy-Italick" !important;
}

.bg-recuperacao-senha p {
  font-size: 16px;
  color: var(--title-color);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.login {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.login:hover {
  color: var(--primary-color) !important;
  border-color: var(--title-color) !important;
  background-color: var(--title-color) !important;
}

.btn {
  width: 400px;
  margin: 0 auto;
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

#div-true h4 {
  font-family: "Uniwars-W00-Heavy-Italick";
  margin-bottom: 30px;
}

.redefinir {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.redefinir:hover {
  color: var(--primary-color);
  border-color: var(--title-color);
  background-color: var(--title-color);
}

.form-control {
  color: var(--title-color);
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  margin-block: 20px;
  width: 400px;
}

input::placeholder {
  color: var(--primary-color);
}

.form-redefinir {
  max-width: 400px;
  margin: 0 auto;
}
.form-senha div {
  max-width: 100%;
}
.validacao-senha {
  margin-top: 50px;
}

input.error,
textarea.error,
select.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}

input.error:hover,
input.error:focus,
textarea.error:hover,
textarea.error:focus,
select.error:hover,
select.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}

.msgErro {
  color: #ff1717 !important;
  text-align: center;
  margin-top: 50px;
}

.loading {
  min-height: 50vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 989px) {
  form {
    margin-top: 40px;
  }
  .password_strength {
    display: grid;
    max-width: 400px;
    margin: 0 auto;
    gap: 10px;
  }

  .btn {
    width: 100%;
  }

  .bg-recuperacao-senha h4 {
    font-size: 20px;
    margin-top: 70px;
    width: 100%;
  }

  .bg-recuperacao-senha p {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .bg-recuperacao-senha {
    height: auto;
  }

  .form-control {
    width: 300px;
  }
}
.senha-item {
  position: relative;
}
.icon-eye {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.form-control {
  width: 100%;
}
.feedback {
  color: #fff;
  margin-top: 20px;
  display: block;
  text-align: center;
  font-size: 13px;
}
</style>