<template>
    <div>
        <EditarPerfil />
    </div>
</template>

<script>
import EditarPerfil from "@/components/EditarPerfil.vue";
export default {
  name: "MeuPerfil",
  components: {
    EditarPerfil
  }
};
</script>