<template>
  <div>
    <b-container v-if="!loading">
      <b-row>
        <b-col cols="12" lg="12">
          <router-link to="/stockclub/home" class="voltar">voltar</router-link>
        </b-col>

        <b-col cols="12" lg="4" class="titulo-edicao">
          <h2>DADOS <strong>BÁSICOS</strong></h2>

          <Notificacao
            :notificacao="notificacao_infos"
            v-if="notificacao_infos.count_timer"
            @atualizaNotificacao="notificacao_infos.count_timer = 0"
          />

          <div class="avatar-wrapper">
            <label for="UploadedFile" class="avatar" style="cursor: pointer">
              <div class="avatar">
                <img
                  v-if="!foto_usuario"
                  class="profile-pic"
                  src="@/assets/img/foto-default.jpg"
                  alt="Imagem do usuário"
                />
                <img
                  v-else
                  class="profile-pic"
                  :src="foto_usuario"
                  alt="Imagem do usuário"
                />
              </div>
            </label>

            <div class="upload-button">
              <label for="UploadedFile">
                <img
                  class="pen"
                  src="@/assets/img/editar.png"
                  alt="Imagem de caneta para editar foto de perfil"
                />
              </label>

              <input
                class="file-upload"
                name="UploadedFile"
                id="UploadedFile"
                type="file"
                ref="foto"
                accept="image/*"
                @change="updateFoto"
              />
            </div>
          </div>
        </b-col>

        <b-col cols="12" lg="8"></b-col>

        <b-form v-if="usuario" data-anima="bottom" @submit.prevent="onSubmit">
          <b-row class="fields">
            <b-col cols="12" lg="6">
              <b-form-group id="nome" label-for="nome">
                <input-with-label
                  id="nome"
                  label="Nome"
                  v-model="usuario.name"
                  input-class="my-input-class menu-input"
                  label-class-custom="my-label-class"
                  required
                  :disabled="focus_phone"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group id="sobrenome" label-for="sobrenome">
                <input-with-label
                  id="sobrenome"
                  label="Sobrenome"
                  v-model="usuario.sobrenome"
                  input-class="my-input-class menu-input"
                  label-class-custom="my-label-class"
                  required
                  :disabled="focus_phone"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group id="cpf" label-for="cpf">
                <input-with-label-mask
                  id="cpf"
                  label="CPF"
                  type="tel"
                  name="cpf"
                  placeholder="CPF: ###.###.###-#"
                  v-model="usuario.cpf"
                  :input-class="{
                    'my-input-class-mask': true,
                    'form-control': true,
                    'menu-input': true,
                  }"
                  :mask="['###.###.###-##']"
                  autocomplete="off"
                  :disabled="focus_phone"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group id="email" label-for="email">
                <input-with-label
                  id="email"
                  label="Email"
                  type="email"
                  v-model="usuario.email"
                  :input-class="{
                    'my-input-class': true,
                    'form-control': true,
                    'menu-input': true,
                    error: hasPlusSign,
                  }"
                  label-class-custom="my-label-class"
                  required
                  :disabled="focus_phone"
                />
              </b-form-group>
            </b-col>

           
           <b-col cols="12" lg="6">
              <b-form-group id="celular" label-for="celular">
                <!-- <input-with-label-mask
                  id="celular"
                  label="Celular"
                  type="tel"
                  name="celular"
                  v-model="usuario.phone"
                  :input-class="{
                    error: focus_phone,
                    success: telefone_ok,
                    'my-input-class-mask': true,
                    'form-control': true,
                    'menu-input': true,
                  }"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  autocomplete="off"
                  minlength="10"
                  required
                /> -->
                <vue-tel-input
                  :inputOptions="inputTelOptions"
                  :preferredCountries="['BR', 'AR', 'US', 'ES']"
                  @input="telValidate"
                  v-model="usuario.phone"
                  id="celular"
                  label="Celular*"
                  type="tel"
                  name="celular"
                  class="form-control"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  autocomplete="off"
                  minlength="10"
                  required
                ></vue-tel-input>
                <span
                  v-if="(focus_phone && !solicita_token) || telefone_ok"
                  class="msgErro notify-celular"
                  :class="{ success: telefone_ok }"
                  >{{
                    telefone_ok
                      ? "Telefone validado com sucesso ✔️"
                      : "Atualize os dados ou ative o celular para receber o token"
                  }}
                </span>
              </b-form-group>
            </b-col>


            <b-col cols="12" lg="6">
              <b-form-group id="datanasc" label-for="datanasc">
                <input-with-label-mask
                  id="datanasc"
                  label="Data de Nascimento"
                  name="datanasc"
                  type="tel"
                  placeholder=""
                  :value="usuario.dataNascimento | dateFormat"
                  :input-class="{
                    'my-input-class-mask': true,
                    'form-control': true,
                    'menu-input': true,
                  }"
                  :mask="['##/##/####']"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group id="cep" label-for="cep">
                <!-- <the-mask
                  :mask="'#####-###'"
                  :masked="false"
                  type="tel"
                  name="cep"
                  id="cep"
                  class="form-control"
                  v-model="usuario.cep"
                  placeholder="CEP: #####-###"
                  required
                  @keyup.native="consultaCep"
                /> -->
                <input-with-label-mask
                  id="cep"
                  label="CEP"
                  type="tel"
                  name="cep"
                  @keyup.native="consultaCep"
                  v-model="usuario.cep"
                  :input-class="{
                    'my-input-class-mask': true,
                    'form-control': true,
                    'menu-input': true,
                  }"
                  :mask="'#####-###'"
                  autocomplete="off"
                  :disabledMasked="true"
                  required
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6" class="d-none d-lg-block">
              <!-- <b-form-group id="uf" label-for="uf">
            
                <input-with-label 
                  id="uf" 
                  label="Cidade, Estado"
                  type="text"
                  :value="endereco_infos"
                  :input-class="{
                    'my-input-class': true,
                    'form-control' : true,
                    'menu-input' : true,
                  }" 
                  label-class-custom="my-label-class"
                  required 
                  disabled
                />
              </b-form-group> -->
            </b-col>

            <b-col>
              <div v-if="solicita_token" class="solicita_token">
                <p class="my-4">
                  Você recebeu um token de verificação por SMS no celular
                  {{ telefone_usuario }}.<br />
                  Insira o código nos campos abaixo:
                </p>

                <div class="codigo">
                  <div v-for="(v, index) in inputs" :key="index">
                    <b-form-group
                      :id="`codigo-${index}`"
                      :label-for="`codigo-${index}`"
                    >
                      <b-form-input
                        :id="`input-${index}`"
                        :name="`input-${index}`"
                        :class="{ error: msgErro.show && !success }"
                        v-model="codigo[index]"
                        type="text"
                        autocomplete="off"
                        required
                        maxlength="1"
                        minlength="1"
                        @keyup="(e) => nextInput(e)"
                      >
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col cols="12" lg="12">
              <div class="btn-avancar" style="margin-top: 0">
                <div class="d-lg-flex">
                  <!-- <b-button
                    :disabled="hasPlusSign"
                    type="submit"
                    class="acessar btn btn-ativar mb-sm-4 mb-lg-0"
                    variant="primary"
                    v-if="
                      !solicita_token &&
                      focus_phone &&
                      usuario.phone == celular_antigo
                    "
                    @click.prevent="reenviarToken"
                    >ATIVAR CELULAR</b-button
                  > -->

                  <b-button
                    :disabled="hasPlusSign"
                    type="submit"
                    class="acessar btn"
                    :class="{ 'btn-salvar': !focus_phone }"
                    >SALVAR</b-button
                  >
                  <!-- v-if="!solicita_token" -->
                  <!-- {{ focus_phone ? "EDITAR CELULAR" : "SALVAR" }} -->

                  <!-- <div class="timer-token" v-if="solicita_token">
                    <p class="destaque" v-if="timer_token > 0" data-anima="top">
                      <b-spinner
                        style="
                          width: 1rem;
                          height: 1rem;
                          border: 2px solid #e6c156;
                          border-right-color: transparent;
                          --bs-spinner-animation-speed: 1s;
                          margin-right: 10px;
                        "
                        label="Large Spinner"
                        variant="warning"
                      ></b-spinner
                      >Aguarde {{ timer_token < 10 ? "0" : ""
                      }}{{ timer_token }} segundos para solicitar o reenvio do
                      Token
                    </p>
                    <a
                      data-anima="bottom"
                      v-if="timer_token < 1"
                      href="#"
                      style="
                        text-align: center;
                        margin: 0px auto 20px;
                        color: rgb(230, 193, 86);
                        background: #e6c15670;
                        padding: 10px 25px;
                        border-radius: 50px;
                        text-decoration: none;
                      "
                      @click.prevent="reenviarToken"
                      >Reenviar token</a
                    >
                  </div> -->
                </div>

                <!-- <b-button
                  type="submit"
                  class="acessar btn"
                  v-if="solicita_token"
                  @click.prevent="confirmarToken"
                  >VALIDAR TOKEN</b-button
                > -->
              </div>
            </b-col>
          </b-row>

          <div v-if="!focus_phone">
            <b-row>
              <b-col cols="12" lg="4" class="titulo-edicao">
                <h2>PILOTO <strong>FAVORITO</strong></h2>
                <Notificacao
                  :notificacao="notificacao_infos"
                  v-if="notificacao_infos.count_timer"
                  @atualizaNotificacao="notificacao_infos.count_timer = 0"
                />
              </b-col>

              <b-col cols="12" lg="8"></b-col>
            </b-row>

            <div>
              <b-row class="fields">
                <b-col cols="12" lg="12">
                  <img
                    class="mt-5 piloto-favorito"
                    :src="selecionado_favorito.imagem"
                    :alt="selecionado_favorito.nome"
                    v-if="selecionado_favorito"
                  />

                  <div class="select-piloto">
                    <b-form-select
                      class="select form-control"
                      v-model="piloto_selecionado"
                      :options="pilotos_options"
                      :disabled="loading"
                      required
                      @change="selectPiloto"
                    ></b-form-select>
                  </div>
                </b-col>

                <!-- <b-col cols="12" lg="6">
                <div class="select-piloto">
                  <b-form-select
                    class="select form-control"
                    v-model="selected2"
                    :options="options2"
                  ></b-form-select>
                  <p>*você pode selecionar mais de um piloto</p>
                </div>

                <div id="outros-pilotos">
                  <div class="pilotosSelecionados">
                    <p>Rodrigo Baptista</p>
                    <img src="@/assets/img/cross.png" alt="ícone de excluir" />
                  </div>

                  <div class="pilotosSelecionados">
                    <p>Lucas Foresti</p>
                    <img src="@/assets/img/cross.png" alt="ícone de excluir" />
                  </div>
                </div>
              </b-col> -->

                <b-col cols="12" lg="12">
                  <div class="btn-avancar">
                    <b-button type="submit" class="acessar btn btn-salvar"
                      >SALVAR</b-button
                    >
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" lg="4" class="titulo-edicao">
                  <h2>MINHAS <strong>CONFIRGURAÇÕES</strong></h2>
                </b-col>

                <b-col cols="12" lg="8"></b-col>

                <b-col cols="12" lg="12">
                  <div class="btn-avancar">
                    <router-link
                      to="/stockclub/atualizar-senha"
                      style="text-decoration: none"
                    >
                      <b-button type="submit" class="acessar btn btn-salvar"
                        >ALTERAR SENHA</b-button
                      >
                    </router-link>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-row>
    </b-container>

    <b-container v-if="loading">
      <div class="loading">
        <b-spinner
          style="
            width: 3rem;
            height: 3rem;
            border-color: #e6c156;
            border-right-color: transparent;
            --bs-spinner-animation-speed: 0.5s;
          "
          label="Large Spinner"
          variant="warning"
        ></b-spinner>
      </div>
    </b-container>
  </div>
</template>


<script>
// import { TheMask } from "vue-the-mask";
import InputWithLabel from "@/components/InputWithLabel.vue";
import InputWithLabelMask from "@/components/InputWithLabelMask.vue";

import { api } from "@/services.js";
import { getCep } from "@/services.js";
import { VueTelInput } from "vue-tel-input";
export default {
  name: "EditarPerfil",
  components: {
    // TheMask,
    InputWithLabel,
    InputWithLabelMask,
    VueTelInput
  },
  data() {
    return {
      inputs: 4,
      codigo_reenviado: false,
      solicita_token: false,
      telefone_ok: false,
      codigo: [],
      telefone_usuario: "",
      loading: false,
      focus_phone: false,
      celular_antigo: "",
      email_antigo: "",
      usuario: {
        cpf: "",
        dataNascimento: "",
        email: "",
        name: "",
        phone: "",
        sobrenome: "",
        pilotoFavorito: {
          id: -1,
          nome: "",
          numero: "",
        },
        cep: "",
        logradouro: "",
        bairro: "",
        cidade: "",
        uf: "",
      },
      foto_usuario: null,
      pilotos: [],
      selecionado_favorito: null,
      piloto_selecionado: null,
      pilotos_options: { value: null, text: "Selecione o seu Piloto Favorito" },

      timer_token: 120,
      timer_interval: null,
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
       inputTelOptions: {
        disabledFetchingCountry: false, 
        showDialCodeInInput: true, 
        placeholder: "Digite seu número de telefone",
      },
    };
  },
  computed: {
    endereco_infos() {
      return this.usuario && this.usuario.logradouro
        ? `${this.usuario.cidade}, ${this.usuario.uf}`
        : "";
    },
    hasPlusSign() {
      return this.usuario.email.includes("+");
    },
  },
  filters: {
    dateFormat(date) {
      const data = new Date(date);
      const opcoes = { day: "2-digit", month: "2-digit", year: "numeric" };
      const dataFormatada = data.toLocaleDateString("pt-BR", opcoes);

      return dataFormatada;
    },
  },
  methods: {
    telValidate(telnumber, objTel) {
      this.usuario.phone = objTel.number;
      if (objTel.valid) {
        // this.errors.celular = false;
      } else {
        // this.errors.celular = true;
      }
    },
    async consultaCep() {
      if (this.usuario.cep.length == 8) {
        getCep(this.usuario.cep).then((resp) => {
          this.usuario = {
            ...this.usuario,
            cep: resp.data.cep,
            logradouro: resp.data.logradouro,
            bairro: resp.data.bairro,
            cidade: resp.data.localidade,
            uf: resp.data.uf,
          };
        });
      }
    },
    tokenTimer() {
      this.timer_interval = setInterval(() => {
        this.timer_token--;
        if (this.timer_token < 1) {
          clearInterval(this.timer_interval);
        }
      }, 1000);
    },
    nextInput(event) {
      setTimeout(() => {
        let [input, id] = event.target.id.split("-");
        id = parseInt(id) + 1;
        let nextInput = document.getElementById(
          input + "-" + parseInt(id).toString()
        );
        if (nextInput) nextInput.focus();
      }, 20);
    },
    reenviarToken() {
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };
      this.loading = true;
      api
        .get(`Perfil/reenvio-token`)
        .then(() => {
          this.solicita_token = true;
          this.$store.dispatch("getUsuario");
          const telefone = this.usuario.phone;
          this.telefone_usuario = telefone.replace(
            /(\d{5})-(\d{4})/,
            "#####-$2"
          );

          this.focus_phone = false;

          this.notificacao_infos = {
            variant: "success",
            mensagem:
              "Token enviado com sucesso para o celular: ${this.telefone_usuario} ",
            count_timer: 10,
          };
          this.timer_token = 120;
          this.tokenTimer();
        })
        .catch((error) => {
          console.log(error);
          this.notificacao_infos = {
            variant: "danger",
            mensagem:
              typeof error.response.data == "string"
                ? error.response.data
                : "Algo deu errado ao validar o token",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmarToken() {
      this.loading = true;
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };

      const data = {
        token: this.codigo.join(""),
      };
      api
        .post(`Perfil/token-telefone`, data)
        .then(() => {
          this.notificacao_infos = {
            variant: "success",
            mensagem: "Atualização realizada com sucesso",
            count_timer: 10,
          };

          window.scrollTo({ top: 0, behavior: "smooth" });
          this.solicita_token = false;
          this.telefone_ok = true;
          //atualiza usuario
          this.$store.dispatch("getUsuario");
        })
        .catch((error) => {
          console.log(error);

          this.notificacao_infos = {
            variant: "danger",
            mensagem:
              typeof error.response.data == "string"
                ? error.response.data
                : "Algo deu errado ao validar o token",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateFoto(event) {
      this.foto_usuario = null;
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.foto_usuario = reader.result;
      };
      reader.readAsDataURL(file);
    },
    selectPiloto() {
      this.usuario.pilotoFavorito = null;
      this.selecionado_favorito = null;
      const pilotoSelecionado = this.pilotos.find(
        (item) => item.idPiloto == this.piloto_selecionado
      );
      if (pilotoSelecionado) {
        this.usuario.pilotoFavorito = {
          id: pilotoSelecionado.idPiloto,
          nome: pilotoSelecionado.nome,
          numero: pilotoSelecionado.numero,
          imagem: pilotoSelecionado.imagemB,
        };
        this.selecionado_favorito = this.usuario.pilotoFavorito;
      }
    },

    onSubmit() {
      this.solicita_token = false;
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };
      this.loading = true;

      if (!this.focus_phone) {
        let foto_format = null;
        if (this.foto_usuario) {
          const regex = /^data:image\/(png|jpeg);base64,/;
          foto_format = this.foto_usuario.replace(regex, "");
          const split = foto_format.split("data:image/;base64,");
          foto_format = split.length == 2 ? split[1] : split[0];
        }

        const usuarioData = {
          ...this.usuario,
          foto: foto_format,
        };

        api
          .post("/Perfil", usuarioData)
          .then(() => {
            // if (usuarioData.phone != this.celular_antigo) {
            //   this.solicita_token = true;
            //   this.timer_token = 120;
            //   this.tokenTimer();
            // }

            this.$store.dispatch("getUsuario");
            // const telefone = this.usuario.phone;
            // this.telefone_usuario = telefone.replace(
            //   /(\d{5})-(\d{4})/,
            //   "#####-$2"
            // );

            this.notificacao_infos = {
              variant: "success",
              mensagem: "Atualização realizada com sucesso",
              count_timer: 10,
            };

            window.scrollTo({ top: 0, behavior: "smooth" });

            if (usuarioData.email != this.email_antigo) {
              this.$router.push("/stockclub/pendente-ativacao");
            }
          })
          .catch((error) => {
            console.log(error);
            this.notificacao_infos = {
              variant: "danger",
              mensagem: error.response
                ? error.response.data
                : "Algo deu errado na requisição",
              count_timer: 10,
            };
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        const usuarioData = {
          telefoneAntigo: this.formatPhoneNumber(this.celular_antigo),
          telefoneNovo: this.usuario.phone,
        };

        api
          .post("/Perfil/telefone", usuarioData)
          .then(() => {
            this.solicita_token = true;
            this.timer_token = 120;
            this.tokenTimer();
            this.$store.dispatch("getUsuario");
            const telefone = this.usuario.phone;
            this.telefone_usuario = telefone.replace(
              /(\d{5})-(\d{4})/,
              "#####-$2"
            );

            this.focus_phone = false;

            this.notificacao_infos = {
              variant: "success",
              mensagem: "Atualização realizada com sucesso",
              count_timer: 10,
            };
            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch((error) => {
            console.log(error);
            this.notificacao_infos = {
              variant: "danger",
              mensagem: error.response.data,
              count_timer: 10,
            };
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    fetchPilotos() {
      this.loading = true;
      this.pilotos_options = [
        { value: null, text: "Selecione o seu Piloto Favorito" },
      ];
      api
        .get(`Piloto/categoria/1`)
        .then((resp) => {
          this.pilotos = resp.data.pilotos;
          resp.data.pilotos.forEach((item) => {
            this.pilotos_options.push({
              value: item.idPiloto,
              text: `#${item.numero} ${item.nome} `,
              numero: item.numero,
              piloto: item,
            });
          });
          this.selectPiloto();
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          });
        });
    },

    formatPhoneNumber(phoneNumber) {
      // Utiliza uma expressão regular para formatar o número de telefone
      // const formattedPhoneNumber = phoneNumber.replace(
      //   /(\d{2})(\d{5})(\d{4})/,
      //   "($1) $2-$3"
      // );
      // return formattedPhoneNumber;
      return phoneNumber;
    },
  },
  mounted() {
    this.fetchPilotos();
    this.usuario = localStorage.user
      ? JSON.parse(localStorage.user)
      : this.$store.state
      ? this.$store.state.user
      : null;

    this.celular_antigo = this.formatPhoneNumber(this.usuario.phone.toString());
    this.email_antigo = this.usuario.email.toString();

    this.foto_usuario = this.usuario.foto
      ? `data:image/;base64,${this.usuario.foto}`
      : null;

    this.piloto_selecionado = this.usuario.pilotoFavorito
      ? this.usuario.pilotoFavorito.id
      : null;

    // if (this.$route.query.phone) {
    //   this.focus_phone = true;
    // }

    // if (!this.usuario.celularAtivo) {
    //   this.focus_phone = true;
    // }
  },
};
</script>

<style scoped>
h2 {
  color: var(--primary-color);
  font-family: "Uniwars-W00-Light";
  font-size: 20px;
  text-align: right;
  font-style: italic;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-color);
}

h2 strong {
  font-family: "Uniwars-W00-Heavy-Italick";
}

.titulo-edicao {
  margin-top: 80px;
}

.avatar,
.upload-button {
  display: flex;
  justify-content: flex-end;
}

.avatar-wrapper {
  margin-top: 50px;
}

.profile-pic {
  border-radius: 50%;
  object-fit: cover;
  object-position: center right;
  width: 100px;
  height: 100px;
  border: 1px solid #eabb4d;
}

.pen {
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  position: relative;
  top: -25px;
}

.file-upload {
  display: none;
}

.campos-cadastro {
  max-width: 750px !important;
  margin: 0 auto;
}

.form-control {
  color: var(--title-color);
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  margin-bottom: 30px;
}

.select {
  margin-bottom: 15px !important;
  padding: 10px 20px;
  color: var(--primary-color);
}

.select-piloto {
  margin-top: 40px;
}

.select-piloto p {
  color: #eabb4d;
  font-size: 13px;
}

input::placeholder {
  color: var(--primary-color);
}
input:disabled {
  background: #ffffff5e;
}

.campos-cadastro {
  margin-top: 40px;
}

.btn-avancar {
  margin-top: 50px;
  margin-bottom: 50px;
}

.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 60px;
  text-align: center;
  width: 300px;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.acessar {
  margin: 0 auto;
  /* border-radius: 30px; */
  color: #fff;
}
.btn-salvar {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: #000;
}

.fields {
  width: 750px;
  margin: 0 auto;
}

.pilotosSelecionados p {
  margin-bottom: 0px !important;
}

.pilotosSelecionados {
  padding: 7px 5px 7px 20px;
  color: #fff;
  font-size: 15px;
  border: 1px solid #939393;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.pilotosSelecionados img {
  object-fit: contain;
  cursor: pointer;
}

.piloto-favorito {
  width: 200px;
  margin: 0 auto;
  display: flex;
}

.label-active {
  background-color: var(--menu-color) !important;
}

@media (max-width: 989px) {
  .piloto-favorito {
    width: 150px;
    margin-bottom: 30px;
  }

  .fields {
    width: 100%;
  }

  .avatar,
  .upload-button {
    justify-content: center;
  }

  h2 {
    text-align: center;
  }

  .select-piloto {
    margin-top: 10px;
  }
  .avatar-wrapper {
    width: 100px;
    margin: 20px auto;
  }
}
.loading {
  min-height: 50vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.msgErro {
  color: #ff1717 !important;
  text-align: center;
  margin-top: 50px;
}
.msgErro.success {
  color: #1aaf1a !important;
  text-align: center;
  margin-top: 50px;
}
.notify-celular {
  display: block !important;
  margin-top: -30px !important;
  margin-bottom: 20px !important;
  text-align: left !important;
}
input.error,
textarea.error,
select.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}
input.success,
textarea.success,
select.success {
  border-color: #1aaf1a !important;
  color: #1aaf1a !important;
}
input.error:hover,
input.error:focus,
textarea.error:hover,
textarea.error:focus,
select.error:hover,
select.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}
input.success:hover,
input.success:focus,
textarea.success:hover,
textarea.success:focus,
select.success:hover,
select.success:focus {
  background: rgba(26, 175, 26, 0.1) !important;
}

.codigo {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}
.codigo input {
  width: 70px;
  height: 60px;
  background: transparent !important;
  border: 1px solid #e6c156 !important;
}

.codigo input:focus,
.codigo input:hover {
  box-shadow: none;
  border-color: #fff !important;
}

.codigo input:focus .codigo input:focus::placeholder {
  color: #e6c156 !important;
  background-color: #ffffff00 !important;
}

.codigo input::placeholder {
  color: #e6c156 !important;
}
.codigo input {
  color: #fff !important;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}

.codigo input.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}

.codigo input.error:hover,
.codigo input.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}

.novolink {
  text-align: center;
  margin: 0 auto;
  display: block;
  /* background: white; */
  max-width: 300px;
  padding: 5px;
  margin-top: 30px;
  color: #e6c156;
}
.novolink.sucesso {
  color: #fff;
  text-decoration: none;
  pointer-events: none;
}
.solicita_token {
  margin: 0 auto;
}
.solicita_token p,
p.destaque {
  color: #fff;
  text-align: center;
}
.timer-token {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px auto;
}
input:disabled {
  background-color: #333;
  opacity: 0.7;
}
.form-control.vue-tel-input {
  display: flex;
  height: auto;
  padding: 0.8rem;
}
.form-control.vue-tel-input input {
  background: transparent !important;
  color: #fff;
  font-size: 16px;
}
.form-control.vue-tel-input input:focus,
.form-control.vue-tel-input:focus-within {
  border-color: #fff !important;
  outline: none;
  box-shadow: none;
}
.form-control.vue-tel-input input::placeholder {
  color: var(--primary-color);
  font-size: 16px;
}

.form-control.vue-tel-input:focus-within input::placeholder {
  color: rgba(147, 147, 147, 0.5);
}
.form-control.vue-tel-input .vti__dropdown:hover,
.form-control.vue-tel-input .vti__dropdown.open {
  background: rgba(147, 147, 147, 0.3);
}
.form-control.vue-tel-input .vti__dropdown-list {
  background: #000;
  color: #fff;
  z-index: 9999 !important;
}
.form-control.vue-tel-input .vti__dropdown-item.highlighted {
  background: #5a5a5a;
}
</style>