<template>
  <div class="bg-pilotos">
    <div class="margin-row">
      <b-container>
        <b-row>
          <b-col class="titulo-pilotos titulo-centralizado">
            <hr class="linha-titulo" />
            <h1 v-if="$route.params.categoria == 'formula-4'" class="titulo"><strong>{{ $t("title.pilotos") }}</strong></h1>
            <h1 v-else class="titulo"><strong>pilotos</strong></h1>
            <hr class="linha-titulo" />
            <p
              v-if="$route.params.categoria == 'formula-4'"
              class="subtitulo"
            >
              {{ $t("title.pilotos-text") }} <br />
              {{ $t("title.pilotos-text2") }}
            </p>
            <p
              v-else
              class="subtitulo"
            >
              Confira a formação oficial da<br />
              temporada 2024.
            </p>
          </b-col>
        </b-row>
        <TodosPilotos />
      </b-container>
    </div>
  </div>
</template>

<script>
import TodosPilotos from '@/components/TodosPilotos.vue';
export default {
  components: {
    TodosPilotos
  },
  computed: {
    pilotosestockscss() {
      return this.$route.params.categoria !== 'pilotosestockscss1' ? 'pilotostockarcss' : 'pilotosgeral';
    }
  }
};
</script>

