<template>
  <transition name="slide-fade">
    <ul class="password_strength" v-if="show">
      <li class="password_strength__item">
        <img
          class="icon"
          :class="{ incomplete: password.length < 8 }"
          :src="
            password.length > 7
              ? require('@/assets/icons/check.svg')
              : require('@/assets/icons/close.svg')
          "
        />
        {{ brasileiro ? "+ de 7 caracteres" : "+ of 7 characters" }}
      </li>
      <li class="password_strength__item">
        <img
          class="icon"
          :class="{ incomplete: !has_uppercase }"
          :src="
            has_uppercase
              ? require('@/assets/icons/check.svg')
              : require('@/assets/icons/close.svg')
          "
        />
        {{ brasileiro ? "1 letra maiúscula" : "1 capital letter" }}
      </li>
      <li class="password_strength__item">
        <img
          class="icon"
          :class="{ incomplete: !has_lowercase }"
          :src="
            has_lowercase
              ? require('@/assets/icons/check.svg')
              : require('@/assets/icons/close.svg')
          "
        />
        {{ brasileiro ? "1 letra minúscula" : "1 lowercase letter" }}
      </li>
      <li class="password_strength__item">
        <img
          class="icon"
          :class="{ incomplete: !has_number }"
          :src="
            has_number
              ? require('@/assets/icons/check.svg')
              : require('@/assets/icons/close.svg')
          "
        />
        {{ brasileiro ? "1 número" : "1 number" }}
      </li>
      <li class="password_strength__item">
        <img
          class="icon"
          :class="{ incomplete: !has_special }"
          :src="
            has_special
              ? require('@/assets/icons/check.svg')
              : require('@/assets/icons/close.svg')
          "
        />
        {{ brasileiro ? "1 carácter especial" : "1 special character" }}
      </li>
    </ul>
  </transition>
</template>

<script>
export default {
  props: {
    brasileiro: {
      type: Boolean,
      default: true,
    },
    password: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    show: false,
    has_number: false,
    has_lowercase: false,
    has_uppercase: false,
    has_special: false,
    has_length: false,
  }),
  methods: {
    password_check() {
      this.has_length = this.password.length > 7;
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /\W/.test(this.password);

      if (
        this.has_length &&
        this.has_number &&
        this.has_lowercase &&
        this.has_uppercase &&
        this.has_special
      ) {
        this.$emit("senhaOk", true);
      } else {
        this.$emit("senhaOk", false);
      }
    },
  },
  watch: {
    password(value) {
      this.password_check();
      if (value.length) this.show = true;
      else this.show = false;
    },
  },
};
</script>

<style scoped>
ul {
  display: flex;
}
li {
  color: var(--title-color);
  font-size: 14px;
  flex: 1;
}
.password_strength {
  padding: 5px 0;
}
.password_strength__item {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-5px);
  opacity: 0;
}

.icon {
  height: 13px;
  width: 19px;
}

@media (max-width: 989px) {
  .password_strength {
    display: grid;
  }
}
</style>