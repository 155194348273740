<template>
    <div>
        <RedefinirSenha />
    </div>
</template>


<script>
import RedefinirSenha from "@/components/RedefinirSenha.vue";
export default {
  components: {
    RedefinirSenha,
  }
};
</script>


