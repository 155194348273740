var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-login"},[_vm._m(0),(_vm.notificacao_infos.count_timer)?_c('Notificacao',{attrs:{"notificacao":_vm.notificacao_infos},on:{"atualizaNotificacao":function($event){_vm.notificacao_infos.count_timer = 0}}}):_vm._e(),(!_vm.loading)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('input-with-label',{attrs:{"id":"input-1","label":"Email","type":"email","input-class":{
          'my-input-class': true,
          error: _vm.error,
        },"label-class-custom":"my-label-class","required":""},model:{value:(_vm.login.email),callback:function ($$v) {_vm.$set(_vm.login, "email", $$v)},expression:"login.email"}}),_c('div',{staticClass:"btn-view",on:{"click":function($event){$event.preventDefault();return _vm.toggleMostrarSenha.apply(null, arguments)}}},[(_vm.mostrarSenha)?_c('img',{staticClass:"view",attrs:{"src":require("@/assets/icons/eye-off.svg"),"alt":"Icone de visualização"}}):_c('img',{staticClass:"view",attrs:{"src":require("@/assets/icons/eye.svg"),"alt":"Icone de visualização"}})]),_c('input-with-label',{attrs:{"id":"text-password","label":"Senha","type":_vm.mostrarSenha ? 'text' : 'password',"aria-describedby":"password-help-block","input-class":{
          'my-input-class': true,
          error: _vm.error,
        },"label-class-custom":"my-label-class","required":""},model:{value:(_vm.login.senha),callback:function ($$v) {_vm.$set(_vm.login, "senha", $$v)},expression:"login.senha"}}),_c('router-link',{staticClass:"lembrar-senha",attrs:{"to":"/stockclub/esqueci-minha-senha"}},[_vm._v("esqueci minha senha")]),_c('br'),_c('b-button',{staticClass:"acessar btn",attrs:{"type":"submit"}},[_vm._v("ACESSAR")]),_c('router-link',{staticClass:"criar-conta btn",staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":"/stockclub/cadastro"}},[_vm._v("CRIAR CONTA")])],1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"loading"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem","border-color":"#e6c156","border-right-color":"transparent","--bs-spinner-animation-speed":"0.5s"},attrs:{"label":"Large Spinner","variant":"warning"}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Cadastre-se no Stock Club "),_c('br'),_vm._v("e faça parte da comunidade Stock Car ")])
}]

export { render, staticRenderFns }