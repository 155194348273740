<template>
  <div v-if="!loading">
    <div class="bg-home-stockclub">
      <div class="bg-login"></div>
      <b-container>
        <b-row>
          <b-col cols="12" lg="7" class="txt-home">
            <div>
              <h2>
                VANTAGENS EXCLUSIVAS PARA VIVER<br />
                EMOÇÕES DENTRO E FORA DAS PISTAS
              </h2>

              <div class="vantagens">
                <div>
                  <img
                    src="@/assets/img/iconmonstr-tag-21.png"
                    alt="Icone de descontos"
                  />
                  <p>Descontos em ingressos</p>
                  <strong>E PRODUTOS</strong>
                </div>

                <div>
                  <img
                    src="@/assets/img/iconmonstr-product-6.png"
                    alt="Icone de sorteios"
                  />
                  <p>Sorteios de produtos</p>
                  <strong>E EXPERIÊNCIAS</strong>
                </div>

                <div>
                  <img
                    src="@/assets/img/iconmonstr-customer-6.png"
                    alt="Icone de bastidores"
                  />
                  <p>Bastidores dos seus</p>
                  <strong>PILOTOS FAVORITOS</strong>
                </div>

                <div>
                  <img
                    src="@/assets/img/iconmonstr-plus-circle-thin.png"
                    alt="Icone de sinal de mais"
                  />
                  <p>E muito</p>
                  <strong>MAIS!</strong>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="1"></b-col>
          <b-col cols="12" lg="4" class="form">
            <FormLogin :email_cadastro="email_cadastro" />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div>
      <LogosHome :show_element="show_element" />
    </div>
  </div>
</template>

<script>
import FormLogin from "@/components/FormLogin.vue";
import LogosHome from "@/components/LogosHome.vue";

export default {
  components: {
    FormLogin,
    LogosHome,
  },
  data() {
    return {
      loading: true,
      email_cadastro: "",
      show_element: {
        montadoras: true,
        patrocinadores: true,
        fornecedores: true,
        mediaPartners: true,
      },
    };
  },
  mounted() {
    this.loading = true;

    if (window.localStorage.token) {
      this.$router.push("/stockclub/home");
    } else {
      this.email_cadastro = this.$route.query.u
        ? atob(this.$route.query.u)
        : null;

      this.loading = false;
    }
  },
};
</script>

<style scoped>
.bg-login {
  background-image: url(../assets/img/BG_LOGIN_CLUB_24.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-blend-mode: overlay;
  background-color: rgb(0 0 0 / 50%);
  min-height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 0;
}
.bg-home-stockclub {
  /* background: #000 !important; */
  background-color: var(--bg-color) !important;
}
.bg-home-stockclub h2 {
  font-size: 25px;
  font-family: "MetronicPro-Regular";
  line-height: 27px;
  color: var(--title-color);
  text-align: center;
}

.txt-home {
  align-items: flex-end;
  display: flex;
  z-index: 9999;
  min-height: 65vh;
}

.vantagens p {
  font-family: "MetronicPro-Regular";
  font-size: 15px;
  color: var(--title-color);
  text-align: center;
  margin-bottom: -5px;
}

.vantagens strong {
  font-family: "MetronicPro-Regular";
  font-size: 19px;
  color: var(--title-color);
  text-transform: uppercase;
}

.vantagens {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  gap: 30px;
}

.vantagens img {
  display: flex;
  margin: 0 auto;
}
.form {
  z-index: 9999;
}

@media (max-width: 989px) {
  .bg-home-stockclub {
    background: #000 !important;
  }
  .form {
    order: -1 !important;
  }

  .logos-rodape {
    margin-top: 350px !important;
  }

  .vantagens {
    display: grid;
    justify-content: center;
    gap: 20px;
    padding: 20px 0 40px 0;
  }

  .bg-login {
    background-image: none !important;
  }

  .form-login {
    margin-top: 50px !important;
  }

  .txt-home {
    align-items: center;
    display: grid;
    min-height: auto;
    margin-top: 40px;
  }

  .bg-home-stockclub h2 {
    font-size: 14px;
    line-height: 22px;
  }
  .vantagens p {
    font-size: 13px;
  }
  .vantagens strong {
    font-size: 14px;
  }
}

@media (max-width: 1500px) {
  .form-login {
    margin-top: 40px;
  }
}
</style>