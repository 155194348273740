<template>
  <section class="container">
    <div v-if="msgErro.show && !loading" class="msgErro">
      {{ msgErro.msg }}
    </div>
    <div data-anima="bottom" v-if="!sem_telefone && !loading">
      <p class="my-4 pt-5 confirma">Atenção, o fluxo de votação mudou</p>
      <p>
        No novo fluxo de votação, agora será necessário informar o token de
        verificação que o usuário receberá no SMS
      </p>
      <p>
        Para isso precisamos que confirme os dados do celular cadastrado, pois o
        SMS será enviado para o mesmo
      </p>
      <p
        class="confirma"
        style="display: block; margin-top: 50px; margin-bottom: 20px"
      >
        {{ telefone_usuario }}
      </p>

      <div class="acoes">
        <router-link
          class="btn btn-home"
          to="/stockclub/meu-perfil?phone=true"
          style="text-decoration: none"
        >
          {{
            usuario.celularAtivo ? "EDITAR MEUS DADOS" : "ATIVAR MEU CELULAR"
          }}
        </router-link>

        <a
          class="btn btn-home"
          href="#"
          style="text-decoration: none; color: inherit"
          @click.prevent="aceiteFluxo"
          v-if="usuario.celularAtivo"
        >
          CONTINUAR PARA VOTAÇÃO
        </a>
      </div>
    </div>
    <div v-if="sem_telefone && !loading">
      <p class="my-4 pt-5 confirma">
        Parece que seu cadastro não está atualizado
      </p>
      <p class="my-4">
        Acesse o link abaixo e atualize o seu celular para continuar a votação
      </p>

      <router-link
        to="/stockclub/meu-perfil?phone=true"
        style="text-decoration: none; color: inherit"
      >
        <button type="button" class="btn btn-home">EDITAR MEUS DADOS</button>
      </router-link>
    </div>

    <b-container v-if="loading">
      <div class="loading">
        <b-spinner
          style="
            width: 3rem;
            height: 3rem;
            border-color: #e6c156;
            border-right-color: transparent;
            --bs-spinner-animation-speed: 0.5s;
          "
          label="Large Spinner"
          variant="warning"
        ></b-spinner>
      </div>
    </b-container>
  </section>
</template>

<script>
import { api } from "@/services.js";

export default {
  name: "PreVotacao",
  data() {
    return {
      loading: true,
      usuario: null,
      telefone_usuario: null,
      sem_telefone: false,
      msgErro: {
        show: false,
        msg: "",
      },
    };
  },
  methods: {
    aceiteFluxo() {
      this.msgErro = {
        show: false,
        msg: "",
      };

      this.loading = true;

      api
        .post("/Perfil/aceite-fluxo")
        .then(() => {
          this.$store.dispatch("getUsuario").then(() => {
            setTimeout(() => {
              this.$router.push("/stockclub/votacao-fanpush");
            }, 100);
          });
        })
        .catch((error) => {
          console.log(error);
          this.msgErro = {
            show: true,
            msg:
              error.response && typeof error.response.data == "string"
                ? error.response.data
                : "Algo deu errado na requisição",
          };
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.usuario = localStorage.user
      ? JSON.parse(localStorage.user)
      : this.$store.state
      ? this.$store.state.user
      : null;

    if (this.usuario.celularAtivo && this.usuario.aceitaFluxoFanpush) {
      this.$router.push("/stockclub/votacao-fanpush");
    } else if (this.usuario.phone) {
      const telefone = this.usuario.phone;
      this.telefone_usuario = telefone.replace(/(\d{5})-(\d{4})/, "#####-$2");
    } else {
      this.sem_telefone = true;
    }

    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
};
</script>


<style scoped>
.container {
  min-height: 60vh;
}
.pilotos-selecionados {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}

.pilotos-selecionados img {
  position: relative;
  top: -30px;
}

.pilotos-selecionados p {
  font-family: "Uniwars-W00-Heavy-Italick";
  color: white;
  font-size: 45px;
}

.nome-modal {
  border-top: 1px solid white;
  margin-top: -28px;
  font-family: "Uniwars-W00-Light";
  font-size: 13px;
  color: white !important;
  font-style: italic;
  text-align: center;
  padding-top: 10px;
}

.btn-home {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  margin: 80px 0;
}

.btn-home:hover {
  color: var(--text-color);
  border-color: var(--title-color);
  background-color: var(--title-color);
}

p {
  color: white;
}
.info-etapa {
  margin-top: -30px;
  color: #542283 !important;
}

.votacao {
  margin-top: 100px;
}

.txt-votacao {
  margin-bottom: 60px;
}

.txt-votacao span {
  color: #843dc6;
}

.txt-votacao p {
  font-size: 16px;
  color: var(--title-color);
}

h2 {
  font-family: "Uniwars-W00-Light";
  font-size: 30px;
  color: var(--title-color);
  font-style: italic;
}

h2 strong {
  font-family: "Uniwars-W00-Heavy-Italick";
}

.circuito {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -75px;
}

.circuito img {
  width: 100%;
  object-fit: contain;
}

.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
  min-width: 300px;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.acessar:hover {
  color: var(--text-color);
  border-color: var(--title-color);
  background-color: var(--title-color);
}

.info-etapa {
  text-align: right;
}

.loading {
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pilotosVotados {
  margin-top: 30px;
  margin-bottom: 60px;
  width: 700px;
}
.acoes {
  display: flex;
  gap: 0 20px;
  align-items: center;
}
.acoes .btn:first-child {
  background: transparent;
  color: var(--primary-color);
}

@media (min-width: 990px) {
  .piloto-modal {
    width: 40%;
    height: 80px;
  }

  .btn-avancar {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 989px) {
  .acoes {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 80px;
  }
  .acoes .btn {
    margin: 0;
  }
  .pilotosVotados {
    width: 100%;
  }

  .modal-content {
    margin-top: 250px;
  }

  .pilotos-selecionados {
    justify-content: center;
    display: flex;
  }

  .pilotos-selecionados img {
    left: 0px;
  }

  .nome-modal {
    text-align: center;
    width: 100%;
  }

  .acessar {
    width: 100%;
  }

  .info-etapa {
    text-align: center;
  }

  .info-etapa {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .votacao {
    margin-top: 50px;
  }

  .txt-votacao {
    text-align: center;
    margin-bottom: 0px;
  }

  .circuito {
    top: 0px;
    margin: 0 auto !important;
    padding-bottom: 30px;
  }
}
.msgErro {
  color: #ff1717 !important;
  text-align: center;
  margin-top: 50px;
}
</style>