<template>
  <div>
    <div class="bg-confirmacao-novo">
      <b-container>
        <b-row>
          <b-col>
            <div class="msg-confirmacao" v-if="!hash && !emailResponsavel">
              <h3>{{ nome }} SUA CONTA STOCK CLUB FOI CRIADA COM SUCESSO!</h3>
              <p>
                Acabamos de te enviar um e-mail. <br />Clique no botão ATIVAR e
                ative sua conta.<br />
                Caso não localize o e-mail, olhe também a pasta Spam ou Lixo
                Eletrônico.
              </p>

              <router-link to="/stockclub/login">
                <b-button type="submit" variant="primary" class="acessar btn"
                  >ACESSAR CONTA</b-button
                >
              </router-link>
            </div>
            <div class="msg-confirmacao" v-if="!hash && !emailResponsavel">
              <h3>{{ nome }} SUA CONTA STOCK CLUB FOI CRIADA COM SUCESSO!</h3>
              <p>
                Acabamos de te enviar um e-mail. <br />Clique no botão ATIVAR e
                ative sua conta.<br />
                Caso não localize o e-mail, olhe também a pasta Spam ou Lixo
                Eletrônico.
              </p>

              <router-link to="/stockclub/login">
                <b-button type="submit" variant="primary" class="acessar btn"
                  >ACESSAR CONTA</b-button
                >
              </router-link>
            </div>

            <div class="msg-confirmacao" v-else>
              <div v-if="!loading">
                <div v-if="!error">
                  <h3 style="color: #fff">
                    PARABÉNS, SUA CONTA STOCK CLUB ESTÁ ATIVA!
                  </h3>
                  <p>Realize o login para acessar os dados de sua conta</p>

                  <router-link to="/stockclub/login">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="acessar btn"
                      >ACESSAR CONTA</b-button
                    >
                  </router-link>
                </div>
                <div v-if="error">
                  <p class="msgErro">
                    Algo deu erro ao realizar a ativação da conta
                  </p>
                </div>
              </div>

              <div v-if="loading" class="loading">
                <b-spinner
                  style="
                    width: 3rem;
                    height: 3rem;
                    border-color: #e6c156;
                    border-right-color: transparent;
                    --bs-spinner-animation-speed: 0.5s;
                  "
                  label="Large Spinner"
                  variant="warning"
                ></b-spinner>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>


<script>
import { api } from "@/services.js";

export default {
  name: "ConfirmacaoRegistro",
  data() {
    return {
      nome: "",
      hash: "",
      emailResponsavel: "",
      loading: false,
      error: false,
    };
  },
  methods: {
    ativacaoConta(endpoint, data) {
      this.loading = true;
      this.error = false;
      const dataJson = { hash: data };
      api
        .post(`/Registro/${endpoint}`, dataJson)
        .then((resp) => {
          this.$router.push(`/stockclub/login?u=${btoa(resp.data.user)}`);
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.nome = this.$route.query.u ? `${this.$route.query.u}, ` : null;
    this.hash = this.$route.query.hash;
    this.emailResponsavel = this.$route.query.resp;

    if (this.hash && !this.emailResponsavel) {
      this.ativacaoConta("confirmacao", this.hash);
    } else if (this.emailResponsavel) {
      this.ativacaoConta("autorizacao-responsavel", this.emailResponsavel);
    }
  },
};
</script>

<style scoped>
.bg-confirmacao-novo {
  background-color: #000;
  min-height: 100vh;
}
.bg-confirmacao {
  background: #000 !important;
}
.msg-confirmacao {
  z-index: 999999 !important;
  position: relative;
  margin-top: 35px;
}
.msg-confirmacao h3 {
  font-family: "Uniwars-W00-Heavy-Italick";
  color: var(--primary-color);
  font-size: 25px;
  text-align: center;
  width: 600px;
  margin-top: 50px;
}

.msg-confirmacao {
  display: grid;
  justify-content: flex-end;
}

.msg-confirmacao p {
  text-align: center;
  line-height: 30px;
  color: var(--title-color);
  font-family: "MetronicPro-Light";
  margin: 30px auto;
  font-size: 20px;
  width: 500px;
}

.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  text-align: center;
  width: 300px;
  margin: 0 auto;
  margin-top: 40px !important;
}
.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
a {
  text-decoration: none;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .msg-confirmacao p {
    width: 400px;
  }

  .msg-confirmacao h3 {
    width: 500px;
    margin-top: 40px;
  }
}

@media (min-width: 990px) and (max-width: 1199px) {
  .msg-confirmacao p {
    width: 300px;
    line-height: 24px;
    font-size: 16px;
  }

  .msg-confirmacao h3 {
    width: 400px;
    margin-top: 60px;
    font-size: 20px;
  }
}

@media (max-width: 989px) {
  .bg-confirmacao {
    height: auto;
  }

  .msg-confirmacao h3 {
    width: 100%;
    font-size: 20px;
    margin-top: 50px;
  }

  .msg-confirmacao p {
    line-height: 25px;
    font-size: 17px;
    width: 100%;
  }
}

.loading {
  min-height: 50vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.msgErro {
  color: #ff1717 !important;
  text-align: center;
  margin-top: 50px;
  margin-top: 150px !important;
  width: 100% !important;
}
</style>
