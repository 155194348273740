var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide-fade"}},[(_vm.show)?_c('ul',{staticClass:"password_strength"},[_c('li',{staticClass:"password_strength__item"},[_c('img',{staticClass:"icon",class:{ incomplete: _vm.password.length < 8 },attrs:{"src":_vm.password.length > 7
            ? require('@/assets/icons/check.svg')
            : require('@/assets/icons/close.svg')}}),_vm._v(" "+_vm._s(_vm.brasileiro ? "+ de 7 caracteres" : "+ of 7 characters")+" ")]),_c('li',{staticClass:"password_strength__item"},[_c('img',{staticClass:"icon",class:{ incomplete: !_vm.has_uppercase },attrs:{"src":_vm.has_uppercase
            ? require('@/assets/icons/check.svg')
            : require('@/assets/icons/close.svg')}}),_vm._v(" "+_vm._s(_vm.brasileiro ? "1 letra maiúscula" : "1 capital letter")+" ")]),_c('li',{staticClass:"password_strength__item"},[_c('img',{staticClass:"icon",class:{ incomplete: !_vm.has_lowercase },attrs:{"src":_vm.has_lowercase
            ? require('@/assets/icons/check.svg')
            : require('@/assets/icons/close.svg')}}),_vm._v(" "+_vm._s(_vm.brasileiro ? "1 letra minúscula" : "1 lowercase letter")+" ")]),_c('li',{staticClass:"password_strength__item"},[_c('img',{staticClass:"icon",class:{ incomplete: !_vm.has_number },attrs:{"src":_vm.has_number
            ? require('@/assets/icons/check.svg')
            : require('@/assets/icons/close.svg')}}),_vm._v(" "+_vm._s(_vm.brasileiro ? "1 número" : "1 number")+" ")]),_c('li',{staticClass:"password_strength__item"},[_c('img',{staticClass:"icon",class:{ incomplete: !_vm.has_special },attrs:{"src":_vm.has_special
            ? require('@/assets/icons/check.svg')
            : require('@/assets/icons/close.svg')}}),_vm._v(" "+_vm._s(_vm.brasileiro ? "1 carácter especial" : "1 special character")+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }