<template>
  <section>

    <div class="loading_global" v-if="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </div>

    <div v-else class="bg-votacao">

        <div v-if="votacao.url">

            <img alt="logo stock car" src="@/assets/img/logo-tv.png" class="logo-tv" />
            <div class="faixa-tempo">
                <div class="container d-flex conteudo-faixa">
                    <div class="d-flex box-tempo">
                        <img alt="Ícone de relógio" src="@/assets/img/relogio.svg" />
                        <p id="countdown">{{ days }}d : {{ hours }}h : {{ minutes }}m : {{ seconds }}s</p>
                    </div>
                
                </div>
            </div>

            <div class="box-video">

                <div class="primeiros box-url">
                                  
                    <b-row>
                        <b-col class="video-tv" cols="12" lg="7">
                            <iframe width="100%" height="100%" :src="`https://www.youtube.com/embed/${this.getYouTubeParam(votacao.url)}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </b-col>

                        <b-col cols="12" lg="5">
                            <div class="selo esconder-desktop">
                                <img alt="Selo snapdragon" src="@/assets/img/selomobile.png" class="img-selo" />
                            </div>
                            <div class="conteudo-bg comurl">
                                <div class="titulo-live">
                                    <div>
                                        <h2>Vivo fan push by snapdragon</h2>
                                        <h3>VOTAÇÃO EM TEMPO REAL</h3>
                                    </div>

                        
                                </div>
                                <div class="selo esconder-mobile">
                                    <img alt="Selo snapdragon" src="@/assets/img/selomobile.png" class="img-selo" />
                                </div>
                            </div>
                            <!----------- OS PRIMEIROS 6 --------------->
                            <b-row >
                                <b-col cols="12" lg="4" class="box-col" v-for="(item, index) in pilotos.slice(0, 6)" :key="index">
                                    <div class="d-flex box-live">
                                        <div class="progresso">
                                            <div class="piloto">
                                                <img alt="Imagem do piloto" :src="item.imagemB" class="piloto-img" />
                                                <p class="posicao-votacao">{{ index + 1 }}</p>
                                            </div>

                                            <h4> <span>{{ item.nome.split(' ')[0] }} </span><br> <strong>{{ item.nome.split(' ')[1] }}</strong> </h4>
                                            <p v-if="item.votos == '0'">{{ item.votos }}%</p>
                                            <p v-else>{{ item.votos.toFixed(2) }}%</p>
                                            <b-progress :value="item.votos" max="100" show-progress></b-progress>
                                        </div>
                                    </div>
                                </b-col>

                            </b-row>
                        </b-col>
                    </b-row>
                    
                    <!------------ RESTANTE ------------>

                    <b-carousel
                        id="carousel-fade"
                        fade
                        indicators
                        :interval="5000"
                        >
                        <b-carousel-slide v-for="(group, groupIndex) in groupedPilotos" :key="groupIndex">
                            <div class="row-lista restante restante-video">
                                <div class="d-flex box-live-lista votacao-comvideo" v-for="(item, index) in group" :key="index">
                                    <div class="progresso progresso-lista">
                                        <p class="posicao-lista">{{ index + 7 + groupIndex * 6 }}</p>
                                        <h4 class="h4lista">
                                        <span>{{ item.nome.split(' ')[0] }} </span><br>
                                        <strong>{{ item.nome.split(' ')[1] }}</strong>
                                        </h4>
                                        <p class="pprogresso" v-if="item.votos == '0'">{{ item.votos }}%</p>
                                        <p class="pprogresso" v-else>{{ item.votos.toFixed(2) }}%</p>
                                        <b-progress :value="item.votos" max="100" show-progress></b-progress>
                                    </div>
                                </div>
                            </div>
                        </b-carousel-slide>
                    </b-carousel>

                </div>
            </div>
        </div>

         <div v-else>
            <img alt="logo stock car" src="@/assets/img/logo-tv.png" class="logo-tv" />

            <div>
                <div class="container">
                    <div class="selo esconder-desktop">
                        <img alt="Selo snapdragon" src="@/assets/img/selomobile.png" class="img-selo" />
                    </div>
                    <div class="conteudo-bg">
                        <div class="titulo-live">
                            <div>
                                <h2>Vivo fan push by snapdragon</h2>
                                <h3>VOTAÇÃO EM TEMPO REAL</h3>
                            </div>

                
                        </div>
                    </div>
                </div>
                
            </div>

            <div>
                <b-container class="primeiros">
                    <!----------- OS PRIMEIROS 6 --------------->
                    <b-row >
                        <b-col cols="12" lg="4" class="box-col" v-for="(item, index) in pilotos.slice(0, 6)" :key="index">
                            <div class="d-flex box-live">
                                <div class="piloto">
                                    <img alt="Imagem do piloto" :src="item.imagemB" class="piloto-img" />
                                    <p class="posicao-votacao">{{ index + 1 }}</p>
                                </div>

                                <div class="progresso">
                                    <h4> <span>{{ item.nome.split(' ')[0] }} </span> <strong>{{ item.nome.split(' ')[1] }}</strong> </h4>
                                    <p v-if="item.votos == '0'">{{ item.votos }}%</p>
                                    <p v-else>{{ item.votos.toFixed(2) }}%</p>
                                    <b-progress :value="item.votos" max="100" show-progress></b-progress>
                                </div>
                            </div>
                        </b-col>

                    </b-row>
                    
                    <!------------ RESTANTE ------------>

                    <b-row class="row-lista restante">
                        <b-col cols="12" lg="2" v-for="(item, index) in pilotos.slice(6, 24)" :key="index" >
                            <div class="d-flex box-live-lista">

                                <div class="progresso progresso-lista">
                                    <p class="posicao-lista">{{ index + 7 }}</p>
                                    <h4 class="h4lista"><span>{{ item.nome.split(' ')[0] }} </span> <br><strong>{{ item.nome.split(' ')[1] }}</strong></h4>
                                    <p class="pprogresso" v-if="item.votos == '0'">{{ item.votos }}%</p>
                                    <p class="pprogresso" v-else>{{ item.votos.toFixed(2) }}%</p>
                                    <b-progress :value="item.votos" max="100" show-progress></b-progress>
                                </div>
                            </div>
                        </b-col>
        
                    </b-row>

                    <div class="selo esconder-mobile">
                            <img alt="Selo snapdragon" src="@/assets/img/selomobile.png" class="img-selo-final" />
                        </div>
                </b-container>
                
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "FanpushTV",
  data() {
    return {
      loading: false,
      votacao: [],
      pilotos: [],
      countdown: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0, 
      carregar: false
    };
  },
   computed: {
    groupedPilotos() {
      const groups = [];
      const itemsPerGroup = 6;
      for (let i = 6; i < 24; i += itemsPerGroup) {
        groups.push(this.pilotos.slice(i, i + itemsPerGroup));
      }
      return groups;
    }
  },
  methods: {
    votar() {
        this.$router.push("/stockclub/votacao-fanpush");
    },

    getYouTubeParam(url) {
      const paramsString = url.split("?")[1];
      if (!paramsString) return null;

      const params = new URLSearchParams(paramsString);

      return params.get("v");
    },

    fetchVotacao() {
      if(!this.carregar) {
        this.loading = true;
      }

      api
        .get('/fanpush/votacao')
        .then((resp) => {
            this.votacao = resp.data;
            this.pilotos = resp.data.pilotos;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
    created() {
        this.countdown = setInterval(() => {
        const now = new Date().getTime();
        
        const endTime = new Date(this.votacao.dataFimVotacao).getTime();
        
        if (isNaN(endTime)) {

            clearInterval(this.countdown);
            this.days = 0;
            this.hours = 0;
            this.minutes = 0;
            this.seconds = 0;
        } else {
            const difference = endTime - now;
            
            if (difference <= 0) {
                clearInterval(this.countdown);
                this.days = 0;
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
            } else {
                this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
                this.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
            }
        }
    }, 1000);
  },
   beforeDestroy() {
    clearInterval(this.countdown);
  },
  mounted() { 
    this.fetchVotacao();

  }
};
</script>

<style scoped>

.votacao-comvideo p, .votacao-comvideo h4 {
    text-align: left;
}

.restante-video {
    display: flex;
    justify-content: start;
    gap: 20px;
}

.box-url {
    padding-bottom: 50px !important;
}

.comvideo .box-live-lista {
    width: 100px;
}

.comvideo {
    overflow: auto;
    gap: 30px;
}

.comurl {
    display: flex;
    align-items: end;
    gap: 20px;
}

.comurl .titulo-live {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 20px;
}

.comurl .titulo-live h2 {
    font-size: 23px;
}

.comurl  .titulo-live h3 {
    font-size: 40px;
}

.box-video {
    margin: 50px;
}

.logo-tv {
    margin: auto;
    display: table;
    width: 230px;
}

.faixa-tempo {
    background-color: #E6C156;
    height: 36px;
    padding: 5px 0px;
}

.faixa-tempo p {
    margin-bottom: 0px;
    color: black;
    font-size: 16px;
    font-family: "MetronicPro-SemiBold";
}

.piloto {
    position: relative;
}

.row-lista {
    margin-top: 50px;
}

.box-live {
    margin-bottom: 20px;
    align-items: center;
    gap: 5px;
}

.box-live-lista {
    margin-bottom: 40px;
    align-items: baseline;
    gap: 15px;
    width: 100%;
}

.posicao-lista {
    font-family: "Uniwars-W00-SemiBold";
    font-style: italic;
    color: #F7B04D;
    font-size: 23px;
}

.posicao-votacao {
    top: 10px;
    left: -2px;
    position: absolute;
    font-family: "Uniwars-W00-SemiBold";
    font-style: italic;
    color: #F7B04D;
    font-size: 30px;
}

.piloto-img {
    width: 132px;
}

.progresso h4 {
    color: white;
    font-family: "MetronicPro-Light";
    font-size: 24px;
    margin-bottom: 0px;
}

.progresso-lista {
    width: 100%;
}

.pprogresso  {
    font-size: 23px !important;
}

.h4lista {
    font-size: 18px !important;
}

.progresso strong {
    font-family: "MetronicPro-Black";
}

.progresso p {
    margin-bottom: 0px;
    font-family: "Uniwars-W00-SemiBold";
    color: #E6C156;
    font-size: 30px;
}

.seta {
    cursor: pointer;
}

.box-tempo {
    top: -2px !important;
    position: relative;
    gap: 10px;
    align-items: end;
}

.conteudo-faixa {
    justify-content: center;
}

.votar-agora {
    border: none;
    font-family: "MetronicPro-Bold";
    background-color: white;
    color: #542283;
    font-size: 11px;
    height: 30px;
    width: 150px;
}

.votar-agora:hover {
    background-color: #542283;
    color: #fff;
}

.btn-votar {
    margin-top: -2px;
}

.bg-votacao {
  background-image: url(../assets/img/bg-video.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.img-selo {
    width: 140px;
    top: -30px !important;
    position: relative;
}

.img-selo-final {
    width: 188px;
    margin: auto;
    display: table;
    margin-bottom: 50px !important;
}

.titulo-live h2 {
    font-family: "Uniwars-W00-SemiBold";
    color: white;
    font-size: 30px;
    font-style: italic;
    margin-bottom: 0px;
}

.titulo-live h3 {
    margin-bottom: 0px;
    color: #E6C156;
    font-size: 45px;
    font-style: italic;
    font-family: "Uniwars-W00-Heavy-Italick";
}

.titulo-live {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}

.atualizar p {
    color: white;
    font-style: italic;
    margin-bottom: 0px;
    font-size: 15px;
    font-family: "Uniwars-W00-SemiBold";
}

.atualizar {
    position: relative;
    top: -3px !important;
    gap: 10px;
}

.primeiros .progresso {
     width: 60% !important;
}

.restante .progresso {
     width: 100%!important;
}

@media (max-width: 990px) {
    .votar-agora {
        height: 38px;
        padding-top: 5px;
    }

    .faixa-tempo, .conteudo-faixa {
        padding: 0px;
    }

    .box-tempo {
        gap: 10px;
        align-items: center;
        margin-left: 20px;
        margin-top: 5px;
    }

    .faixa-tempo p {
        font-size: 13px;
    }

    .esconder-mobile {
        display: none;
    }

    .bg-votacao {
        background-image: url(../assets/img/bgmobilelive.png);
        background-size: contain;
    }

    .atualizar p {
        font-size: 11px;
    }

    .progresso h4 {
        font-size: 18px;
    }

    .progresso p {
        font-size: 23px;
    }

    .titulo-live {
        padding: 0px 5px;
        gap: 5px;
    }

    .titulo-live h2 {
        font-size: 15px;
    }

    .titulo-live h3 {
        font-size: 18px;
    }

    .row-lista {
        margin-top: 0px;
    }

    .img-selo {
        top: 0px !important;
        margin: auto;
        display: table;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .comurl .titulo-live h3 {
        font-size: 26px;
    }

    .box-col {
        padding: 0px 15px 0px 25px;
    }
}

@media (min-width:991px) {

    .box-url {
        padding: 0px 50px;
    }

    .esconder-desktop {
        display: none;
    }
}

@media (min-width: 2001px) {

    .bg-votacao {
        height: 100vh;
    }
}

</style>