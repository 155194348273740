import Vue from 'vue'
import Vuex from 'vuex'
import {
  api
} from '@/services.js'

import {
  EventBus
} from "@/main.js";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: false,
    categoria: null,
    footer: null,
    user: {
      "name": "",
      "sobrenome": "",
      "cpf": "",
      "email": "",
      "phone": "",
      "dataNascimento": "",
      "pilotoFavorito": null
    },
    btn_ingresso: {
      show: false,
      link: ''
    },
    idioma: 'pt-br'
  },
  getters: {},
  mutations: {
    UPDATE_IDIOMA(state, payload) {
      state.idioma = payload;
    },
    UPDATE_CATEGORIA(state, payload) {
      state.categoria = payload;
    },
    UPDATE_LOGIN(state, payload) {
      state.login = payload;
    },
    UPDATE_USER(state, payload) {
      window.localStorage.user = JSON.stringify(payload)
      state.user = payload;
    },
    UPDATE_FOOTER(state, payload) {
      state.footer = payload;
    },
    UPDATE_INGRESSO(state, payload) {
      state.btn_ingresso = payload;
    },
  },
  actions: {
    getCategoria(context, payload) {
      if (payload) {
        return api.get(`/Categoria/${payload}`)
          .then(resp => {
            context.commit('UPDATE_CATEGORIA', resp.data.categoria)
            window.sessionStorage.categoria = JSON.stringify(resp.data.categoria);
            EventBus.$emit("updateCategoria", resp.data.categoria);


            return resp.data
          })
      }

    },

    getFooter(context, payload) {
      if (payload) {
        return api.get(`/Footer/categoria/${payload}`)
          .then(resp => {
            context.commit('UPDATE_FOOTER', resp.data.itemsRodape)
            window.sessionStorage.footer = JSON.stringify(resp.data.itemsRodape);
            return resp.data
          })
      }

    },
    logarUsuario(context, payload) {
      return api.post("/Autenticacao/login", payload)
        .then(response => {
          window.localStorage.token = response.data.accessToken;
        })
    },


    getUsuario(context) {
      return api.get(`/Perfil`)
        .then(response => {
          context.commit('UPDATE_USER', response.data.perfil)
          context.commit('UPDATE_LOGIN', true)
          EventBus.$emit("updateRouter");

        }).catch((error) => {
          if (error.response.status == 401) {
            context.dispatch('deslogarUsuario')
            this.$router.push('/stockclub/login')
          }
        })
    },

    deslogarUsuario(context) {
      context.commit('UPDATE_USER', {
        "idUsuario": -1,
        "name": "",
        "sobrenome": "",
        "cpf": "",
        "email": "",
        "phone": "",
        "dataNascimento": "",
        "genero": "",
        "cidade": "",
        "uf": "",
        "createdAt": "",
        "updatedAt": "",
        "foto": "",
        "emailOptin": false,
        "smsOptin": false,
        "whatsAppOptin": false,
        "aceitaFluxoFanpush": false,
        "celularAtivo": false,
        "dataAlteracaoCelular": "",
        "pilotoFavorito": {
          "id": -1,
          "nome": "",
          "numero": "",
          "imagem": "",
          "background": "",
          "capacete": "",
          "carro3d": ""
        }
      })
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('votosLocais')
      context.commit('UPDATE_LOGIN', false)


      EventBus.$emit("updateRouter");
    },
    mostrarBtnIngresso(context, payload) {
      window.sessionStorage.btn_ingresso = JSON.stringify(payload);
      context.commit('UPDATE_INGRESSO', payload)
    },
    alterarIdioma(context, payload) {
      window.sessionStorage.idioma = payload;
      context.commit('UPDATE_IDIOMA', payload)
    }
  },
  modules: {},
})