<template>
  <div>
    <PilotoInterna />
    <!------------------------ NOTÍCIAS -----------------------

    <b-container class="noticias">
      <b-row>
        <b-col class="titulo-noticia titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 class="titulo"><strong>ÚLTIMAS</strong> NOTÍCIAS</h1>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>
      <b-row class="box-noticia">
        <b-col cols="12" lg="7" class="box-img"
          ><img class="img-noticia" src="@/assets/img/img-noticias.jpg"
        /></b-col>
        <b-col cols="12" lg="5" class="box-texto">
          <div class="linha-noticia"></div>
          <h2>KTF Sports segue com Felipe Baptista na Stock Pro Series 2023</h2>
          <p>23 Jan 2023</p>
          <p>
            A KTF Sports anunciou na tarde desta segunda-feira (23) mais um nome
            para compor o seu line-up de pilotos para a temporada 2023. O jovem
            Felipe Baptista, vencedor e pole-position da última etapa do...
          </p>
          <router-link to="/">Ver mais</router-link>
        </b-col>
      </b-row>
    </b-container>-->

  </div>
</template>

<script>
import PilotoInterna from '@/components/PilotoInterna.vue';
export default {
  name: "DetalhePiloto",
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
  components: {
    PilotoInterna
  }
};
</script>

