<template>
    <div>
      <GaleriaFotos />
    </div>
</template>


<script>

import GaleriaFotos from "@/components/GaleriaFotos.vue";

export default {
  name: "Galeria",
  components: {
    GaleriaFotos
    
  },
};
</script>

<style scoped>

</style>


