<template>
  <div>
    <b-container v-if="!loading">
      <b-row>
        <b-col cols="12" lg="12">
          <div class="infos-bastidores">
            <h3>
              #{{
                usuario && usuario.pilotoFavorito
                  ? usuario.pilotoFavorito.numero
                  : ""
              }}
            </h3>
            <h4>bastidores</h4>
            <div class="alterar">
              <img alt="Ícone de configuração" src="@/assets/img/config.png" />
              <router-link
                to="/stockclub/meu-perfil"
                style="text-decoration: none; color: inherit"
                ><p>alterar piloto favorito</p></router-link
              >
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="fotos-bastidores">
        <b-col
          cols="12"
          lg="6"
          class="img-bastidores mb-4"
          v-for="(item, index) in galeriaItens"
          :key="index"
        >
          <router-link
            :to="`/stockcar/proseries/piloto/${usuario.pilotoFavorito.id}`"
            ><img class="borda" :src="item"
          /></router-link>
        </b-col>
      </b-row>

      <b-row v-if="galeriaItens.length">
        <b-col cols="12" lg="12" class="btn-banner">
          <router-link
            :to="`/stockcar/proseries/piloto/${usuario.pilotoFavorito.id}`"
            class="btn"
            name="mais-imagens"
            ><strong>MAIS</strong> IMAGENS</router-link
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { api } from "@/services.js";

export default {
  name: "Bastidores",
  data() {
    return {
      galeriaItens: [],
      loading: false,
      usuario: null,
    };
  },

  methods: {
    fetchGaleriaPiloto() {
      this.galeriaItens = [];
      this.loading = true;
      api
        .get(`Piloto/${this.usuario.pilotoFavorito.id}/bastidores`)
        .then((resp) => {
          resp.data.galerias.forEach((item, index) => {
            if (index < 4) {
              this.galeriaItens.push(item);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.usuario = localStorage.user
        ? JSON.parse(localStorage.user)
        : this.$store.state
        ? this.$store.state.user
        : null;
      if (this.usuario.pilotoFavorito) {
        this.fetchGaleriaPiloto();
      }
    });
  },
};
</script>

<style scoped>
.infos-bastidores h3 {
  font-family: "Uniwars-W00-Heavy-Italick";
  color: white;
  font-size: 100px;
  text-align: center;
  margin-bottom: 0px;
}

.infos-bastidores h4 {
  font-family: "UniwarsW00Regular";
  font-size: 30px;
  font-style: italic;
  color: #eabb4d;
  text-align: center;
  margin-top: -15px;
}

.alterar {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 8px;
  border-top: 1px solid #707070;
  width: 190px;
  margin-top: 50px;
}

.alterar img {
  object-fit: contain;
}

.alterar p {
  color: #707070;
  margin-bottom: 4px;
}

.fotos-bastidores img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border: 1px solid #eabb4d;
}

.fotos-bastidores {
  margin-top: 80px;
}

.menor {
  height: 330px !important;
}

.btn-banner {
  display: block;
  align-self: flex-end;
  position: relative;
  top: 50px;
  margin-bottom: 100px;
}

.btn {
  font-family: "Uniwars-W00-Light";
  font-size: 16px;
  padding: 15px 30px;
  background-color: transparent;
  border-radius: 6px;
  text-decoration: none;
  border: 1px solid;
  text-align: center;
  color: var(--primary-color);
  transition: 0.3s;
  display: block;
  margin: 0 auto;
  width: 250px;
}

.btn strong {
  margin-right: 10px;
  font-family: "Uniwars-W00-Heavy-Italick";
}

.btn:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
}

@media (min-width: 990px) {
  .ultima {
    display: flex;
    align-items: flex-end;
  }
}

@media (max-width: 989px) {
  .fotos-bastidores img,
  .menor {
    height: 200px;
  }

  .fotos-bastidores img {
    /* margin-block: 20px; */
  }

  .fotos-bastidores {
    margin-top: 40px;
  }
  .infos-bastidores h3 {
    font-size: 48px;
  }

  .infos-bastidores h4 {
    font-size: 24px;
    margin-top: 10px;
  }
}
</style>
