<template>
  <section>
    <div>
      <div class="bg-noticia-unica">
        <b-container>
          <b-row>
            <b-col cols="12" lg="12">
              <div class="img-noticia-unica">
                <img src="@/assets/img/buenosaires/bg-historia.png" alt="Imagem Buenos Aires"/>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <b-container>
        <div class="box-titulo">
          <div class="proxima-etapa">
            <div class="linha-etapa"></div>
            <h3>Argentina: paixão pela velocidade</h3>
            <p class="hora-etapa">11/03/2024</p>
          </div>
        </div>

        <div class="txt-noticia textos">
          <div class="txt-buenos">
            <p>
                A temporada 2023 da Stock Car Pro Series representou o regresso da principal categoria do automobilismo brasileiro ao exterior. 
                Depois de seis anos, a caravana da competição cruzou as fronteiras e partiu para acelerar em Buenos Aires, capital da Argentina, 
                no icônico Autódromo Oscar y Juan Galvez, onde lendas como Juan Manuel Fangio e Michael Schumacher já venceram corridas.<br><br>
                
                
                Buenos Aires foi responsável por reunir um dos maiores grids da história da Pro Series. Em 2005, nada menos que 41 carros 
                correram na capital argentina, entre eles seis pilotos com passagem pela Fórmula 1: Ingo Hoffmann, Chico Serra, Raul Boesel, 
                Christian Fittipaldi, Luciano Burti e o argentino Esteban Tuero. <br><br>
                
                Em 2023, a Stock Car correu no traçado número 8 do circuito bonaerense, com 3.380 metros de extensão. Foi o circuito mais longo que a 
                categoria competiu em solo argentino. A primeira prova da Pro Series em Buenos Aires aconteceu em 30 de outubro de 2005. 
                Christian Fittipaldi marcou a pole position com Mitsubishi Lancer, enquanto o primeiro vencedor foi Giuliano Losacco, em jornada 
                fundamental para a conquista do bicampeonato naquele ano. <br><br>
                
                Curiosamente, todos os pilotos brasileiros que venceram pela Stock Car em Buenos Aires foram campeões da categoria. Além de Losacco, 
                escalaram o topo do pódio argentino Ingo Hoffmann, Cacá Bueno, Felipe Fraga e Rubens Barrichello. Em 2023, Gabriel Casagrande deu 
                sequência à sina no ano em que foi bicampeão. E o lendário ídolo local Matías Rossi, maior vencedor em atividade no TC2000, 
                fez a festa do público no autódromo. <br><br>
                
                Aliás, a paixão do argentino pelo automobilismo é histórica e marcante. Não é raro, por exemplo, ver fãs com bandeiras do seu piloto e, 
                principalmente, da sua marca preferida. O público costuma lotar os autódromos, e um reflexo disso foi visto na última visita da Stock Car 
                ao país vizinho. Em 2023, no evento que teve como ponto alto os 200 Km de Buenos Aires pelo TC2000, mais de 50 mil pessoas estiveram em Galvez.<br><br>
                
                 A última passagem da Stock Car em Buenos Aires foi de uma verdadeira festa do automobilismo brasileiro e argentino. Além de toda a presença 
                 massiva do público, os pilotos deram um verdadeiro espetáculo com grandes disputas. Felipe Fraga foi um dos grandes nomes do fim de semana 
                 com a conquista da pole e também como maior pontuador da etapa. Outro piloto que brilhou foi Gianluca Petecof, que ficou a apenas 0s009 
                 da pole e foi ao pódio. E, em grande forma, Gabriel Casagrande pavimentou mais uma parte do seu caminho rumo ao título com a vitória na 
                 Corrida 1, enquanto Matías Rossi completou um domingo de muita festa em um dos cenários mais importantes do automobilismo mundial. <br><br>
                 
                 Agora, a Stock Car se prepara para voltar à Argentina, com regresso definido para os dias 5 e 6 de outubro de 2024.
            </p>
          </div>
        </div>
      </b-container>

      <div class="secao-tags">
        <b-container>
          <b-row>
            <b-col cols="12" lg="12" class="conteudo-tags textos">
              <hr />
              <p>Tags</p>
              <div class="tags">
                <div>
                  <p>Stock Car</p>
                </div>
                <div>
                  <p>Argentina</p>
                </div>
                <div>
                  <p>Buenos Aires</p>
                </div>
              </div>

              <hr />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Historia",
};
</script>

<style scoped>

.txt-buenos p {
    color: var(--text-color);
}
.bg-noticia-unica {
  background-image: url('@/assets/img/buenosaires/bg-historia.png'); 
  min-height: 700px;
  background-blend-mode: overlay;
  background-color: rgb(0 0 0 / 60%) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.img-noticia-unica img {
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 70%;
}

.box-titulo {
  background-color: var(--primary-color);
  padding: 40px 40px 100px 40px;
}

.linha-etapa {
  opacity: 1;
  border: 3px solid;
  width: 150px;
  color: var(--text-color);
}

.box-titulo p {
  color: var(--text-color);
}

.box-titulo h3 {
  margin-top: 30px;
  color: var(--text-color);
  font-size: 35px;
  font-family: "MetronicPro-Bold";
}

.txt-noticia {
  width: 75%;
  display: grid;
  margin: 0 auto;
  margin-top: -150px;
}

.txt-noticia strong {
  font-size: 19px;
}

.textos p,
.textos div {
  font-family: "MetronicPro-Regular" !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--title-color) !important;
}

.secao-tags hr {
  background-color: #fff;
  height: 2px;
  margin-top: 70px;
  margin-block: 50px;
}

.conteudo-tags {
  width: 75%;
  margin: 0 auto;
}

.tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.tags div {
  border-radius: 5px;
  background-color: #2b262a;
  padding: 10px 25px;
}

.tags p {
  color: #707070 !important;
  margin-bottom: 0px !important;
}

@media (max-width: 989px) {
  .conteudo-tags {
    width: 95%;
  }

  .bg-noticia-unica {
    background-size: contain !important;
    min-height: auto !important;
  }

  .img-noticia-unica img {
    margin-top: 35px;
  }

  .txt-noticia {
    width: 95%;
    margin-top: 30px;
  }
}

@media (min-width: 990px) {
  .box-titulo {
    position: relative;
    top: -250px;
    width: 50%;
  }
}
</style>