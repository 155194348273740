import axios from 'axios';
import store from '@/store/index'

// const url = 'https://www.stockproseries.com.br/api/v1';
const url = process.env.VUE_APP_API_HOST;

const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers.ApiKey = '980372f6-a917-4d8d-a6b0-fc76b6b2d6c3'
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config
  },
  function (error) {
    return Promise.reject(error);
  }
)

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (err) {


  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  if (err.response.status != 404) {
    const mensagem_erro_telegram = `
🔥   Atenção\n\n 
🖥️  Aplicação: ${process.env.VUE_APP_PROJECT_NAME}\n
📅  Data: ${new Date().toLocaleString()}\n
🌐   API: ${err.response.config.url}\n
🚩  Erro: code - ${err.response.status} | erro - ${JSON.stringify(err.response.data)}\n
📄  Body: ${btoa(err.config.data)}

`;

    errorMonitoria(mensagem_erro_telegram)
  }



  if (err.response.data == 'Usuário não encontrado' || err.response.status == 401) {
    store.dispatch("deslogarUsuario");
  }
  return Promise.reject(err);
});



export const api = {
  get(endpoint) {
    const idioma = store.state.idioma;

    if (endpoint.includes('?')) {
      return axiosInstance.get(`${endpoint}&idioma=${idioma}`);
    } else {
      return axiosInstance.get(`${endpoint}?idioma=${idioma}`);
    }
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
};

export function getCep(cep) {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`)
}

export function errorMonitoria(mensagem) {
  // console.log(mensagem)
  fetch(`https://api.telegram.org/bot5307523807:AAHuxs-SIftLXbN_HQ_AqX5GsPur5TViBys/sendMessage?chat_id=-911193531&text=${encodeURIComponent(mensagem)}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}