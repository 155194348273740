<template>
  <section class="esg-page">
    <div class="main-galeao">
      <div style="z-index: 10; position: relative">
        <b-row class="galeao-content" data-anima="top">
          <b-col cols="12" class="col-card">
            <b-row>
              <div class="titulos">
                <h4 class="titulo-aux">acelere conosco!</h4>
                <p>
                  <strong>Obrigado por se cadastrar!</strong>
                  <br />Entraremos em contato com você assim que surgir uma vaga
                  com o seu perfil. Enquanto aguarda, que tal fazer parte
                  comunidade de fãs da Stock Car?
                </p>
              </div>
            </b-row>
          </b-col>
        </b-row>
        <div class="btn-item">
          <router-link :to="`/stockclub/login`" class="btn-acao"
            >VENHA PARA O STOCK CLUB</router-link
          >
        </div>
      </div>

      <div class="overlay-galeao"></div>
    </div>

    <LogosHome :show_element="show_element.rodape" />
  </section>
</template>
  
<script>
import LogosHome from "@/components/LogosHome.vue";

export default {
  name: "ObrigadoTrabalheConosco",
  data() {
    return {
      show_element: {
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
    };
  },
  components: {
    LogosHome,
  },
};
</script>

<style scoped>
.overlay-galeao {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../assets/img/bg-trabalhe-conosco-1.png");
  background-size: cover;
  background-position: center;
  z-index: 0;
  background-blend-mode: overlay;
  background-color: rgb(0 0 0 /70%);
}

.main-galeao {
  height: calc(100vh - 135px);
}

.galeao-content {
  padding-top: 200px;
  margin: 0;
}

.imgs-rodape {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
}

.imgs-rodape img {
  width: auto;
  height: 41px;
}

.container-cadastro {
  margin: 40px auto;
}
.titulos p {
  text-align: center;
  color: var(--title-color);
}
.titulo-aux {
  font-family: "Uniwars-W00-SemiBold";
  color: var(--primary-color);
  font-size: 22px;
  text-align: center;
}
.btn-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-acao {
  font-family: "Uniwars-W00-SemiBold";
  font-size: 16px;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--text-color) !important;
  border-radius: 6px;
  padding: 10px 70px;
  text-decoration: none;
  text-align: center;
  margin: 15px auto;
  margin-top: 70px;
  display: block;
}
</style>
  