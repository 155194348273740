<template>
  <b-container data-anima="top">
    <b-row>
      <b-col cols="12" lg="7" class="titulo-edicao ativacao-email">
        <h2>ATENÇÃO, <strong>SUA CONTA SERÁ DESLOGADA</strong></h2>

        <div class="mt-5">
          <p>
            Como o endereço de e-mail foi alterado, para sua segurança
            precisamos que você faça novamente a ativação.
          </p>
          <p>
            Estamos enviando o link de ativação para o e-mail cadastrado.
            <br />Verifique a caixa de entrada e o SPAM
          </p>

          <p class="destaque">
            <b-spinner
              style="
                width: 1rem;
                height: 1rem;
                border: 2px solid #e6c156;
                border-right-color: transparent;
                --bs-spinner-animation-speed: 1s;
                margin-right: 10px;
              "
              label="Large Spinner"
              variant="warning"
            ></b-spinner
            >Você será deslogado em {{ timer_deslogue < 10 ? "0" : ""
            }}{{ timer_deslogue }}
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "PendenteAtivacao",
  data() {
    return {
      timer_deslogue: 15,
    };
  },
  methods: {
    deslogarUsuario() {
      setTimeout(() => {
        this.$store.dispatch("deslogarUsuario");
        this.$router.push("/stockclub/login");
      }, 15000);

      setInterval(() => {
        this.timer_deslogue--;
      }, 1000);
    },
  },
  mounted() {
    this.deslogarUsuario();
  },
};
</script>

<style scoped>
h2 {
  color: var(--primary-color);
  font-family: "Uniwars-W00-Light";
  font-size: 20px;
  text-align: right;
  font-style: italic;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-color);
}

h2 strong {
  font-family: "Uniwars-W00-Heavy-Italick";
}

.titulo-edicao {
  margin-top: 80px;
}
.ativacao-email p {
  color: #fff;
  font-size: 18px;
}
.ativacao-email p.destaque {
  color: #e6c156;
}
</style>