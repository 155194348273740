<template>
  <div class="bg-recuperacao-senha">
    <b-container v-if="!loading && !hasParams && !login">
      <b-row>
        <b-col>
          <h4>
            Atenção, caso tenha conta na plataforma, você receberá o email para
            redefinir a senha
          </h4>
          <p>
            No e-mail que te enviamos, clique no link REDEFINIR SENHA para criar
            uma nova senha.
          </p>

          <router-link
            to="/stockclub/login"
            style="text-decoration: none; color: inherit"
            ><b-button type="submit" class="login btn"
              >LOGIN</b-button
            ></router-link
          >
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="!loading && hasParams && !login">
      <b-row>
        <b-col>
          <h4 class="heavy-italic">REDEFINIR SENHA</h4>
          <!-- <div v-if="msgErro" class="msgErro">
            Ocorreu um erro ao processar a requisição.
          </div> -->

          <b-form @submit.prevent="resetPassword" class="form-senha">
            <Notificacao
              :notificacao="notificacao_infos"
              v-if="notificacao_infos.count_timer"
              @atualizaNotificacao="notificacao_infos.count_timer = 0"
            />
            <div class="form-redefinir">
              <div class="senha-item">
                <b-form-input
                  type="password"
                  id="senha"
                  aria-describedby="password-help-block"
                  placeholder="Nova Senha"
                  required
                  v-model="password"
                  v-on:input="verificarInput"
                ></b-form-input>
                <img
                  src="@/assets/icons/eye.svg"
                  alt="Mostrar senha"
                  class="icon-eye"
                  @click="mostraSenha('senha', 'text')"
                  v-if="!show_password.senha"
                />
                <img
                  v-else
                  src="@/assets/icons/eye-off.svg"
                  alt="Ocultar senha"
                  class="icon-eye"
                  @click="mostraSenha('senha', 'password')"
                />
              </div>

              <div class="senha-item">
                <b-form-input
                  id="senhaConfirma"
                  :class="{
                    error: password != confirmPassword || errors.password,
                  }"
                  type="password"
                  placeholder="Confirmação de senha"
                  required
                  v-model="confirmPassword"
                  onpaste="return false"
                  ondrop="return false"
                  autocomplete="senhaConfirma-"
                ></b-form-input>
                <img
                  src="@/assets/icons/eye.svg"
                  alt="Mostrar senha"
                  class="icon-eye"
                  @click="mostraSenha('senhaConfirma', 'text')"
                  v-if="!show_password.senhaConfirma"
                />
                <img
                  v-else
                  src="@/assets/icons/eye-off.svg"
                  alt="Ocultar senha"
                  class="icon-eye"
                  @click="mostraSenha('senhaConfirma', 'password')"
                />
              </div>

              <b-button
                type="submit"
                class="redefinir btn"
                :disabled="password != confirmPassword || !requisitoSenha"
                >REDEFINIR</b-button
              >
              <span
                v-if="password && password != confirmPassword"
                class="feedback"
              >
                - Ambos campos de senha devem ser iguais</span
              >
              <span v-if="password && !requisitoSenha" class="feedback">
                - Senhas não atendem os requisitos obrigatórios</span
              >
            </div>
          </b-form>

          <div class="validacao-senha">
            <PasswordValidation
              :password="password"
              @senhaOk="validationSenha"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="!loading && login">
      <b-row>
        <b-col>
          <h4>Senha redefinida com sucesso!</h4>
          <p>
            Acabamos de redefinir a sua senha. <br />Clique no botão LOGIN para
            entrar na sua conta.
          </p>
          <router-link
            to="/stockclub/login"
            style="text-decoration: none; color: inherit"
            ><b-button type="submit" class="login btn"
              >LOGIN</b-button
            ></router-link
          >
        </b-col>
      </b-row>
    </b-container>

    <div v-if="loading" class="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import PasswordValidation from "./PasswordValidation.vue";

export default {
  name: "RedefinirSenha",
  components: {
    PasswordValidation,
  },
  data() {
    return {
      loading: false,
      hasParams: false,
      requisitoSenha: false,
      msgErro: false,
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
      confirmPassword: "",
      login: false,
      password: "",
      hash: "",
      code: "",
      errors: {
        senha: false,
      },
      show_password: {
        senha: false,
        senhaConfirma: false,
      },
    };
  },
  methods: {
    mostraSenha(el, type) {
      this.show_password[el] = !this.show_password[el];
      document.getElementById(el).setAttribute("type", type);
    },
    resetPassword() {
      if (this.password == this.confirmPassword || this.requisitoSenha) {
        this.msgErro = false;
        this.loading = true;

        this.notificacao_infos = {
          variant: "primary",
          mensagem: "",
          count_timer: 0,
        };

        const data = {
          confirmPassword: this.confirmPassword,
          password: this.password,
          hash: this.$route.query.h,
          code: this.$route.query.c,
        };
        this.$emit("loading", true);
        api
          .post("/Registro/resetar-senha", data)
          .then(() => {
            this.login = true;
            //this.$router.push("/stockclub/login");
          })
          .catch((error) => {
            this.msgErro = true;
            console.log(error);
            this.notificacao_infos = {
              variant: "danger",
              mensagem: "Ocorreu um erro ao processar a requisição.",
              count_timer: 10,
            };
          })
          .finally(() => {
            this.loading = false;
            this.$emit("loading", false);
          });
      }
    },

    validationSenha(data) {
      this.requisitoSenha = data;
    },

    verificarInput() {
      if (this.password === "") {
        this.requisitoSenha = false;
      }
    },
  },

  mounted() {
    if (localStorage.token) {
      this.$router.push("/stockclub/atualizar-senha");
    } else {
      this.hasParams = this.$route.query.h && this.$route.query.c;
    }
  },
};
</script>

<style scoped>
.bg-recuperacao-senha h4 {
  font-size: 25px;
  line-height: 27px;
  color: var(--title-color);
  margin-bottom: 50px;
  text-align: center;
  width: 600px;
  margin: 0 auto;
  margin-top: 100px;
}

.heavy-italic {
  font-family: "Uniwars-W00-Heavy-Italick" !important;
}

.bg-recuperacao-senha p {
  font-size: 16px;
  color: var(--title-color);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.login {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.login:hover {
  color: var(--primary-color) !important;
  border-color: var(--title-color) !important;
  background-color: var(--title-color) !important;
}

.btn {
  width: 400px;
  margin: 0 auto;
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

#div-true h4 {
  font-family: "Uniwars-W00-Heavy-Italick";
  margin-bottom: 30px;
}

.redefinir {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.redefinir:hover {
  color: var(--primary-color);
  border-color: var(--title-color);
  background-color: var(--title-color);
}
form {
  max-width: 400px;
  margin: 20px auto;
}
.form-control {
  color: var(--title-color);
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  margin-block: 20px;
}

input::placeholder {
  color: var(--primary-color);
}

.form-redefinir {
  max-width: 300px;
  margin: 0 auto;
}

.validacao-senha {
  margin-top: 50px;
}

input.error,
textarea.error,
select.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}

input.error:hover,
input.error:focus,
textarea.error:hover,
textarea.error:focus,
select.error:hover,
select.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}

.msgErro {
  color: #ff1717 !important;
  text-align: center;
  margin-top: 50px;
}

.loading {
  min-height: 50vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feedback {
  color: #fff;
  margin-top: 20px;
  display: block;
  text-align: center;
  font-size: 13px;
}

@media (max-width: 989px) {
  .password_strength {
    display: grid;
    max-width: 400px;
    margin: 0 auto;
    gap: 10px;
  }

  .btn {
    width: 100%;
  }

  .bg-recuperacao-senha h4 {
    font-size: 20px;
    margin-top: 70px;
    width: 100%;
  }

  .bg-recuperacao-senha p {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .bg-recuperacao-senha {
    height: auto;
  }

  .form-control {
    width: 100%;
  }
}
.form-senha div {
  max-width: 100%;
}
.senha-item {
  position: relative;
}
.icon-eye {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
</style>