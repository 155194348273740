<template>
  <section class="esg-page">
    <div class="main-galeao margin-row">
      <div style="z-index: 10; position: relative">
        <b-row
          class="row-cards galeao-content align-items-center"
          data-anima="top"
        >
          <b-col cols="0" md="4" sm="0" class="col-card">
            <div class="box-title">
              <h4>
                O impacto positivo da maior categoria do automobilismo
                brasileiro
              </h4>
            </div>
          </b-col>
          <b-col class="oq-esg primeiro-item">
            <p>
              <span>O QUE É ESG?</span>
              ESG é uma abordagem usada para avaliar o quanto uma corporação
              trabalha em prol de objetivos sociais e não apenas para gerar
              lucro. Visando melhorar a sociedade na qual está inserida e também
              permite validar o quanto a empresa tem consciência de seu papel
              como empregadora e agente social.
            </p>
          </b-col>
        </b-row>
        <b-row class="row-cards" data-anima="top" style="margin-top: 30px">
          <b-col class="oq-esg">
            <p>
              O movimento para a criação deste tipo de abordagem surgiu no fim
              da década de 1960, quando a oposição da opinião pública dos
              Estados Unidos à Guerra do Vietnã começou a exigir que as empresas
              do país levassem em consideração aspectos que iriam além do lucro
              na hora de fechar seus negócios. Mas apesar deste senso moral
              datar desta época, foi apenas em 2004 que o que conhecemos hoje
              como ESG tomou forma.
            </p>
          </b-col>
        </b-row>
      </div>

      <div class="overlay-galeao"></div>
    </div>

    <b-container class="bv-row pilares-container" data-anima="bottom">
      <b-row>
        <b-col class="titulo-centralizado">
          <h3 class="titulo-aux margin-t">
            São três os pilares que compõem essa abordagem:
          </h3>
        </b-col>
      </b-row>
      <div class="pilares">
        <div class="pilares-item">
          <img src="@/assets/img/pilares-eco.png" alt="pilares-eco" />
          <h5>Enviromental</h5>
          <p>Pilar Ambiental</p>
        </div>
        <div class="pilares-item">
          <img src="@/assets/img/pilares-social.png" alt="pilares-eco" />
          <h5>Social</h5>
          <p>Pilar Social</p>
        </div>
        <div class="pilares-item">
          <img src="@/assets/img/pilares-gov.png" alt="pilares-eco" />
          <h5>Governance</h5>
          <p>Pilar Governança</p>
        </div>
      </div>
    </b-container>

    <b-container class="bv-row esg-da-stock" data-anima="bottom">
      <b-row>
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h2 class="titulo">
            <strong><i>O ESG DA STOCK CAR</i></strong>
          </h2>
          <hr class="linha-titulo" />

          <h3 class="titulo-aux">PILAR AMBIENTAL</h3>
        </b-col>
      </b-row>

      <b-row class="">
        <b-col cols="12" lg="6" class="hist-content img-hist carbono-neutro-bg">
        </b-col>
        <b-col cols="12" lg="6" class="hist-content box-texto">
          <div class="linha-noticia"></div>
          <h2>EVENTO CARBONO NEUTRO</h2>
          <p style="color: var(--text-color); font-weight: bold">
            Neutralização da Emissão de Carbono de todas as etapas
          </p>
          <p style="color: var(--text-color)">
            Em parceria com a Orma, empresa que trabalha com neutralização e
            compra de créditos de carbono, a Stock Car neutraliza todas as
            emissões geradas em um final de semana de corrida, sendo assim, um
            evento carbono neutro.
          </p>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="12" lg="6" class="hist-content box-texto">
          <div class="linha-noticia"></div>
          <h2>EVENTO LIVRE DE RESÍDUOS</h2>
          <p style="color: var(--text-color); font-weight: bold">
            Economia circular: rerrefino do óleo lubrificante utilizado pelas
            equipes
          </p>
          <p style="color: var(--text-color)">
            LWART Soluções Ambientes, empresa 100% brasileira, faz toda coleta
            de óleo lubrificante das equipes da F4 no brasil, fazendo seu
            transporte e depois o rerrefino. Com isso recebemos o Certificado de
            Destinação Final, documento de valor legal que assegura a
            conformidade com as normas ambientais.
          </p>
        </b-col>
        <b-col cols="12" lg="6" class="hist-content img-hist livre-residuos-bg">
        </b-col>
      </b-row>
    </b-container>

    <b-container class="bv-row mt-5" data-anima="bottom">
      <b-row class="mb-5">
        <b-col class="titulo-centralizado">
          <h3 class="titulo-aux">PILAR SOCIAL</h3>
        </b-col>
      </b-row>

      <b-row class="">
        <b-col cols="12" lg="6" class="hist-content img-hist instituto-bg">
        </b-col>
        <b-col cols="12" lg="6" class="hist-content box-texto">
          <div class="linha-noticia"></div>
          <h2>APOIO AO INSTITUTO INGO HOFFMAN</h2>

          <p style="color: var(--text-color)">
            Possuímos um trabalho sólido de apoio ao Instituto Ingo Hoffmann,
            que leva o nome e é presidido pelo maior campeão da história da
            Stock Car. Fundado em 2005, o Instituto auxilia no tratamento de
            crianças com câncer, oferecendo acompanhamento individual, apoio
            emocional e até mesmo moradia para aqueles que não têm condições de
            mudar de cidade para fazer o tratamento.
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="bv-row mt-5" data-anima="bottom">
      <div class="pilares social-itens">
        <div class="pilares-item">
          <img src="@/assets/img/bilheteria.png" alt="bilheteria" />
          <h5>Bilheteria</h5>
          <p>
            Parte da bilheteria arrecadada durante a temporada é doada para
            ajudar na manutenção do Instituto.
          </p>
        </div>
        <div class="pilares-item">
          <img src="@/assets/img/img-nft.png" alt="img-nft" />
          <h5>NFT</h5>
          <p>
            Em 2022 a categoria lançou sua primeira coleção de NFTs, a Hall of
            Fame, e parte da renda obtida com a venda da coleção é revertida
            para o instituto.
          </p>
        </div>
        <div class="pilares-item">
          <img src="@/assets/img/playforcase.png" alt="playforcase" />
          <h5>Play for a Cause</h5>
          <p>
            Parceria com a plataforma que realiza leilões beneficientes de
            experiências e itens esportivos únicos.
          </p>
        </div>
      </div>
    </b-container>

    <b-container class="bv-row mt-5" data-anima="bottom">
      <b-row class="mb-5">
        <b-col class="titulo-centralizado">
          <h3 class="titulo-aux">GOVERNANÇA</h3>
        </b-col>
      </b-row>

      <b-row class="">
        <b-col cols="12" lg="6" class="hist-content img-hist governanca-bg">
        </b-col>
        <b-col cols="12" lg="6" class="hist-content box-texto">
          <div class="linha-noticia"></div>
          <h2>TRANSPARÊNCIA, HONESTIDADE, INTEGRIDADE E RESPEITO</h2>

          <p style="color: var(--text-color)">
            Acreditamos em um local de trabalho sem discriminação, inclusivo e
            que preza pela confiança e transparência com nossos acionistas,
            parceiros, patrocinadores e colaboradores, seguindo os Princípios e
            Direitos Fundamentais declarados pela Organização Internacional do
            Trabalho (OIT). Prezamos pelo bem-estar físico e mental de nossos
            funcionários, ao proporcionar um ambiente de trabalho seguro,
            inspirador e equilibrado, estendendo esse cuidado do ambiente
            corporativo ao ambiente de competição, que engloba também atletas,
            equipes, clientes, fãs, fornecedores e trabalhadores terceirizados.
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="bv-row mt-5" data-anima="bottom">
      <b-row class="mb-5">
        <b-col class="titulo-centralizado">
          <h3 class="titulo-aux">
            Sabemos que ainda têm muito a ser feito,<br />
            isso é só o começo.
          </h3>
        </b-col>
      </b-row>
    </b-container>

    <LogosHome :show_element="show_element.rodape" />
  </section>
</template>
  
<script>
import LogosHome from "@/components/LogosHome.vue";
import { EventBus } from "@/main.js";

export default {
  data() {
    return {
      videoId: "PJ3naMUEYHE",
      modalOpen: false,

      show_element: {
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
    };
  },
  components: {
    LogosHome,
  },

  methods: {
    verificaElemento(data = null) {
      const categoria = data || this.$route.params.categoria;

      this.show_element = {
        rodape: {
          montadoras: true,
          patrocinadores: true,
          fornecedores: true,
          mediaPartners: true,
        },
      };
      switch (categoria) {
        case "proseries": {
          this.show_element = {
            rodape: {
              montadoras: true,
              patrocinadores: true,
              fornecedores: true,
              mediaPartners: true,
            },
          };
          break;
        }

        case "stock-series": {
          this.show_element = {
            rodape: {
              montadoras: true,
              patrocinadores: true,
              fornecedores: true,
              mediaPartners: true,
            },
          };
          break;
        }

        case "e-stock": {
          this.show_element = {
            rodape: {
              montadoras: false,
              patrocinadores: false,
              fornecedores: false,
              mediaPartners: false,
            },
          };
          break;
        }
      }
    },
  },

  mounted() {
    this.verificaElemento();
  },

  created() {
    EventBus.$on("updateRouter", (data) => {
      this.verificaElemento(data);
    });
  },
};
</script>

<style scoped>
.overlay-galeao {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../assets/img/bg-esg.png");
  background-size: cover;
  background-position: center;
  z-index: 0;
  background-blend-mode: overlay;
  background-color: var(--overlay);
}

.main-galeao {
  height: calc(100vh - 135px);
}

.galeao-content {
  padding-top: 400px;
}

.imgs-rodape {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
}

.imgs-rodape img {
  width: auto;
  height: 41px;
}

.titulo-centralizado {
  padding-top: 20px;
}

.hist-content {
  /* height: 420px; */
}

.box-texto {
  padding: 80px !important;
  background-color: var(--primary-color);
}

.box-texto h2 {
  margin-bottom: 20px;
  font-family: "MetronicPro-Bold";
  font-size: 35px;
  line-height: 36px;
  color: var(--text-color);
}

.button-link {
  border: 1px solid #0e080d;
  text-decoration: none;
  border-radius: 10px;
  padding: 12px 100px 16px 100px;
  float: left;
  cursor: pointer;

  font-family: "MetronicPro-Semibold";
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
  margin-top: 20px;
}

.button-link::first-letter {
  text-transform: uppercase;
}

.depoimento {
  background: #e6c156 0% 0% no-repeat padding-box;
  opacity: 0.9;
  max-width: 460px;
  width: 100%;
  max-height: 480px;
  height: 100%;
}

.depoimento-space {
  display: flex;
  align-content: center;
  flex-wrap: inherit;
}

.depoimento-img-piloto {
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.img-depoimento-space {
  width: 50%;
  height: 100%;
  background-color: red;
  position: absolute;
  top: -50px;
  left: 22px;
}

.depoimento-titulo {
  color: #0e080d;
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  width: 40%;
  height: 90%;
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
}
.depoimento-titulo strong {
  color: #0e080d;
  font-family: "MetronicPro-Bold";
  font-size: 20px;
}

.depoimento-desc {
  height: 50%;
  padding-left: 32px;
  padding-right: 32px;
  text-align: left;
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  color: #0e080d;
}

.circuito {
  min-width: 100px;
  min-height: 100px;
}

.titulo-noticia {
  width: 360px;
  font-family: MetronicPro-SemiBold;
  font-size: 1.3rem;
  color: var(--title-color);
  margin-bottom: 0px;
  margin-top: 20px;
  line-height: 28px;
  text-align: left;
}

.title-num-galeao {
  color: var(--primary-color);
}

.desc-estrutura {
  background: transparent linear-gradient(90deg, #2b262a 0%, #0e080d00 92%) 0%
    0% no-repeat padding-box;
  color: #ffffff;
  margin-top: 40px;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.title-estrutura {
  font-family: MetronicPro-Bold;
  letter-spacing: 0px;
  color: #707070;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.p-estrutura {
  font-family: MetronicPro-Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
}

.line-estrutura {
  border: 1px solid #707070;
  margin-bottom: 12px;
}

.img-estrutura {
  width: 100%;
}

.linha-branca {
  border: 1px solid #ffffff;
  margin-bottom: 40px;
  margin-top: 40px;
}

.title-main-galeao {
  color: #ffffff;
  font-family: MetronicPro-Bold;
  letter-spacing: 0px;
  font-size: 28px;
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 80px;
}

.responsive-mobile {
  display: none;
}

.container-novidades .boxes-noticias .coluna-noticia {
  border-right: none;
  border-left: none;
}

.img-noticia {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.play-icon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.play-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 35px solid rgba(255, 255, 255, 0.9); /* Cor e transparência do ícone */
}

.boxes-noticias {
  padding-top: 30px;
  padding-bottom: 30px;
}

.boxes-noticias p {
  font: normal normal 300 17px/25px MetronicPro-Light;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 30px;
}

.boxes-noticias h2 {
  font: normal normal bold 22px/26px MetronicPro-Bold;
  letter-spacing: 0px;
  margin: 17px 0;
  color: var(--primary-color);
}

.boxes-noticias a,
.boxes-noticias a:hover {
  color: var(--primary-color);
}

.boxes-noticias img {
  margin-bottom: 10px;
  border: 1px solid var(--primary-color);
}

.boxes-noticias .coluna-noticia {
  padding-right: 20px;
  border-right: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  padding-left: 20px;
}

.modal-body {
  display: flex !important;
  justify-content: center !important;
}

.col-card {
  margin-bottom: 0px !important;
}

.circuito-mobile {
  display: flex;
  justify-content: center;
}

@media (max-width: 991px) {
  .linha-primary {
    border: 1px solid var(--primary-color);
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .hist-content {
    height: auto;
  }

  .button-link {
    padding: 12px 16px;
  }

  .historia-bg {
    background-position: center;
  }

  .button-link {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .responsive-manual {
    display: none;
  }

  .responsive-mobile {
    display: initial;
  }

  .imgs-rodape img {
    width: 120px;
  }

  .logo-mobile {
    position: absolute;
    left: 50%;
    max-width: 264px;
    transform: translate(-50%, -50%);
    bottom: -248px;
  }

  .img-hist {
    height: 244px;
  }
}

@media (max-height: 660px) {
  .main-galeao {
    height: 78vh;
  }
}

.box-title {
  background: var(--primary-color);
  padding: 60px 30px;
}
.box-title h4::before {
  content: "";
  width: 130px;
  height: 4px;
  background: #000;
  display: block;
  margin-bottom: 25px;
}
.box-title h4 {
  font-family: MetronicPro-Regular;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: #000;
}
.oq-esg.primeiro-item {
  /* background: red; */
  margin-left: 40px;
  max-width: 50%;
}
.oq-esg {
  max-width: 90%;
}
.oq-esg p {
  color: #ffffff;
  font-size: 18px;
  line-height: 25px;
  /* font-family: MetronicPro-Regular; */
  font-weight: normal;
}
.oq-esg p span {
  color: var(--primary-color);
  font-size: 22px;
  font-family: MetronicPro-Regular;
  font-weight: bold;
  display: block;
  margin-bottom: 15px;
}
.titulo-aux {
  font-family: "Uniwars-W00-SemiBold";
  color: var(--primary-color);
  font-size: 22px;
}
.margin-t {
  margin-top: 100px;
}
.pilares {
  display: flex;
  justify-content: space-between;
  /* gap: 100px; */
  margin-top: 50px;
}
.pilares-item {
  border: 1px solid var(--primary-color);
  padding: 40px 20px;
  border-radius: 10px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}
.pilares-item img {
  max-width: 110px;
  margin: 0 auto;
  text-align: center;
}
.pilares-item h5 {
  font-family: "Uniwars-W00-SemiBold";
  font-size: 18px;
  color: var(--primary-color);
  margin: 30px 0 20px 0;
}
.pilares-item p {
  color: #ffffff;
  font-size: 22px;
}
.esg-da-stock {
  margin-top: 100px;
}
.esg-da-stock h3 {
  margin: 50px 0;
}
.carbono-neutro-bg {
  background-image: url("../assets/img/carbono-neutro.png");
  background-size: cover;
}

.livre-residuos-bg {
  background-image: url("../assets/img/livre-residuos.png");
  background-size: cover;
}
.instituto-bg {
  background-image: url("../assets/img/instituto.png");
  background-size: cover;
}
.social-itens {
  /* background: red; */
}
.social-itens .pilares-item {
  border: none;
  padding: 0;
  text-align: center;
}
.social-itens .pilares-item img {
  max-width: 300px;
}
.social-itens .pilares-item p {
  font-size: 18px;
}
.governanca-bg {
  background-image: url("../assets/img/governanca.png");
  background-size: cover;
}
@media (max-width: 1500px) {
  .galeao-content {
    padding-top: 300px;
  }
  .pilares-container {
    margin-top: 200px;
  }
  .box-texto h2 {
    font-size: 28px;
  }
  .box-texto {
    padding: 60px !important;
  }
}
@media (max-width: 1100px) {
  .box-title h4 {
    font-size: 22px;
  }
  .pilares-container {
    /* margin-top: 550px; */
    margin-top: 300px;
  }
  .oq-esg.primeiro-item {
    margin: 0;
    max-width: 100%;
    margin-top: 40px;
  }
  .oq-esg p span {
    font-size: 18px;
  }
  .oq-esg p {
    font-size: 16px;
  }
  .titulo-aux {
    font-size: 18px;
    text-align: left;
  }
  .pilares {
    gap: 20px;
    overflow-x: scroll;
  }
  .pilares::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
    height: 1px;
  }

  .pilares-item {
    min-width: 270px !important;
  }
  .box-texto {
    padding: 30px 25px !important;
  }
  .social-itens {
    gap: 20px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  @media (max-width: 400px) {
    .esg-page {
      overflow-x: hidden;
    }
    .pilares-container {
      margin-top: 550px;
    }
    .margin-t {
      margin-top: 250px;
    }
  }
}
</style>
  