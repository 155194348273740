<template>
  <div class="form-login">
    <h3>
      Cadastre-se no Stock Club <br />e faça parte da comunidade Stock Car
    </h3>
    <!-- <div v-if="error" class="msgErro">{{ errorMessage }}</div> -->
    <Notificacao
      :notificacao="notificacao_infos"
      v-if="notificacao_infos.count_timer"
      @atualizaNotificacao="notificacao_infos.count_timer = 0"
    />

    <b-form @submit.prevent="onSubmit" v-if="!loading">
      <b-form-group id="input-group-1" label-for="input-1">
        <input-with-label
          id="input-1"
          label="Email"
          type="email"
          v-model="login.email"
          :input-class="{
            'my-input-class': true,
            error: error,
          }"
          label-class-custom="my-label-class"
          required
        />

        <div @click.prevent="toggleMostrarSenha" class="btn-view">
                            <img v-if="mostrarSenha"
                                src="@/assets/icons/eye-off.svg"
                                alt="Icone de visualização"
                                class="view"
                            />
                            <img v-else
                                src="@/assets/icons/eye.svg"
                                alt="Icone de visualização"
                                class="view"
                            />
                        </div>

        <input-with-label
          id="text-password"
          label="Senha"
          :type="mostrarSenha ? 'text' : 'password'"
          aria-describedby="password-help-block"
          v-model="login.senha"
          :input-class="{
            'my-input-class': true,
            error: error,
          }"
          label-class-custom="my-label-class"
          required
        />
        <router-link class="lembrar-senha" to="/stockclub/esqueci-minha-senha"
          >esqueci minha senha</router-link
        >
        <br />

        <b-button type="submit" class="acessar btn">ACESSAR</b-button>

        <router-link
          to="/stockclub/cadastro"
          style="text-decoration: none; color: inherit"
          class="criar-conta btn"
          >CRIAR CONTA</router-link
        >
      </b-form-group>
    </b-form>
    <div v-if="loading" class="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import InputWithLabel from "@/components/InputWithLabel.vue";

export default {
  name: "FormLogin",
  components: {
    InputWithLabel,
  },
  props: {
    email_cadastro: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mostrarSenha: false,
      loading: false,
      error: false,
      login: {
        email: "",
        senha: "",
      },
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
    };
  },
  methods: {
     toggleMostrarSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },
    onSubmit() {
      window.scrollTo(0, 0);
      this.error = false;
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };
      if (this.login.senha) {
        this.loading = true;
        this.$store
          .dispatch("logarUsuario", this.login)
          .then(() => {
            this.$store.dispatch("getUsuario").then(() => {
              setTimeout(() => {
                this.$router.push({
                  path: "/stockclub/home",
                  query: this.$route.query,
                });
              }, 200);
            });
          })
          .catch((error) => {
            console.log(error);
            this.error = true;
            this.loading = false;
            this.notificacao_infos = {
              variant: "danger",
              mensagem: error.response
                ? error.response.data
                : "Algo deu errado na requisição",
              count_timer: 10,
            };
          });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.email_cadastro) {
          this.login.email = this.email_cadastro;
          document.querySelector("#text-password").focus();
        }
      }, 1000);
    });
  },
};
</script>

<style scoped>
.form-login {
  margin-top: 50px;
}
h3 {
  font-size: 19px;
  text-align: center;
  line-height: 27px;
  font-style: italic;
  font-family: "MetronicPro-SemiBold";
  color: var(--primary-color);
  margin-bottom: 30px;
}

.form-control {
  color: var(--title-color);
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  margin-block: 20px;
}

input::placeholder {
  color: var(--primary-color);
}

.lembrar-senha {
  text-align: left;
  font-size: 12px;
  font-family: "MetronicPro-Regular";
  letter-spacing: 0.4px;
  color: #939393;
  text-decoration: none;
}
.lembrar-senha:hover {
  text-decoration: underline;
}

.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
  width: 100%;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.acessar:hover {
  color: var(--primary-color);
  border-color: var(--title-color);
  background-color: var(--title-color);
}

.criar-conta {
  color: var(--primary-color);
  border-color: var(--title-color) !important;
  background-color: var(--title-color);
}

.criar-conta:hover {
  color: var(--text-color);
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color);
}

.loading {
  min-height: 37vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
input:focus,
input:hover {
  border-color: #fff;
}
input.error,
textarea.error,
select.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}

input.error:hover,
input.error:focus,
textarea.error:hover,
textarea.error:focus,
select.error:hover,
select.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}

.msgErro {
  color: #ff1717 !important;
  text-align: center;
  margin-top: 50px;
}

.btn-view {
      position: relative;
}

.view {
    top: 16px;
    position: absolute;
    right: 13px;
    cursor: pointer;
    z-index: 999999999;
}

@media (max-width: 989px) {
  .form-login h3 {
    font-size: 18px;
  }
  .criar-conta {
    margin-top: 10px;
  }
  .lembrar-senha {
    padding: 5px;
  }
}
</style>