<template>
  <section>
    <div>
      <div class="bg-noticia-unica">
        <b-container>
          <b-row>
            <b-col cols="12" lg="12">
              <div class="img-noticia-unica">
                <img src="@/assets/img/imgs-bh/img-hist.png" alt="Imagem Buenos Aires"/>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <b-container>
        <div class="box-titulo">
          <div class="proxima-etapa">
            <div class="linha-etapa"></div>
            <h3>Mais que uma corrida!</h3>
            <p class="hora-etapa">10/07/2024</p>
          </div>
        </div>

        <div class="txt-noticia textos">
          <div class="txt-buenos">
            <p>
                O BH Stock Festival promete experiências diferenciadas e múltiplas para quem for assistir à prova in loco. As atividades envolvem entretenimento para toda a família e com preços acessíveis. A Esplanada do Mineirão será transformada em uma enorme Fan Zone, que receberá shows musicais, festival gastronômico, kart, autorama, espaço kids, simuladores, roda gigante e muito mais.
            </p>
          </div>
        </div>
      </b-container>

      <div class="secao-tags">
        <b-container>
          <b-row>
            <b-col cols="12" lg="12" class="conteudo-tags textos">
              <hr />
              <p>Tags</p>
              <div class="tags">
                <div>
                  <p>Stock Car</p>
                </div>
                <div>
                  <p>Belo Horizonte</p>
                </div>
              </div>

              <hr />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "HistoriaBH",
};
</script>

<style scoped>

.txt-buenos p {
    color: var(--text-color);
}
.bg-noticia-unica {
  background-image: url('@/assets/img/imgs-bh/img-hist.png'); 
  min-height: 700px;
  background-blend-mode: overlay;
  background-color: rgb(0 0 0 / 60%) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.img-noticia-unica img {
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 70%;
}

.box-titulo {
  background-color: var(--primary-color);
  padding: 40px 40px 100px 40px;
}

.linha-etapa {
  opacity: 1;
  border: 3px solid;
  width: 150px;
  color: var(--text-color);
}

.box-titulo p {
  color: var(--text-color);
}

.box-titulo h3 {
  margin-top: 30px;
  color: var(--text-color);
  font-size: 35px;
  font-family: "MetronicPro-Bold";
}

.txt-noticia {
  width: 75%;
  display: grid;
  margin: 0 auto;
  margin-top: -150px;
}

.txt-noticia strong {
  font-size: 19px;
}

.textos p,
.textos div {
  font-family: "MetronicPro-Regular" !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--title-color) !important;
}

.secao-tags hr {
  background-color: #fff;
  height: 2px;
  margin-top: 70px;
  margin-block: 50px;
}

.conteudo-tags {
  width: 75%;
  margin: 0 auto;
}

.tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.tags div {
  border-radius: 5px;
  background-color: #2b262a;
  padding: 10px 25px;
}

.tags p {
  color: #707070 !important;
  margin-bottom: 0px !important;
}

@media (max-width: 989px) {
  .conteudo-tags {
    width: 95%;
  }

  .bg-noticia-unica {
    background-size: contain !important;
    min-height: auto !important;
  }

  .img-noticia-unica img {
    margin-top: 35px;
  }

  .txt-noticia {
    width: 95%;
    margin-top: 30px;
  }
}

@media (min-width: 990px) {
  .box-titulo {
    position: relative;
    top: -250px;
    width: 50%;
  }
}
</style>