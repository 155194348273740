<template>
  <section>
    <NoticiaUnica />
  </section>
</template>

<script>
import NoticiaUnica from "@/components/NoticiaUnica.vue";

export default {
  name: "Noticia",
  components: {
    NoticiaUnica
    
  },
};
</script>

