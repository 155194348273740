<template>
  <!---------------------- MENU ------------------------>

  <div>
    <!------------------------ NOTÍCIAS ------------------------->

    <div class="container-novidades margin-row">
      <b-row>
        <b-col class="titulo-novidade titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 v-if="$route.params.categoria == 'formula-4'" class="titulo">
            <strong>{{ $t("title.novidadestitle1") }}</strong>
            {{ $t("title.novidadestitle2") }}
          </h1>
          <h1 v-else class="titulo">
            <strong>NOVIDADES</strong> {{ ano_selecionado }}
          </h1>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>

      <b-row>
        <b-col class="titulo-novidade">
          <b-container class="bv-row noticias">
            <div class="grid-item">
              <div style="position: relative">
                <label for="">Pesquisa</label>

                <b-input-group class="input-search">
                  <b-form-input
                    type="text"
                    id="search-2"
                    :value="searchValue"
                    :state="localState"
                    class="my-input-class"
                    placeholder="Pesquisar"
                    @input="handleInput($event)"
                    @keydown.enter="valueInputSearch"
                    @focus="focused = true"
                    @blur="focused = false"
                  />
                  <b-input-group-append>
                    <span class="input-icon" @click="valueInputSearch">
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Interface / Search_Magnifying_Glass">
                          <path
                            id="Vector"
                            d="M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                            stroke="var(--primary-color)"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </svg>
                    </span>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="item-select">
                <label for="">Escolha a temporada</label>
                <b-form-select
                  class="select form-control"
                  v-model="ano_selecionado"
                  :options="anos_options"
                  @change="changeAno"
                  required
                ></b-form-select>
              </div>
            </div>
          </b-container>
        </b-col>
      </b-row>

      <div>
        <b-tabs content-class="mt-3">
          <b-tab
            v-if="$route.params.categoria == 'formula-4'"
            :title="$t('title.noticias')"
            active
          >
            <b-container class="bv-row noticias">
              <Noticias
                :sections="sections"
                :ano_selecionado="ano_selecionado"
              />
            </b-container>
          </b-tab>

          <b-tab v-else title="Notícias" active>
            <b-container class="bv-row noticias">
              <Noticias
                :pesquisa="pesquisa"
                :sections="sections"
                :ano_selecionado="ano_selecionado"
              />
            </b-container>
          </b-tab>

          <b-tab
            v-if="$route.params.categoria == 'formula-4'"
            :title="$t('title.galerias')"
          >
            <b-container class="galeria">
              <b-row v-if="!loading">
                <b-col
                  cols="12"
                  lg="4"
                  class="box-galeria"
                  v-for="(item, index) in galeriaItens"
                  :key="`${item.id}-${index}`"
                >
                  <router-link
                    :to="`/stockcar/${
                      $route.params.categoria || 'proseries'
                    }/galeria/${item.id}`"
                    ><img class="borda" :src="item.imagemGaleria[0]"
                  /></router-link>
                  <h3>{{ item.titulo }}</h3>
                </b-col>
              </b-row>
              <b-row v-if="loading">
                <b-col cols="12" lg="4" class="box-galeria">
                  <b-skeleton-img v-if="loading"></b-skeleton-img>
                </b-col>
                <b-col cols="12" lg="4" class="box-galeria">
                  <b-skeleton-img v-if="loading"></b-skeleton-img>
                </b-col>
                <b-col cols="12" lg="4" class="box-galeria">
                  <b-skeleton-img v-if="loading"></b-skeleton-img>
                </b-col>
              </b-row>

              <div
                class="link-router"
                v-if="
                  $route.name == 'Novidades' && !disabledBtn && !galeria_ativa
                "
              >
                <a
                  v-if="$route.params.categoria == 'formula-4'"
                  href="#"
                  class="btn-stock"
                  name="carregar-mais"
                  @click.prevent="getProximaPagina"
                  >{{ $t("button.carregar-mais") }}</a
                >
                <a
                  v-else
                  href="#"
                  class="btn-stock"
                  name="carregar-mais"
                  @click.prevent="getProximaPagina"
                  >Carregar mais</a
                >
              </div>
            </b-container>
          </b-tab>

          <b-tab v-else title="Galerias">
            <b-container class="galeria">
              <b-row v-if="!loading">
                <b-col
                  cols="12"
                  lg="4"
                  class="box-galeria"
                  v-for="(item, index) in galeriaItens"
                  :key="`${item.id}-${index}`"
                >
                  <router-link
                    :to="`/stockcar/${
                      $route.params.categoria || 'proseries'
                    }/galeria/${item.id}`"
                    ><img class="borda" :src="item.imagemGaleria[0]"
                  /></router-link>
                  <h3>{{ item.titulo }}</h3>
                </b-col>
              </b-row>
              <b-row v-if="loading">
                <b-col cols="12" lg="4" class="box-galeria">
                  <b-skeleton-img v-if="loading"></b-skeleton-img>
                </b-col>
                <b-col cols="12" lg="4" class="box-galeria">
                  <b-skeleton-img v-if="loading"></b-skeleton-img>
                </b-col>
                <b-col cols="12" lg="4" class="box-galeria">
                  <b-skeleton-img v-if="loading"></b-skeleton-img>
                </b-col>
              </b-row>

              <div
                class="link-router"
                v-if="
                  $route.name == 'Novidades' && !disabledBtn && !galeria_ativa
                "
              >
                <a
                  href="#"
                  class="btn-stock"
                  name="carregar-mais"
                  @click.prevent="getProximaPagina"
                  >Carregar mais</a
                >
              </div>
            </b-container>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

import Noticias from "@/components/Noticias.vue";
export default {
  name: "Novidades",
  components: {
    Noticias,
  },
  data() {
    return {
      disabledBtn: false,
      galeria_ativa: true,
      searchValue: "",
      galeriaItens: [],
      paginacao: {
        tamanhoPagina: 10,
        numeroPagina: 0,
      },
      categoria: "",
      loading: false,
      sections: {
        primeira: true,
        segunda: true,
        terceira: false,
      },
      focused: false,
      localState: this.state,
      debounceTimer: null,
      pesquisa: "",
      searchValueAtual: "",
      anos_options: [
        {
          value: "2023",
          text: "2023",
        },
        {
          value: "2024",
          text: "2024",
        },
      ],
      ano_selecionado: new Date().getFullYear(),
    };
  },
  methods: {
    changeAno() {
      // teste
      let page = {
        tamanhoPagina: 12,
        numeroPagina: 1,
      };
      this.galeriaItens = [];

      this.fetchGaleria(
        mapCategorias[this.$route.params.categoria || "proseries"],
        page
      );
    },
    getProximaPagina() {
      let page = {
        tamanhoPagina: 12,
        numeroPagina: this.paginacao.numeroPagina + 1,
      };
      this.fetchGaleria(
        mapCategorias[this.$route.params.categoria || "proseries"],
        page
      );
    },
    handleSearchInput(value) {
      // Atualiza o valor de pesquisa
      this.searchValue = value;
    },
    fetchGaleriaPiloto() {
      this.loading = true;
      this.galeria_ativa = true;
      this.galeriaItens = [];
      api
        .get(`Piloto/${this.$route.query.piloto}/galerias`)
        .then((resp) => {
          resp.data.resultado.forEach((item) => {
            this.galeriaItens.push(item);
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchGaleria(id_categoria, page) {
      const ano_filtro = this.ano_selecionado
        ? `&ano=${this.ano_selecionado}`
        : "";
      const query = `?tamanhoPagina=${page.tamanhoPagina}&numeroPagina=${page.numeroPagina}${ano_filtro}`;
      this.categoria = id_categoria;
      this.loading = true;
      api
        .get(`Noticias/categoria/${id_categoria}/galeria${query}`)
        .then((resp) => {
          if (resp.data.resultado.length) {
            this.disabledBtn = false;

            resp.data.resultado.forEach((item) => {
              this.galeriaItens.push(item);
            });

            this.paginacao = {
              tamanhoPagina: resp.data.tamanhoPagina,
              numeroPagina: resp.data.numeroPagina,
            };
          } else {
            this.disabledBtn = true;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleInput(event) {
      this.searchValueAtual = event;
    },
    valueInputSearch() {
      this.pesquisa = this.searchValueAtual;
    },
  },
  watch: {
    value() {
      this.localState = this.value !== "" ? true : null;
    },
    state() {
      this.localState = this.state;
    },
  },
  mounted() {
    let page = {
      tamanhoPagina: 12,
      numeroPagina: 1,
    };
    this.galeriaItens = [];

    // if (!this.$route.query.piloto) {
    //   this.fetchGaleria(
    //     mapCategorias[this.$route.params.categoria || "proseries"],
    //     page
    //   );
    // } else {
    //   this.fetchGaleriaPiloto();
    // }
    this.fetchGaleria(
      mapCategorias[this.$route.params.categoria || "proseries"],
      page
    );
  },
};
</script>

<style scoped>
.link-router {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.noticias {
  margin-bottom: 50px;
}

.label-active {
  position: absolute;
  top: -10px;
  margin-left: 12px;
  font-size: 12px;
  color: var(--primary-color);
  transition: all 0.2s ease-out;
  background-color: #000000;
  padding: 0 5px;
  border-radius: 4px !important;
}

.my-input-class.menu-input + .label-active {
  background-color: #0e080d !important;
  border-radius: 4px !important;
}
.input-search .my-input-class {
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: var(--bg-input-search);
  height: 56px;
  color: var(--primary-color) !important;
  margin-bottom: 30px;
  border-radius: 8px !important;
}

.my-input-class:disabled {
  background-color: #333;
  opacity: 0.7;
}

.input-search .my-input-class:focus {
  border: 1px solid #ffffff;
  background-color: var(--bg-input-search);
  outline: none !important;
  box-shadow: none !important;
}

.my-input-class.is-valid {
  border: 1px solid var(--primary-color);
  background-image: none !important;
}

.my-input-class.is-valid:focus {
  border: 1px solid #fff;
  background-image: none !important;
}

.my-input-class.error + label {
  color: #ff1717;
}
.my-input-class.error {
  border: 1px solid #ff1717;
  color: #ff1717;
}

.my-input-class:focus + label {
  color: #fff;
}

.input-icon {
  width: 20px;
  position: absolute;
  right: 18px; /* Ajuste a posição conforme necessário */
  top: 29%;
  transform: translateY(-50%);
  z-index: 12;
  cursor: pointer;
}

@media (max-width: 989px) {
  .input-icon {
    top: 32% !important;
  }
}

@media (max-width: 400px) {
  .input-icon {
    top: 33% !important;
  }
}

.label-inactive {
  position: absolute;
  top: 14px;
  margin-left: 16px;
  font-size: 16px;
  color: var(--primary-color);
  transition: all 0.2s ease-out;
  cursor: auto;
}

.my-label-class {
  z-index: 10;
}
.grid-item {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 30px;
}
.item-select {
  margin: 0;
}
.item-select select {
  background: var(--bg-input-search) !important;
  color: var(--primary-color) !important;
}
.grid-item label {
  color: var(--title-color) !important;
  margin-bottom: 7px;
  text-transform: uppercase;
}
@media (max-width: 989px) {
  .grid-item {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .item-select select {
    width: 100%;
  }
}
</style>
