<template>
  <div style="background-color: #000000">
    <b-container v-if="!submitConfirmed">
      <b-row v-if="!loading">
        <b-col cols="12" lg="12">
          <h2 class="titulo-camarotes">
            <strong>Sua marca vai acelerar conosco!</strong>
          </h2>

          <p class="text-camarotes">
            Informe seus dados que entraremos em contato para apresentarmos os
            detalhes e condições para trazer seu negócio para nossos eventos
          </p>
        </b-col>
      </b-row>

      <b-form
        @submit.prevent="enviarCamarotes"
        v-if="!loading"
        data-anima="bottom"
        style="margin-top: 40px"
      >
        <b-row class="campos-cadastro">
          <b-col cols="12">
            <Notificacao
              :notificacao="notificacao_infos"
              v-if="notificacao_infos.count_timer"
              @atualizaNotificacao="notificacao_infos.count_timer = 0"
            />
          </b-col>

          <b-col cols="12" lg="12" class="float-container">
            <b-form-group id="empresa" label-for="empresa">
              <input-with-label
                id="empresa"
                label="Empresa"
                v-model="form.empresa"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6" class="float-container">
            <b-form-group id="nomeResponsavel" label-for="nomeResponsavel">
              <input-with-label
                id="nomeResponsavel"
                label="Nome do Responsável"
                v-model="form.nomeResponsavel"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group id="cargo" label-for="cargo">
              <input-with-label
                id="cargo"
                type="text"
                label="Cargo"
                v-model="form.cargo"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label-for="emailCorporativo">
              <input-with-label
                id="emailCorporativo"
                type="email"
                label="E-mail Corporativo"
                v-model="form.emailCorporativo"
                :input-class="{
                  error: form.emailCorporativo.indexOf('@') === -1,
                  'my-input-class': true,
                }"
                label-class-custom="my-label-class"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group label-for="celular">
              <input-with-label-mask
                id="celular"
                label="Celular"
                type="tel"
                name="celular"
                v-model="form.celular"
                :input-class="{
                  error: !isTelefoneValido,
                  'my-input-class-mask': true,
                  'form-control': true,
                }"
                :mask="['(##) ####-####', '(##) #####-####']"
                autocomplete="off"
                minlength="10"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="12">
            <div class="btn-avancar">
              <b-button
                type="submit"
                class="acessar btn"
                :disabled="
                  form.emailCorporativo.indexOf('@') === -1 || !isTelefoneValido
                "
                >ENVIAR
              </b-button>
            </div>
          </b-col>

          <b-col cols="12" lg="12">
            <p class="terms-form">
              Ao enviar meus dados, estou de acordo com os
              <a>termos e condições</a> e <a>política de privacidade</a>
            </p>
          </b-col>
        </b-row>
      </b-form>
    </b-container>

    <b-container v-if="loading" data-anima="bottom" class="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </b-container>

    <div v-if="!loading && submitConfirmed" class="main-b2b-form">
      <b-row class="flex-1">
        <b-col class="titulo-centralizado">
          <h2 class="titulo">
            <strong
              >AGRADECEMOS SEU INTERESSE<br />
              EM ACELERAR COM A GENTE!</strong
            >
          </h2>
        </b-col>
      </b-row>

      <b-container>
        <p class="p-camarotes">
          Em breve, entraremos em contato para apresentarmos todos os detalhes e
          condições para a contratação do espaço corporativo de seu interesse.
        </p>
      </b-container>

      <div class="btn-avancar">
        <b-button
          type="submit"
          class="acessar btn"
          to="/stockcar/proseries/seja-patrocinador"
          >VOLTAR
        </b-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import InputWithLabel from "@/components/InputWithLabel.vue";
import InputWithLabelMask from "@/components/InputWithLabelMask.vue";
import { api } from "@/services.js";

export default {
  data() {
    return {
      loading: false,
      submitConfirmed: false,
      form: {
        empresa: "",
        nomeResponsavel: "",
        cargo: "",
        emailCorporativo: "",
        celular: "",
      },
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
    };
  },
  components: {
    InputWithLabel,
    InputWithLabelMask,
  },

  methods: {
    enviarCamarotes() {
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };

      this.loading = true;
      const data = {
        empresa: this.form.empresa,
        nomeResponsavel: this.form.nomeResponsavel,
        cargo: this.form.cargo,
        email: this.form.emailCorporativo,
        celular: this.form.celular,
      };
      api
        .post(`/Patrocinador`, data)
        .then(() => {
          this.onSubmit();
        })
        .catch((error) => {
          console.log(error);
          this.notificacao_infos = {
            variant: "danger",
            mensagem: "Falha ao enviar o formulario, tente novamente.",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSubmit() {
      this.submitConfirmed = true;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    isTelefoneValido() {
      return this.form.celular.length === 15;
    },
  },

  created() {
    this.form.idEmpresa = this.$route.params.id_camarote;
  },
};
</script>
    
<style scoped>
.main-b2b-form {
  height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: linear-gradient(#000000a3, black), var(--bg-camarote-b2b);
  background-repeat: no-repeat;
  background-size: cover;
}

.main-b2b-form .titulo {
  background-image: none;
  color: var(--primary-color);
}

.form-control {
  color: var(--title-color);
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  margin-bottom: 30px;
}

.p-camarotes {
  font-family: MetronicPro-Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 1.5rem;
  text-align: center;
}

.titulo-camarotes {
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 30px;
  font-family: "Uniwars-W00-Light";
  text-align: center;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.text-camarotes {
  font-family: MetronicPro-SemiBold;
  text-align: center;
  color: var(--title-color);
  font-size: 18px;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 40px;
}

.campos-cadastro {
  max-width: 750px !important;
  margin: 0 auto;
}

.loading {
  min-height: 37vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
  width: 300px;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.btn-avancar {
  display: flex;
  justify-content: center;
}

.terms-form {
  margin-top: 60px;
  color: var(--primary-color);
}

.terms-form a:hover,
.terms-form a {
  text-decoration: underline;
  cursor: pointer;
}

.select {
  padding: 10px 20px;
  color: var(--primary-color);
}

input,
select {
  transition: 0.3s;
}
input:focus,
input:hover,
select:focus,
select:hover {
  box-shadow: none;
  border-color: #fff;
}

input:focus,
select:focus,
input:focus::placeholder,
select:focus::placeholder {
  /* color: var(--primary-color) !important; */
  background-color: #ffffff00 !important;
  color: #fff;
}

input::placeholder,
select::placeholder {
  color: var(--primary-color);
}
input,
select {
  color: var(--title-color) !important;
}

select:focus {
  color: #bda049 !important;
}

.checkbox-camarotes {
  border-radius: 8px;
  background-color: #ffffff;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s;
  margin-right: 8px;
  cursor: pointer;
}

.label-camarotes {
  color: var(--primary-color);
  text-align: center;
  cursor: pointer;
}

.input-outros {
  width: 100%;
  background-color: #000000;
  border: 1px solid var(--primary-color);
  color: var(--title-color);
  padding-left: 8px;
  padding-right: 8px;
}

@media (max-width: 600px) {
  .titulo-camarotes {
    font-size: 1rem;
  }

  .text-camarotes {
    font-size: 0.8rem;
  }
}

@media (max-width: 450px) {
  .label-camarotes {
    font-size: 0.8rem;
  }
}
</style>
      