<template>
  <section>
    <b-container>
      <div class="votacao" v-if="!loading">
        <div v-if="fanpush.listaPilotos.length">
          <div>
            <b-container>
              <div class="tag">⚠️ Preview Votação Fanpush</div>
              <b-row>
                <b-col cols="12" lg="7" class="txt-votacao">
                  <h2>Escolha <strong>seus pilotos</strong></h2>
                  <p>
                    Você tem direito a dar <span>3 votos</span> na próxima
                    Etapa. Escolha seus <br />pilotos favoritos para ganhar.
                  </p>
                </b-col>

                <b-col cols="12" lg="4" class="info-etapa">
                  <InformacoesEtapas @fotoCircuito="fotoCircuito" />
                </b-col>

                <b-col cols="12" lg="1" class="circuito">
                  <img :src="img_circuito" alt="Imagem do circuito" />
                </b-col>
              </b-row>
            </b-container>

            <CardsPilotos
              :votacao_fanpush="true"
              :pilotos_fanpush="fanpush.listaPilotos"
              :preview="true"
            />
          </div>
        </div>
        <div data-anima="top" v-else>
          <div>
            <p class="my-4 py-5 confirma">
              Fanpush não está disponível no momento
            </p>
            <!-- <p class="my-4">Tenta novamente mais tarde. Logo estará regularizada.</p> -->

            <router-link
              to="/stockclub/home"
              style="text-decoration: none; color: inherit"
            >
              <button type="button" class="btn btn-home">IR PARA HOME</button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="pb-5 w-50 m-auto">
        <Notificacao
          :notificacao="notificacao_infos"
          v-if="notificacao_infos.count_timer"
          @atualizaNotificacao="notificacao_infos.count_timer = 0"
        />
      </div>
    </b-container>
  </section>
</template>

<script>
import CardsPilotos from "@/components/CardsPilotos.vue";
import InformacoesEtapas from "@/components/InformacoesEtapas.vue";

import { api } from "@/services.js";

export default {
  name: "VotacaoPiloto",
  data() {
    return {
      loading: false,
      img_circuito: null,
      fanpush: {
        fanpushAberto: false,
        listaPilotos: [],
      },
      idEtapaFanpush: null,
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
    };
  },
  components: {
    CardsPilotos,
    InformacoesEtapas,
  },
  methods: {
    fotoCircuito(data) {
      this.img_circuito = data;
    },

    fetchFanPush(id_etapa) {
      if (id_etapa) {
        this.loading = true;
        this.notificacao_infos = {
          variant: "primary",
          mensagem: "",
          count_timer: 0,
        };

        api
          .get(`/Fanpush/validacao/${id_etapa}`)
          .then((resp) => {
            this.fanpush = resp.data;

            if (!this.fanpush.listaPilotos.length) {
              this.notificacao_infos = {
                variant: "primary",
                mensagem: "Não há pilotos cadastrados nesse Fanpush",
                count_timer: 100 * 100,
              };
            }
          })
          .catch((error) => {
            console.log(error);
            this.notificacao_infos = {
              variant: "danger",
              mensagem: error.response
                ? error.response.data
                : "Algo deu errado na requisição",
              count_timer: 10,
            };
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.fetchFanPush(this.$route.params.id_etapa);
  },
};
</script>

<style scoped>
section {
  min-height: 50vh;
}
.pilotos-selecionados {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}

.pilotos-selecionados img {
  position: relative;
  top: -30px;
  width: 80px;
  object-fit: contain;
}

.pilotos-selecionados p {
  font-family: "Uniwars-W00-Heavy-Italick";
  color: white;
  font-size: 45px;
  opacity: 0.8;
}

.nome-modal {
  border-top: 1px solid #ffffff52;
  margin-top: -28px;
  font-family: "Uniwars-W00-Light";
  font-size: 13px;
  color: white !important;
  font-style: italic;
  text-align: center;
  padding-top: 10px;
}

.btn-home {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  margin: 0 auto;
  margin-bottom: 80px;
}

.btn-home:hover {
  color: var(--text-color);
  border-color: var(--title-color);
  background-color: var(--title-color);
}

.my-4 {
  text-align: center;
  color: white;
}
.info-etapa {
  margin-top: -30px;
  color: #542283 !important;
}

.votacao {
  margin-top: 100px;
}

.txt-votacao {
  margin-bottom: 60px;
}
.txt-votacao * {
  text-decoration: line-through;
}

.txt-votacao span {
  color: #843dc6;
}

.txt-votacao p {
  font-size: 16px;
  color: var(--title-color);
}

h2 {
  font-family: "Uniwars-W00-Light";
  font-size: 30px;
  color: var(--title-color);
  font-style: italic;
}

h2 strong {
  font-family: "Uniwars-W00-Heavy-Italick";
}

.circuito {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -75px;
}

.circuito img {
  width: 100%;
  object-fit: contain;
}

.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
  /* width: 300px; */
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.acessar:hover {
  color: var(--text-color);
  border-color: var(--title-color);
  background-color: var(--title-color);
}

.info-etapa {
  text-align: right;
}

.loading {
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pilotosVotados {
  margin-top: 30px;
  margin-bottom: 60px;
  width: 700px;
}

@media (min-width: 990px) {
  .piloto-modal {
    width: 40%;
    height: 80px;
  }

  .btn-avancar {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 989px) {
  .pilotosVotados {
    width: 100%;
  }

  .modal-content {
    margin-top: 250px;
  }

  .pilotos-selecionados {
    justify-content: center;
    display: flex;
    gap: 20px;
  }

  .pilotos-selecionados img {
    left: 0px;
  }
  .pilotos-selecionados p {
    font-size: 32px;
  }

  .nome-modal {
    text-align: center;
    width: 100%;
  }

  .acessar {
    width: 100%;
  }

  .info-etapa {
    text-align: center;
  }

  .info-etapa {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .votacao {
    margin-top: 50px;
  }

  .txt-votacao {
    text-align: center;
    margin-bottom: 0px;
  }

  .circuito {
    top: 0px;
    margin: 0 auto !important;
    padding-bottom: 30px;
  }
}

.codigo {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}
.codigo input {
  width: 70px;
  height: 60px;
  background: transparent !important;
  border: 1px solid #e6c156 !important;
}

input:focus,
input:hover {
  box-shadow: none;
  border-color: #fff !important;
}

input:focus input:focus::placeholder {
  color: #e6c156 !important;
  background-color: #ffffff00 !important;
}

input::placeholder {
  color: #e6c156 !important;
}
input {
  color: #fff !important;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}

input.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}

input.error:hover,
input.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}
.novolink {
  text-align: center;
  margin: 0 auto;
  display: block;
  /* background: white; */
  max-width: 250px;
  padding: 5px;
  margin-top: 30px;
  color: #e6c156;

  text-align: center;
  color: rgb(230, 193, 86);
  background: #e6c15670;
  padding: 10px 25px;
  border-radius: 5px;
  text-decoration: none;
}
.novolink.sucesso {
  color: #fff;
  text-decoration: none;
  pointer-events: none;
}
.modal-footer {
  margin: 0 auto;
  justify-content: center;
}
.confirma {
  font-size: 18px;
}
.titulo-modal {
  max-width: 90%;
  margin: 0 auto;
  line-height: 1.5;
}
.tag {
  background: rgb(132, 61, 199);
  color: #fff;
  display: inline-block;
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 30px;
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(132, 61, 199, 0.8);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(132, 61, 199, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(132, 61, 199, 0);
  }
}
</style>