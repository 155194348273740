<template>
  <b-container>
    <div class="container-cadastro">
      <div class="titulos">
        <h4 class="titulo-aux">
          Quer participar do banco de talentos da Stock Car?
        </h4>
        <p>
          Cadastre seu currículo abaixo que havendo uma oportunidade no seu
          perfil, nosso time irá entrar em contato com você.
        </p>
      </div>
      <Notificacao
        :notificacao="notificacao_infos"
        v-if="notificacao_infos.count_timer"
        @atualizaNotificacao="notificacao_infos.count_timer = 0"
      />

      <form @submit.prevent="onSubmit" v-show="!loading">
        <b-row>
          <b-col cols="12">
            <b-form-group id="nome" label-for="nome">
              <input-with-label
                id="nome"
                label="Nome completo *"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                v-model="form.nome"
                :required="true"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group id="email" label-for="email">
              <input-with-label
                type="email"
                id="email"
                label="E-mail *"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                :required="true"
                v-model="form.email"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group id="celular" label-for="celular">
              <input-with-label-mask
                id="celular"
                label="Celular *"
                type="tel"
                name="celular"
                :input-class="{
                  'my-input-class-mask': true,
                  'form-control': true,
                }"
                :mask="['(##) ####-####', '(##) #####-####']"
                autocomplete="off"
                minlength="10"
                :required="true"
                v-model="form.celular"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group id="cep" label-for="cep">
              <input-with-label-mask
                id="cep"
                label="CEP *"
                type="tel"
                name="cep"
                :input-class="{
                  'my-input-class-mask': true,
                  'form-control': true,
                }"
                :mask="'#####-###'"
                autocomplete="off"
                :required="true"
                :disabledMasked="true"
                v-model="form.cep"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6" class="item-file">
            <input
              type="file"
              class="file-form"
              id="curriculo"
              ref="curriculo"
              accept=".pdf, .doc, .docx"
            />
            <span>Arquivos em .doc ou .pdf</span>
          </b-col>

          <b-col cols="12">
            <b-form-group id="area" label-for="area">
              <b-form-select
                class="select form-control"
                placeholder="Área de interresse*"
                required
                v-model="form.idArea"
                :options="departamentos"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group id="linkedin" label-for="linkedin">
              <input-with-label
                id="linkedin"
                label="Linkedin *"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                v-model="form.linkedin"
                :required="true"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group id="pq" label-for="pq">
              <span class="label-span"
                >Por que você gostaria de trabalhar na Vicar? (max 800
                caracteres)</span
              >
              <textarea
                id="pq"
                class="form-control textarea-item"
                placeholder="Escreva aqui"
                required
                v-model="form.porque"
                maxlength="300"
              ></textarea>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group id="pq" label-for="pq">
              <span class="label-span"
                >Conta pra gente qual o seu grande sonho? (max 800
                caracteres)</span
              >
              <textarea
                id="pq"
                class="form-control textarea-item"
                placeholder="Escreva aqui"
                required
                v-model="form.sonho"
                maxlength="300"
              ></textarea>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button type="submit" class="acessar btn"
              >Enviar o seu CV</b-button
            >
          </b-col>

          <b-col cols="12" class="informativo">
            <span>*Campo obrigatório</span>
            <span
              >Ao enviar meus dados, estou de acordo com os
              <router-link
                :to="`/stockcar/${
                  $route.params.categoria || 'proseries'
                }/termos-de-uso`"
                >termos e condições</router-link
              >
              e
              <router-link
                :to="`/stockcar/${
                  $route.params.categoria || 'proseries'
                }/politica-privacidade`"
                >política de privacidade</router-link
              >
            </span>
          </b-col>
        </b-row>
      </form>
      <div v-if="loading" class="loading">
        <b-spinner
          style="
            width: 3rem;
            height: 3rem;
            border-color: #e6c156;
            border-right-color: transparent;
            --bs-spinner-animation-speed: 0.5s;
          "
          label="Large Spinner"
          variant="warning"
        ></b-spinner>
      </div></div
  ></b-container>
</template>

<script>
import InputWithLabel from "@/components/InputWithLabel.vue";
import InputWithLabelMask from "@/components/InputWithLabelMask.vue";

import { api } from "@/services";

export default {
  name: "CadastroTrabalheConosco",
  components: {
    InputWithLabel,
    InputWithLabelMask,
  },
  data() {
    return {
      loading: false,
      departamentos: [
        {
          text: "Área de interresse *",
          value: null,
        },
      ],
      form: {
        celular: "",
        cep: "",
        email: "",
        idArea: null,
        nome: "",
        linkedin: "",
        porque: "",
        sonho: "",
      },
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
      checkpoint: 0,
      idCandidato: null,
    };
  },
  methods: {
    getAreasInteresses() {
      api.get("RH/Area").then((resp) => {
        resp.data.forEach((item) => {
          this.departamentos.push({
            text: item.nome,
            value: item.idArea,
          });
        });
      });
    },
    onSubmit() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };
      if (this.$refs.curriculo.files[0]) {
        if (this.checkpoint == 1) {
          this.saveCurriculo();
        } else {
          this.loading = true;
          api
            .post("/RH/Candidato", this.form)
            .then((resp) => {
              this.checkpoint = 1;
              this.idCandidato = resp.data.idCandidato;
              setTimeout(() => {
                this.saveCurriculo(this.idCandidato);
              }, 100);
            })
            .catch((error) => {
              this.loading = false;

              this.notificacao_infos = {
                variant: "danger",
                mensagem: error.response
                  ? error.response.data.errors
                  : "Algo deu errado ao enviar os dados para o Banco de talentos",
                count_timer: 10,
              };
            });
        }
      } else {
        this.notificacao_infos = {
          variant: "danger",
          mensagem: "Anexo do Cv obrigatório",
          count_timer: 10,
        };
      }
    },
    saveCurriculo(id) {
      this.idCandidato = this.idCandidato || id;

      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };

      if (this.idCandidato > 0) {
        this.loading = true;
        const form = new FormData();
        const cv = this.$refs.curriculo.files[0];
        form.append("idCandidato", this.idCandidato);
        form.append("File", cv);

        api
          .post("RH/Candidato/Curriculo", form)
          .then(() => {
            this.checkpoint = 2;
            this.$router.push(
              `/stockcar/${
                this.$route.params.categoria || "proseries"
              }/trabalhe-conosco/obrigado`
            );
          })
          .catch((error) => {
            this.notificacao_infos = {
              variant: "danger",
              mensagem: error.response
                ? error.response.data
                : "Ocorreu um erro ao salvar o curriculo. Tente novamente",
              count_timer: 10,
            };
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getAreasInteresses();
  },
};
</script>

<style scoped>
.container-cadastro {
  margin: 40px auto;
}
.titulos p {
  text-align: center;
  color: var(--title-color);
}
.titulo-aux {
  font-family: "Uniwars-W00-SemiBold";
  color: var(--primary-color);
  font-size: 22px;
  text-align: center;
}

.file-form {
  color: var(--title-color);
  padding: 0.7rem 1rem;
  margin-bottom: 30px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background-color: transparent;
  height: 56px;
  width: 100%;
  position: relative;
}
.item-file span {
  text-align: right;
  display: block;
  margin-top: -30px;
  font-size: 14px;
  color: #95865c;
}

.form-control {
  color: var(--title-color);
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  margin-bottom: 30px;
}

.select {
  padding: 10px 20px;
  color: var(--primary-color);
}
input,
select {
  transition: 0.3s;
}
input:focus,
input:hover,
select:focus,
select:hover,
textarea:focus,
textarea:hover {
  box-shadow: none;
  border-color: #fff;
}

input:focus,
select:focus,
input:focus::placeholder,
select:focus::placeholder,
textarea:focus,
textarea:focus::placeholder {
  /* color: var(--primary-color) !important; */
  background-color: #ffffff00 !important;
  color: #fff;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  color: var(--primary-color);
}
input,
select,
textarea {
  color: var(--title-color) !important;
}

select:focus {
  color: #bda049 !important;
}
input.error,
textarea.error,
select.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}

input.error:hover,
input.error:focus,
textarea.error:hover,
textarea.error:focus,
select.error:hover,
select.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}

.custom-control-label p {
  cursor: pointer;
}
.textarea-item {
  height: 20vh;
  outline: none;
  resize: none;
}
@media (max-width: 768px) {
  .textarea-item {
    height: 10vh !important;
  }
}
span.label-span {
  margin-left: 15px;
  color: var(--primary-color);
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}
.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
  width: 300px;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  margin: 0 auto;
}
.informativo {
  margin: 40px auto;
}
.informativo span {
  display: block;
  text-align: center;
  color: var(--primary-color);
  font-size: 13px;
}
.informativo span a {
  color: var(--primary-color);
}
.loading {
  min-height: 37vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>