<template>
    <div>
        <AtualizarSenha />
    </div>
</template>


<script>
import AtualizarSenha from "@/components/AtualizarSenha.vue";
export default {
  components: {
    AtualizarSenha,
  }
};
</script>


