<template>
  <b-container>
    <canvas id="line-chart"></canvas>
  </b-container>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  props: {
    dadosGrafico: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const categoriaColor = getComputedStyle(
      document.querySelector("#app")
    ).getPropertyValue("--primary-color");

    new Chart(document.getElementById("line-chart"), {
      type: "line",
      data: {
        labels: [...this.dadosGrafico.labels.map((item) => item)],
        datasets: [
          {
            label: "PONTOS DO CAMPEONATO",
            backgroundColor: categoriaColor,
            borderColor: categoriaColor,
            data: [...this.dadosGrafico.pontos.map((item) => item)],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            ticks: {
              font: {
                size: 16,
              },
            },
          },
        },
      },
    });

    if (this.$route.params.categoria == "formula-4") {
      setTimeout(() => {
        const bg = document.querySelector("#line-chart");
        bg.style.background = `transparent` ;
      }, 100);
    }
  },
};
</script>

<style scoped>
#line-chart {
  background: transparent linear-gradient(180deg, #0e080d00 0%, #2b262a 100%) 0%
    0% no-repeat padding-box;
  padding: 20px;
  border-radius: 10px;
  height: 40vh;
}
</style>