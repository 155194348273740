<template>
  <div>
    <CircuitoInterna />
  </div>
</template>

<script>
import CircuitoInterna from '@/components/CircuitoInterna.vue';
export default {
  components: {
    CircuitoInterna
  }
};
</script>

