<template>
  <div>
    <FormCamarotesB2B />
  </div>
</template>

<script>
import FormCamarotesB2B from "@/components/FormCamarotesB2B.vue";

export default {
  data() {
    return {};
  },
  components: {
    FormCamarotesB2B,
  },

  methods: {},

  created() {},
};
</script>

<style scoped>
</style>
  