<template>
  <section class="stock-channel" id="stockchannel">
    <b-container>
      <b-row>
        <b-col cols="12" lg="12">
          <img
            src="@/assets/img/logo-live.png"
            alt="logo Stock Channel"
            class="logo-live"
          />
          <p class="subtitulo">Outras corridas</p>
        </b-col>

        <b-col cols="12" lg="4">
          <div class="custom-item" v-show="!loading" data-anima="top">
            <img :src="ListaVideos.thumbnail" alt="Imagem thumbnail do video" />

            <h3>{{ ListaVideos.nome }}</h3>
            <p>
              ( {{ ListaVideos.quantidadeDeVideos }} vídeo{{
                ListaVideos.quantidadeDeVideos > 1 ? "s" : ""
              }}
              )
            </p>
          </div>
        </b-col>

        <b-col cols="12" lg="8">
          <div class="lista-lives" v-show="!loading" data-anima="bottom">
            <div
              v-for="(live, index) in ListaVideos.videos"
              :key="index"
              class="live"
              @click="abrirModal(live)"
            >
              <img :src="live.thumbnail" alt="imagem do vídeo" />

              <p>{{ live.titulo }}</p>
            </div>
          </div>
        </b-col>

        <b-modal id="modal-live-item" hide-footer variant="dark">
          <template #modal-title>
            <img src="@/assets/img/icone-cam.png" alt="ícone de câmera" />
            STOCK CAR CHANNEL
          </template>
          <div class="d-block text-center">
            <h3>{{ modalData.titulo }}</h3>

            <iframe
              width="100%"
              height="400"
              :src="`${modalData.link}?autoplay=1&mute=1`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </b-modal>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { api } from "@/services.js";

export default {
  name: "ItemCarrosselStockChannel",
  data() {
    return {
      ListaVideos: {
        videos: [],
        idBiblioteca: "",
      },
      loading: false,
      modalData: {
        titulo: "",
        link: "",
      },
    };
  },
  methods: {
    fetchVideos(idBiblioteca) {
      this.loading = true;
      api
        .get(`/StockChannel/Biblioteca/${idBiblioteca}`)
        .then((resp) => {
          this.ListaVideos = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    abrirModal(live) {
      this.modalData = { ...live };

      if (live) {
        this.modalData = {
          titulo: live.titulo,
          link: `https://www.youtube.com/embed/${this.getYouTubeParam(
            live.link
          )}`,
        };
      }

      this.$bvModal.show("modal-live-item");
    },

    getYouTubeParam(url) {
      const paramsString = url.split("?")[1];
      if (!paramsString) return null;

      const params = new URLSearchParams(paramsString);

      return params.get("v");
    },
  },
  mounted() {
    this.loading = true;
    this.fetchVideos(this.$route.params.id);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}
::-webkit-scrollbar-thumb {
  background: #eabb4e !important;
  border-radius: 5px;
  height: 50px;
}

.live {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  border-radius: 10px;
  background-color: #00000075;
  margin-bottom: 10px;
  cursor: pointer;
}

.live:hover {
  background-color: #000000c4;
}

.live img {
  margin-top: 3px;
  width: 150px;
  border-radius: 15px;
}

.live p {
  margin-top: 18px;
  color: #ffffff;
  font-size: 14px;
  padding-left: 20px;
  line-height: 20px;
  padding-right: 30px;
}

.logo-live {
  width: 500px;
  margin: 0 auto;
  display: flex;
  padding-top: 150px;
}

.custom-item h3 {
  margin: 0 auto;
  width: 350px;
  color: #eabb4e;
  font-family: "Uniwars-W00-SemiBold";
  font-size: 17px;
  padding: 20px 20px;
  text-align: center;
}

.custom-item img {
  border-radius: 15px !important;
  margin: 0 auto;
  display: block;
}

.subtitulo {
  text-align: center;
  color: #eabb4e !important;
  font-family: "Uniwars-W00-SemiBold";
  font-size: 14px;
  font-style: italic;
  padding: 30px 0px 30px 0px;
}

.custom-item p {
  text-align: center;
  font-size: 17px;
  color: #ffffff;
  font-size: 14px;
  padding-top: 20px;
}

.logo-live {
  padding-top: 150px;
}

.lista-lives {
  margin-bottom: 60px;
  max-height: 400px;
  overflow-y: auto;
}

.stock-channel {
  height: 1000px;
  background-image: url(../assets/img/bg_lives.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #000;
}

@media (max-width: 768px) {
  .stock-channel {
    background-size: cover;
    height: auto;
  }

  .logo-live {
    width: 300px;
    padding-top: 50px;
    padding-bottom: 10px;
  }

  .lista-lives {
    margin-top: 0px;
    max-height: initial;
  }

  .live img {
    width: 200px;
  }

  .live {
    display: block;
    text-align: center;
  }

  .live p {
    margin-top: 10px;
  }

  .subtitulo {
    padding: 0px;
  }

  .custom-item img {
    width: 100%;
  }
}
</style>
