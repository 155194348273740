<template>
  <div style="background-color: #000000">
    <b-container class="container">
      <b-row v-if="!loading">
        <b-col cols="12" lg="12">
          <a href="#" class="voltar" @click.prevent="step = 0" v-if="step == 1"
            >voltar</a
          >
          <!-- <div class="wProgress all-steps" id="all-steps">
            <ul class="list-unstyled multi-steps">
              <li class="step">
                <img
                  src="@/assets/img/icone_carro.png"
                  class="imgCarroProgress"
                  alt="Imagem Carro Miniatura"
                  :style="carroMiniatura"
                />
              </li>
              <li class="step"></li>
              <li class="step"></li>
              <li class="step"></li>
              <li class="step"></li>
              <li class="step noList">
                <img
                  src="@/assets/img/icone_bandeira.png"
                  class="icBandeira"
                  alt="Icone Bandeira de chegada"
                />
              </li>
            </ul>
          </div> -->
        </b-col>
      </b-row>

      <b-form
        @submit.prevent="verificaUsuario"
        v-show="!loading && step == 0"
        data-anima="bottom"
        style="margin-top: 40px"
      >
        <b-row class="campos-cadastro">
          <b-col cols="12">
            <Notificacao
              :notificacao="notificacao_infos"
              v-if="notificacao_infos.count_timer"
              @atualizaNotificacao="notificacao_infos.count_timer = 0"
            />
          </b-col>

          <b-col cols="12" class="topo-item">
            <h5 class="required-info">
              {{
                brasileiro
                  ? "*Todos os campos são obrigatórios"
                  : "*All fields are required"
              }}
            </h5>
            <a href="#" @click.prevent="changeBrasileiro">
              {{
                !brasileiro
                  ? "É Brasileiro? Clique aqui"
                  : "Is not Brazilian? Click here"
              }}</a
            >
          </b-col>

          <b-col cols="12" lg="6" class="float-container" v-if="brasileiro">
            <b-form-group id="nome" label-for="nome">
              <input-with-label
                id="nome"
                label="Nome*"
                v-model="form.nome"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="12" class="float-container" v-else>
            <b-form-group id="nome" label-for="nome">
              <input-with-label
                id="nome"
                label="Name*"
                v-model="form.nome"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <!-- <b-form-group id="sobrenome" label-for="sobrenome">
              <input-with-label
                id="sobrenome"
                type="text"
                label="Sobrenome*"
                v-model="form.sobrenome"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                required
              />
            </b-form-group> -->
            <div>
              <b-form-select
                class="select form-control"
                v-model="form.genero"
                :options="options"
                required
              ></b-form-select>
            </div>
          </b-col>

          <b-col cols="12" lg="6">
            <div v-if="brasileiro">
              <b-form-group id="cpf" label-for="cpf">
                <!-- <the-mask
                :class="{ error: errors.cpf }"
                :mask="['###.###.###-##']"
                :masked="false"
                type="tel"
                name="cpf"
                id="cpf"
                class="form-control"
                autocomplete="off"
                placeholder="CPF: ###.###.###-##"
                v-model="form.cpf"
                @keyup.native="validaDocumento"
              /> -->
                <input-with-label-mask
                  id="cpf"
                  label="CPF*"
                  type="tel"
                  name="cpf"
                  @keyup.native="validaDocumento"
                  placeholder="CPF: ###.###.###-#"
                  v-model="form.cpf"
                  :input-class="{
                    error: errors.cpf,
                    'my-input-class-mask': true,
                    'form-control': true,
                  }"
                  :mask="['###.###.###-##']"
                  autocomplete="off"
                />
                <span class="error-span" v-if="errors.cpf"
                  >- Número de CPF inválido</span
                >
              </b-form-group>
            </div>
            <div v-else>
              <div>
                <b-form-select
                  class="select form-control"
                  v-model="form.pais"
                  :options="options_paises"
                  required
                ></b-form-select>
              </div>
            </div>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group id="datanasc" label-for="datanasc">
              <!-- <the-mask
                :class="{ error: nao_se_cadastra }"
                v-model="form.dataNascimento"
                :mask="['##/##/####']"
                :masked="true"
                type="tel"
                name="datanasc"
                id="datanasc"
                class="form-control"
                autocomplete="off"
                placeholder="Data de Nascimento: ##/##/####"
                @keyup.native="validaIdade"
              /> -->
              <input-with-label-mask
                id="datanasc"
                :label="brasileiro ? 'Data de Nascimento*' : 'Date of Birth'"
                name="datanasc"
                type="tel"
                @keyup.native="validaIdade"
                placeholder=""
                v-model="form.dataNascimento"
                :input-class="{
                  error: nao_se_cadastra,
                  'my-input-class-mask': true,
                  'form-control': true,
                }"
                :mask="['##/##/####']"
                autocomplete="off"
                :required="true"
              />
              <span class="error-span" v-if="nao_se_cadastra"
                >- {{ notificacao_infos.mensagem }}</span
              >
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="solicitaEmailResp" data-anima="top">
            <b-form-group label-for="EmailResponsible">
              <b-form-input
                :class="{
                  error:
                    emailResponsavel == form.email || emailResponsavelHasPlus,
                }"
                id="EmailResponsible"
                type="email"
                placeholder="E-mail do responsável*"
                required
                v-model="emailResponsavel"
              ></b-form-input>
              <span
                style="
                  display: block;
                  margin-top: -25px;
                  margin-bottom: 20px;
                  color: var(--title-color);
                  font-size: 14px;
                "
              >
                Usuários com idades entre 14 e 17 anos, precisam indicar o
                e-mail de um responsável legal
              </span>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label-for="email">
              <input-with-label
                id="email"
                type="email"
                label="E-mail*"
                v-model="form.email"
                :input-class="{
                  error: form.email ? form.email.indexOf('@') === -1 : false,
                  'my-input-class': true,
                }"
                label-class-custom="my-label-class"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label-for="emailConfirm">
              <input-with-label
                id="emailConfirm"
                type="email"
                :label="brasileiro ? 'Confirme o e-mail*' : 'Confirm email*'"
                v-model="form.emailConfirm"
                :input-class="{
                  error: validaConfirmaEmail,
                  'my-input-class': true,
                }"
                label-class-custom="my-label-class"
                @keyup.native="
                  validaItem(form.email, form.emailConfirm, 'email')
                "
                onpaste="return false"
                ondrop="return false"
                autocomplete="emailConfirm-"
                required
              />
              <span class="error-span" v-if="validaConfirmaEmail"
                >-
                {{
                  brasileiro
                    ? "Ambos os campos de email devem ser iguais"
                    : "Both email fields must be the same."
                }}</span
              >

              <span class="error-span" v-if="validaCaracterEmail"
                >- {{ brasileiro ? "E-mail inválido" : "Invalid e-mail" }}</span
              >
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12" lg="2" v-if="!brasileiro">
            <select
              v-model="selectedCountry"
              style="padding: 10px; width: 100%; height: 56px"
              class="form-control"
            >
              <option
                v-for="country in countries"
                :key="country.code"
                :value="country.dial_code"
              >
                {{ country.dial_code }}
              </option>
            </select>
          </b-col> -->

          <!-- <b-col cols="12" lg="6" v-if="brasileiro"> -->
          <b-col cols="12" lg="6">
            <b-form-group label-for="celular">
              <!-- :class="{
                  error: form.telefone ? errors.celular : false,
                  'my-input-class-mask': true,
                  'form-control': true,
                }" -->
              <vue-tel-input
                :inputOptions="this.brasileiro ? inputTelOptions : inputTelOptions2"
                :preferredCountries="['BR', 'AR', 'US', 'ES']"
                @input="telValidate"
                id="celular"
                label="Celular*"
                type="tel"
                name="celular"
                class="form-control"
                :mask="['(##) ####-####', '(##) #####-####']"
                autocomplete="vue-tel-input"
                minlength="10"
                required
              ></vue-tel-input>

              <!-- <input-with-label-mask
                id="celular"
                label="Celular*"
                type="tel"
                name="celular"
                v-model="form.telefone"
                :input-class="{
                  error: form.telefone
                    ? !isTelefoneValido || errors.celular
                    : false,
                  'my-input-class-mask': true,
                  'form-control': true,
                }"
                :mask="['(##) ####-####', '(##) #####-####']"
                autocomplete="off"
                minlength="10"
                required
              /> -->
              <span class="error-span" v-if="form.telefone && errors.celular"
                >-
                {{
                  brasileiro
                    ? "Número de Celular inválido"
                    : "Invalid cell phone number"
                }}</span
              >
            </b-form-group>
            <!-- <b-form-group id="uf" label-for="uf">
              <input-with-label
                id="uf"
                type="text"
                label="Cidade, Estado*"
                v-model="endereco_infos"
                input-class="my-input-class"
                label-class-custom="my-label-class"
                required
                :disabled="true"
                maxlength="2"
              />
            </b-form-group> -->
          </b-col>

          <b-col cols="12" lg="6" v-if="brasileiro">
            <b-form-group id="cep" label-for="cep">
              <!-- <the-mask
                :mask="'#####-###'"
                :masked="false"
                type="tel"
                name="cep"
                id="cep"
                class="form-control"
                v-model="form.cep"
                placeholder="CEP: #####-###"
                required
                @keyup.native="consultaCep"
              /> -->
              <input-with-label-mask
                id="cep"
                label="CEP*"
                type="tel"
                name="cep"
                @keyup.native="consultaCep"
                v-model="form.cep"
                :input-class="{
                  'my-input-class-mask': true,
                  'form-control': true,
                }"
                :mask="'#####-###'"
                autocomplete="off"
                :required="true"
                :disabledMasked="true"
              />
            </b-form-group>
          </b-col>

          <div v-if="endereco_infos && brasileiro" data-anima="bottom">
            <p class="endereco-info">📍 {{ endereco_infos }}</p>
          </div>

          <b-col cols="12" lg="6">
            <!-- <div>
              <b-form-select
                class="select form-control"
                v-model="form.genero"
                :options="options"
                required
              ></b-form-select>
            </div> -->
          </b-col>

          <b-col cols="12" lg="6"> </b-col>

          <b-col cols="12" lg="6">
            <div class="item-senha">
              <b-form-group label-for="senha">
                <input-with-label
                  id="senha"
                  :label="brasileiro ? 'Senha*' : 'Password*'"
                  type="password"
                  v-model="form.senha"
                  :input-class="{
                    'my-input-class': true,
                  }"
                  label-class-custom="my-label-class"
                  required
                />
                <img
                  src="@/assets/icons/eye.svg"
                  alt="Mostrar senha"
                  class="icon-eye"
                  @click="mostraSenha('senha', 'text')"
                  v-if="!show_password.senha"
                />
                <img
                  v-else
                  src="@/assets/icons/eye-off.svg"
                  alt="Ocultar senha"
                  class="icon-eye"
                  @click="mostraSenha('senha', 'password')"
                />
              </b-form-group>
            </div>
          </b-col>

          <b-col cols="12" lg="6">
            <div class="item-senha">
              <b-form-group label-for="senhaConfirma">
                <!-- <b-form-input
                id="senhaConfirma"
                :class="{
                  error: form.senha != form.senhaConfirma || errors.senha,
                }"
                type="password"
                placeholder="Confirmação senha"
                required
                v-model="form.senhaConfirma"
                @keyup.native="
                  validaItem(form.senha, form.senhaConfirma, 'senha')
                "
                onpaste="return false"
                ondrop="return false"
                autocomplete="senhaConfirma-"
              ></b-form-input> -->
                <input-with-label
                  id="senhaConfirma"
                  :label="
                    brasileiro ? 'Confirmação senha*' : 'Confirm password*'
                  "
                  type="password"
                  v-model="form.senhaConfirma"
                  :input-class="{
                    error: form.senha != form.senhaConfirma || errors.senha,
                    'my-input-class': true,
                  }"
                  label-class-custom="my-label-class"
                  @keyup.native="
                    validaItem(form.senha, form.senhaConfirma, 'senha')
                  "
                  onpaste="return false"
                  ondrop="return false"
                  required
                />
                <img
                  src="@/assets/icons/eye.svg"
                  alt="Mostrar senha"
                  class="icon-eye"
                  @click="mostraSenha('senhaConfirma', 'text')"
                  v-if="!show_password.senhaConfirma"
                />
                <img
                  v-else
                  src="@/assets/icons/eye-off.svg"
                  alt="Ocultar senha"
                  class="icon-eye"
                  @click="mostraSenha('senhaConfirma', 'password')"
                />
                <span
                  class="error-span"
                  v-if="
                    form.senha &&
                    form.senha != form.senhaConfirma &&
                    requisitoSenha
                  "
                  >-
                  {{
                    brasileiro
                      ? "Ambos campos de senha devem ser iguais"
                      : "Both password fields must be the same"
                  }}</span
                >

                <span class="error-span" v-if="!requisitoSenha"
                  >-
                  {{
                    brasileiro
                      ? "Por favor, verifique os critérios da senha."
                      : "Please verify the password requirements."
                  }}</span
                >
              </b-form-group>
            </div>
          </b-col>

          <div>
            <PasswordValidation
              :password="form.senha"
              :brasileiro="brasileiro"
              @senhaOk="validationSenha"
            />
          </div>

          <b-col cols="12" lg="12">
            <hr class="linha" />

            <b-form-checkbox
              class="check-termos"
              id="checkbox-termos"
              v-model="form.aceitaTermos"
              name="checkbox-termos"
            >
              <p class="termos" v-if="brasileiro">
                Li e concordo com o
                <a
                  href="https://www.stockproseries.com.br/politica-de-privacidade"
                  target="_blank"
                  >Termos de Uso e Política de Privacidade.</a
                >
              </p>
              <p class="termos" v-else>
                I have read and agree to the
                <a
                  href="https://www.stockproseries.com.br/politica-de-privacidade"
                  target="_blank"
                  >Terms of Use and Privacy Policy.</a
                >
              </p>
            </b-form-checkbox>

            <p class="txt-cadastro" v-if="brasileiro">
              Escolha abaixo os canais em que podemos entrar em contato para
              você ficar por dentro das novidades, ofertas, calendário e muito
              mais:
            </p>
            <p class="txt-cadastro" v-else></p>
          </b-col>

          <b-col cols="12" lg="2">
            <b-form-checkbox
              id="checkbox-email"
              v-model="form.optaEmail"
              name="checkbox-email"
            >
              <p>email</p>
            </b-form-checkbox>
          </b-col>

          <b-col cols="12" lg="2">
            <b-form-checkbox
              id="checkbox-sms"
              v-model="form.optaSms"
              name="checkbox-sms"
            >
              <p>sms</p>
            </b-form-checkbox>
          </b-col>

          <b-col cols="12" lg="2">
            <b-form-checkbox
              id="checkbox-whatsapp"
              v-model="form.optaWhatsapp"
              name="checkbox-whatsapp"
            >
              <p>whatsapp</p>
            </b-form-checkbox>
          </b-col>

          <b-col cols="12" lg="12">
            <div class="btn-avancar">
              <b-button
                type="submit"
                class="acessar btn"
                :disabled="botaoContinue"
                v-if="step == 0"
              >
                {{ brasileiro ? "AVANÇAR" : "CONTINUE" }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>

      <PilotoFavorito
        :form="form"
        :questaoPiloto="questaoPiloto"
        v-if="step == 1 && questaoPiloto"
        @loading="hasLoading"
      />

      <div v-if="loading" class="loading">
        <b-spinner
          style="
            width: 3rem;
            height: 3rem;
            border-color: #e6c156;
            border-right-color: transparent;
            --bs-spinner-animation-speed: 0.5s;
          "
          label="Large Spinner"
          variant="warning"
        ></b-spinner>
      </div>

      <b-modal
        id="modal-alert"
        title=""
        size="lg"
        variant="dark"
        hide-footer
        @hide="closeModalAlert"
      >
        <div>
          <h3>{{ infos_modal_alert.titulo }}</h3>
          <div v-html="infos_modal_alert.texto"></div>
          <button class="btn btn-ok" @click.prevent="closeModalAlert">
            {{ infos_modal_alert.btn }}
          </button>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import PilotoFavorito from "@/views/PilotoFavorito.vue";
import validateCPF from "@/validator/cpf.js";
import calculaIdade from "@/validator/idade.js";
// import { TheMask } from "vue-the-mask";
import InputWithLabel from "@/components/InputWithLabel.vue";
import InputWithLabelMask from "@/components/InputWithLabelMask.vue";

import { api } from "@/services.js";
import { getCep } from "@/services.js";

import PasswordValidation from "./PasswordValidation.vue";
import { paises } from "@/helpers.js";

import { VueTelInput } from "vue-tel-input";

export default {
  name: "Cadastro",
  components: {
    PilotoFavorito,
    // TheMask,
    PasswordValidation,
    InputWithLabel,
    InputWithLabelMask,
    VueTelInput,
  },
  data() {
    return {
      countries: [],
      selectedCountry: "",
      step: 0,
      questaoPiloto: null,
      loading: false,
      requisitoSenha: false,
      nao_se_cadastra: false,
      errors: {
        email: false,
        cpf: false,
        senha: false,
        celular: false,
      },
      solicitaEmailResp: false,
      emailResponsavel: "",
      form: {
        nome: "",
        sobrenome: undefined,
        cpf: undefined,
        email: "",
        emailConfirm: "",
        genero: null,
        cep: undefined,
        logradouro: undefined,
        bairro: undefined,
        cidade: undefined,
        uf: undefined,
        senha: "",
        senhaConfirma: "",
        telefone: undefined,
        dataNascimento: "",
        optaEmail: false,
        optaSms: false,
        optaWhatsapp: false,
        aceitaTermos: false,
        pais: null,
        responses: [
          {
            idPesquisaPerfil: -1,
            resposta: "",
          },
        ],
        estrangeiro: false,
      },
      options: [
        { value: null, text: "Selecione o gênero*" },
        { value: "Feminino", text: "Feminino" },
        { value: "Masculino", text: "Masculino" },
        { value: "não-binário", text: "Não-binário" },
        { value: "prefiro não dizer", text: "Prefiro não dizer" },
      ],
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
      show_password: {
        senha: false,
        senhaConfirma: false,
      },
      brasileiro: true,
      options_paises: [{ value: null, text: "Select country*" }],
      inputTelOptions: {
        disabledFetchingCountry: false, // Permite a seleção manual do país
        showDialCodeInInput: true, // Exibe o código do país no input
        placeholder: "Digite seu número de telefone",
      },
      inputTelOptions2: {
        disabledFetchingCountry: false, // Permite a seleção manual do país
        showDialCodeInInput: true, // Exibe o código do país no input
        placeholder: "Enter your phone number",
      },
      infos_modal_alert: {
        titulo: "",
        texto: "",
        btn: "",
        goLogin: false,
      },
    };
  },
  watch: {
    step(v) {
      if (v == 0 && this.form.dataNascimento) {
        const dataSplit = this.form.dataNascimento.split("-");
        this.form.dataNascimento = `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;
      }
    },
  },
  computed: {
    botaoContinue() {
      return !this.form.aceitaTermos ||
        this.errors.email ||
        this.hasPlusSign ||
        this.confirmEmailHasPlusSign ||
        this.form.email.indexOf("@") === -1 ||
        this.form.emailConfirm.indexOf("@") === -1 ||
        this.emailResponsavel == this.form.email ||
        this.form.senha != this.form.senhaConfirma ||
        this.errors.senha ||
        !this.requisitoSenha ||
        this.nao_se_cadastra ||
        !this.form.dataNascimento ||
        this.brasileiro
        ? !this.form.cpf
        : false || this.errors.cpf;
    },
    validaConfirmaEmail() {
      if (this.form.emailConfirm) {
        return (
          this.form.emailConfirm.indexOf("@") === -1 ||
          this.form.email != this.form.emailConfirm ||
          this.errors.email
        );
      } else {
        return false;
      }
    },

    validaCaracterEmail() {
      if (this.form.email) {
        return this.hasPlusSign;
      } else {
        return false;
      }
    },
    endereco_infos() {
      return this.form.logradouro
        ? `${this.form.logradouro}, ${this.form.bairro}, ${this.form.cidade}-${this.form.uf}`
        : "";
    },
    carroMiniatura() {
      return this.step == 1 ? "left: 470%" : "left: 0%";
    },
    hasPlusSign() {
      return this.form.email.includes("+");
    },
    confirmEmailHasPlusSign() {
      return this.form.emailConfirm.includes("+");
    },
    emailResponsavelHasPlus() {
      return this.emailResponsavel.includes("+");
    },
    // isTelefoneValido() {
    //   return this.form.telefone.length === 15;
    // },
  },
  methods: {
    telValidate(telnumber, objTel) {
      this.form.telefone = objTel.number;
      if (objTel.valid) {
        this.errors.celular = false;
      } else {
        this.errors.celular = true;
      }
    },
    closeModalAlert() {
      if (this.infos_modal_alert.goLogin) {
        this.$router.push("/stockclub/login");
      } else {
        this.$bvModal.hide("modal-alert");
      }
    },
    openModalAlert(dados_modal = null) {
      if (dados_modal?.titulo) {
        this.infos_modal_alert = { ...dados_modal };
        setTimeout(() => {
          this.$bvModal.show("modal-alert");
        }, 200);
      }
    },
    changeBrasileiro() {
      this.brasileiro = !this.brasileiro;

      this.form = {
        ...this.form,
        nome: "",
        sobrenome: undefined,
        cpf: undefined,
        email: "",
        emailConfirm: "",
        genero: null,
        cep: undefined,
        logradouro: undefined,
        bairro: undefined,
        cidade: undefined,
        uf: undefined,
        senha: "",
        senhaConfirma: "",
        telefone: undefined,
        dataNascimento: "",
        pais: null,
      };
      if (this.brasileiro) {
        this.options = [
          { value: null, text: "Selecione o gênero*" },
          { value: "Feminino", text: "Feminino" },
          { value: "Masculino", text: "Masculino" },
          { value: "não-binário", text: "Não-binário" },
          { value: "prefiro não dizer", text: "Prefiro não dizer" },
        ];
      } else {
        this.options = [
          { value: null, text: "Select gender*" },
          { value: "Feminino", text: "Female" },
          { value: "Masculino", text: "Male" },
          { value: "não-binário", text: "Non-binary" },
          { value: "prefiro não dizer", text: "Rather not answer" },
        ];
      }
    },
    formatPaisesSelect() {
      paises.forEach((pais) => {
        this.options_paises.push({
          value: pais.nome_pais,
          text: `${pais.sigla} | ${pais.nome_pais} | ${pais.nome_pais_int}`,
        });
      });
    },
    mostraSenha(el, type) {
      this.show_password[el] = !this.show_password[el];
      document.getElementById(el).setAttribute("type", type);
    },
    async consultaCep() {
      if (this.form.cep.length == 8) {
        getCep(this.form.cep).then((resp) => {
          this.form = {
            ...this.form,
            cep: resp.data.cep,
            logradouro: resp.data.logradouro,
            bairro: resp.data.bairro,
            cidade: resp.data.localidade,
            uf: resp.data.uf,
          };
        });
      }
    },
    validationSenha(data) {
      this.requisitoSenha = data;
      if (!data) {
        this.errors.senha = true;
      }
    },
    hasLoading(data) {
      this.loading = data;
    },
    validaIdade(e) {
      let value = e.target.value;
      this.nao_se_cadastra = false;
      if (value.length >= 10) {
        this.notificacao_infos = {
          variant: "primary",
          mensagem: "",
          count_timer: 0,
        };

        const idade = calculaIdade(value);

        if (idade > 13 && idade < 18) {
          this.solicitaEmailResp = true;
        } else {
          this.solicitaEmailResp = false;
        }

        if (idade < 14) {
          if (idade == false) {
            this.notificacao_infos = {
              variant: "danger",
              mensagem:
                "Data informada inválida, por favor coloque uma data válida",
              count_timer: 10,
            };
          } else {
            this.nao_se_cadastra = true;
            this.notificacao_infos = {
              variant: "danger",
              mensagem:
                "Usuário menor de 14 anos NÃO pode ser cadastrar na plataforma",
              count_timer: 10,
            };
          }
        }
      }
    },
    validaItem(item1, item2, field) {
      this.errors[field] = false;
      if (item1.trim() != item2.trim()) this.errors[field] = true;
    },
    validaDocumento() {
      this.errors.cpf = true;
      if (this.form.cpf.length >= 11) {
        this.errors.cpf = !validateCPF(this.form.cpf);
      }
      if (!this.form.cpf.length) this.errors.cpf = false;
    },

    verificaUsuario() {
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };

      this.loading = true;
      const data = {
        email: this.form.email,
        cpf: this.form.cpf,
        celular: this.form.telefone,
      };
      api
        .post(`/Registro/usuario-existe`, data)
        .then(() => {
          this.onSubmit();
        })
        .catch((error) => {
          console.log(error);
          this.notificacao_infos = {
            variant: "danger",
            mensagem: error.response
              ? error.response.data
              : "Usuário já possui cadastro. Verifique o E-mail e tente novamente.",
            count_timer: 10,
          };
          this.openModalAlert({
            titulo: "Atenção",
            texto: `<p>${
              error.response
                ? error.response.data
                : "Usuário já possui cadastro. Verifique o E-mail e tente novamente."
            } </p>`,
            btn: "OK",
            goLogin: false,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSubmit() {
      if (this.emailResponsavel)
        this.form.EmailResponsavel = this.emailResponsavel;

      //const dataSplit = this.form.dataNascimento.split("/");
      //this.form.dataNascimento = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;

      this.form.estrangeiro = !this.brasileiro;

      this.step++;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (this.form.estrangeiro) {
        this.sendTokenSms(this.form.telefone);
      }
    },
    sendTokenSms(tel) {
      api.get(`/registro/enviar-codigo?celular=${encodeURIComponent(tel)}`);
    },
    fetchCadastro() {
      this.loading = true;
      api
        .get(`/Registro`)
        .then((resp) => {
          this.questaoPiloto = resp.data.find(
            (item) =>
              item.pergunta.toUpperCase() ==
              "QUAL O SEU PILOTO FAVORITO NA STOCK CAR?"
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadCountries() {
      this.countries = require("@/assets/CountryCodes.json");
    },
  },
  mounted() {
    this.loadCountries();

    this.fetchCadastro();

    this.formatPaisesSelect();
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
}
.campos-cadastro {
  max-width: 750px !important;
  margin: 0 auto;
}

.form-control {
  color: var(--title-color);
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  margin-bottom: 30px;
}

.select {
  padding: 10px 20px;
  color: var(--primary-color);
}
input,
select {
  transition: 0.3s;
}
input:focus,
input:hover,
select:focus,
select:hover {
  box-shadow: none;
  border-color: #fff;
}

input:focus,
select:focus,
input:focus::placeholder,
select:focus::placeholder {
  /* color: var(--primary-color) !important; */
  background-color: #ffffff00 !important;
  color: #fff;
}

input::placeholder,
select::placeholder {
  color: var(--primary-color);
}
input,
select {
  color: var(--title-color) !important;
}

select:focus {
  color: #bda049 !important;
}
input.error,
textarea.error,
select.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}

input.error:hover,
input.error:focus,
textarea.error:hover,
textarea.error:focus,
select.error:hover,
select.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}

.custom-control-label p {
  cursor: pointer;
}

.campos-cadastro {
  margin-top: 40px;
}

.linha {
  background-color: grey;
  height: 1px;
  width: 100%;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
}

.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
  width: 300px;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.custom-checkbox,
.custom-checkbox a {
  color: #e6c156 !important;
}

.custom-control-label p {
  padding-left: 10px;
}

.txt-cadastro {
  font-size: 17px;
  line-height: 20px;
  color: #939393;
  margin-top: 30px;
}

/* input[data-v-dbe0902e] {
  color: var(--primary-color) !important;
} */

@media (min-width: 990px) {
  .btn-avancar {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 989px) {
  .check-termos.custom-control.custom-checkbox {
    display: flex;
  }
  .termos,
  .custom-control-label p {
    font-size: 14px;
    padding: 10px;
  }
  .txt-cadastro {
    font-size: 15px;
  }
  .custom-checkbox {
    margin-bottom: 5px;
  }

  .custom-control-label p {
    margin: 0;
  }
  .container {
    min-height: 100vh;
  }

  /* .check-termos.custom-control.custom-checkbox {
    display: inline-flex;
  } */

  .wProgress {
    max-width: 85% !important;
  }

  .multi-steps > li {
    padding-left: 50px;
  }

  .multi-steps > li:after {
    width: 107px;
    left: -10px;
  }

  .imgCarroProgress {
    margin-left: -50px;
    display: block;
    margin-top: -30px;
  }
  #all-steps {
    display: grid;
  }

  .campos-cadastro {
    margin-top: 0px;
  }

  .acessar {
    width: 100%;
  }

  .icBandeira {
    margin-top: -60px;
  }
}
input:disabled {
  background-color: #333;
  opacity: 0.7;
}
.loading {
  min-height: 37vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-input-class {
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  margin-bottom: 30px;
}

.my-label-class {
  font-size: 14px;
  color: #999;
}
.required-info {
  color: var(--primary-color);
  font-size: 15px;
  font-weight: normal;
}
.endereco-info {
  color: #fff;
  font-size: 18px;
  margin-top: -10px;
}
.item-senha {
  display: flex;
  position: relative;
}
.item-senha .form-group {
  width: 100%;
}
.icon-eye {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.topo-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.topo-item a {
  color: #fff;
}
.error-span {
  color: #ff1717;
  margin: 20px 0;
  margin-top: -25px;
  display: block;
  font-size: 14px;
}

@media (max-width: 989px) {
  .icon-eye {
    top: 10px;
  }
  .error-span {
    margin-top: -13px;
  }
  .topo-item {
    display: block;
  }
}
</style>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>

<style>
.form-control.vue-tel-input {
  display: flex;
  height: auto;
  padding: 0.8rem;
}
.form-control.vue-tel-input input {
  background: transparent !important;
  color: #fff;
  font-size: 16px;
}
.form-control.vue-tel-input input:focus,
.form-control.vue-tel-input:focus-within {
  border-color: #fff !important;
  outline: none;
  box-shadow: none;
}
.form-control.vue-tel-input input::placeholder {
  color: var(--primary-color);
  font-size: 16px;
}

.form-control.vue-tel-input:focus-within input::placeholder {
  color: rgba(147, 147, 147, 0.5);
}
.form-control.vue-tel-input .vti__dropdown:hover,
.form-control.vue-tel-input .vti__dropdown.open {
  background: rgba(147, 147, 147, 0.3);
}
.form-control.vue-tel-input .vti__dropdown-list {
  background: #000;
  color: #fff;
  z-index: 9999 !important;
}
.form-control.vue-tel-input .vti__dropdown-item.highlighted {
  background: #5a5a5a;
}
</style>
