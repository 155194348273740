<template>
  <div>
    <div
      class="detalhe-circuito margin-row padding-mob"
      v-if="!loading && circuito"
      data-anima="top">
      <b-row>
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 class="titulo">
            <strong>{{ circuito.circuito }} </strong>
          </h1>
          <hr class="linha-titulo" />
          <div class="box-circuito">
            <img :src="circuito.circuitoImagem" alt="Imagem do circuito" />
          </div>
        </b-col>
      </b-row>

      <b-container class="circuitos">
        <b-row>
          <b-col cols="12" lg="6" class="col-etapa">
            <div class="proxima-etapa" v-if="circuito.proximaEtapa">
              <div class="linha-etapa"></div>
              <h3 v-if="$route.params.categoria == 'formula-4'">{{ $t('title.proxima-etapa') }}</h3>
              <h3 v-else>{{ circuito.circuito }}</h3>
              <div class="itens-etapa">
                <div class="txt-etapa">
                  <p class="data-etapa">
                    {{ circuito.cidade }}
                  </p>
                </div>
                <div v-if="circuito.proximaEtapa.urlTickets" class="tickets">
                  <a
                    v-if="$route.params.categoria == 'formula-4'"
                    :href="circuito.proximaEtapa.urlTickets"
                    target="_blank"
                    class="btn-ingresso"
                    >{{ $t('title.comprar-ingresso') }}</a
                  >
                  <a
                    v-else
                    :href="circuito.proximaEtapa.urlTickets"
                    target="_blank"
                    class="btn-ingresso"
                    >Garanta seu ingresso!</a>
                </div>
              </div>
            </div>

            <div class="proxima-etapa" v-else>
              <div class="linha-etapa"></div>
              <h3>{{ circuito.circuito }}</h3>
              <div class="txt-etapa">
                <p class="data-etapa">Em breve</p>
              </div>
            </div>

            <div v-if="circuito.ondeAssistir">
                <h3 class="onde-assistir" v-if="$route.params.categoria == 'formula-4'" >{{ $t('title.onde-assistir') }}</h3>
                <h3 class="onde-assistir" v-else>Onde assistir</h3>

                <div class="logo-assistir">
                  <img
                    v-for="item in circuito.ondeAssistir"
                    :key="item.nomeCanal"
                    :src="item.imgCanal"
                    :alt="item.tag"
                  />
                </div>
            </div>
          </b-col>

          <b-col cols="12" lg="6">
            <div class="txt-circuito padding-mob txt-center-mob">
              <div v-html="circuito.sobre" class="html"></div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div v-if="circuito.urlVideo">
        <div>
          <b-row>
            <b-col class="titulo-centralizado titulo-video">
              <hr class="linha-titulo" />
              <h2 class="titulo">
                <strong>assista a </strong> última etapa
              </h2>
              <hr class="linha-titulo" />
            </b-col>
          </b-row>
        </div>
    </div>

    <b-container v-if="circuito.urlVideo">
      <div>
        <a :href="circuito.urlVideo" target="_blank">
          <img :src="circuito.thumbVideo"
            alt="Thumbnail do video da última etapa"
            class="thumb-etapa"
          />
        </a>
      </div>
    </b-container>

    <b-container class="box-historia" data-anima="bottom"
    :class="{ 'box-trofeu': $route.params.categoria != 'formula-4', 'box-trofeuf4': $route.params.categoria === 'formula-4' }">
          <div class="conteudo-trofeu">
            <img :src="circuito.statsImagem"  alt="Imagem Stats" class="trofeu"/>
            <div class="txt-trofeu">
              <h3>STATS <br><strong>{{ circuito.circuito }}</strong></h3>
              <p>NÚMERO DE CORRIDAS</p>
              <p class="resposta-stats"><span>{{ circuito.numeroDeCorridas }}</span></p>
              <div class="numero-trofeu"> 
                <div>
                  <p>EXTENSÃO</p>
                  <p class="resposta-stats-mob"><span>{{ circuito.extensao }}</span></p>
                </div>
                <div>
                  <p>CURVAS</p>
                  <p class="resposta-stats-mob"><span>{{ circuito.curvas }}</span></p>
                </div>
              </div>

              <div class="numero-trofeu box-mobile"> 
                <div class="linhacinza"> </div>
                <div class="linhacinza"></div>
              </div>

              <div class="numero-trofeu" v-if="$route.params.categoria != 'formula-4' && $route.params.categoria != 'stock-series'"> 
                <div>
                  <p>VOLTA MAIS RÁPIDA</p>
                  <p class="resposta-stats-mob"><span>{{ circuito.voltaMaisRapida }}</span></p>
                </div>
                <div>
                  <p>REI DA PISTA</p>
                  <p class="resposta-stats-mob"><span>{{ circuito.reiDaPista }}</span></p>
                </div>
              </div>

              <div class="numero-trofeu box-mobile" v-if="$route.params.categoria != 'formula-4' && $route.params.categoria != 'stock-series'"> 
                <div class="linhacinza"> </div>
                <div class="linhacinza"></div>
              </div>

              <div class="numero-trofeu"> 
                <div>
                  <p>SENTIDO</p>
                  <p class="resposta-stats-mob"><span>{{ circuito.sentido }}</span></p>
                </div>
                <div>
                  <p>PRIMEIRO ANO NA STOCK</p>
                  <p class="resposta-stats-mob"><span>{{ circuito.primeiroAnoStock }}</span></p>
                </div>
              </div>

              <div class="numero-trofeu box-mobile"> 
                <div class="linhacinza"> </div>
                <div class="linhacinza"></div>
              </div>
            </div>
          </div>
        </b-container>

    <div>
      <b-container class="mt">
        <b-row v-if="loading" data-anima="bottom">
          <b-col cols="12" lg="12">
            <div>
              <b-skeleton
                animation="wave"
                width="40%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                width="60%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                width="30%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                width="80%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                width="100%"
                class="mt-4 mb-3"
              ></b-skeleton>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>


<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  name: "CircuitoInterna",
  data() {
    return {
      loading: false,
      circuito: [],
    };
  },
  methods: {
    fetchCircutoUnico(id_categoria, id_circuito) {
      this.loading = true;
      api
        .get(`Circuito/${id_circuito}/categoria/${id_categoria}`)
        .then((resp) => {
          this.circuito = resp.data;
          document.title = `Circuitos | ${this.circuito.circuito}`;
          if (this.circuito) {
            setTimeout(() => {
              const bg = document.querySelector(".detalhe-circuito");
              bg.style.backgroundImage = `url('${this.circuito.backgroundImagem}')`;
            }, 100);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchCircutoUnico(
      mapCategorias[this.$route.params.categoria || "proseries"],
      this.$route.params.id
    );
  },

};
</script>

<style scoped>
.logo-assistir {
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
}

.thumb-etapa {
    width: 720px;
    margin: 0 auto;
    display: block;
    padding-top: 20px;
}

.logo-assistir img {
  height: 60px;
}
.proxima-etapa h3 {
  margin-bottom: 8px;
  margin-top: 20px;
  color: var(--text-color);
  font-size: 25px;
  font-family: "MetronicPro-Bold";
  text-transform: uppercase;
}

.hora-etapa {
  color: var(--text-color);
  font-family: "Uniwars-W00-Light";
  font-size: 15px;
  margin-bottom: 0px;
}

.txt-etapa {
  width: 180px;
}

.txt-circuito p {
  padding-left: 20px;
  color: var(--title-color);
}
.html {
  color: var(--title-color);
}
.txt-circuito h3 {
  margin-top: 30px;
  padding-left: 20px;
  color: var(--primary-color);
  font-size: 35px;
  font-family: "MetronicPro-Bold";
}

.mt {
  margin-top: 100px;
}
.proxima-etapa {
  padding: 50px 30px 60px 30px;
}

.proxima-etapa {
  background: var(--primary-color);
  max-height: 270px;
}

.linha-etapa {
  opacity: 1;
  border: 3px solid;
  width: 150px;
  color: var(--text-color);
}

.data-etapa {
  color: var(--text-color);
  font-family: "MetronicPro-Regular";
  font-size: 20px;
  margin-bottom: 0px;
}

.onde-assistir {
  margin-top: 15px;
  color: var(--primary-color);
  margin-bottom: 20px;
}
.tickets {
  margin-top: 20px;
}

.titulo-video {
  padding-top: 80px !important;
}

.btn-ingresso {
  font-family: MetronicPro-Regular;
  font-size: 16px;
  padding: 15px 40px;
  width: 280px;
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  transition: 0.3s;
  text-shadow: none;
  cursor: pointer;
  font-weight: normal;
  display: block;
}

/*----------- BOX -------------*/


.box-historia {
  background: #000000 linear-gradient(90deg, #2B262A00 0%, #2B262A 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 40px 100px;
}

.box-historia p {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0px;
  width: 50%;
}

.box-historia h2 {
  color: #EABB4E;
  font-size: 30px;
  font-family: "Uniwars-W00-SemiBold";
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: end;
}

.conteudo-historia {
  display: flex;
}

.trofeu {
  width: 368px;
  height: 100%;
}

.box-trofeuf4 {
  margin-top: 50px;
  background: transparent linear-gradient(180deg, #e5e5e5 0%, #b4b4b400 100%) 0% 0% no-repeat padding-box !important;
  padding: 40px 60px;
}

.box-trofeu {
  margin-top: 50px;
  background: transparent linear-gradient(180deg, #2B262A 0%, #2B262A00 100%) 0% 0% no-repeat padding-box !important;
  padding: 40px 60px;
}

.conteudo-trofeu {
  display: flex;
  gap: 60px;
}

.conteudo-trofeu h3 {
  color: var(--primary-color);
  font-size: 30px;
  font-family: "Uniwars-W00-Light";
  margin-bottom: 30px;
}

.conteudo-trofeu strong {
  font-family: "Uniwars-W00-SemiBold";
}

.conteudo-trofeu p {
  padding-bottom: 10px;
  font-family: "MetronicPro-SemiBold";
  color: #706E6B;
  font-size: 19px;
  margin-bottom: 20px;
  width: 80%;
  margin-bottom: 0px;
}

.resposta-stats {
  border-bottom: 1px solid #706E6B;
  margin-bottom: 20px !important;
}

.txt-trofeu {
  width: 100%;
}

.conteudo-trofeu span {
  font-family: "MetronicPro-Regular" !important;
  color: var(--title-color);
  font-size: 20px;
}

.numero-trofeu {
  display: flex;
  gap: 20px;
}

.numero-trofeu p {
  width: 300px;
}

.linhacinza {
    height: 1px;
    width: 300px;
    background-color: #706E6B;
    margin-bottom: 20px;
  }

@media (max-width: 989px) {
  .box-mobile {
    display: none !important;
  }
}
@media (max-width: 989px) {

  .resposta-stats-mob {
    border-bottom: 1px solid #706E6B;
    margin-bottom: 20px !important;
  }

  .detalhe-circuito {
    background-size: auto !important;
  }
  .titulo {
    font-size: 30px !important;
  }
  .thumb-etapa {
    width: 100%;
  }

  .conteudo-trofeu p {
    font-size: 19px;
  }

  .conteudo-tempo {
    display: block;
  }

  .box-historia {
    padding: 30px;
  }

  .conteudo-historia {
    display: block;
  }

  .box-historia h2, .box-historia p {
    width: 100% !important;
  }

  .box-historia h2 {
    justify-content: start;
  }

  .trofeu {
    width: 100%;
  }

  .conteudo-trofeu, .numero-trofeu {
    display: block;
  }

  .conteudo-trofeu h3 {
    font-size: 30px;
    margin-top: 30px;
  }

  .btn-ingresso {
    width:100%;
  }

  .logo-assistir {
    padding-left: 0px;
  }
  .itens-etapa {
    flex-direction: column;
    align-items: flex-start;
  }

  .titulo-video {
    padding-top: 50px !important;
  }

  .onde-assistir{
    text-align: center;
    margin-bottom: 0px;
  }
  
  .proxima-etapa {
    background: var(--primary-color);
    height: 100%;
  }

}

</style>