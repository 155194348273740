<template>
  <div>
    <section
      style="background-color: #0e080d !important"
      v-if="!loading && produtos"
    >
      <div class="main-b2b">
        <b-row class="flex-1">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h2 class="titulo">
              <strong>UMA EXPERIÊNCIA VIP A CADA CORRIDA</strong>
            </h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>

        <b-container data-anima="bottom">
          <p class="p-estrutura" style="margin-bottom: 10px">
            A Vicar, empresa promotora dos maiores eventos do esporte a motor da
            América Latina, elevou a Stock Car Pro Series a um patamar de
            excelência internacional, sendo apontada como uma das cinco
            principais competições do tipo Turismo em todo o mundo. São muitas
            as opções de ativações que oferecemos para a sua empresa promover
            sua marca, incentivar a equipe e criar um relacionamento
            diferenciado com parceiros e clientes: Camarotes, Torcidas
            Organizadas, Ativações, Lounges, Espaços VIP e produtos
            personalizados.
          </p>
        </b-container>
      </div>

      <b-container data-anima="bottom">
        <p class="p-estrutura-mobile" style="margin-bottom: 10px">
          A Vicar, empresa promotora dos maiores eventos do esporte a motor da
          América Latina, elevou a Stock Car Pro Series a um patamar de
          excelência internacional, sendo apontada como uma das cinco principais
          competições do tipo Turismo em todo o mundo. São muitas as opções de
          ativações que oferecemos para a sua empresa promover sua marca,
          incentivar a equipe e criar um relacionamento diferenciado com
          parceiros e clientes: Camarotes, Torcidas Organizadas, Ativações,
          Lounges, Espaços VIP e produtos personalizados.
        </p>
      </b-container>

      <b-container class="espaco-camarotes">
        <b-row
          v-for="produto in produtos"
          :key="produto.idProduto"
          class="divisao"
        >
          <b-col
            cols="12"
            lg="6"
            :style="{ backgroundImage: 'url(' + produto.imagem + ')' }"
            class="hist-content img-hist historia-bg"
          >
          </b-col>

          <b-col cols="12" lg="6" class="hist-content box-texto">
            <div class="linha-noticia"></div>
            <h2><div v-html="produto.conteudo"></div></h2>
            <b-card no-body class="mb-4 camarotes">
              <p style="color: var(--text-color)" v-if="produto.dataInicio">
                De {{ formatDate(produto.dataInicio) }} Até
                {{ formatDate(produto.dataFim) }}
              </p>
              <p style="color: var(--text-color)">
                {{ produto.local }}, {{ produto.cidade }} - {{ produto.uf }}
              </p>

              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button
                  class="button-camarote-more"
                  block
                  v-b-toggle="`accordion-${produto.idProduto}`"
                  >Ver mais +</b-button
                >
                <b-button
                  :to="`/stockcar/${
                    $route.params.categoria || 'proseries'
                  }/camarotes-b2b/${produto.idProduto}?p=${produto.nome}`"
                  class="button-contact-camarote float-end"
                  >Solicitar contato</b-button
                >
              </b-card-header>
            </b-card>
            <b-collapse
              :id="`accordion-${produto.idProduto}`"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <div v-html="produto.descricao"></div>
              </b-card-body>
            </b-collapse>
          </b-col>
        </b-row>
      </b-container>

      <LogosHome :show_element="show_element.rodape" />
    </section>

    <section
      style="background-color: #0e080d !important"
      v-if="!loading && !produtos"
    >
      <div class="main-b2b">
        <b-row class="flex-1">
          <b-col class="titulo-centralizado">
            <h2 class="p-estrutura">
              <strong
                >Nenhum evento/produto encontrado, tente mais tarde.</strong
              >
            </h2>
          </b-col>
        </b-row>
      </div>
    </section>

    <b-container v-if="loading" data-anima="bottom" class="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </b-container>
  </div>
</template>

<script>
import { api } from "@/services.js";
import LogosHome from "@/components/LogosHome.vue";

export default {
  data() {
    return {
      loading: false,
      produtos: null,
      show_element: {
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
    };
  },
  components: {
    LogosHome,
  },

  methods: {
    fetchProdutos() {
      this.loading = true;
      api
        .get(`/B2B/Produto`)
        .then((resp) => {
          this.produtos = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed

      return `${day}/${month}`;
    },

    verificaElemento(data = null) {
      const categoria = data || this.$route.params.categoria;

      this.show_element = {
        rodape: {
          montadoras: true,
          patrocinadores: true,
          fornecedores: true,
          mediaPartners: true,
        },
      };
      switch (categoria) {
        case "proseries": {
          this.show_element = {
            rodape: {
              montadoras: true,
              patrocinadores: true,
              fornecedores: true,
              mediaPartners: true,
            },
          };
          break;
        }

        case "stock-series": {
          this.show_element = {
            rodape: {
              montadoras: true,
              patrocinadores: true,
              fornecedores: true,
              mediaPartners: true,
            },
          };
          break;
        }

        case "e-stock": {
          this.show_element = {
            rodape: {
              montadoras: false,
              patrocinadores: false,
              fornecedores: false,
              mediaPartners: false,
            },
          };
          break;
        }
      }
    },
  },

  mounted() {
    this.verificaElemento();
    this.fetchProdutos();
  },

  created() {},
};
</script>
  
<style scoped>
.main-b2b {
  height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#0e080d6e, #0e080d), var(--bg-camarote-b2b);
  background-repeat: no-repeat;
  background-size: cover;
}

.main-b2b .titulo {
  background-image: none;
  font-size: 34px;
  color: #fff;
}

.loading {
  min-height: 37vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-estrutura {
  font-family: MetronicPro-Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.p-estrutura-mobile {
  font-family: MetronicPro-Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 0.8rem;
  display: none;
}

.img-hist {
  background-size: cover;
  background-position: center;
}

.flex-1 {
  flex-grow: 1;
}

.box-camarote {
  width: 100%;
  background-color: red;
  max-height: 500px;
  height: 100%;
  margin-bottom: 40px;
}

.hist-content {
  height: 420px;
}

.box-texto {
  padding: 40px !important;
  background-color: var(--primary-color);
}

.box-texto h2 {
  margin-bottom: 20px;
  font-family: "MetronicPro-Bold";
  font-size: 35px;
  line-height: 36px;
  color: var(--text-color);
}

.button-camarote-more {
  background-color: transparent;
  text-decoration: underline;
  color: var(--text-color);
  border: none;
  font-size: 20px;
  padding: 0px !important;
  text-align: initial;
}

.button-camarote-more:hover,
.button-camarote-more:active {
  background-color: transparent !important;
}

.card-header {
  background-color: transparent;
  border: none;
  font-family: "MetronicPro-Semibold";
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
}

.button-contact-camarote {
  width: 226px;
  background-color: var(--text-color);
  border: none;
  color: var(--primary-color);
  text-transform: uppercase;
}

.button-contact-camarote:hover,
.button-contact-camarote:active {
  background-color: var(--text-color) !important;
  border: none;
  color: var(--primary-color) !important;
  opacity: 0.8;
}

.button-contact-camarote:focus {
  opacity: 1 !important;
}

.card.camarotes {
  background-color: transparent;
  border: none;
  font-family: "MetronicPro-Semibold";
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
}

.card-text {
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
}

.espaco-camarotes {
  margin-top: 60px;
}

.card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (max-height: 848px) {
  .main-b2b {
    height: 90vh;
  }
}

@media (max-width: 660px) {
  .main-b2b {
    height: 44vh;
  }

  .hist-content.box-texto {
    height: auto;
  }
  .titulo {
    font-size: 0.9rem !important;
  }

  .hist-content {
    height: 280px;
  }

  .p-estrutura-mobile {
    display: initial;
  }
  .p-estrutura {
    display: none;
    padding: 16px;
  }
}
</style>
    