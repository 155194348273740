<template>
  <section>
    <div v-show="!loading && !token_sms_ativacao">
      <b-container>
        <b-row>
          <b-col cols="12" lg="12" class="piloto-favorito">
            <h3>
              {{ form.nome || "Ei" }}, qual o seu piloto favorito na Stock Car?
            </h3>
            <p class="frase-top">
              A partir desta escolha, sua página do Stock Club será
              personalizada com fotos e informações do seu piloto favorito.
            </p>

            <Notificacao
              :notificacao="notificacao_infos"
              v-if="notificacao_infos.count_timer"
              @atualizaNotificacao="notificacao_infos.count_timer = 0"
            />
          </b-col>
        </b-row>
      </b-container>

      <CardsPilotos @selectedPiloto="selectedPiloto" data-anima="bottom" />

      <b-container class="pb-5 mt-5">
        <b-row>
          <b-col cols="12" lg="12">
            <div class="item-recaptcha">
              <Recaptcha
                :sitekey="sitekey"
                ref="recaptchaForm"
                @setRecaptcha="setRecaptcha"
              />
            </div>
            <hr class="linha mt-5" />
            <div class="btn-avancar">
              <b-button
                id="botao-final"
                type="submit"
                class="acessar btn"
                :disabled="!piloto_selecionado"
                @click.prevent="onSubmit"
                >CONTINUE</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-show="!loading && token_sms_ativacao" class="mt-5">
      <b-container>
        <b-row>
          <Notificacao
            :notificacao="notificacao_infos"
            v-if="notificacao_infos.count_timer"
            @atualizaNotificacao="notificacao_infos.count_timer = 0"
          />
          <b-col cols="12" lg="12" class="piloto-favorito">
            <h3>
              {{ form.nome || "Hey" }}, Enter the verification code sent to your
              cell phone:
            </h3>
            <form @submit.prevent="validarCodigo">
              <div class="codigo">
                <div v-for="(v, index) in inputs" :key="index">
                  <b-form-group
                    :id="`codigo-${index}`"
                    :label-for="`codigo-${index}`"
                  >
                    <!-- :class="{ error: msgErro.show && !success }" -->
                    <b-form-input
                      :id="`input-${index}`"
                      :name="`input-${index}`"
                      v-model="codigo[index]"
                      type="text"
                      autocomplete="off"
                      required
                      maxlength="1"
                      minlength="1"
                      @keyup="(e) => nextInput(e)"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="btn-codigo">
                <b-button
                  type="submit"
                  class="acessar btn"
                  :disabled="!codigo.length"
                  >CONTINUE</b-button
                >
              </div>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="loading" class="loading" data-anima="top">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";

import CardsPilotos from "@/components/CardsPilotos.vue";
import Recaptcha from "@/components/security/Recaptcha.vue";
export default {
  name: "PilotoFavorito",
  data() {
    return {
      recaptcha_code: "",
      sitekey: process.env.VUE_APP_KEY_CAPTCHA,
      piloto_selecionado: null,
      loading: false,

      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
      token_sms_ativacao: false,
      inputs: 6,
      codigo: [],
    };
  },
  components: {
    CardsPilotos,
    Recaptcha,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    questaoPiloto: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    validarCodigo() {
      this.loading = false;
      const data = {
        code: this.codigo.join(""),
        celular: this.form.telefone,
      };
      api
        .post(`/registro/validar-codigo`, data)
        .then(() => {
          this.token_sms_ativacao = false;
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "danger",
            mensagem: error.response
              ? error.response.data
              : "Algo deu errado ao criar a conta",
            count_timer: 10,
          };
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    nextInput(event) {
      setTimeout(() => {
        let [input, id] = event.target.id.split("-");
        id = parseInt(id) + 1;
        let nextInput = document.getElementById(
          input + "-" + parseInt(id).toString()
        );
        if (nextInput) nextInput.focus();
      }, 20);
    },
    selectedPiloto(data) {
      this.piloto_selecionado = data.nome;
      const botaoFinal = document.getElementById("botao-final");
      const botaoPosicaoY = botaoFinal.offsetTop;
      const alturaJanela = window.innerHeight;
      const pontoRolagem = botaoPosicaoY - alturaJanela / 2;
      window.scrollTo({ top: pontoRolagem, behavior: "smooth" });
    },
    setRecaptcha(token) {
      this.recaptcha_code = token;
      this.onSubmit();
    },
    onSubmit() {
      if (this.recaptcha_code) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.token_sms_ativacao = false;

        this.token_sms_ativacao = false;

        this.token_sms_ativacao = false;

        this.notificacao_infos = {
          variant: "primary",
          mensagem: "",
          count_timer: 0,
        };

        const data = {
          ...this.form,
          responses: [
            {
              idPesquisaPerfil: this.questaoPiloto.idPesquisaPerfil,
              resposta: this.piloto_selecionado,
            },
          ],
          captcha: this.recaptcha_code,
        };

        this.loading = true;
        this.$emit("loading", true);
        api
          .post(`/Registro`, data)
          .then(() => {
            this.token_sms_ativacao = true;
            this.$router.push(`/stockclub/confirmacao-registro?u=${data.nome}`);
          })
          .catch((error) => {
            this.notificacao_infos = {
              variant: "danger",
              mensagem: error.response
                ? error.response.data
                : "Algo deu errado ao criar a conta",
              count_timer: 10,
            };
            this.loading = false;
            this.$emit("loading", false);
            console.log(error);

            this.$refs.recaptchaForm.resetRecaptcha();
          });
      } else {
        this.$refs.recaptchaForm.executeRecaptcha();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.token_sms_ativacao = this.form.estrangeiro;
    });
  },
};

</script>

<style scoped>
.linha {
  color: #eabb4d !important;
  height: 1px;
  width: 100%;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
}
.piloto-favorito h3 {
  color: #eabb4d;
  font-family: "MetronicPro-SemiBold";
  font-style: italic;
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 20px;
}
.piloto-favorito .frase-top {
  color: #fff;
  text-align: center;
  margin-bottom: 80px;
}
.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
  width: 300px;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
.btn-avancar {
  margin-bottom: 100px;
}

@media (min-width: 990px) {
  .btn-avancar {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 989px) {
  .acessar {
    width: 100%;
  }

  .wProgress {
    max-width: 85% !important;
  }

  .multi-steps > li {
    padding-left: 50px;
  }

  .multi-steps > li:after {
    width: 107px;
    left: -10px;
  }

  .imgCarroProgress {
    margin-left: -50px;
    display: block;
    margin-top: -30px;
  }
  #all-steps {
    display: grid;
  }

  .icBandeira {
    margin-top: -60px;
  }
}
.loading {
  min-height: 50vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.msgErro {
  color: #ff1717 !important;
  text-align: center;
  margin-top: 50px;
}
.codigo {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  margin-top: 40px;
}
.codigo input {
  width: 70px;
  height: 60px;
  background: transparent !important;
  border: 1px solid #e6c156 !important;
}

.codigo input:focus,
.codigo input:hover {
  box-shadow: none;
  border-color: #fff !important;
}

.codigo input:focus .codigo input:focus::placeholder {
  color: #e6c156 !important;
  background-color: #ffffff00 !important;
}

.codigo input::placeholder {
  color: #e6c156 !important;
}
.codigo input {
  color: #fff !important;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}

.codigo input.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}

.codigo input.error:hover,
.codigo input.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}
.btn-codigo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 30px;
}
</style>
