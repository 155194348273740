<template>
  <section class="page-start">
    <div>
        <img src="@/assets/img/quiz/logo.png" alt="Logo stock quiz" class="img-logoquiz" />
        <h2>SABE TUDO SOBRE A STOCK CAR? </h2>
        <h3>PARTICIPE E PROVE QUE SEU CONHECIMENTO É CAMPEÃO!</h3>

        <router-link to="/stockcar/proseries/stock-quiz" class="btn-comecar">COMEÇAR</router-link>
    </div>
    <LogosHome :show_element="show_element.rodape" />
  </section>
</template>
  
<script>
import LogosHome from "@/components/LogosHome.vue";
export default {
  name: "StartQuiz",
  data() {
    return {
      show_element: {
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
    };
  },
  components: {
    LogosHome,
  },
};
</script>

<style scoped>

.page-start {
    background-image: url('@/assets/img/quiz/bg-liso.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.page-start h2 {
    font-family: "MetronicPro-Bold";
    letter-spacing: 6px;
    color: #EABB4E;
    text-transform: uppercase;
    text-align: center;
    margin: 20px 0px 10px 0px;
}

.page-start h3 {
    font-family: "MetronicPro-Light";
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
}

.img-logoquiz {
  margin: auto;
  display: block;
  padding-top: 120px;
  padding-bottom: 30px;
}

.btn-comecar {
    font-family: "MetronicPro-Bold";
    padding: 10px 0px;
    text-align: center;
    margin: auto;
    display: table;
    width: 280px;
    letter-spacing: 3px;
    border-radius: 7px;
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: #B48544;
    border: 2px solid #F7B04D;
    text-decoration: none;
    margin-top: 20px !important;
}

.btn-comecar:hover {
    background-color: #F7B04D;
}

@media (max-width: 989px) {
  .img-logoquiz {
    padding-top: 50px;
  }
}

</style>
  