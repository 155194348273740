<template>
  <div>
    <div id="recaptcha"></div>
  </div>
</template>

<script>
if (typeof window !== "undefined") {
  window.recaptchaLoaded = new Promise((resolve) => {
    window.vueRecaptchaInit = resolve;
  });
  const recaptchaScript = document.createElement("script");
  recaptchaScript.setAttribute(
    "src",
    "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaInit&render=explicit"
  );
  recaptchaScript.setAttribute("async", "");
  recaptchaScript.setAttribute("defer", "");
  document.body.appendChild(recaptchaScript);
}

export default {
  name: "Recaptcha",
  props: {
    sitekey: String,
  },
  methods: {
    resetRecaptcha() {
      window.grecaptcha.reset();
    },
    executeRecaptcha() {
      window.grecaptcha.execute();
    },
    onVerify(response) {
      this.$emit("setRecaptcha", response);
    },
  },
  created() {
    if (typeof window === "undefined") return;

    window.recaptchaLoaded.then(() => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.render("recaptcha", {
          sitekey: this.sitekey,
          size: "invisible",
          callback: (response) => {
            this.onVerify(response);
          },
        });
      });
    });
  },
};
</script>

<style>
</style>