<template>
  <section class="destaque-beneficios" v-if="beneficios.length">
    <b-container class="slide-beneficios">
      <div>
        <h1 class="titulo-h1">GIFTS</h1>
        <h2 class="titulo-h2">CAMPEÕES</h2>
        <h3 class="titulo-h3">
          RESGATE SEUS BENEFÍCIOS EXCLUSIVOS DO STOCK CLUB
        </h3>
      </div>

      <div v-show="!loading">
        <b-carousel id="carousel" :interval="15000" controls no-animation>
          <b-carousel-slide v-for="item in beneficios" :key="item.id">
            <template v-slot:img>
              <div class="custom-slide-content">
                <div class="box-conteudo-beneficios">
                  <div class="box-nome">
                    <h3 class="nome-beneficio">{{ item.nome }}</h3>
                  </div>
                  <img
                    :src="item.urlImagem"
                    alt="Imagem de destaque benefícios"
                    class="img-beneficio"
                  />

                  <div class="baixar">
                    <a @click.prevent="download(item)">
                      <img
                        src="@/assets/img/icone-download.png"
                        alt="Imagem de ícone de download"
                        class="icone-baixar"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <div class="loading" v-if="loading">
        <b-spinner
          style="
            width: 3rem;
            height: 3rem;
            border-color: #e6c156;
            border-right-color: transparent;
            --bs-spinner-animation-speed: 0.5s;
          "
          label="Large Spinner"
          variant="warning"
        ></b-spinner>
      </div>
    </b-container>
  </section>
</template>

<script>
import axios from "axios";
import { api } from "@/services.js";
export default {
  name: "DestaquesBeneficios",
  data() {
    return {
      beneficios: [],
      loading: false,
    };
  },
  methods: {
    download(item) {
      this.loading = true;
      const headers = {
        ApiKey: "980372f6-a917-4d8d-a6b0-fc76b6b2d6c3",
        Authorization: `Bearer ${window.localStorage.token}`,
      };

      axios
        .get(`${process.env.VUE_APP_API_HOST}/Beneficio/${item.id}/Download`, {
          responseType: "blob",
          headers,
        })
        .then((response) => {
          const fileName = item.nome;
          // const urlParts = "sua_url_de_download".split(".");
          // const extension = urlParts[urlParts.length - 1];
          let contentType = "image/png";
          // if (extension === "jpg" || extension === "jpeg") {
          //   contentType = "image/jpeg";
          // }
          const blob = new Blob([response.data], { type: contentType });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;

          a.click();

          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            a.remove();
          }, 1000);
        })
        .catch((error) => {
          console.error("Erro:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchBeneficios() {
      this.loading = true;
      api
        .get("/Beneficio")
        .then((resp) => {
          this.beneficios = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchBeneficios();
  },
};
</script>

<style scoped>
.slide-beneficios {
  display: flex;
}
.destaque-beneficios .row {
  padding: 50px 0px;
}
.col-textos {
  padding-top: 50px;
}
.titulo-h1 {
  color: #eabb4e;
  font-size: 98px;
  font-family: "MetronicPro-Black";
  font-style: italic;
  position: relative;
  top: -25px;
}
.titulo-h2 {
  color: #ffffff;
  font-family: "MetronicPro-Regular";
  font-size: 46px;
  padding-left: 50px;
  text-align: center;
  top: -80px;
  position: relative;
}
.titulo-h3 {
  color: #ffffff;
  font-family: "MetronicPro-Light";
  font-size: 24px;
  position: relative;
  top: -75px;
  width: 470px;
  padding-left: 10px;
}
.img-beneficio {
  transform: rotate(-2deg);
  width: 400px;
  height: 100%;
}
.box-conteudo-beneficios {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.nome-beneficio {
  align-items: end;
  display: flex;
  color: #ffffff;
  font-family: "MetronicPro-Bold";
  font-size: 19px;
  font-style: italic;
  text-align: right;
}

.icone-baixar {
  cursor: pointer;
  margin-bottom: 10px;
}

.box-nome {
  width: 235px !important;
}

@media (min-width: 990px) {
  .baixar,
  .box-nome {
    align-items: end;
    display: flex;
  }
}
@media (max-width: 989px) {
  .baixar {
    margin: 20px;
    display: flex;
    justify-content: center;
  }
  .img-beneficio {
    width: 100%;
  }
  .box-conteudo-beneficios {
    padding: 0px 30px;
    display: block;
  }
  .slide-beneficios {
    display: block;
  }
  .box-nome {
    margin: 0 auto;
    display: table;
  }
  .col-textos {
    padding-top: 0px;
  }
  .destaque-beneficios .row {
    padding: 20px 0px;
  }
  .titulo-h1 {
    top: 0px;
    font-size: 40px;
    text-align: center;
  }
  .titulo-h2 {
    margin-bottom: 0px !important;
    font-size: 28px;
    padding-left: 0px;
    text-align: center;
    top: -10px;
  }
  .titulo-h3 {
    width: 100%;
    padding-left: 0px;
    top: 0px;
    text-align: center;
    font-size: 20px;
  }
  .nome-beneficio {
    width: 100%;
    font-size: 18px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1280px) and (max-width: 1399px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1280px;
  }
}

.loading {
  min-height: 37vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
