/* eslint-disable */
import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import englishEn from "./locales/en.json";
import portuguesPt from "./locales/pt.json";

const i18n = new VueI18n({
  locale: 'pt',
  messages: {
    en: englishEn,
    pt: portuguesPt
  },
});

export default i18n;
