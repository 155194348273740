<template>
  <div>
    <FormSejaPatrocinador />
  </div>
</template>

<script>
import FormSejaPatrocinador from "@/components/FormSejaPatrocinador.vue";

export default {
  data() {
    return {};
  },
  components: {
    FormSejaPatrocinador,
  },

  methods: {},

  created() {},
};
</script>
  
<style scoped>
</style>
    