<template>
  <div>
    <StockChannel />
  </div>
</template>

<script>

import StockChannel from "@/components/StockChannel.vue";

export default {
  components: {
    StockChannel
  }
};
</script>
<style scoped>
.bg {
  background: #000;
}
</style>
