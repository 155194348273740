<template>
  <section>
    <div class="main-galeao responsive-manual margin-row container">
      <div style="z-index: 10; position: relative">
        <b-row class="row-cards galeao-content" data-anima="top">
          <b-col
            cols="0"
            offset-md="1"
            offset-xl="2"
            xl="5"
            lg="5"
            md="5"
            sm="0"
            class="col-card" style="margin-left: 0px;"
          >
            <div class="box-proxima-etapa">
              <img
                class="circuito"
                src="../assets/img/imgs-bh/circ-bh.png"
                alt="Imagem do circuito"
                style="margin-bottom: 20px;"
              />

              <div class="infoData">

                <div class="data">
                  <p class="dia">18</p>
                  <p class="mes">AGO</p>
                  <p class="mes">2024</p>
                </div>

                <div class="local">
                  <p class="pista">BH Stock Festival </p>
                  <p class="estado">Belo Horizonte - MG</p>
                </div>
              </div>
            </div>
            <div class="linha"></div>
            <div class="titulo-noticia">
              O Festival entra para a história da capital mineira ao trazer para a cidade uma etapa da Stock Car Pro Series, principal categoria do automobilismo brasileiro.
            </div>
          </b-col>
          <b-col cols="12" lg="4" md="5" class="col-card galeao-content">
            <img
              class="logo-buenos"
              style="margin-left: auto; max-width: 400px;"
              src="../assets/img/imgs-bh/logo-bh.png"
            />
            <button class="ingresso" @click="garantirIngresso">GARANTA SEU INGRESSO</button>
          </b-col> 
        </b-row>
      </div>

      <div class="overlay-galeao bg-buenosaires bg-bh"></div>
    </div>

    <div class="responsive-mobile">
      <div>
        <div class="bg-buenosaires-mobile bg-bh-mobile">

          <img class="logo-mobile" src="../assets/img/imgs-bh/logo-bh.png" />
          <button class="ingresso" @click="garantirIngresso">GARANTA SEU INGRESSO</button>
        
         <div style="padding-left: 80px; padding-right: 80px">
          <div class="linha-branca"></div>
         </div>

        <h2 class="title-main-galeao">
            O Festival entra para a história da capital mineira ao trazer para a cidade uma etapa da Stock Car Pro Series, principal categoria do automobilismo brasileiro.
        </h2>

           <div class="circuito-mobile">
            <img
              class="circuito"
              src="../assets/img/imgs-bh/circ-bh.png"
              alt="Imagem do circuito"
            />
          </div>

          <div class="box-proxima-etapa">
            <div class="infoData">

                <div class="data">
                  <p class="dia">18</p>
                  <p class="mes">AGO</p>
                  <p class="mes">2024</p>
                </div>

              <div class="local">
                <p class="pista">BH Stock Festival</p>
                <p class="estado">Belo Horizonte - MG</p>
              </div>
            </div>
            
          </div>
        </div>

      </div>
    </div>

    <b-container class="bv-row noticias" data-anima="bottom">
      <b-row>
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h2 class="titulo">
            <strong><i>HISTÓRIA</i></strong>
          </h2>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>

      <b-row class="box-historia">
        <b-col
          cols="12"
          lg="6"
          class="hist-content img-hist historia-bg bg-hist"
        >
        </b-col>
        <b-col cols="12" lg="6" class="hist-content box-texto">
          <div class="linha-noticia"></div>
          <h2>Mais que uma corrida!</h2>
          <p style="color: var(--text-color); margin-bottom: 30px;">
           O BH Stock Festival promete experiências diferenciadas e múltiplas para quem for assistir à prova in loco. As atividades envolvem entretenimento para toda a família e com preços acessíveis. A Esplanada do Mineirão será transformada em uma enorme Fan Zone, que receberá shows musicais, festival gastronômico, kart, autorama, espaço kids, simuladores, roda gigante e muito mais.
          </p>
          <a href="https://www.stockproseries.com.br/stockcar/proseries/noticia/1769" style="text-decoration: none;" class="leia-mais">
            Leia mais
          </a>
        </b-col>
      </b-row>
    </b-container>

    <b-row class="mb-5 mt-5">
      <b-col class="titulo-centralizado">
        <hr class="linha-titulo" />
        <h2 class="titulo">
          <strong><i>BH STOCK FESTIVAL </i></strong> EM NÚMEROS
        </h2>
        <hr class="linha-titulo" />
      </b-col>
    </b-row>

    <b-container>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="5" xl="5" class="primeiracoluna">
          <h2 class="title-num-galeao">Retorno 360</h2>

          <img class="img-estrutura" src="../assets/img/imgs-bh/imgnum.png" />

          <div class="desc-estrutura">

            <p class="p-estrutura">R$ 70 milhões de investimento na cidade;</p>
            <div class="line-estrutura"></div>

            <p class="p-estrutura">R$ 200 milhões injetados na economia de BH;</p>
            <div class="line-estrutura"></div> 

            <p class="p-estrutura">Mais de 30 mil pessoas visitando a cidade na etapa;</p>
            <div class="line-estrutura"></div>

            <p class="p-estrutura"> R$ 20 milhões de impostos gerados para o município;</p>
            <div class="line-estrutura"></div>

            <p class="p-estrutura">Mais de 2 mil empregos diretos;</p>
            <div class="line-estrutura"></div>

            <p class="p-estrutura">Transmissão da etapa de BH para 171 países por meio de TV aberta, TV fechada e canais no YouTube.</p>

          </div>
        </b-col>

        <b-col cols="12" sm="12" md="12" lg="2" xl="2" class="segundacoluna">
          <div class="linha-primary"></div>
        </b-col>

        <b-col cols="12" sm="12" md="12" lg="5" xl="5" class="terceiracoluna">
          <h2 class="title-num-galeao">O circuito</h2>

          <img class="img-estrutura" src="../assets/img/imgs-bh/imgnum2.png" />

          <div class="desc-estrutura">
            <p class="p-estrutura">
              3.200 metros;
            </p>
            <div class="line-estrutura"></div>

            <p class="p-estrutura">
              8 curvas
            </p>
          </div>

          <div class="texto-circuito">
            <p>
                O circuito passa pelo entorno do Mineirão e segue pelas imediações. A reta principal do trajeto será na Avenida Coronel Oscar 
                Paschoal, entre o Centro Esportivo Universitário (CEU) e o Hall de entrada do estádio. Os carros partirão em direção à 
                Avenida Antônio Abrahão Caram e continuarão em direção à Avenida Rei Pelé. 
            </p>

            <p>
                Após contornar o Mineirão, os carros subirão a Avenida Presidente Carlos Luz até próximo ao trevo do bairro Ouro Preto. Ali, 
                os pilotos farão uma curva de 180 graus para retornar e voltar “na contramão” para o início do circuito, de volta à Avenida 
                Coronel Oscar Paschoal.   
            </p>

            <p>
                Diversas estruturas temporárias serão movimentadas para a realização do evento. Cerca de 7 km de gradis, 4 km de guardrail 
                e 3 km de blocos de concreto serão posicionados em todo o circuito para garantir a segurança dos pilotos, equipes e público presente.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>


    <b-row class="margin-depo">
      <b-col class="titulo-centralizado">
        <hr class="linha-titulo" />
        <h2 class="titulo">
          <strong><i>DEPOIMENTOS</i></strong>
        </h2>
        <hr class="linha-titulo" />
      </b-col>
    </b-row>

    <b-row class="bg-galeao-2 bg-depoimentos bgDepoimentosBH">
      <b-col cols="0" lg="12" md="7" class="col-desk"> </b-col>
      <div>
        <b-container>
          <b-row>
            <b-col cols="12" lg="8">
                <img
                    class="img-desktop"
                    style="width: 100%;"
                    src="../assets/img/imgs-bh/dep-mob.png"
                 />
            </b-col>
            <b-col cols="12" lg="4">
              <b-carousel
                id="carousel-2"
                :interval="10000"
                controls
                indicators
                background="#e6c156"
                img-width="1024"
                img-height="580"
                style="text-shadow: 1px 1px 2px #333"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-carousel-slide
                  img-src="https://emailimagesnb.s3.sa-east-1.amazonaws.com/guids/CABINET_739f4e8d235fc6f33869011f2d71c7ce4decb04f715d24f2d0f33c00c953e62f/images/bgcarrossel_g12.jpg"
                >
                  <div class="img-carrossel">
                    <img
                      class="thumb-depoimento"
                      alt="Imagem do Daniel Serra"
                      src="../assets/img/imgs-bh/felipe.png"
                    />
                    <p>
                      <strong>Felipe Massa, </strong> <br />piloto da Stock Car e ex Fórmula 1<br />
                    </p>
                  </div>

                  <div class="html" id="html">
                    "É muito importante uma corrida como essa para a cidade, pois traz business, trabalho e tantas oportunidades. E eu fico muito honrado e 
                    feliz de fazer parte de tudo isso, largando na primeira corrida de Belo Horizonte"
                  </div>
                </b-carousel-slide>

                <b-carousel-slide
                  img-src="https://emailimagesnb.s3.sa-east-1.amazonaws.com/guids/CABINET_739f4e8d235fc6f33869011f2d71c7ce4decb04f715d24f2d0f33c00c953e62f/images/bgcarrossel_g12.jpg"
                >
                  <div class="img-carrossel">
                    <img
                      class="thumb-depoimento"
                      alt="Imagem do Daniel Serra"
                      src="../assets/img/imgs-bh/caca.png"
                    />
                    <p>
                      <strong>Cacá Bueno, </strong> <br />piloto 5x campeão da Stock Car<br />
                    </p>
                  </div>

                  <div class="html" id="html">
                    "Eu, como carioca, sempre recebi muitos fãs mineiros de automobilismo em Jacarepaguá. E sempre sentimos falta de andar numa praça tão importante quanto BH, pois é uma cidade extremamente importante para todos nós e para os nossos patrocinadores. Eu estou feliz e entusiasmado com a ideia de vir para cá e sabendo do impacto que essa corrida vai causar na economia, no comércio e na quantidade de empregos que vai gerar. Sem dúvida nenhuma, a Stock Car movimenta um circo gigantesco e um legado também"
                  </div>
                </b-carousel-slide>

                <b-carousel-slide
                  img-src="https://emailimagesnb.s3.sa-east-1.amazonaws.com/guids/CABINET_739f4e8d235fc6f33869011f2d71c7ce4decb04f715d24f2d0f33c00c953e62f/images/bgcarrossel_g12.jpg"
                >
                  <div class="img-carrossel">
                    <img
                      class="thumb-depoimento"
                      alt="Imagem do Daniel Serra"
                      src="../assets/img/imgs-bh/reginaldo.png"
                    />
                    <p>
                      <strong>Reginaldo Leme, </strong> <br />referência na cobertura de esportes a motor <br />
                    </p>
                  </div>

                  <div class="html" id="html">
                    "Estou aqui ao lado de dois grandes profissionais, com o Felipe Massa, que, depois de tudo que ele fez na Fórmula 1, com um carro estreitinho, e uma experiência tão linda, até chegar na Stock Car. Logo em seguida temos o Cacá, que é um profissional e tanto com vários títulos na história do Brasil. Com tudo isso, o que posso dizer é que será um evento grande. Isso é incrível para a cidade e para o público que gosta do esporte", comemora.
                  </div>
                </b-carousel-slide>
                
              </b-carousel>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-row>

    <b-row class="margin-depo">
      <b-col class="titulo-centralizado">
        <hr class="linha-titulo" />
        <h2 class="titulo">
          <strong><i>GALERIA</i></strong> DE VIDEOS
        </h2>
        <hr class="linha-titulo" />
      </b-col>
    </b-row>

    <b-container>
      <b-carousel id="carousel" :interval="15000" controls>
        <b-carousel-slide class="slides-videos" v-for="(pair, index) in videoPairs" :key="index">
          <div class="boxes-slides-videos">
            <!-- Primeiro vídeo -->
            <div class="box-slide" v-for="(video, videoIndex) in pair" :key="videoIndex">
              <p>{{ video.date }}</p>
              <img
                :src="video.thumbnail"
                :alt="`Imagem do video ${index * 2 + videoIndex + 1}`"
                loading="lazy"
                @click="openModal(video)"
              />
              <h2>{{ video.title }}</h2>
            </div>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </b-container>

    <b-modal v-if="selectedVideo" v-model="modalOpen" @hide="closeModal" id="modal-1">
      <iframe :src="selectedVideo.url" frameborder="0" width="560" height="315"></iframe>
    </b-modal>

     <b-row class="margin-depo">
      <b-col class="titulo-centralizado">
        <hr class="linha-titulo" />
        <h2 class="titulo">
          <strong><i>GALERIA</i></strong> DE FOTOS
        </h2>
        <hr class="linha-titulo" />
      </b-col>
    </b-row>

    <b-container class="modal-image">
      <b-carousel id="carousel" :interval="15000" controls>
        <b-carousel-slide v-for="(pair, index) in imagePairs" :key="index" class="slides-videos">
          <div class="boxes-slides-videos">
            <div class="box-slide" v-for="(image, i) in pair" :key="i">
              <img :src="require(`../assets/img/imgs-bh/${image}`)" 
              alt="Imagem do evento" 
              class="img-evento"
              @click="openModalImage(require(`../assets/img/imgs-bh/${image}`))" />
            </div>
          </div>        
        </b-carousel-slide>
      </b-carousel>

        <b-modal ref="modal" id="imageModal" hide-footer size="xl">
          <img :src="modalImageSrc" alt="Imagem em tamanho grande" class="img-fluid">
        </b-modal>

        <p class="creditos">Créditos das fotos: Thiago Poncherello</p>
    </b-container>

    <LogosHome :show_element="show_element.rodape" />
  </section>
</template>
  
<script>
import LogosHome from "@/components/LogosHome.vue";
import { BModal } from "bootstrap-vue";

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      modalImageSrc: '',
      modalOpen: false,
      selectedVideo: null,
      images: [],
      videos: [
        {
          date: "18 jun 2024",
          thumbnail: "https://i.ytimg.com/vi_webp/T3DXsjQgmKQ/maxresdefault.webp",
          title: "O BH Stock Festival apoia o SOS Lagoa da Pampulha para recuperar o cartão postal da capital mineira!",
          url: "https://www.youtube.com/embed/T3DXsjQgmKQ?si=MeEF1BlWZZOOsQFD",
        },

        {
          date: "10 jun 2024",
          thumbnail: "https://i.ytimg.com/vi_webp/ZhEn7-FgpcE/maxresdefault.webp",
          title: "Gaetano di mauro testa a pista do bh stock festival com simulador",
          url: "https://www.youtube.com/embed/ZhEn7-FgpcE?si=QsX9PZt3f4i-KwZH",
        },

        {
          date: "17 abr 2024",
          thumbnail: "https://i.ytimg.com/vi_webp/lTgxaEhFTo4/maxresdefault.webp",
          title: "O compromisso ambiental do BH STOCK FESTIVAL para BH foi firmado e vai se estender por 5 anos.",
          url: "https://www.youtube.com/embed/lTgxaEhFTo4?si=-xpzkzASkZDqqQEg",
        },

        {
          date: "14 mar 2024",
          thumbnail: "https://i.ytimg.com/vi/HtaOdkoydkk/hqdefault.jpg",
          title: "Os pilotos cacá bueno e felipe massa fazem visita em bh",
          url: "https://www.youtube.com/embed/HtaOdkoydkk?si=lT49PWTuh6GrFYyP",
        },

        {
          date: "7 mar 2024",
          thumbnail: "https://i.ytimg.com/vi_webp/uGMTO5skOB8/maxresdefault.webp",
          title: "Simulador circuito do BH Stock Festival",
          url: "https://www.youtube.com/embed/uGMTO5skOB8?si=cXfMKE-24uJxF63a",
        },

        {
          date: "5 mar 2024",
          thumbnail: "https://i.ytimg.com/vi/MfLgY3oZmTo/sddefault.jpg",
          title: "BH Stock Festival / Música Tema - Tianastácia",
          url: "https://www.youtube.com/embed/MfLgY3oZmTo?si=jQbRBmhg9db-qIWV",
        },

        {
          date: "2 mar 2024",
          thumbnail: "https://i.ytimg.com/vi/oUQkZYFTjMk/sddefault.jpg",
          title: "AUTOMOBILISMO, UMA PAIXÃO! David Simpatia",
          url: "https://www.youtube.com/embed/oUQkZYFTjMk?si=HYmOou-DWOFltSdT",
        },

        {
          date: "2 mar 2024",
          thumbnail: "https://i.ytimg.com/vi/MHz4DjBZXTU/sddefault.jpg",
          title: "AUTOMOBILISMO, UMA PAIXÃO! Toninho da Matta",
          url: "https://www.youtube.com/embed/MHz4DjBZXTU?si=5CnMGhWqGzKT-6g0",
        },

        {
          date: "2 mar 2024",
          thumbnail: "https://i.ytimg.com/vi/zQ_2r4fcpOk/sddefault.jpg",
          title: "AUTOMOBILISMO, UMA PAIXÃO! Christiano Augusto",
          url: "https://www.youtube.com/embed/zQ_2r4fcpOk?si=VPjzLZSKH58N58Tp",
        },

        {
          date: "27 fev 2024",
          thumbnail: "https://i.ytimg.com/vi/ZIymmcPVD5Y/sddefault.jpg",
          title: "AUTOMOBILISMO, UMA PAIXÃO! Sérgio Sette Câmara, piloto mineiro de Fórmula E e ex-piloto de Fórmula 1",
          url: "https://www.youtube.com/embed/ZIymmcPVD5Y?si=rns2b7Di343rXDwF",
        },

        {
          date: "27 fev 2024",
          thumbnail: "https://i.ytimg.com/vi/8iNnsnfT1gQ/sddefault.jpg",
          title: "AUTOMOBILISMO, UMA PAIXÃO! Sérgio Sette Câmara, piloto mineiro de Fórmula E e ex-piloto de Fórmula 1",
          url: "https://www.youtube.com/embed/8iNnsnfT1gQ?si=_ZAdtECEYMtARtFp",
        },

        {
          date: "20 fev 2024",
          thumbnail: "https://i.ytimg.com/vi/LIbecOlDzUA/sddefault.jpg",
          title: "Programa Conversa de Redação da Rádio Itatiaia - BH Stock Festival",
          url: "https://www.youtube.com/embed/LIbecOlDzUA?si=DH3QEJnDjfcOi3Wf",
        },

        {
          date: "23 dez 2023",
          thumbnail: "https://i.ytimg.com/vi/9z-D8luaz30/sddefault.jpg",
          title: "BH STOCK FESTIVAL - 18 de agosto de 2024, no Mineirão!",
          url: "https://www.youtube.com/embed/9z-D8luaz30?si=p_LF_qHm-GzV9fK4",
        },

       
      ],
      show_element: {
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
    };
  },
  components: {
    LogosHome,
    BModal,
  },
  computed: {
      videoPairs() {
        const pairs = [];
        for (let i = 0; i < this.videos.length; i += 2) {
          const pair = [this.videos[i]];
          if (this.videos[i + 1]) {
            pair.push(this.videos[i + 1]);
          }
          pairs.push(pair);
        }
        return pairs;
      },
      imagePairs() {
        const pairs = [];
        for (let i = 0; i < this.images.length; i += 2) {
          if (i + 1 < this.images.length) {
            pairs.push([this.images[i], this.images[i + 1]]);
          } else {
            pairs.push([this.images[i]]);
          }
        }
        return pairs;
      }
    }, 
  created() {
    this.loadImages();
  },

  methods: {
    loadImages() {
      try {
        const pegarNome = require.context('@/assets/img/imgs-bh', false, /^\.\/IMG.*\.jpg$/);

        this.images = pegarNome.keys().map(fileName => {
          const imageName = fileName.replace('./', '');
          return imageName
        });
 

      } catch (error) {
        console.error("Error loading images: ", error);
      }
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    garantirIngresso() {
        window.open("https://www.sympla.com.br/evento/bh-stock-festival/2411037", "_blank")
    },
    openModalImage(imageSrc) {
      this.modalImageSrc = imageSrc;
      this.$refs.modal.show();
    },
    openModal(video) {
      this.selectedVideo = video;
      this.modalOpen = true;
    },
    closeModal() {
      this.selectedVideo = null;
      this.modalOpen = false;
    },
  },

};
</script>

<style scoped>

.bg-galeao-2 P {
    margin-left: 20px;
}

#html {
    margin-top: 20px;
}

.creditos {
    color: white;
    text-align: center;
    margin-top: 40px;
}

.texto-circuito {
    color: white;
    padding: 0px 20px;
    margin-top: 20px;
}

.ingresso:hover {
    background-color: #e1b944;
}

.logo-buenos {
    top: 125px;
    position: absolute;
    margin-left: 0; 
    right: 0;
}

.leia-mais {
    transition: background-color 0.5s ease;
    border: 1px solid #0E080D;
    font-weight: bold;
    color: #0E080D;
    padding: 10px 70px;
    text-decoration: none;
    border-radius: 10px;
}

.leia-mais:hover {
  background-color: #0E080D;
  color: #EABB4E;
}

.boxes-slides-videos {
    display: flex !important;
    justify-content: center  !important;
    width: 100%;
    margin: 0 auto  !important;
}

.box-slide {
    margin-top: 20px;
    text-align: left;
    width: 500px;
}
.box-slide img {
    width: 400px;
    cursor: pointer;
}
.box-slide p {
    color: #707070;
    font-size: 17px;
    font-family: "MetronicPro-Light";
    text-align: left;
}
.box-slide h2 {
    text-align: left;
    width: 400px;
    margin-top: 15px;
    font-size: 22px;
    color: #E6C156;
    font-family: "MetronicPro-Bold";
}
.subtitulo-eventos {
    color: #E6C156;
    font-size: 27px;
    font-family: "MetronicPro-SemiBold";
}
.overlay-galeao {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;
  background-blend-mode: overlay;
  background-color: var(--overlay);
}

.main-galeao {
  height: calc(100vh - 135px);
}

.galeao-content {
  width: 100% !important;
  height: calc(100vh - 230px);
  padding-top: 15px;
}

.titulo-centralizado {
  padding-top: 20px;
}

.hist-content {
  height: 420px;
}

.box-texto {
  padding: 40px !important;
  background-color: var(--primary-color);
}

.box-texto h2 {
  margin-bottom: 20px;
  font-family: "MetronicPro-Bold";
  font-size: 35px;
  line-height: 36px;
  color: var(--text-color);
}

.button-link {
  border: 1px solid #0e080d;
  text-decoration: none;
  border-radius: 10px;
  padding: 12px 100px 16px 100px;
  float: left;
  cursor: pointer;

  font-family: "MetronicPro-Semibold";
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
  margin-top: 20px;
}

.button-link::first-letter {
  text-transform: uppercase;
}

.depoimento {
  background: #e6c156 0% 0% no-repeat padding-box;
  opacity: 0.9;
  max-width: 460px;
  width: 100%;
  max-height: 480px;
  height: 100%;
}

.depoimento-space {
  display: flex;
  align-content: center;
  flex-wrap: inherit;
}

.depoimento-img-piloto {
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.img-depoimento-space {
  width: 50%;
  height: 100%;
  background-color: red;
  position: absolute;
  top: -50px;
  left: 22px;
}

.depoimento-titulo {
  color: #0e080d;
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  width: 40%;
  height: 90%;
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
}
.depoimento-titulo strong {
  color: #0e080d;
  font-family: "MetronicPro-Bold";
  font-size: 20px;
}

.depoimento-desc {
  height: 50%;
  padding-left: 32px;
  padding-right: 32px;
  text-align: left;
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  color: #0e080d;
}

.circuito {
  min-width: 120px;
  min-height: 100px;
}

.titulo-noticia {
  width: 450px;
  font-family: "MetronicPro-Regular";
  font-size: 25px;
  color: var(--title-color);
  margin-bottom: 0px;
  margin-top: 15px;
  line-height: 28px;
  text-align: left;
}

.title-num-galeao {
  color: var(--primary-color);
}

.desc-estrutura {
  background: transparent linear-gradient(90deg, #2b262a 0%, #0e080d00 92%) 0%
    0% no-repeat padding-box;
  color: #ffffff;
  margin-top: 40px;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.title-estrutura {
  font-family: MetronicPro-Bold;
  letter-spacing: 0px;
  color: #707070;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.p-estrutura {
  font-family: MetronicPro-Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
}

.line-estrutura {
  border: 1px solid #707070;
  margin-bottom: 12px;
}

.img-estrutura {
  width: 100%;
}

.linha-branca {
  border: 1px solid #ffffff;
  margin-bottom: 40px;
  margin-top: 20px;
}

.title-main-galeao {
  color: #ffffff;
  font-family: MetronicPro-Bold;
  letter-spacing: 0px;
  font-size: 22px;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 40px;
}

.responsive-mobile {
  display: none;
}

.container-novidades .boxes-noticias .coluna-noticia {
  border-right: none;
  border-left: none;
}

.img-noticia {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.play-icon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.play-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 35px solid rgba(255, 255, 255, 0.9); /* Cor e transparência do ícone */
}

.boxes-noticias {
  padding-top: 30px;
  padding-bottom: 30px;
}

.boxes-noticias p {
  font: normal normal 300 17px/25px MetronicPro-Light;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 30px;
}

.boxes-noticias h2 {
  font: normal normal bold 22px/26px MetronicPro-Bold;
  letter-spacing: 0px;
  margin: 17px 0;
  color: var(--primary-color);
}

.boxes-noticias a,
.boxes-noticias a:hover {
  color: var(--primary-color);
}

.boxes-noticias img {
  margin-bottom: 10px;
  border: 1px solid var(--primary-color);
}

.boxes-noticias .coluna-noticia {
  padding-right: 20px;
  border-right: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  padding-left: 20px;
}

.modal-body {
  display: flex !important;
  justify-content: center !important;
}

.col-card {
  margin-bottom: 0px !important;
}

.circuito-mobile {
  display: flex;
  justify-content: center;
}

.mes {
    font-size: 15px;
}
.box-historia {
    margin-top: 40px;
}
.thumb-depoimento {
    width: 100px;
}
.margin-depo {
    margin-top: 60px;
    margin-bottom: 30px;
}
.bg-galeao-2 .img-carrossel {
    margin-top: 25px;
}
.subtitulo-numeros {
    color: #E6C156;
    font-size: 24px;
    font-family: "UniwarsW00Regular";
}
.row-cards {
    margin-left: 0px !important;
}

.bg-bh, .bg-bh-mobile {
  background-image: url(@/assets/img/imgs-bh/BG.gif) !important;
} 

#carousel-2 {
    min-height: 420px !important;
}

@media (max-width: 991px) {

    .bg-galeao-2 .img-carrossel {
        margin-top: 10px;
    }

    .thumb-depoimento {
        width: 85px;
    }

    #carousel-2 #html, #carousel-2 #html *, #carousel-2 #html p, #carousel-2 #html span {
        font-size: 15px !important;
        line-height: 18px !important;
    }

    .bgDepoimentosBH .carousel-item {
        height: 415px  !important;
    }

    .titulo {
        font-size: 20px;
    }
    .bg-bh-mobile {
        background-size: contain !important;
        margin-bottom: 50px !important;
    }

    .responsive-manual {
        display: none;
    }

    .responsive-mobile {
        display: initial;
    }

    .logo-mobile {
        width: 300px !important;
        margin: auto;
        display: table;
        padding-top: 30px;
    }

    .img-hist {
        height: 244px;
    }

    .ingresso {
        background-color: #e6c156;
        border: none;
        font-family: "Uniwars-W00-SemiBold";
        border-radius: 8px;
        padding: 5px 10px;
        font-size: 13px;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        margin: auto;
        display: table;
    }

    .subtitulo-numeros, .subtitulo-eventos {
        font-size: 16px;
    }
    .boxes-slides-videos {
        gap: 15px;
    }
    .box-slide img, .box-slide {
        width: 100%;
    }
    .box-slide h2 {
        width: 100% !important;
        font-size: 14px;
    }
   .margin-depo {
        margin-top: 20px;
        margin-bottom: 0px;
   }
  .linha-primary {
    border: 1px solid #e6c156;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .button-link {
    padding: 12px 16px;
  }

  .button-link {
    width: 100%;
    text-align: center;
  }

  .hist-content p {
    line-height: 20px;
  }

  .box-texto h2 {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 33px !important;
  }

  .box-texto {
    padding: 40px 20px !important;
  }

   .box-slide img {
        height: 100px;
        object-fit: cover;
    }

    .bg-depoimentos {
        background-size: contain !important;
        height: auto !important;
        background-image: none !important;
    }
}


@media (max-height: 660px) {
  .main-galeao {
    height: 78vh;
  }
}

@media (min-width: 991px) {
    .img-evento {
        height: 270px !important;
        object-fit: cover !important;
    }

    .img-desktop {
        display: none;
    }

    .box-slide img {
        height: 225px;
        object-fit: cover;
    }

    .primeiracoluna, .terceiracoluna {
        width: 45%;
    }

    .segundacoluna {
        width: 10%;
    }

  .ingresso {
        top: 300px;
        position: absolute;
        margin-left: 0;
        right: 35px;
        background-color: #e6c156;
        border: none;
        font-family: "Uniwars-W00-SemiBold";
        border-radius: 8px;
        padding: 8px 20px;
    }
}
</style>
  