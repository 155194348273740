<template>
  <section>


    <div class="container-piloto">
    <!-- PILOTOS E-STOCK -->
    <b-tabs content-class="mt-3" v-if="$route.params.categoria == 'e-stock'" v-model="abaAtiva" class="tab-e-stock">
      <!-- CATEGORIA PRO -->
      <b-tab title="PRO" :value="0" active @click="fetchPilotosEstock('PRO')">
        <b-row class="row-cards" v-if="!loading" data-anima="top">
          <b-col cols="12" lg="4" class="col-card" v-for="item in pilotos" :key="item.idPiloto">
            <!-- <router-link :to="`/stockcar/${$route.params.categoria || 'proseries'}/piloto/${item.idPiloto}`" style="text-decoration: none; color: inherit"> -->
              <b-card :img-src="item.imagemB" img-alt="Image" img-top tag="article" style="max-width: 17rem" class="mb-2 card-pilotos">
                <b-card-text class="detalhes-piloto">
                  <h3 class="num-card">#{{ item.numero }}</h3>
                  <p v-if="$route.params.categoria != 'e-stock'" class="pts-card">{{ item.pontuacao }} <span>pts</span></p>
                  <p class="nome-card">{{ primeiroNome(item.nome) }} <br /><strong class="bold">{{ ultimoNome(item.nome) }}</strong></p>
                </b-card-text>
                <!-- <div class="logosCard">
                  <img :src="item.logoMontadora" alt="Logo da montadora" />
                  <img :src="item.logoEquipe" alt="Logo da equipe" />
                </div> -->
              </b-card>
            <!-- </router-link> -->
          </b-col>
        </b-row>
 
        <b-row class="row-cards" v-if="loading" data-anima="bottom">
          <b-col cols="12" lg="4">
            <b-skeleton-img height="350px"></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>
          <b-col cols="12" lg="4">
            <b-skeleton-img height="350px"></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>
          <b-col cols="12" lg="4">
            <b-skeleton-img height="350px"></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>
        </b-row>
      </b-tab>

      <!-- CATEGORIA AM -->
      <b-tab title="AM" :value="1"  @click="fetchPilotosEstock('Amador')" class="custom-tab">
        <b-row class="row-cards" v-if="!loading" data-anima="top">
          <b-col cols="12" lg="4" class="col-card" v-for="item in pilotos" :key="item.idPiloto">
            <!-- <router-link :to="`/stockcar/${$route.params.categoria || 'proseries'}/piloto/${item.idPiloto}`" style="text-decoration: none; color: inherit"> -->
              <b-card :img-src="item.imagemB" img-alt="Image" img-top tag="article" style="max-width: 17rem" class="mb-2 card-pilotos">
                <b-card-text class="detalhes-piloto">
                  <h3 class="num-card">#{{ item.numero }}</h3>
                  <p v-if="$route.params.categoria != 'e-stock'" class="pts-card">{{ item.pontuacao }} <span>pts</span></p>
                  <p class="nome-card">{{ primeiroNome(item.nome) }} <br /><strong class="bold">{{ ultimoNome(item.nome) }}</strong></p>
                </b-card-text>
                <!-- <div class="logosCard">
                  <img :src="item.logoMontadora" alt="Logo da montadora" />
                  <img :src="item.logoEquipe" alt="Logo da equipe" />
                </div> -->
              </b-card>
            <!-- </router-link> -->
          </b-col>
        </b-row>

        <b-row class="row-cards" v-if="loading" data-anima="bottom">
          <b-col cols="12" lg="4">
            <b-skeleton-img height="350px"></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>
          <b-col cols="12" lg="4">
            <b-skeleton-img height="350px"></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>
          <b-col cols="12" lg="4">
            <b-skeleton-img height="350px"></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    </div>

    <!-- TODOS OS PILOTOS EXCETO E-STOCK -->
    <b-row class="row-cards" v-if="!loading && $route.params.categoria != 'e-stock' " data-anima="top">
      <b-col cols="12" lg="4" class="col-card" v-for="item in pilotos" :key="item.idPiloto">

        <router-link v-if="$route.params.categoria != 'turismo-nacional-antigo'" :to="`/stockcar/${$route.params.categoria || 'proseries'}/piloto/${item.idPiloto
          }`" style="text-decoration: none; color: inherit">
          <b-card title="" :img-src="item.imagemB" img-alt="Image" img-top tag="article" style="max-width: 17rem"
            class="mb-2 card-pilotos">

            <b-card-text class="detalhes-piloto">
              <h3 class="num-card">#{{ item.numero }}</h3>
              <p class="pts-card">{{ item.pontuacao }} <span>pts</span></p>
              <p class="nome-card">
                {{ primeiroNome(item.nome) }} <br />
                <strong class="bold">{{ ultimoNome(item.nome) }}</strong>
              </p>
            </b-card-text>

            <div class="logosCard" v-if="$route.params.categoria != 'e-stock'">
              <img :src="item.logoMontadora" alt="Logo da montadora" />
              <img :src="item.logoEquipe" alt="Logo da equipe" />
            </div>

          </b-card>

        </router-link>

        <div v-else style="text-decoration: none; color: inherit; cursor: auto;">
          <b-card title="" :img-src="item.imagemB" img-alt="Image" img-top tag="article" style="max-width: 17rem"
            class="mb-2 card-pilotos">

            <b-card-text class="detalhes-piloto">
              <h3 class="num-card">#{{ item.numero }}</h3>
              <p class="nome-card">
                {{ primeiroNome(item.nome) }} <br />
                <strong class="bold">{{ ultimoNome(item.nome) }}</strong>
              </p>
            </b-card-text>

            <div class="logosCard logos-turismo">
              <img :src="item.logoMontadora" alt="Logo da montadora" />
              <img :src="item.logoEquipe" alt="Logo da equipe" />
            </div>

          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-row class="row-cards" v-if="loading" data-anima="bottom">
      <b-col cols="12" lg="4">
        <b-skeleton-img height="350px"></b-skeleton-img>
        <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
        <b-skeleton animation="wave" width="100%"></b-skeleton>
      </b-col>
      <b-col cols="12" lg="4">
        <b-skeleton-img height="350px"></b-skeleton-img>
        <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
        <b-skeleton animation="wave" width="100%"></b-skeleton>
      </b-col>
      <b-col cols="12" lg="4">
        <b-skeleton-img height="350px"></b-skeleton-img>
        <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
        <b-skeleton animation="wave" width="100%"></b-skeleton>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  name: "TodosPilotos",
  data() {
    return {
      loading: false,
      pilotos: [],
      abaAtiva: 0,
      subcategoria: "",
    };
  },
  props: {
    pilotosExterno: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    primeiroNome(nome) {
      return nome.replace(/ .*/, "");
    },
    ultimoNome(nome) {
      let splitNome = nome.split(" ");
      let sobrenome = splitNome.slice(1).join(" ");
      return sobrenome;
    },
    
    fetchPilotos(id_categoria) {
      this.loading = true;
      api
        .get(`Piloto/categoria/${id_categoria}`)
        .then((resp) => {
          this.pilotos = resp.data.pilotos;
          console.log('>>>>>>>>>', this.pilotos)
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },

    fetchPilotosEstock(subcategoria) {
      this.loading = true;
    api
      .get(`Piloto/categoria/2?subCategoria=${subcategoria}`)
      .then((resp) => {
        this.pilotos = resp.data.pilotos;
        console.log('>>>>>>>>>', this.pilotos);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  },

  },
  mounted() {
    if (this.$route.params.categoria == 'e-stock'){
      this.abaAtiva = 0; 
      this.fetchPilotosEstock('PRO')
    }
    else if (this.$route.name == "DetalheEquipe") {
      this.pilotos = this.pilotosExterno;
    } else {
      this.fetchPilotos(
        mapCategorias[this.$route.params.categoria || "proseries"]
      );
    }
  },
};
</script>

<style scoped>
  .container-piloto .nav-tabs {
    width: 300px !important;
  }

.logosCard {
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 50px;
  top: 80px;
}

.pts-card span {
  font-family: "Uniwars-W00-Black";
  font-size: 14px;
}

article.card.mb-2.card-pilotos {
  max-width: 17rem;
}





@media (min-width: 991px) {
  .logos-turismo {
    top: 115px !important;
  }
  .nav-tabs {
    flex-direction: column !important;
    align-content: center !important;
    width: 300px !important;
  }

  .container-piloto .nav-tabs {
    width: 300px !important;
  }

  .tab-e-stock ul {
    width: 100% !important;
  }
}

</style>