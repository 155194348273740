<template>
  <section class="stock-channel" id="stockchannel">
    <b-container>
      <b-row>
        <b-col cols="12" lg="12">
          <img
            src="@/assets/img/logo-live.png"
            alt="logo Stock Channel"
            class="logo-live"
          />
        </b-col>

        <b-carousel id="carousel" :interval="15000" controls>
          <b-carousel-slide
            v-for="(liveGroup, index) in liveGroups"
            :key="index"
          >
            <template v-slot:img>
              <div class="slides-lives">
                <div
                  v-for="(live, subIndex) in liveGroup"
                  :key="subIndex"
                  class="custom-slide-content"
                >
                  <div>
                    <router-link
                      :to="`/stockclub/channel/${live.idBiblioteca}`"
                      style="text-decoration: none; color: inherit"
                    >
                      <img
                        :src="live.thumbnail"
                        alt="Imagem thumbnail do vídeo"
                      />
                      <h3>{{ live.nome }}</h3>
                      <p>
                        ( {{ live.quantidadeDeVideos }} vídeo{{
                          live.quantidadeDeVideos > 1 ? "s" : ""
                        }}
                        )
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "StockChannel",
  data() {
    return {
      lives: [],
      loading: false,
    };
  },
  computed: {
    liveGroups() {
      const groupSize = 3;
      const groupedLives = [];
      for (let i = 0; i < this.lives.length; i += groupSize) {
        groupedLives.push(this.lives.slice(i, i + groupSize));
      }
      return groupedLives;
    },
  },
  methods: {
    fetchLives() {
      this.loading = true;
      api
        .get("/StockChannel/Biblioteca")
        .then((resp) => {
          this.lives = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.fetchLives();
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
}
.slides-lives {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.slides-lives h3 {
  margin: 0 auto;
  width: 350px;
  color: #eabb4e;
  font-family: "Uniwars-W00-SemiBold";
  font-size: 17px;
  text-align: center;
  padding: 20px 20px;
}

.slides-lives p {
  font-size: 17px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding-top: 0px;
}

.slides-lives img {
  margin: 0 auto;
  max-width: 350px;
  display: block;
  border-radius: 10px;
  box-shadow: 0px 9px 10px #00000029;
}

.logo-live {
  width: 500px;
  margin: 0 auto;
  display: flex;
  padding-top: 150px;
  padding-bottom: 70px;
}

.stock-channel {
  height: 120vh;
  background-image: url(../assets/img/bg_lives.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin: 60px auto;
}

@media (max-width: 1500px) {
  .logo-live {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .stock-channel {
    height: auto;
    background-size: cover;
    padding: 0px 0;
  }
  .slides-lives img {
    max-width: 250px;
  }

  .logo-live {
    width: 300px;
    padding-top: 70px;
    padding-bottom: 10px;
  }

  .slides-lives {
    padding: 0px !important;
    width: 100%;
    display: block;
    margin-bottom: 50px;
  }

  .slides-lives h3 {
    padding: 20px 30px;
  }
}
</style>
