<template>
  <!------------------------ EQUIPES ------------------------->

  <div class="bg-equipes margin-row">
    <b-container>
      <b-row>
        <b-col class="titulo-pilotos titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 v-if="$route.params.categoria == 'formula-4'" class="titulo"><strong>{{ $t("title.equipes") }}</strong></h1>
          <h1 v-else class="titulo"><strong>equipes</strong></h1>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>

      <b-row class="row-cards" v-if="!loading" data-anima="top">
        <b-col
          cols="12"
          lg="4"
          class="col-card"
          v-for="item in equipes"
          :key="item.idEquipe"
        >
          <router-link
            :to="`/stockcar/${$route.params.categoria || 'proseries'}/equipe/${
              item.idEquipe
            }`"
            style="text-decoration: none; color: inherit"
          >
            <b-card
              title=""
              :img-src="item.logoEquipeB || item.logoEquipe"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 17rem"
              class="mb-2 card-pilotos"
            >
              <b-card-text class="card-equipe txt-center-mob">
                <p class="nome-card txt-center-mob">
                  <strong class="bold">{{ item.nomeEquipe }}</strong>
                </p>
              </b-card-text>

              <!-- <div class="pts-equipes txt-center-mob">
                <h3 class="num-card">{{ item.pontuacaoEquipe }}</h3>
                <span>pts</span>
              </div> -->
            </b-card>
          </router-link>
        </b-col>
      </b-row>

      <b-row class="row-cards" v-if="loading" data-anima="bottom">
        <b-col cols="12" lg="4" class="col-card">
          <div>
            <b-skeleton-img height="350px"></b-skeleton-img>
            <b-skeleton
              animation="wave"
              width="60%"
              class="mt-4 mb-3"
            ></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </div>
        </b-col>

        <b-col cols="12" lg="4" class="col-card">
          <div>
            <b-skeleton-img height="350px"></b-skeleton-img>
            <b-skeleton
              animation="wave"
              width="60%"
              class="mt-4 mb-3"
            ></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </div>
        </b-col>

        <b-col cols="12" lg="4" class="col-card">
          <div>
            <b-skeleton-img height="350px"></b-skeleton-img>
            <b-skeleton
              animation="wave"
              width="60%"
              class="mt-4 mb-3"
            ></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  name: "TodasEquipes",
  data() {
    return {
      equipes: [],
      loading: false,
    };
  },
  methods: {
    fetchEquipes(categoria_id) {
      this.loading = true;
      api
        .get(`Equipe/categoria/${categoria_id}`)
        .then((resp) => {
          this.equipes = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.fetchEquipes(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
  },
};
</script>

<style scoped>
.pts-equipes h3 {
  margin-bottom: -5px;
  font-size: 35px !important;
  color: var(--primary-color);
}
</style>