<template>
  <div>
    <!------------------------ CIRCUITOS ------------------------->

    <div class="container-circuito margin-row padding-mob">
      <b-row>
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 v-if="$route.params.categoria == 'formula-4'" class="titulo"><strong>{{ $t("title.circuitos") }} </strong></h1>
          <h1 v-else class="titulo"><strong>CIRCUITOS </strong></h1>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>

      <b-container class="circuitos">
        <b-row v-if="!loading && $route.params.categoria == 'proseries'" data-anima="top">
          <b-col
            cols="12"
            lg="4"
            class="col-circuito"
            v-for="item in circuitos"
            :key="item.idCircuito"
          >
            <router-link
              :to="`/stockcar/${
                $route.params.categoria || 'proseries'
              }/circuito/${item.idCircuito}`"
              style="text-decoration: none; color: inherit"
            >
              <div class="box-circuito" :style="{ backgroundImage: item.isHovered ? 'url(' + item.backgroundGif + ')' : 'url(' + item.circuitoCard + ')' }" 
              @mouseover="handleMouseOver(item)"
              @mouseout="handleMouseOut(item)">
                <img :src="item.circuitoImagem" alt="Imagem do circuito"/>
                <p class="pista">{{ item.circuito }}</p>
                <p class="estado">{{ item.cidade }}</p>
              </div>
            </router-link>
          </b-col>
        </b-row>

         <b-row v-else-if="$route.params.categoria == 'stock-series' || $route.params.categoria == 'e-stock' || $route.params.categoria == 'formula-4'" data-anima="top">
          <b-col
            cols="12"
            lg="4"
            class="col-circuito"
            v-for="item in circuitos"
            :key="item.idCircuito"
          >
              <div class="box-circuito">
                <img :src="item.circuitoImagem" alt="Imagem do circuito"/>
                <p class="pista">{{ item.circuito }}</p>
                <p class="estado">{{ item.cidade }}</p>
              </div>
          </b-col>
        </b-row>

        <b-row v-else data-anima="top">
          <b-col
            cols="12"
            lg="4"
            class="col-circuito"
            v-for="item in circuitos"
            :key="item.idCircuito"
          >
            <router-link
              :to="`/stockcar/${
                $route.params.categoria || 'proseries'
              }/circuito/${item.idCircuito}`"
              style="text-decoration: none; color: inherit"
            >
              <div class="box-circuito">
                <img :src="item.circuitoImagem" alt="Imagem do circuito"/>
                <p class="pista">{{ item.circuito }}</p>
                <p class="estado">{{ item.cidade }}</p>
              </div>
            </router-link>
          </b-col>
        </b-row>

        <b-row v-if="loading" data-anima="bottom">
          <b-col cols="12" lg="4" class="col-circuito">
            <div>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton
                animation="wave"
                width="60%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
            </div>
          </b-col>
          <b-col cols="12" lg="4" class="col-circuito">
            <div>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton
                animation="wave"
                width="60%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
            </div>
          </b-col>
          <b-col cols="12" lg="4" class="col-circuito">
            <div>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton
                animation="wave"
                width="60%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  name: "TodosCircuitos",
  data() {
    return {
      loading: false,
      circuitos: [],
    };
  },
  methods: {
    handleMouseOver(item) {
      this.$set(item, 'isHovered', true);
    },
    handleMouseOut(item) {
      this.$set(item, 'isHovered', false);
    },
    fetchCircutos(id_categoria) {
      this.loading = true;
      api
        .get(`Circuito/categoria/${id_categoria}`)
        .then((resp) => {
          this.circuitos = resp.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchCircutos(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
  },
};
</script>

<style scoped>
.box-circuito {
  text-align: center;
  background-size: contain !important;
  background-position: center;
  border-radius: 10px;
  width: 350px;
  height: 308px;
}

.circuitos {
  margin-top: 100px;
}

.col-circuito {
  margin-bottom: 80px;
}

@media (max-width: 990px) {
  .box-circuito {
    width: 100%;
    background-repeat: no-repeat;
  }

  .circuitos {
    margin-top: 50px;
  }

}
</style>