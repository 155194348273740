<template>
  <section>
    <!-- <Banners /> -->
    <TodasEtapas />
  </section>
</template>

<script>
// import Banners from "@/components/Banners.vue";
import TodasEtapas from "@/components/TodasEtapas.vue";

export default {
  name: "Etapas",
  components: {
    // Banners,
    TodasEtapas,
  },
};
</script>


