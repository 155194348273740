<template>
  <div>
    <div class="etapa-bg">
      <b-container>
        <b-row>
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h1 v-if="$route.params.categoria == 'formula-4'" class="titulo">
              <strong>{{ $t("title.todas-as-etapas1") }}</strong>
              {{ $t("title.todas-as-etapas2") }}
            </h1>
            <h1 v-else class="titulo"><strong>TODAS</strong> AS ETAPAS</h1>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>
        <div class="item-select">
          <label for="">Escolha a temporada</label>
          <b-form-select v-if="$route.params.categoria == 'e-stock'" class="select form-control" v-model="ano_selecionados" :options="anos_optionss"
          @change="changeAno" required></b-form-select>
          <b-form-select v-else class="select form-control" v-model="ano_selecionado" :options="anos_options"
            @change="changeAno" required></b-form-select>
        </div>
      </b-container>

      <b-container class="cards-content">
        <b-row v-if="!loading && etapas.length" class="mt-5">
          <b-col cols="12" lg="3" class="etapas" v-for="item in etapas" :key="item.idEtapa">
            <div class="tabs-etapas" :class="{
              turismo: $route.params.categoria == 'turismo-nacional',
            }">
              <b-tabs content-class="">
                <!-- v-if="$route.params.categoria == 'formula-4'" -->
                <b-tab :title="corrida.descricao" v-for="(corrida, index) in item.corridas" :key="corrida.descricao"
                  :active="index == 0">
                  <div class="box-etapas">
                    <h3 class="gp-title" v-if="
                      $route.params.categoria == 'formula-4' &&
                      item.dataDia == '03' &&
                      item.dataMes == 'NOV'
                    ">
                      GP São Paulo de F1
                    </h3>
                    <img class="circuito" :src="item.imagemCircuito" alt="Imagem do circuito" />
                    <div class="boxInfos">
                      <div class="data">
                        <p class="dia">{{ item.dataDia }}</p>
                        <p class="mes">{{ item.dataMes }}</p>
                        <p class="ano">{{ item.dataAno }}</p>
                      </div>

                      <div class="local">
                        <p class="pista">{{ item.circuito }}</p>
                        <p class="estado">{{ item.cidade }}</p>

                      </div>
                    </div>
                  </div>

                  <div v-if="item.finalizada && $route.params.categoria != 'turismo-nacional'">
                    <div class="podio" v-if="corrida?.podio.length">
                      <div class="item-podio" v-for="posicao in corrida.podio" :key="posicao.posicao">
                        <span class="posicao">{{ posicao.posicao }}º</span>
                        <img :src="posicao.imagem" :alt="posicao.nome" />
                        <p>
                          {{ getLastName(posicao.nome) }}
                          <span>{{ posicao.numero }}#</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="item.finalizada && $route.params.categoria == 'turismo-nacional'">
                    <div class="podio" v-if="corrida?.podioTurismo.length">
                      <div class="item-podio" v-for="(posicao, index) in corrida.podioTurismo" :key="index">
                        <span class="posicao">{{ index + 1 }}º</span>
                        <div v-for="(piloto, pilotoIndex) in posicao.pilotos" :key="pilotoIndex">
                          <p>
                            {{ getLastName(piloto.nome) }}

                            <span>{{ piloto.numero }}#</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="item.proxima && btn_ingresso.show">
                    <router-link v-if="$route.params.categoria == 'formula-4'"
                      :to="`/stockcar/${$route.params.categoria}/circuito/${item.idCircuito}`" class="btn-saibamais">{{
                        $t("title.comprar-ingresso") }}</router-link>
                    <router-link v-else :to="`/stockcar/${$route.params.categoria}/circuito/${item.idCircuito}`"
                      class="btn-saibamais">Comprar ingresso</router-link>
                  </div>



                  <div v-else-if="$route.params.categoria == 'e-stock'">
                  </div>

                  <div v-else>
                    <div v-if="corrida.descricao != 'SUPER RACE'">
                      <router-link v-if="$route.params.categoria == 'formula-4'"
                        :to="`/stockcar/${$route.params.categoria}/circuito/${item.idCircuito}`"
                        class="btn-saibamais">{{ $t("button.saiba_mais") }}</router-link>
                      <router-link v-else :to="`/stockcar/${$route.params.categoria}/circuito/${item.idCircuito}`"
                        class="btn-saibamais">Saiba mais</router-link>
                    </div>
                  </div>



                  <router-link v-if="item.programacoes && !item.finalizada"
                    :to="`/stockcar/${$route.params.categoria}/programacao/${item.idCircuito}`"
                    class="btn-programacao btn-saibamais">Programação</router-link>
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="loading" class="mt-5">
          <b-col cols="12" lg="3" class="etapas">
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>

          <b-col cols="12" lg="3" class="etapas">
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>
          <b-col cols="12" lg="3" class="etapas">
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>
          <b-col cols="12" lg="3" class="etapas">
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton animation="wave" width="60%" class="mt-4 mb-3"></b-skeleton>
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </b-col>
        </b-row>

        <b-row v-if="!etapas.length && !loading" class="m-5 mensagemEtapas">
          <b-col cols="12">
            <p>Nenhuma etapa encontrada</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  name: "TodasEtapas",
  data() {
    return {
      ano_selecionados: 2024,
      anos_optionss:[{value: 2024, text:2024}],
      anos_options: [
        {
          value: "2023",
          text: "2023",
        },
        {
          value: "2024",
          text: "2024",
        },
      ],
      ano_selecionado: new Date().getFullYear().toString(),
      etapas: [],
      loading: false,
      corrida1: "",
      corrida2: "",
      corrida3: "",
    };
  },
  computed: {
    btn_ingresso() {
      return this.$store.state.btn_ingresso ||
        window.sessionStorage.btn_ingresso
        ? JSON.parse(window.sessionStorage.btn_ingresso)
        : {
          show: false,
          link: "",
        };
    },
  },

  methods: {
    changeAno() {
      this.etapas = [];
      this.fetchEtapas(
        mapCategorias[this.$route.params.categoria || "proseries"]
      );
      this.fetchEtapasEstock(
        mapCategorias[this.$route.params.categoria || "proseries"]
      );
    },
    getLastName(nome) {
      let splitNome = nome.split(" ");
      let sobrenome = splitNome.slice(1).join(" ");
      return sobrenome;
    },
    fetchEtapas(categoria_id) {
      if (!this.etapas.length) {
        this.loading = true;
        api
          .get(`Etapa/categoria/${categoria_id}?ano=${this.ano_selecionado}`)
          .then((resp) => {
            this.etapas = resp.data.etapas;
            console.log('>>>>>fetchEtapas>>>>>', this.etapas)
          })
          .catch((error) => {
              console.error('Erro', error);           
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    fetchEtapasEstock() {
      if (!this.etapas.length) {
        this.loading = true;
        api
          .get(`Etapa/categoria2/CategoriaId2?ano=2024`)
          .then((resp) => {
            this.etapas = resp.data.etapas;
            console.log('>>>>fetchEtapasEstock>>>>>>', this.etapas)
          })
          .catch((error) => {
              console.error('Erro', error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    corridaTraducao() {
      if (this.$route.params.categoria == "formula-4") {
        this.corrida1 = this.$t("title.corrida1");
      }
    },
  },
  mounted() {
    this.loading = true;

    if (this.$route.params.categoria == "e-stock"){
      this.fetchEtapasEstock(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
    } else {
      this.fetchEtapas(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
    }


    this.corridaTraducao();
  },
};
</script>

<style scoped>
.item-select {
  margin-top: 50px !important;
}

.mensagemEtapas {
  height: calc(100vh - 400px);
  color: var(--title-color);
}

.etapa-bg {
  background-image: var(--bg-etapas);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  background-blend-mode: overlay;
  background-color: var(--overlay);
  background-size: 100% !important;
  height: auto;
}

.btn-ingresso {
  background-color: var(--bg-color) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  margin-top: 20px;
  width: 250px !important;
}

.btn-ingresso:hover {
  background-color: var(--primary-color) !important;
  color: var(--bg-color) !important;
}

.ano {
  color: var(--title-color);
  font-size: 14px;
  font-weight: 600;
  font-family: Uniwars-W00-Light;
}

.box-etapas .local {
  padding-left: 35px;
  text-align: left;
}

.box-etapas .data {
  text-align: left;
}

.box-etapas .circuito {
  margin: 0 auto;
  display: flex;
  width: 200px;
}

/* podio */

.tabs-etapas.turismo {
  min-height: 700px !important;
}

.turismo .item-podio {
  display: block;
  text-align: center;
}

.podio {
  margin-top: 15px;
}

.item-podio {
  /* background: red; */
  display: grid;
  grid-template-columns: 30px 1fr 4fr;
  align-items: center;
  background: var(--bg-podio-etapa);
  border-radius: 6px;
  padding: 5px 10px;
  font-weight: 600;
  line-height: 24px;
}

.item-podio+.item-podio {
  margin-top: 10px;
}

.item-podio .posicao {
  font-size: 18px;
  font-family: "Uniwars-W00-SemiBold";
  color: #707070;
  text-align: center;
  font-weight: 400;
}

.item-podio img {
  max-width: 40px;
  margin-left: 5px;
}

.item-podio p {
  font-weight: 400;
  color: var(--title-color);
  margin: 0;
  font-size: 14px;
}

.item-podio p span {
  font-weight: normal;
  font-size: 14px;
}

.btn-saibamais {
  font-family: "MetronicPro-Regular";
  font-size: 16px;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--text-color) !important;
  border-radius: 6px;
  padding: 10px 70px;
  text-decoration: none;
  display: block;
  text-align: center;
  margin: 15px auto;
  margin-top: 70px;
}

.btn-saibamais {
  margin-top: 16px;
}

.btn-saibamais::first-letter {
  text-transform: uppercase;
}

.cards-content {
  margin-bottom: 60px;
}

@media (max-width: 1200px) and (min-width: 990px) {
  .item-podio p {
    font-weight: 400;
    color: var(--title-color);
    margin: 0;
    font-size: 11px;
  }

  .data {
    padding-right: 12px;
  }

  .local {
    padding-left: 12px !important;
  }

  .item-podio .posicao {
    font-size: 14px;
    font-family: "Uniwars-W00-SemiBold";
    color: #707070;
    text-align: center;
    font-weight: 400;
  }

  .item-podio p span {
    font-weight: normal;
    font-size: 11px;
  }

  .btn-saibamais {
    padding: 10px 10px;
  }
}

@media (max-width: 989px) {

  .tabs-etapas.turismo {
    min-height: 585px !important;
  }

  .btn-saibamais {
    margin: 15px auto;
    padding: 10px 10px;
    margin-bottom: 0px;
  }

  .item-podio p span {
    font-weight: normal;
    font-size: 16px;
  }
}

.btn-saibamais:hover {
  background-color: var(--title-color) !important;
  border-color: var(--title-color) !important;
}

/*
.tabs-etapas.turismo {
  min-height: auto !important;
  height: 412px !important;
}*/
.gp-title {
  font-family: "Uniwars-W00-Light";
  color: #e10600;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.msgCorrida {
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
  color: var(--primary-color) !important;
}
</style>