import { render, staticRenderFns } from "./Cadastro.vue?vue&type=template&id=62113942&scoped=true&"
import script from "./Cadastro.vue?vue&type=script&lang=js&"
export * from "./Cadastro.vue?vue&type=script&lang=js&"
import style0 from "./Cadastro.vue?vue&type=style&index=0&id=62113942&prod&scoped=true&lang=css&"
import style1 from "vue-tel-input/dist/vue-tel-input.css?vue&type=style&index=1&prod&lang=css&"
import style2 from "./Cadastro.vue?vue&type=style&index=2&id=62113942&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62113942",
  null
  
)

export default component.exports