<template>
    <div>
        <Cadastro />
    </div>
</template>

<script>
import Cadastro from '@/components/Cadastro.vue';
export default {
  name: "CriarConta",
  components: {
    Cadastro
  }
};
</script>