<template>
  <section v-if="usuario.cpf" class="quiz">
    <div>
      <b-container v-if="!pesquisaFinalizada">
        <b-row>
          <b-col cols="12" lg="5" class="text-center col-foto">
            <img :src="currentImage" alt="Imagem número 1" class="img-quiz" />
          </b-col>

          <b-col cols="12" lg="7">
            <img src="@/assets/img/quiz/logo.png" alt="Logo stock quiz" class="img-logoquiz" />
            <h3 class="pergunta-quiz">{{ pergunta }}</h3>

            <div class="box-respostas-quiz">
              <b-button v-for="(resposta, index) in respostas" :key="index" @click.prevent="onSubmit(resposta)"
                  type="button" class="resposta-quiz" :class="{
                  'incorreto': perguntaRespondida && resposta === respostaSelecionada && !pesquisa.respostaCorreta,
                  'correto': perguntaRespondida && resposta === respostaSelecionada && pesquisa.respostaCorreta }"
                  :disabled="perguntaRespondida">
                {{ resposta.descricao }}
              </b-button>
            </div>
          </b-col>

          <b-col cols="12" lg="12" class="barra-progresso">
            <div class="box-barra">
              <p class="info-perguntas">{{ pesquisa.perguntaAtual }} de {{ pesquisa.totalPerguntas }} </p>
              <b-progress :value="barraProgresso" max="100"></b-progress>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container v-else>
        <b-row class="row-resultado">
          <b-col cols="12" lg="6" class="text-center">
            <img :src="imagemAtual" @mouseover="imagemAtual = imagemHover" @mouseout="imagemAtual = imagemMostrar"
              alt="Imagem número 1" class="img-quiz-resultado" />
          </b-col>

          <b-col cols="12" lg="6">
            <h3 class="parabens">PARABÉNS!</h3>
            <p class="txt-resultado" v-if="perguntasCorretas == 0">VOCÊ NÃO ACERTOU NENHUMA PERGUNTA</p>
            <p class="txt-resultado" v-else-if="perguntasCorretas == 1">VOCÊ ACERTOU {{ perguntasCorretas }} PERGUNTA
            </p>
            <p class="txt-resultado" v-else>VOCÊ ACERTOU {{ perguntasCorretas }} PERGUNTAS</p>

            <p class="compartilhar">COMPARTILHAR NA REDE SOCIAL</p>

            <div class="d-flex redes-sociais">
              <a style="cursor: pointer;" @click="baixarImagem">
                <img src="@/assets/img/quiz/baixar.png" alt="Ícone de baixar" />
              </a>
              <a @click="showModal" href="#">
                <img src="@/assets/img/quiz/comp.png" alt="Botão de compartilhar" class="btn-comp"/>
              </a>
            </div>

            <p class="fique-de-olho">Fique de olho:</p>
            <p class="txt-breve">Em breve novas perguntas para testar seu conhecimento!</p>

            <b-modal id="modalquiz" v-model="isModalVisible" title="" @shown="triggerResize">
              <button class="buttonvoltar" @click="showModal">
                <label class="voltarrr"><strong>{{ VOLTAR }}</strong></label>
              </button>
              <carousel :perPage="3" :perPageCustom="[[0, 1], [990, 3]]" :navigation-enabled="true" class="verdesktop"
                ref="carouselDesktop" :paginationEnabled="false">
                <slide class="p-2mobs">
                  <div class="p-2">
                    <div class="card-passo">
                      <div class="bordernum">
                        <strong>1</strong>
                      </div>
                      <h3 style="color: white; font-size: 100%; text-align: center">BAIXE A IMAGEM <br />DE DIVULGAÇÃO
                      </h3>
                      <div style="display: flex; align-items: center; justify-content: center">
                        <img src='@/assets/img/quiz/quiz-img-1.png' alt=""> <br />
                      </div>
                      <button class="buttonBaixar" @click="baixarImagem">
                        <img :src="doawloadImage" alt="" style="height: 25px;">
                        <label for="" style="padding-left: 5%; cursor: pointer;"> <strong>B A I X A R</strong></label>
                      </button>
                    </div>
                  </div>
                </slide>

                <slide class="p-2mobs">
                  <div class="p-2">
                    <div class="card-passo">
                      <div class="bordernum">
                        <strong>2</strong>
                      </div>
                      <h3 style="color: white; font-size: 100%; text-align: center">ABRA O INSTAGRAM <br />PELO CELULAR
                      </h3>
                      <div style="display: flex; align-items: center; justify-content: center">
                        <img src='@/assets/img/quiz/quiz-img-2.png' alt=""> <br />
                      </div>
                    </div>
                  </div>
                </slide>

                <slide class="p-2mobs">
                  <div class="p-2">
                    <div class="card-passo">
                      <div class="bordernum">
                        <strong>3</strong>
                      </div>
                      <h3 style="color: white; font-size: 100%; text-align: center">SELECIONE A FOTO BAIXADA</h3> <br />
                      <div style="display: flex; align-items: center; justify-content: center">
                        <img src='@/assets/img/quiz/quiz-img-3.png' alt=""> <br />
                      </div>
                    </div>
                  </div>
                </slide>

                <slide class="p-2mobs">
                  <div class="p-2">
                    <div class="card-passo">
                      <div class="bordernum">
                        <strong>4</strong>
                      </div>
                      <h3 style="color: white; font-size: 100%; text-align: center">AGORA É SÓ POSTAR!</h3> <br />
                      <div style="display: flex; align-items: center; justify-content: center">
                        <img src='@/assets/img/quiz/quiz-img-1.png' alt=""> <br />
                      </div>
                    </div>
                  </div>
                </slide>
              </carousel>
            </b-modal>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <LogosHome :show_element="show_element.rodape" />
  </section>

  <div v-else>
    <div style="display: flex; justify-content: center; margin-bottom: 3%">
      <FormLogin />
    </div>
  </div>

</template>

<script>
import { api } from "@/services.js";
import LogosHome from "@/components/LogosHome.vue";
import FormLogin from "@/components/FormLogin.vue";

export default {
  name: "StockQuiz",
  data() {
    return {
      perguntaRespondida: false, 
      usuario: '',
      imagemMostrar: '',
      imagemAtual: '',
      imagemHover: '',
      cardBaixar: '',
      mensagem: '',
      idPergunta: '',
      respostaSelecionada: '',
      barraProgresso: 0,
      pesquisaFinalizada: false,
      currentImage: "",
      doawloadImage: require('@/assets/icons/download.png'),
      perguntasCorretas: '',
      VOLTAR: 'X',
      show_element: {
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
      pergunta: '',
      pesquisa: {},
      respostas: [],
      ordemPergunta: 0,
      isModalVisible: false,
    };
  },

  mounted() {
  this.usuario = localStorage.user
    ? JSON.parse(localStorage.user)
    : this.$store.state
      ? this.$store.state.user
      : null;

  this.getPerguntas();
  this.imagemSelecionada();

  if (this.pesquisa && this.pesquisa.perguntaAtual) {
    this.currentImage = this.getCurrentImage();
  }
},
  watch: {
    pesquisaFinalizada(newVal) {
      if (newVal) {
        this.executarAcaoFinalizacao();
      }
    },
    'pesquisa.perguntaAtual'(newQuestion) {
      if (newQuestion) {
        this.currentImage = this.getCurrentImage();
      }
    }
  },
  methods: {
    getCurrentImage() {
      if (this.pesquisa && this.pesquisa.perguntaAtual) {
        return require(`@/assets/img/quiz/img${this.pesquisa.perguntaAtual}.png`);
      }
    },

    getHoverImage() {
      if (this.pesquisa && this.pesquisa.perguntaAtual) {
        return require(`@/assets/img/quiz/sticker${this.pesquisa.perguntaAtual}.png`);
      }
    },

    imagemSelecionada() {
      if (this.perguntasCorretas < 4) {
        this.cardBaixar = require('@/assets/img/quiz/compartilhar-1.png');
        this.imagemMostrar = require('@/assets/img/quiz/frente-1.png');
        this.imagemHover = require('@/assets/img/quiz/tras-1.png');
      } else if (this.perguntasCorretas > 4 && this.perguntasCorretas < 8) {
        this.cardBaixar = require('@/assets/img/quiz/compartilhar-3.png');
        this.imagemMostrar = require('@/assets/img/quiz/frente-3.png');
        this.imagemHover = require('@/assets/img/quiz/tras-3.png');
      } else {
        this.cardBaixar = require('@/assets/img/quiz/compartilhar-2.png');
        this.imagemMostrar = require('@/assets/img/quiz/frente-2.png');
        this.imagemHover = require('@/assets/img/quiz/tras-2.png');
      }
      this.imagemAtual = this.imagemMostrar;
    },

    baixarImagem() {
      const link = document.createElement('a');
      link.href = this.cardBaixar;
      link.download = 'Stockcar.png';
      link.click();
    },

    getPerguntas() {
      api
        .get(`Quiz`)
        .then((resp) => {
          this.pesquisa = resp.data;
          this.perguntasCorretas = resp.data.respostasCorretas;
          this.mensagem = resp.data.faseEncerrada;

          this.barraProgresso = (this.pesquisa.perguntaAtual / this.pesquisa.totalPerguntas) * 100;

          if (this.mensagem) {
            this.pesquisaFinalizada = true;
          } else {
            this.pergunta = resp.data.pergunta.descricao;
            this.respostas = resp.data.pergunta.respostas;
          }

          this.currentImage = this.getCurrentImage();

          setTimeout(() => {
            this.currentImage = this.getHoverImage();
          }, 800);

        })
        .catch((error) => console.log(error));
    },

    triggerResize() {
      window.dispatchEvent(new Event('resize'));
    },

    showModal() {
      this.isModalVisible = !this.isModalVisible;
    },

    onSubmit(resposta) {
      this.loading = true;

      let data = {
        idPesquisa: this.pesquisa.idPesquisa,
        idPesquisaPergunta: resposta.idPesquisaPergunta,
        idRespostas: [resposta.idPesquisaPerguntaResposta],
      };

      api
        .post(`Quiz`, data)
        .then((resp) => {
          this.pesquisa = resp.data;
          console.log("pesquisa", this.pesquisa)
          this.perguntasCorretas = resp.data.respostasCorretas;

          this.respostaSelecionada = resposta; 
          this.perguntaRespondida = true; 

          if (this.pesquisa.pergunta) {
            
            setTimeout(() => {   
                this.pergunta = resp.data.pergunta.descricao;
                this.respostas = resp.data.pergunta.respostas;
                this.barraProgresso = (this.pesquisa.perguntaAtual / this.pesquisa.totalPerguntas) * 100;
                this.perguntaRespondida = false; 
            }, 1000); 

          } else {
            this.pesquisaFinalizada = true;
          }

          if (this.pesquisa.faseEncerrada) {
            this.pesquisaFinalizada = true;
          }

          setTimeout(() => {
            this.currentImage = this.getHoverImage();
          }, 800);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    LogosHome,
    FormLogin
  },
};
</script>


<style scoped>

.bordernum {
  display: flex;
  border-radius: 50%;
  width: 37px;
  height: 37px;
  background-color: #52575A;
  border: 2px solid #BD9234;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.card-passo h3{
  font-size: 20px !important;
  margin-bottom: 20px;
}

.buttonBaixar {
    border: 2px solid #EABB4E;
    background-color: #B48544;
    border-radius: 44px;
    width: 250px;
    display: block;
    margin: auto;
    padding: 8px 0px;
    color: white;
    font-size: 20px;
    margin-top: 5%;
}

.modalquiz {
  width: 100px;
}

.quiz {
  background-image: url('@/assets/img/quiz/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.img-quiz {
  margin-top: 100px;
  width: 100%;
}

.img-quiz-resultado {
  margin-top: 40px;
}

.verdesktop {
  padding-top: 3%;
}

.p-2 {
  display: flex;
  flex-direction: row;
  background-color: #313131;
  margin-left: 5%;
  justify-content: center;
  border: 2px solid #BD9234;
  border-radius: 5%;
  height: 470px;
}

.p-2mobs{
  display: block;
}

.card-passo {
  height: 100%;
}

.img-logoquiz {
  margin-top: 50px;
  width: 190px;
}

.pergunta-quiz {
  color: #EABB4E;
  font-size: 20px;
  letter-spacing: 2.4px;
  font-family: "MetronicPro-Light";
  width: 550px;
  margin-top: 25px;
  margin-bottom: 40px;
}

.resposta-quiz {
  background-color: transparent;
  border-radius: 7px;
  border: 2px solid #F7B04D;
  color: white;
  font-size: 20px;
  letter-spacing: 2.3px;
  width: 350px;
  margin-bottom: 10px;
}

.resposta-quiz:hover {
  background-color: #F7B04D;
}

.box-respostas-quiz {
  display: grid;
}

.btn-comp {
  width: 300px;
}

.barra-progresso .progress-bar {
  background-color: #EABB4E !important;
  border-radius: 40px !important;
}

.barra-progresso .progress {
  background-color: #707070 !important;
  border-radius: 40px !important;
  height: 20px !important;
}

.box-barra {
  width: 400px;
  margin-top: 60px !important;
  margin: auto;
}

.parabens {
  color: #FFFFFF;
  font-size: 51px;
  font-family: "Uniwars-W00-SemiBold";
  margin-top: 100px;
}

.txt-resultado {
  letter-spacing: 4.2px;
  color: #FFFFFF;
  font-size: 21px;
  font-family: "MetronicPro-Bold";
  margin-bottom: 70px;
}

.buttonvoltar {
  display: flex;
  background-color: #00000000;
  border-bottom: none;
  color: white;
  flex-direction: row-reverse;
  border: none;

}

.voltarrr {
  align-self: end !important;
  justify-items: end;
  cursor: pointer;
}

.compartilhar {
  color: #c9a34a;
  font-size: 24px;
  letter-spacing: 0px;
  font-family: "MetronicPro-Bold";
}

.row-resultado {
  align-items: center;
}

.redes-sociais {
  justify-content: start;
  gap: 20px;
  margin-bottom: 70px;
}

.info-perguntas {
    font-family: "MetronicPro-Light";
    color: white;
    text-align: center;
    margin-bottom: 5px;
}

.fique-de-olho {
    color: #EABB4E;
    margin-bottom: 0px;
    font-size: 18px;
    font-family: "MetronicPro-Bold";
    text-transform: uppercase;
}

.txt-breve {
  color: #FFFFFF;
  font-size: 18px;
  font-family: "MetronicPro-Light";
}

.correto {
    background-color: #00a100 !important;
    border-color: #00a100 !important;
}

.incorreto {
    border-color: #cb0000 !important;
    background-color: #cb0000 !important;
}

@media (max-width: 990px) {

  .btn-comp {
    width: 100%;
  }

  .txt-resultado {
    margin-bottom: 20px;
  }

  .p-2mobs {
    display: none;
    display: flex;
    flex-direction: row;
    background-color: rgb(70, 70, 78);
    border: 2px solid #BD9234;
    border-radius: 5%;
    width: 75px;
    margin-left: 0%;
  }

  .p-2{
    width: 100%;
    border: none;
  }

  .pergunta-quiz {
    width: 100%;
    text-align: center;
  }

  .img-logoquiz {
    margin: auto;
    display: block;
    margin-top: 20px !important;
  }

  .redes-sociais {
    gap: 5px;
    margin-bottom: 20px !important;
  }

  .box-barra {
    width: 100%;
    margin-top: 20px !important;
  }

  .col-foto {
    order: 1;
  }

  .parabens {
    font-size: 40px;
    margin-top: 20px !important;
  }

  .img-quiz {
    margin-top: 20px;
  }

  .quiz {
    background-size: initial;
  }
}
</style>