<template>
  <div>
    <b-container>
      <b-row
        class="justify-content-center"
        v-show="!loading"
        data-anima="bottom"
      >
        <div v-if="msg" class="msg" data-anima="top">
          <b-alert show variant="warning" class="alert">
            ⚠️ Você já selecionou o máximo de pilotos permitidos.
          </b-alert>
        </div>
        <b-col
          class="align-self-center"
          cols="6"
          lg="2"
          v-for="item in pilotos"
          :key="item.idPiloto"
        >
          <div
            @click.prevent="selected(`item-piloto-${item.idPiloto}`, item)"
            :id="`item-piloto-${item.idPiloto}`"
            class="item-piloto"
          >
            <div class="info-piloto">
              <span>#{{ item.numero }}</span>
              <span>{{ item.nome }}</span>
            </div>
            <b-form-radio
              class="checkbox-piloto"
              :id="`checkbox-${item.idPiloto}`"
              name="pilotos"
              :value="item"
            >
              <img class="card-piloto" :src="item.imagemB" :alt="item.nome" />
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <div v-if="loading" class="loading">
        <b-spinner
          style="
            width: 3rem;
            height: 3rem;
            border-color: #e6c156;
            border-right-color: transparent;
            --bs-spinner-animation-speed: 0.5s;
          "
          label="Large Spinner"
          variant="warning"
        ></b-spinner>
      </div>
    </b-container>
  </div>
</template>

<script>
import { api } from "@/services.js";

export default {
  name: "CardsPilotos",

  data() {
    return {
      loading: false,
      pilotos: [],
      votacaoPiloto: [],
      msg: false,
    };
  },
  props: {
    votacao_fanpush: {
      type: Boolean,
      default: false,
    },
    pilotos_fanpush: {
      type: Array,
      default: () => [],
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    selected(item, piloto) {
      if (!this.preview) {
        this.msg = false;
        if (this.votacao_fanpush) {
          const maxSelections = 3;
          const includes = this.votacaoPiloto.findIndex(
            (item) => item.idPiloto === piloto.idPiloto
          );

          if (includes >= 0) {
            this.votacaoPiloto.splice(includes, 1);
            document.querySelector(`#${item}`).classList.remove("selected");
          } else {
            if (this.votacaoPiloto.length < maxSelections) {
              this.votacaoPiloto.push(piloto);
              document.querySelector(`#${item}`).classList.add("selected");
            } else {
              this.msg = true;
              window.scrollTo({ top: 0, behavior: "smooth" });
            }
          }

          //console.log(this.votacaoPiloto);
          this.$emit("updateVotacaoPiloto", this.votacaoPiloto);
        } else {
          document
            .querySelectorAll(".item-piloto")
            .forEach((item) => item.classList.remove("selected"));

          document.querySelector(`#${item}`).classList.add("selected");

          setTimeout(() => {
            this.$emit("selectedPiloto", piloto);
          }, 100);
        }
      }
    },
    fetchPilotos() {
      if (this.votacao_fanpush) {
        this.pilotos = this.pilotos_fanpush;
      } else {
        this.loading = true;
        api
          .get(`Piloto/categoria/1`)
          .then((resp) => {
            this.pilotos = resp.data.pilotos;
          })
          .catch((error) => console.log(error))
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            });
          });
      }
    },
  },
  mounted() {
    this.fetchPilotos();
  },
};
</script>

<style scoped>
input {
  display: none;
}

.card-piloto {
  width: 100%;
  position: relative;
  top: -30px;
  z-index: 999;
  border: 3px solid #0e080d00;
  cursor: pointer;
  left: -10px;
}

.card-piloto:hover,
.selected .card-piloto {
  border: 3px solid #542283;
  border-radius: 10px;
  opacity: 1;
}

.piloto-favorito h3 {
  color: #eabb4d;
  font-family: "MetronicPro-SemiBold";
  font-style: italic;
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 80px;
}
.info-piloto {
  position: relative;
  max-width: 35%;
  left: 10px;
  top: 160px;
  line-height: 20px;
  z-index: 9999999;
  text-transform: capitalize !important;
}
.info-piloto span {
  display: block;
  color: #fff;
  font-weight: 600;
}
.info-piloto span:first-child {
  color: #eabb4d;
  font-family: "Uniwars-W00-Heavy-Italick";
  font-size: 24px;
  text-shadow: #00000079 2px 4px;
}

.msg {
  color: #ff1717;
  text-align: center;
}

.alert {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 50px;
}

@media (max-width: 989px) {
  .icBandeira {
    margin-top: -60px;
  }

  .info-piloto {
    top: 140px;
  }

  .alert {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .card-piloto {
    left: -20px;
  }
}
.loading {
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
