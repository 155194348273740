<template>
  <div>
    <div>
      <b-container class="bg-fanpush">
        <b-row class="row-fanpush">
          <b-col cols="12" lg="4" class="boxSnapdragon">
            <img
              src="@/assets/img/snapdragon_vivo.png"
              class="selo-vivo"
              alt="Imagem de selo do fanpush"
              width="300"
            />
            <h3 class="textCenter">
              Vivo Fan Push by Snapdragon. <br />Votação que premia pilotos com
              um Push adicional.
            </h3>
            <p class="textCenter">
              Agora, seu voto para o VIVO Fan Push by Snapdragon será realizado através do Paddock Fan, aplicativo oficial para os fãs da Stock Car.
            </p>
            <p class="textCenter">
              Tenha acesso a conteúdos exclusivos, resgate de ingressos, informações sobre os seus pilotos favoritos e sinta-se parte de cada ultrapassagem – tudo isso na palma da sua mão.
            </p>
            <p>Baixe agora e conheça o Paddock Fan!</p>
            <div class="espaco"></div>

            <div
              :class="vencedores.votacaoAberta ? `btnFanpush` : `btnFanpush`"
            >
              <a href="http://paddockfan.com.br/" target="_blank"

                name="vote"
                class="btn-fanpush"
                :aria-disabled="vencedores.votacaoAberta"
                role="button"
                >PARTICIPE, VOTE!</a
              >
            </div>
          </b-col>

          <b-col cols="12" lg="8" class="info-fanpush">
            <div class="beneficios">
              <img
                src="@/assets/img/100cvs.png"
                alt="100 cvs de potência extra"
              />
              <img
                src="@/assets/img/abertura.png"
                alt="abertura total da borboleta"
              />
              <img src="@/assets/img/pura.png" alt="pura adrenalina" />
            </div>

            <b-row
              class="vencedores-fanpush"
              v-if="!loading && vencedores.ultimosVencedores.length"
            >
              <h3 class="textCenter">
                <strong>Vencedores</strong> da última rodada
              </h3>

              <b-col
                id="vencedor"
                cols="12"
                lg="2"
                v-for="item in vencedores.ultimosVencedores"
                :key="item.idPiloto"
              >
              <div class="imagem-vencedor">
                <img :src="item.imagemC" alt="imagem do piloto" />
              </div>
              <div class="informacao-vencedor">  
                <h4 class="numPiloto textCenter">#{{ item.numero }}</h4>
                <p class="textCenter">
                  {{ primeiroNome(item.nome) }} <br /><strong
                    class="fontStrong"
                    >{{ ultimoNome(item.nome) }}</strong
                  >
                </p>
              </div>  
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "Fanpush",
  data() {
    return {
      loading: false,
      vencedores: {
        votacaoAberta: false,
        ultimosVencedores: [],
      },
    };
  },

  methods: {
    primeiroNome(nome) {
      return nome.replace(/ .*/, "");
    },
    ultimoNome(nome) {
      let splitNome = nome.split(" ");
      let sobrenome = splitNome.slice(1).join(" ");
      return sobrenome;
    },
    fetchFanPushVencedores() {
      this.loading = true;
      api
        .get("Fanpush/ultimos-vencedores")
        .then((resp) => {
          this.vencedores = resp.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchFanPushVencedores();
  },
};
</script>

<style scoped>
.boxSnapdragon {
  background-color: rgb(84 34 131 / 50%);
  text-align: center;
  padding: 50px 40px 30px 40px !important;
}

.vencedores-fanpush p {
  color: var(--title-color) !important;
}

.numPiloto {
  margin-bottom: 0px;
  color: #707070;
  font-size: 22px;
  font-family: "Uniwars-W00-Black";
}

.container-fanpush {
  margin-bottom: 60px;
}

.beneficios {
  display: grid;
  padding-top: 150px;
}
.info-fanpush h3 {
  margin-top: 50px;
  font-family: "Uniwars-W00-Light";
  font-size: 16px;
  color: var(--title-color);
  margin-bottom: 20px;
}

.info-fanpush h3 strong {
  font-family: "Uniwars-W00-SemiBold";
}

.boxSnapdragon h3 {
  font-family: "MetronicPro-Bold";
  text-align: left;
  font-size: 26px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: var(--title-color);
}

.boxSnapdragon p {
  font-family: "MetronicPro-Regular";
  text-align: left;
  font-size: 16px;
  margin-bottom: 30px;
  color: var(--title-color);
}

.espaco {
  margin-top: 50px;
}

.btn-fanpush {
  font-family: "MetronicPro-Regular";
  font-size: 17px;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--text-color) !important;
  border-radius: 6px;
  padding: 10px 70px;
  text-decoration: none;
}

.btn-fanpush:hover {
  background-color: var(--title-color) !important;
  border-color: var(--title-color) !important;
}

.desabilitado a {
  pointer-events: none;
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  font-family: "MetronicPro-Regular";
  font-size: 17px;
  border-radius: 6px;
  padding: 10px 70px;
  background-color: black;
  color: white;
}

.vencedores-fanpush {
  position: relative;
  top: -30px;
}

.selo-vivo {
  width: 300px;
}

@media (max-width: 989px) {
  .boxSnapdragon {
    padding: 20px 20px 40px 20px !important;
  }

  .boxSnapdragon h3 {
    font-size: 20px;
  }

  .beneficios {
    padding-top: 50px;
  }

  .vencedores-fanpush {
    top: 0px;
  }
}

@media (max-width: 990px) {
  #vencedor {
    display: flex !important;
    flex-direction: row; 
    text-align: left;    
  }

  .imagem-vencedor {
    margin-right: 50px; 
    flex: 0 0 auto; 
  }

  .informacao-vencedor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 343.1px;
  width: 150px;
  top: 0;
  }

  .informacao-vencedor > * {
    margin-bottom: 5px; 
    margin-top: auto;
  }

  .informacao-vencedor h4 {
    margin-bottom: 10px; 
  }
  
  .informacao-vencedor p {
    margin-top: 0; 
  }
  
}

</style>