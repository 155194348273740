<template>
    <div>
        <EsqueciMinhaSenha />
    </div>
</template>


<script>
import EsqueciMinhaSenha from '@/components/EsqueciMinhaSenha.vue';

export default {
  components: {
    EsqueciMinhaSenha,
  }
};
</script>
