<template>
    <div class="bg-confirmacao">
        <b-container>
          <b-row>
              <b-col>
                 <div class="msg-confirmacao">
                    <h3>FERNANDA, SUA CONTA STOCK CLUB FOI CRIADA COM SUCESSO!</h3>
                    <p>Acabamos de te enviar um e-mail. <br/>Clique no botão ATIVAR e ative sua conta.<br/>
                    Caso não localize o e-mail, olhe também a pasta Spam ou Lixo Eletrônico.</p>

                    <b-button type="submit" class="acessar btn">REENVIAR TOKEN DE ATIVAÇÃO</b-button>

                    <span>O token será enviado por e-mail.</span>
                 </div>
              </b-col>
          </b-row>
        </b-container>
    </div>
</template>


<script>
export default {
  name: "AtivacaoConta",
};
</script>

<style scoped>
    .msg-confirmacao h3 {
        font-family: 'Uniwars-W00-Heavy-Italick';
        color: var(--primary-color);
        font-size: 25px;
        text-align: center;
        width: 600px;
        margin-top: 150px;
    }

    .msg-confirmacao {
        display: grid;
        justify-content: flex-end;
    }

    .msg-confirmacao p {
        text-align: center;
        line-height: 30px;
        color:var(--title-color);
        font-family: 'MetronicPro-Light';
        margin: 20px auto 0;
        font-size: 20px;
        width: 500px;
    }

     .btn {
        padding: 10px;
        font-size: 20px;
        font-family: 'Uniwars-W00-Heavy-Italick';
        letter-spacing: 0;
        display: block;
        text-align: center;
        width: 300px;
        margin: 0 auto;
        margin-top: 40px !important;
    }

    .btn:active {
        background-color: var(--primary-color);
        border-color: var(--primary-color) !important;
        color: var(--text-color);
    }

    .acessar {
        color: var(--text-color);
        border-color: var(--primary-color);
        background-color: var(--primary-color);
    }

    .msg-confirmacao span {
        color: #939393!important;
        font-size: 13px;
        letter-spacing: 0.4px;
        text-align: center;
        margin-top: 40px;
    }

    @media (min-width: 1200px) and (max-width: 1399px) {
        .msg-confirmacao p {
            width: 400px;
        }
        
        .msg-confirmacao h3 {
            width: 500px;
            margin-top: 60px;
        }
    }

    @media (min-width: 990px) and (max-width: 1199px) {
        .msg-confirmacao p {
            width: 300px;
            line-height: 24px;
            font-size: 16px;
        }
        
        .msg-confirmacao h3 {
            width: 400px;
            margin-top: 60px;
            font-size: 20px;
        }
    }

    @media (max-width: 989px) {
        .bg-confirmacao {
            height: auto;
            background: none;
        }

        .msg-confirmacao h3 {
            width: 100%;
            font-size: 20px;
            margin-top: 50px;
        }

        .msg-confirmacao p {
            line-height: 25px;
            font-size: 17px;
            width: 100%;
        }
    }
</style>
