<template>
  <div class="bg">
    <BannerStockClub :fanpush="fanpush" />

    <VotacaoFanpush :fanpush="fanpush" />

    <DestaquesBeneficios />

    <Bastidores />

    <PesquisaUsuario />
  </div>
</template>

<script>
import { api } from "@/services.js";

import BannerStockClub from "@/components/BannerStockClub.vue";
import VotacaoFanpush from "@/components/VotacaoFanpush.vue";
import Bastidores from "@/components/Bastidores.vue";
import PesquisaUsuario from "@/components/PesquisaUsuario.vue";
import DestaquesBeneficios from "@/components/DestaquesBeneficios.vue";
import { EventBus } from "@/main.js";

export default {
  components: {
    BannerStockClub,
    VotacaoFanpush,
    Bastidores,
    PesquisaUsuario,
    DestaquesBeneficios
  },
  data() {
    return {
      fanpush: null,
    };
  },
  methods: {
    fetchFanPush() {
      this.loading = true;
      api
        .get(`/Fanpush`)
        .then((resp) => {
          this.fanpush = resp.data.fanpush;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchFanPush();

    if (this.$route.query.live) {
      EventBus.$emit("liveLogado" , true);
    }
  },
};
</script>
<style scoped>
.bg {
  background: #000;
}
</style>
