<template>
  <section class="esg-page">
    <div class="main-galeao margin-row">
      <div style="z-index: 10; position: relative">
        <b-row
          class="row-cards galeao-content align-items-center"
          data-anima="top"
        >
          <b-col md="4" sm="0" class="col-card">
            <div class="box-title">
              <h4>
                Quer acelerar e fazer parte do time que produz os maiores
                eventos de automobilismo da América Latina?
              </h4>
            </div>
          </b-col>
          <b-col class="oq-esg primeiro-item">
            <p>
              A Vicar é uma empresa promotora de eventos de automobilismo
              esportivo pertencente à Veloci Investimentos, que é controlada
              pelo Fundo United Partners. Atualmente, em seu portfólio estão as
              categorias Stock Car Pro Series, Stock Series, Formula 4 Brasil e
              Turismo Nacional. Desde os anos 1990, a Vicar realiza os maiores
              eventos do esporte a motor da América Latina e elevou a Stock Car
              Pro Series a um patamar de excelência internacional, sendo
              apontada como uma das cinco principais competições do tipo Turismo
              em todo o mundo.
            </p>
          </b-col>
        </b-row>
        <div class="btn-item">
          <router-link
            :to="`/stockcar/${
              $route.params.categoria || 'proseries'
            }/trabalhe-conosco/cadastro`"
            class="btn-acao"
            >Envie seu currículo</router-link
          >
        </div>
      </div>

      <div class="overlay-galeao"></div>
    </div>

    <b-container class="bv-row valores-container" data-anima="bottom">
      <div>
        <div class="valor-item">
          <h4>Missão</h4>
          <p>Gerar negócios usando o motorsports como "meio".</p>
        </div>
        <div class="valor-item">
          <h4>Visão</h4>
          <p>
            Levantar entretenimento esportivo para os fãs do automobilismo,
            fomentando e protegendo o ecossistema de negócios de funcionários,
            equipes, pilotos, patrocinadores, fornecedores e acionistas.
          </p>
        </div>

        <div class="valor-item">
          <h4>Valores</h4>
          <div class="flex-valor">
            <img src="@/assets/img/icon-respeito.png" alt="icon-respeito" />
            <p>
              <strong>Respeito, Desenvolvimento e Reconhecimento:</strong> nós
              colocamos as pessoas em primeiro lugar. Elas são a força e a
              vitalidade do nosso negócio;
            </p>
          </div>
          <div class="flex-valor">
            <img src="@/assets/img/icon-etica.png" alt="icon-etica" />

            <p>
              <strong>Ética:</strong> nossas ações e relações são baseadas na
              verdade, integridade, honestidade, isonomia, transparência,
              justiça e bem comum;
            </p>
          </div>

          <div class="flex-valor">
            <img src="@/assets/img/icon-inovacao.png" alt="icon-inovacao" />

            <p>
              <strong>Inovação e Ousadia:</strong> cultivamos o profissionalismo
              na busca de fazer diferente, por meio de iniciativas inovadoras e
              ousadas.
            </p>
          </div>
        </div>
      </div>
      <h3>
        O QUE<br />
        VALORIZAMOS
      </h3>
    </b-container>

    <b-container class="bv-row areas" data-anima="bottom">
      <b-row>
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h2 class="titulo">
            <strong><i>Áreas de atuação na Vicar</i></strong>
          </h2>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>
      <div class="areas-atuacao">
        <div v-for="(dep, index) in departamentos" :key="dep.idArea">
          <div v-if="index <= ver_mais_qtd" class="area-item">
            {{ dep.nome }}
          </div>
        </div>
      </div>
      <div class="ver-mais" v-if="departamentos.length > 10">
        <a href="#" @click.prevent="verMais"
          >VER {{ ver_mais_qtd == 10 ? "MAIS" : "MENOS" }}</a
        >
      </div>
      <div class="video-item" @click="openVideo">
        <iframe
          width="100%"
          height="750"
          class="video"
          src="https://www.youtube.com/embed/4c603CwmMGs?si=nVEFdvgmCtM_Ce1E"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </b-container>

    <b-container
      class="bv-row areas"
      data-anima="bottom"
      v-if="depoimentos_itens.length"
    >
      <b-row>
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h2 class="titulo">
            <strong><i>Depoimentos</i></strong>
          </h2>
          <hr class="linha-titulo" />
        </b-col>

        <div class="depoimentos">
          <div
            class="depoimento-item"
            v-for="item in depoimentos_itens"
            :key="item.nome"
          >
            <div class="depoimento-top">
              <img :src="item.imagem" :alt="item.nome" />
              <div class="dados">
                <h6>{{ item.nome }}</h6>
                <span>{{ item.cargo }}</span>
              </div>
            </div>

            <div class="text-depoimento" v-html="item.depoimento"></div>
          </div>
        </div>
      </b-row>
    </b-container>

    <b-container class="bv-row areas" data-anima="bottom">
      <b-row>
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h2 class="titulo">
            <strong><i>FAÇA PARTE DO NOSSO BANCO DE TALENTOS</i></strong>
          </h2>
          <hr class="linha-titulo" />
        </b-col>
        <div class="btn-item">
          <router-link
            :to="`/stockcar/${
              $route.params.categoria || 'proseries'
            }/trabalhe-conosco/cadastro`"
            class="btn-acao"
            >Cadastre-se</router-link
          >
        </div>
      </b-row>
    </b-container>

    <LogosHome :show_element="show_element.rodape" />
  </section>
</template>
  
<script>
import LogosHome from "@/components/LogosHome.vue";
import { api } from "@/services";

export default {
  name: "TrabalheConosco",
  data() {
    return {
      ver_mais_qtd: 10,
      departamentos: [],
      depoimentos_itens: [],

      show_element: {
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
      bg_atual: 1,
    };
  },
  components: {
    LogosHome,
  },

  methods: {
    getImg() {
      switch (this.bg_atual) {
        case 1: {
          this.bg_atual = 2;
          break;
        }
        case 2: {
          this.bg_atual = 3;
          break;
        }
        case 3: {
          this.bg_atual = 1;
          break;
        }
        default: {
          this.bg_atual = 1;
          break;
        }
      }
      // return require(`@/assets/img/bg-trabalhe-conosco-${this.bg_atual}.png`);
      return this.bg_atual;
    },
    carroselFake() {
      const bg = document.querySelector(".overlay-galeao");

      setInterval(() => {
        // bg.style.backgroundImage = `url(${this.getImg()})`;
        bg.classList.remove("bg-1", "bg-2", "bg-3");
        bg.classList.add(`bg-${this.getImg()}`);
      }, 5000);
    },
    openVideo() {
      open("https://www.youtube.com/@stockcarchannel");
    },
    verMais() {
      this.ver_mais_qtd = this.ver_mais_qtd == 10 ? 18 : 10;
    },
    getAreasInteresses() {
      api.get("RH/Area").then((resp) => {
        this.departamentos = resp.data;
      });
    },
    getDepoimentos() {
      api.get("RH/Depoimento").then((resp) => {
        this.depoimentos_itens = resp.data;
      });
    },
  },

  mounted() {
    this.getAreasInteresses();
    this.getDepoimentos();
    this.carroselFake();
  },
};
</script>

<style scoped>
.overlay-galeao {
  width: 100%;
  height: 950px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../assets/img/bg-trabalhe-conosco-1.png");
  background-size: cover;
  background-position: center;
  z-index: 0;
  background-blend-mode: overlay;
  background-color: rgb(0 0 0 /60%);
}
.overlay-galeao.bg-1 {
  background-image: url("../assets/img/bg-trabalhe-conosco-1.png");
}
.overlay-galeao.bg-2 {
  background-image: url("../assets/img/bg-trabalhe-conosco-2.png");
}
.overlay-galeao.bg-3 {
  background-image: url("../assets/img/bg-trabalhe-conosco-3.png");
}

.main-galeao {
  height: calc(100vh - 135px);
}

.galeao-content {
  padding-top: 350px;
}

.imgs-rodape {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
}

.imgs-rodape img {
  width: auto;
  height: 41px;
}

.titulo-centralizado {
  padding-top: 20px;
}

.hist-content {
  /* height: 420px; */
}

.box-texto {
  padding: 80px !important;
  background-color: var(--primary-color);
}

.box-texto h2 {
  margin-bottom: 20px;
  font-family: "MetronicPro-Bold";
  font-size: 35px;
  line-height: 36px;
  color: var(--text-color);
}

.button-link {
  border: 1px solid #0e080d;
  text-decoration: none;
  border-radius: 10px;
  padding: 12px 100px 16px 100px;
  float: left;
  cursor: pointer;

  font-family: "MetronicPro-Semibold";
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
  margin-top: 20px;
}

.button-link::first-letter {
  text-transform: uppercase;
}

.depoimento {
  background: #e6c156 0% 0% no-repeat padding-box;
  opacity: 0.9;
  max-width: 460px;
  width: 100%;
  max-height: 480px;
  height: 100%;
}

.depoimento-space {
  display: flex;
  align-content: center;
  flex-wrap: inherit;
}

.depoimento-img-piloto {
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.img-depoimento-space {
  width: 50%;
  height: 100%;
  background-color: red;
  position: absolute;
  top: -50px;
  left: 22px;
}

.depoimento-titulo {
  color: #0e080d;
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  width: 40%;
  height: 90%;
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
}
.depoimento-titulo strong {
  color: #0e080d;
  font-family: "MetronicPro-Bold";
  font-size: 20px;
}

.depoimento-desc {
  height: 50%;
  padding-left: 32px;
  padding-right: 32px;
  text-align: left;
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  color: #0e080d;
}

.circuito {
  min-width: 100px;
  min-height: 100px;
}

.titulo-noticia {
  width: 360px;
  font-family: MetronicPro-SemiBold;
  font-size: 1.3rem;
  color: var(--title-color);
  margin-bottom: 0px;
  margin-top: 20px;
  line-height: 28px;
  text-align: left;
}

.title-num-galeao {
  color: var(--primary-color);
}

.desc-estrutura {
  background: transparent linear-gradient(90deg, #2b262a 0%, #0e080d00 92%) 0%
    0% no-repeat padding-box;
  color: #ffffff;
  margin-top: 40px;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.title-estrutura {
  font-family: MetronicPro-Bold;
  letter-spacing: 0px;
  color: #707070;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.p-estrutura {
  font-family: MetronicPro-Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
}

.line-estrutura {
  border: 1px solid #707070;
  margin-bottom: 12px;
}

.img-estrutura {
  width: 100%;
}

.linha-branca {
  border: 1px solid #ffffff;
  margin-bottom: 40px;
  margin-top: 40px;
}

.title-main-galeao {
  color: #ffffff;
  font-family: MetronicPro-Bold;
  letter-spacing: 0px;
  font-size: 28px;
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 80px;
}

.responsive-mobile {
  display: none;
}

.container-novidades .boxes-noticias .coluna-noticia {
  border-right: none;
  border-left: none;
}

.img-noticia {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.play-icon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.play-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 35px solid rgba(255, 255, 255, 0.9); /* Cor e transparência do ícone */
}

.boxes-noticias {
  padding-top: 30px;
  padding-bottom: 30px;
}

.boxes-noticias p {
  font: normal normal 300 17px/25px MetronicPro-Light;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 30px;
}

.boxes-noticias h2 {
  font: normal normal bold 22px/26px MetronicPro-Bold;
  letter-spacing: 0px;
  margin: 17px 0;
  color: var(--primary-color);
}

.boxes-noticias a,
.boxes-noticias a:hover {
  color: var(--primary-color);
}

.boxes-noticias img {
  margin-bottom: 10px;
  border: 1px solid var(--primary-color);
}

.boxes-noticias .coluna-noticia {
  padding-right: 20px;
  border-right: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  padding-left: 20px;
}

.modal-body {
  display: flex !important;
  justify-content: center !important;
}

.col-card {
  margin-bottom: 0px !important;
}

.circuito-mobile {
  display: flex;
  justify-content: center;
}

@media (max-width: 991px) {
  .linha-primary {
    border: 1px solid var(--primary-color);
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .hist-content {
    height: auto;
  }

  .button-link {
    padding: 12px 16px;
  }

  .historia-bg {
    background-position: center;
  }

  .button-link {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .responsive-manual {
    display: none;
  }

  .responsive-mobile {
    display: initial;
  }

  .imgs-rodape img {
    width: 120px;
  }

  .logo-mobile {
    position: absolute;
    left: 50%;
    max-width: 264px;
    transform: translate(-50%, -50%);
    bottom: -248px;
  }

  .img-hist {
    height: 244px;
  }
}

@media (max-height: 660px) {
  .main-galeao {
    height: 78vh;
  }
}

.box-title {
  background: var(--primary-color);
  padding: 60px 30px;
}
.box-title h4::before {
  content: "";
  width: 130px;
  height: 4px;
  background: #000;
  display: block;
  margin-bottom: 25px;
}
.box-title h4 {
  font-family: MetronicPro-Regular;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: #000;
}
.oq-esg.primeiro-item {
  /* background: red; */
  margin-left: 40px;
  max-width: 50%;
}
.oq-esg {
  max-width: 90%;
}
.oq-esg p {
  color: #ffffff;
  font-size: 22px;
  line-height: 1.5;
  /* font-family: MetronicPro-Regular; */
  font-weight: normal;
}
.oq-esg p span {
  color: var(--primary-color);
  font-size: 22px;
  font-family: MetronicPro-Regular;
  font-weight: bold;
  display: block;
  margin-bottom: 15px;
}
.titulo-aux {
  font-family: "Uniwars-W00-SemiBold";
  color: var(--primary-color);
  font-size: 22px;
}
.margin-t {
  margin-top: 100px;
}
.pilares {
  display: flex;
  justify-content: space-between;
  /* gap: 100px; */
  margin-top: 50px;
}
.pilares-item {
  border: 1px solid var(--primary-color);
  padding: 40px 20px;
  border-radius: 10px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}
.pilares-item img {
  max-width: 110px;
  margin: 0 auto;
  text-align: center;
}
.pilares-item h5 {
  font-family: "Uniwars-W00-SemiBold";
  font-size: 18px;
  color: var(--primary-color);
  margin: 30px 0 20px 0;
}
.pilares-item p {
  color: #ffffff;
  font-size: 22px;
}
.esg-da-stock {
  margin-top: 100px;
}
.esg-da-stock h3 {
  margin: 50px 0;
}
.carbono-neutro-bg {
  background-image: url("../assets/img/carbono-neutro.png");
  background-size: cover;
}

.livre-residuos-bg {
  background-image: url("../assets/img/livre-residuos.png");
  background-size: cover;
}
.instituto-bg {
  background-image: url("../assets/img/instituto.png");
  background-size: cover;
}
.social-itens {
  /* background: red; */
}
.social-itens .pilares-item {
  border: none;
  padding: 0;
  text-align: center;
}
.social-itens .pilares-item img {
  max-width: 300px;
}
.social-itens .pilares-item p {
  font-size: 18px;
}
.governanca-bg {
  background-image: url("../assets/img/governanca.png");
  background-size: cover;
}
@media (max-width: 1500px) {
  .galeao-content {
    padding-top: 300px;
  }
  .pilares-container {
    margin-top: 150px;
  }
  .box-texto h2 {
    font-size: 28px;
  }
  .box-texto {
    padding: 60px !important;
  }
}
@media (max-width: 1100px) {
  .titulo-aux {
    font-size: 18px;
    text-align: left;
  }
  .pilares {
    gap: 20px;
    overflow-x: scroll;
  }
  .pilares::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
    height: 1px;
  }

  .pilares-item {
    min-width: 270px !important;
  }
  .box-texto {
    padding: 30px 25px !important;
  }
  .social-itens {
    gap: 20px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .oq-esg.primeiro-item {
    max-width: 100%;
  }
  .esg-page {
    overflow-x: hidden;
  }
  .pilares-container {
    margin-top: 550px;
  }
  .margin-t {
    margin-top: 250px;
  }
}

.valores-container {
  background: transparent linear-gradient(270deg, #2b262a 0%, #0e080d00 100%) 0%
    0% no-repeat padding-box;
  margin: 100px auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 45px;
  border-radius: 5px;
  padding: 40px 0;
}

.valores-container .valor-item + .valor-item {
  margin-top: 30px;
}
.valores-container .valor-item h4 {
  color: var(--primary-color);
  font-size: 22px;
}
.valores-container .valor-item p {
  color: var(--title-color);
  font-size: 15px;
  margin: 0;
}
.valores-container h3 {
  color: var(--primary-color);
  font-family: "Uniwars-W00-SemiBold";
}
.flex-valor {
  display: flex;
  align-items: center;
  gap: 15px;
}
.flex-valor img {
  max-width: 36px;
}
.flex-valor + .flex-valor {
  margin-top: 15px;
}

.areas-atuacao {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 40px 0;
}
.areas-atuacao .area-item {
  border: 1px solid var(--primary-color);
  font-size: 18px;
  padding: 25px 50px;
  border-radius: 5px;
  color: var(--title-color);
}
/*
.areas .video-item {
  background-image: url("../assets/img/mask-video-trabalhe.png");
  background-size: cover;
  background-position: center;
  height: 650px;
  border-radius: 5px;
  margin-top: 30px;
  cursor: pointer;
}*/
.areas .ver-mais {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 80px;
}
.areas .ver-mais a {
  color: var(--primary-color);
  font-family: "Uniwars-W00-SemiBold";
  display: flex;
  align-items: center;
  gap: 30px;
  flex: 1;
}
.areas .ver-mais a::before {
  content: "";
  width: 40%;
  height: 1px;
  background-color: var(--primary-color);
  display: inline-block;
  flex: 1;
}
.areas .ver-mais a::after {
  content: "";
  width: 40%;
  height: 1px;
  background-color: var(--primary-color);
  display: inline-block;
  flex: 1;
}
.btn-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-acao {
  font-family: "Uniwars-W00-SemiBold";
  font-size: 16px;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--text-color) !important;
  border-radius: 6px;
  padding: 10px 70px;
  text-decoration: none;
  text-align: center;
  margin: 15px auto;
  margin-top: 70px;
  display: block;
}
.depoimentos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 90px;
  padding: 80px 60px;
  overflow: auto;
}
.depoimentos::-webkit-scrollbar-thumb {
  background: #ffffff6e;
  border-radius: 5px;
  height: 1px;
}

.depoimento-item {
  background: var(--primary-color);
  max-width: 460px;
  min-width: 460px;
  flex: 1;
  padding: 50px 30px;
}
.depoimento-item .depoimento-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  align-items: center;
}
.depoimento-item .depoimento-top img {
  max-width: 225px;
  margin: 0;
  margin-top: -110px;
  /* margin-left: -18px; */
}

.depoimento-item .dados h6 {
  font-weight: bold;
  margin: 0;
}
.depoimento-item .dados span {
  font-size: 14px;
}
.depoimento-item .text-depoimento {
  margin-top: 20px;
}
@media (max-width: 1370px) {
  /* .valores-container {
    margin: 300px auto;
  } */
  .galeao-content {
    padding-top: 300px;
  }
  .box-title h4 {
    font-size: 18px;
  }
  .pilares-container {
    /* margin-top: 550px; */
    margin-top: 300px;
  }
  .valores-container {
    margin: 100px auto !important;
    margin-top: 350px !important;
  }
  .oq-esg.primeiro-item {
    margin: 0;
    max-width: 60%;
    margin-top: 40px;
  }
  .oq-esg p span {
    font-size: 18px;
  }
  .oq-esg p {
    font-size: 16px;
  }
}
@media (max-width: 1550px) {
  .valores-container {
    margin-top: 300px !important;
  }
}

@media (max-width: 768px) {
  .overlay-galeao {
    min-height: 110vh;
  }
  .galeao-content {
    padding-top: 100px;
  }
  .valores-container {
    margin: 200px auto;
    margin-bottom: 50px;

    padding: 40px 20px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 180px !important;
  }
  .areas-atuacao {
    justify-content: flex-start;
  }
  .areas-atuacao .area-item {
    padding: 15px 30px;
  }
  .depoimentos {
    padding: 60px 20px;
  }
  .depoimento-item {
    max-width: 100%;
    min-width: 100%;
  }
  .depoimento-item .depoimento-top img {
    max-width: 150px;
  }
  .video {
    height: 300px !important;
  }
  .oq-esg.primeiro-item {
    max-width: 100%;
  }
  .btn-acao {
    padding: 10px 30px;
  }
}
@media (max-width: 400px) {
  .valores-container {
    margin-top: 350px !important;
  }
}
</style>
  