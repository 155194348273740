<template>
  <div id="app" :data-tema="getTema">
    <BaseMenu :logo="categoria.imgLogo" v-if="categoria && $route.path !== '/stockcar/proseries/fanpush-tv'" />
    <transition mode="out-in" id="main">
      <router-view />
    </transition>
    <Rodape v-show="!loading" v-if="$route.path !== '/stockcar/proseries/fanpush-tv'" data-anima="bottom" />

    <div class="loading_global" v-if="loading_global">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </div>
  </div>
</template>


<script>
import { EventBus } from "@/main.js";

import BaseMenu from "@/components/BaseMenu.vue";
import Rodape from "@/components/Rodape.vue";
// import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  components: {
    BaseMenu,
    Rodape,
  },
  data() {
    return {
      loading: false,
      loading_global: false,
      categoria: null,
    };
  },
  computed: {
    getTema() {
      return (this.$store.state.categoria && this.$store.state.categoria.tag) ||
        sessionStorage.categoria
        ? JSON.parse(sessionStorage.categoria).tag
        : "stockcarproseries" || "stockcarproseries";
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang.split("-")[0];
      this.$store.dispatch("alterarIdioma", lang);
    },
    fetchCategoria(id_categoria) {
      this.loading = true;
      this.categoria = null;

      this.$store
        .dispatch("getCategoria", id_categoria)
        .then((resp) => {
          this.categoria = resp.categoria;
          document.querySelector("#favicon").setAttribute("href", "");
          setTimeout(() => {
            document
              .querySelector("#favicon")
              .setAttribute("href", this.categoria.imgCategoria);
          }, 100);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.fetchCategoria(
        mapCategorias[this.$route.params.categoria || "proseries"]
      );
    }, 100);

    if (localStorage.token) {
      this.$store.dispatch("getUsuario");
    }
  },
  created() {
    EventBus.$on("updateRouter", (data) => {
      this.fetchCategoria(mapCategorias[data || "proseries"]);
    });

    EventBus.$on("loadingGlobal", (data) => {
      this.loading_global = data;
    });

    this.changeLanguage(sessionStorage.idioma || "pt-br");
  },
};
</script>

<style>
@font-face {
  font-family: "MetronicPro-Light";
  src: url("./assets/fonts/MetronicPro-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "MetronicPro-Black";
  src: url("./assets/fonts/MetronicPro-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "MetronicPro-Regular";
  src: url("./assets/fonts/MetronicPro-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "MetronicPro-SemiBold";
  src: url("./assets/fonts/MetronicPro-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "MetronicPro-Bold";
  src: url("./assets/fonts/MetronicPro-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Uniwars-W00-Light";
  src: url("./assets/fonts/Uniwars-W00-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "UniwarsW00Regular";
  src: url("./assets/fonts/UniwarsW00Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Uniwars-W00-SemiBold";
  src: url("./assets/fonts/Uniwars-W00-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Uniwars-W00-Black";
  src: url("./assets/fonts/Uniwars-W00-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "UniwarsW00Heavy";
  src: url("./assets/fonts/UniwarsW00Heavy.ttf");
}

@font-face {
  font-family: "Uniwars-W00-Heavy-Italick";
  src: url("./assets/fonts/Uniwars-W00-Heavy-Italic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Venera-300";
  src: url("./assets/fonts/Venera-300.otf");
  font-display: swap;
}

@font-face {
  font-family: "Venera-700";
  src: url("./assets/fonts/Venera-700.otf");
  font-display: swap;
}

@font-face {
  font-family: "Venera-900";
  src: url("./assets/fonts/Venera-900.otf");
  font-display: swap;
}

@font-face {
  font-family: "UniwarsW00Heavy";
  src: url("./assets/fonts/UniwarsW00Heavy.ttf");
  font-display: swap;
}

@import url("./assets/css/main.css");
@import url("./assets/css/gp.css");
#app {
  background-color: var(--bg-color) !important;
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: clip;
}
#main {
  flex: 1;
}

.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}
body {
  overflow-x: hidden;
}

#dropdown-idiomas .dropdown-item {
  display: flex;
  align-items: center;
  gap: 15px;
}
.loading_global {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 99999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.idiomas-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.idiomas-mobile #dropdown-idiomas {
  border: 1px solid #000;
}
.idiomas-mobile .dropdown-toggle {
  color: #000 !important;
}
</style>

