<template>
  <div class="bg-pesquisa">
    <b-container>
      <b-row>
        <b-col cols="12" lg="12">
          <!--<div class="pesquisa">
            <img
              :src="carro_piloto"
              alt="Imagem de carro"
              v-if="carro_piloto"
            />
            <h3>Queremos você no GRID!</h3>
            <p>
              {{ nome_usuario }} você já é parte da nossa comunidade e queremos
              construir e oferecer uma experiência cada vez melhor para o fã de
              Stock Car. Por este motivo gostaríamos de saber mais sobre você.
            </p>
          </div>-->

          <PesquisaEnriquencimento />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PesquisaEnriquencimento from "./PesquisaEnriquencimento.vue";
export default {
  name: "PesquisaUsuario",
  components: {
    PesquisaEnriquencimento,
  },
  data() {
    return {
      usuario: null,
    };
  },
  computed: {
    nome_usuario() {
      return this.usuario ? `${this.usuario.name},` : null;
    },
    carro_piloto() {
      return this.usuario && this.usuario.pilotoFavorito
        ? this.usuario.pilotoFavorito.carro3d
        : null;
    },
  },
  mounted() {
    this.usuario = localStorage.user
      ? JSON.parse(localStorage.user)
      : this.$store.state
      ? this.$store.state.user
      : null;
  },
};
</script>

<style scoped>
.grid p {
  font-family: "MetronicPro-Bold";
  font-size: 18px;
  margin-bottom: 0px;
  border-left: 1px solid black;
  padding-left: 15px;
}

.perguntas {
  padding: 30px;
  background-color: #eabb4d;
}

.pesquisa {
  margin-top: 50px;
  margin-bottom: 50px;
}

.pesquisa img {
  width: 600px;
  margin: 0 auto;
  display: flex;
}

.pesquisa h3 {
  font-family: "Uniwars-W00-Heavy-Italick";
  color: white;
  font-size: 32px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: -30px;
}

.pesquisa p {
  color: white;
  font-size: 16px;
  text-align: center;
  width: 550px;
  margin: 0 auto;
  margin-top: 40px;
}
@media (max-width: 989px) {
  .pesquisa h3 {
    font-size: 24px;
  }
}
</style>