<template>
  <div>
    <section v-if="!loading">
      <div class="main-termos-menores">
        <b-container class="align-items-end">
          <b-col cols="12" lg="7" class="hist-content box-texto">
            <div class="linha-noticia"></div>
            <b-card no-body class="mb-1 camarotes">
              <h2>Termo de responsabilidade para menor de 18 anos</h2>

              <b-button @click="downloadPDF" class="btn-termos-menores"
                >Baixar o termo</b-button
              >
            </b-card>
          </b-col>
        </b-container>

        <b-container class="space-icons-banner" data-anima="bottom">
          <div>
            <p class="title-termos-menores">classificação etária</p>
            <p class="text-termos">
              De 02 a 18 anos, acompanhados de responsável legal, mediante
              compra de ingresso. É obrigatória a presentação dos documentos
              de<br />
              identificação originais, com foto, do responsável e menor.
            </p>

            <p class="text-termos">
              Menores de dois (2) anos não terão acesso ao evento.
            </p>
          </div>
        </b-container>
      </div>

      <div class="main-termos-menores-mobile">
        <b-container class="">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h2 class="titulo title-mobile">
              <strong>Termo de responsabilidade para menor de 18 anos</strong>
            </h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-container>

        <b-container style="margin-bottom: 60px; margin-top: 60px">
          <p class="title-termos-menores">classificação etária</p>
          <p class="text-termos">
            De 02 a 18 anos, acompanhados de responsável legal, mediante compra
            de ingresso. É obrigatória a presentação dos documentos de<br />
            identificação originais, com foto, do responsável e menor.
          </p>

          <p class="text-termos">
            Menores de dois (2) anos não terão acesso ao evento.
          </p>

          <b-button @click="downloadPDF" class="btn-termos-menores btn-mobile"
            >Baixar o termo</b-button
          >
        </b-container>
      </div>
    </section>

    <b-container v-if="loading" data-anima="bottom" class="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </b-container>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  components: {},

  methods: {
    downloadPDF() {
      const pdfPath =
        "https://emailimagesnb-v3.s3.sa-east-1.amazonaws.com/oink/stockcar/Termo_de_Menores.pdf";
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = "Termo_de_Menores.pdf";
      link.target = "_blank";
      link.click();
    },
  },

  mounted() {},

  created() {},
};
</script>
    
  <style scoped>
.main-termos-menores {
  height: 80vh;
  display: flex;
  flex-direction: initial;
  background-image: linear-gradient(transparent, black), var(--bg-termo-menores);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.main-termos-menores-mobile {
  height: calc(100vh - 300px);
  display: none;
  flex-direction: column;
  background-image: linear-gradient(transparent, black), var(--bg-termo-menores);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.main-termos-menores .titulo {
  background-image: none;
  font-size: 34px;
  text-transform: uppercase;
}

.loading {
  min-height: 37vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-estrutura {
  font-family: MetronicPro-Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.card-plataforma {
  border: 1px solid var(--primary-color);
  max-width: 300px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 24px;
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  max-width: 250px;
  width: 100%;
  font-family: "MetronicPro-Bold";
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 30px 0px 30px 0px;
  margin-top: 40px;
  text-transform: uppercase;
}

.card-img {
  border: 1px solid var(--primary-color);
  max-width: 250px;
  background-repeat: no-repeat;
  height: 320px;
  background-size: cover;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 24px;
}

.btn-termos-menores {
  background-color: #000000;
  font-family: "Uniwars-W00-SemiBold";
  font-size: 1.2rem;
  border: none;
  padding: 8px 12px;
}

.title-mobile {
  color: var(--primary-color);
}

.title-termos-menores {
  font-family: "MetronicPro-Black" !important;
  color: var(--title-color);
  text-transform: uppercase;
  font-size: 1.4rem;
}

.text-termos {
  font-family: "MetronicPro-Light";
  font-size: 1.2rem;
  color: var(--title-color);
}

.align-items-end {
  justify-content: center;
  display: flex;
  align-items: flex-end;
}

.img-hist {
  background-size: cover;
  background-position: center;
}

.flex-1 {
  flex-grow: 1;
}

.box-camarote {
  width: 100%;
  background-color: red;
  max-height: 500px;
  height: 100%;
  margin-bottom: 40px;
}

.hist-content {
  height: auto;
}

.icons-cards {
  max-width: 150px;
  height: 150px;
  fill: var(--title-color);
}

.box-texto {
  padding: 40px !important;
  background-color: var(--primary-color);
}

.box-texto h2 {
  margin-bottom: 20px;
  font-family: "MetronicPro-Bold";
  font-size: 35px;
  line-height: 36px;
  color: var(--text-color);
  text-transform: uppercase;
}

.button-camarote-more:hover,
.button-camarote-more:active {
  background-color: transparent !important;
}

.card-header {
  background-color: transparent;
  border: none;
  font-family: "MetronicPro-Semibold";
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
}

.space-icons-banner {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.button-contact-camarote {
  width: 226px;
  font-family: "MetronicPro-Light";
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid var(--text-color);
  color: var(--text-color);
}

.button-contact-camarote:hover,
.button-contact-camarote:active {
  filter: brightness(0.5);
  background-color: transparent !important;
  border: 1px solid var(--text-color) !important;
  color: var(--text-color) !important;
}

.button-contact-camarote:focus {
  opacity: 1 !important;
}

.card.camarotes {
  background-color: transparent;
  border: none;
  font-family: "MetronicPro-Semibold";
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
}

.card-text {
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
}

.espaco-camarotes {
  margin-top: 60px;
}

.btn-mobile {
  background-color: var(--primary-color);
  color: var(--text-color);
  margin-top: 60px;
}

.card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (max-width: 1638px) {
  .box-texto h2 {
    font-size: 1rem !important;
    line-height: 20px;
  }
}

@media (max-width: 1600px) {
  .box-texto h2 {
    font-size: 1.4rem !important;
    line-height: 20px !important;
  }

  .center-images {
    /* margin-top: 58% !important; */
  }

  .btn-termos-menores,
  .text-termos {
    font-size: 1rem;
  }

  .title-termos-menores {
    font-size: 1rem;
  }

  .hist-content {
    height: auto;
  }
}

@media (max-width: 1382px) {
  .main-termos-menores-mobile h2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 800px) {
  .titulo-patrocinadores {
    font-size: 1.2rem;
  }

  .main-termos-menores-mobile {
    display: flex;
    height: auto;
  }

  .main-termos-menores {
    display: none;
  }
}

@media (max-width: 660px) {
  .main-termos-menores,
  .main-termos-menores-mobile {
    height: auto;
  }

  .hist-content {
    height: 250px;
  }

  .box-texto {
    height: auto;
  }

  .main-termos-menores-mobile h2 {
    font-size: 1rem;
  }

  .titulo-patrocinadores {
    font-size: 1rem;
  }
}
</style>
      