<template>
  <section>
    <div class="secao-montadoras" v-if="!loading">
      <div class="titulo-montadoras">
        <b-container class="bv-row">
          <b-row>
            <b-col class="titulo-centralizado">
              <hr class="linha-titulo" />
              <h1 class="titulo"><strong>MONTADORAS</strong></h1>
              <hr class="linha-titulo" />
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="montadoras" v-if="montadoras.length">
        <div v-for="item in montadoras" :key="item.idMontadora">
          <img
            alt="Imagem de fundo da montadora"
            class="bg-montadoras"
            :src="item.imagemBackground"
          />
          <div class="conteudo-montadoras">
            <img
              alt="Logo da montadora"
              class="img-centralizada"
              :src="item.logoMontadora"
            /><br />
            <img
              alt="Imagem de carro da montadora"
              class="img-centralizada"
              :src="item.imagemCarro"
            />
            <div class="txt-chevorlet">
              <div v-html="item.sobre"></div>

              <div class="rede-social">
                <a :href="item.webSite" target="_blank" v-if="item.webSite"
                  ><img
                    src="@/assets/img/icone-globe.png"
                    alt="Ícone de globo web"
                    class="globo"
                  />
                </a>

                <a
                  :href="item.instagram"
                  target="_blank"
                  v-if="item.instagram"
                  class="instagram"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="container loading">
      <b-skeleton animation="wave" width="40%"></b-skeleton>
      <b-skeleton animation="wave" width="50%"></b-skeleton>
      <b-skeleton animation="wave" width="30%"></b-skeleton>

      <b-skeleton animation="wave" width="70%"></b-skeleton>
      <b-skeleton animation="wave" width="90%"></b-skeleton>
      <b-skeleton animation="wave" width="40%"></b-skeleton>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  name: "BaseMontadoras",
  data() {
    return {
      montadoras: [],
      loading: false,
    };
  },
  methods: {
    fetchMontadoras(categoria_id) {
      this.loading = true;
      api
        .get(`/Montadora/categoria/${categoria_id}`)
        .then((resp) => {
          this.montadoras = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.fetchMontadoras(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
  },
};
</script>

<style scoped>
.montadoras {
  display: flex;
}
.bg-montadoras {
  width: 49.8vw;
  min-width: 49.8vw;
}

.titulo {
  background-image: none;
}

.txt-chevorlet p {
  font-family: "MetronicPro-Regular" !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--title-color) !important;
}

.txt-chevorlet h3 {
  font-family: "MetronicPro-Regular" !important;
  color: var(--title-color) !important;
  margin-bottom: 20px;
}

.img-centralizada {
  margin: 0 auto;
  display: flex;
}

.txt-chevorlet {
  width: 65%;
  margin: 0 auto;
  margin-top: 50px;
}
.secao-montadoras {
  min-height: 100vh;
}

.globo {
  margin-top: 30px;
}

.rede-social {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.instagram {
  margin: inherit !important;
  margin-top: 30px !important;
}

.titulo-centralizado {
  padding-top: 0px;
}

@media (max-width: 989px) {
  .montadoras {
    display: grid;
  }

  .bg-montadoras {
    width: 100% !important;
  }

  .titulo-montadoras {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .txt-chevorlet {
    width: 90%;
  }
}

@media (min-width: 990px) {
  .titulo-montadoras {
    position: absolute;
    width: 100%;
    margin-top: 60px;
  }
}
.loading {
  padding: 100px 0;
}
.loading .b-skeleton + .b-skeleton {
  margin-top: 40px;
}

@media (min-width: 991px) and (max-width: 1499px) {
  .conteudo-montadoras {
    position: relative;
    top: -100px;
  }
}

@media (min-width: 1500px) {
  .conteudo-montadoras {
    position: relative;
    top: -250px;
  }
}
</style>