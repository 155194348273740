<template>
  <section>
    <div class="votacao">
      <div v-if="fanpush && fanpush.fanpushAberto && !nao_votar">
        <div v-if="(!fanpush?.usuarioVotou || !fanpush?.validado) && !mensagem">
          <b-container>
            <b-row>
              <b-col cols="12" lg="7" class="txt-votacao">
                <h2>Escolha <strong>seus pilotos</strong></h2>
                <p>
                  Você tem direito a dar <span>3 votos</span> na próxima Etapa.
                  Escolha seus <br />pilotos favoritos para ganhar.
                </p>
              </b-col>

              <b-col cols="12" lg="4" class="info-etapa">
                <InformacoesEtapas @fotoCircuito="fotoCircuito" />
              </b-col>

              <b-col cols="12" lg="1" class="circuito">
                <img :src="img_circuito" alt="Imagem do circuito" />
              </b-col>
            </b-row>
          </b-container>

          <CardsPilotos
            v-if="fanpush.listaPilotos"
            :votacao_fanpush="true"
            :pilotos_fanpush="fanpush.listaPilotos"
            @updateVotacaoPiloto="handleUpdateVotacaoPiloto"
          />
          <b-container class="mt-5">
            <b-row>
              <b-col cols="12" lg="12">
                <hr class="linha mt-5" />
                <div class="btn-avancar">
                  <b-button
                    v-b-modal.modal-1
                    type="button"
                    class="acessar btn"
                    :disabled="!showButton"
                    >CONTINUAR</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>

      <div
        v-if="mensagem || (fanpush?.usuarioVotou && fanpush?.validado)"
        class="container"
      >
        <p class="my-4 confirma">Você escolheu 3 pilotos!</p>
        <p class="my-4" style="width: 600px; margin: 0 auto;">
          Lembre-se, os 6 mais bem votados receberão a potência de um Vivo Fan
          Push by Snapdragon. Acompanhe a votação em tempo real clicando abaixo:
        </p>
        <b-container class="pilotosVotados" v-if="pilotosVotados.length">
          <b-row>
            <b-col
              cols="12"
              lg="4"
              v-for="(piloto, index) in pilotosVotados"
              :key="`${piloto.id || piloto.idPiloto}-${index}`"
            >
              <div class="pilotos-selecionados">
                <p>{{ piloto.numero }}</p>
                <img
                  :src="piloto.imagem"
                  alt="Imagem do piloto"
                  class="piloto-modal"
                />
              </div>
              <p class="nome-modal">{{ piloto.nome }}</p>
            </b-col>
          </b-row>
        </b-container>
        <router-link
          to="/stockclub/votacao-fanpush-live"
          style="text-decoration: none; color: inherit"
        >
          <button type="button" class="btn btn-home">ACOMPANHAR VOTAÇÃO</button>
        </router-link>
      </div>
    </div>
    <div data-anima="top" v-if="!nao_votar && !loading">
      <div v-if="!fanpush.fanpushAberto && !fanpush.usuarioVotou">
        <p class="my-4 py-5 confirma">Fanpush não está disponível no momento</p>
        <!-- <p class="my-4">Tenta novamente mais tarde. Logo estará regularizada.</p> -->

        <router-link
          to="/stockclub/home"
          style="text-decoration: none; color: inherit"
        >
          <button type="button" class="btn btn-home">IR PARA HOME</button>
        </router-link>
      </div>
    </div>
    <div v-if="nao_votar && !loading">
      <p class="my-4 pt-5 confirma">
        Parece que seu cadastro não está atualizado
      </p>
      <p class="my-4">
        Acesse o link abaixo e atualize o seu celular para continuar a votação
      </p>

      <router-link
        to="/stockclub/meu-perfil?phone=true"
        style="text-decoration: none; color: inherit"
      >
        <button type="button" class="btn btn-home">EDITAR MEU PERFIL</button>
      </router-link>
    </div>

    <b-container>
      <b-modal id="modal-1" @hidden="resetModal">
        <div>
          <div class="titulo-modal">
            <!-- <p class="my-4 confirma" v-if="solicita_token">
              Você recebeu um token de verificação por SMS no celular cadastrado
              {{ telefone_usuario }}.<br />
              Insira o código nos campos abaixo:
            </p> -->
            <p class="my-4 confirma">
              Você confirma que está votando nesses 3 pilotos para o vivo fan
              push by snapdragon?
            </p>
          </div>

          <div v-if="loading" class="loading">
            <b-spinner
              style="
                width: 3rem;
                height: 3rem;
                border-color: #e6c156;
                border-right-color: transparent;
                --bs-spinner-animation-speed: 0.5s;
              "
              label="Large Spinner"
              variant="warning"
            ></b-spinner>
          </div>

          <Notificacao
            :notificacao="notificacao_infos"
            v-if="notificacao_infos.count_timer"
            @atualizaNotificacao="notificacao_infos.count_timer = 0"
          />

          <b-container v-if="!loading" data-anima="bottom">
            <b-row>
              <b-col
                cols="12"
                lg="4"
                v-for="piloto in votacaoPiloto"
                :key="piloto.idPiloto || piloto.id"
              >
                <div class="pilotos-selecionados">
                  <p>{{ piloto.numero }}</p>
                  <img
                    v-if="piloto.imagem"
                    :src="piloto.imagem"
                    alt="Imagem do piloto"
                    class="piloto-modal"
                  />
                </div>
                <p class="nome-modal">{{ piloto.nome }}</p>
              </b-col>
            </b-row>

            <!-- <b-row v-if="solicita_token" class="mt-5">
              <b-col cols="12">
                <div class="form-step1">
                  <b-form>
                    <div>
                      <div class="codigo">
                        <div v-for="(v, index) in inputs" :key="index">
                          <b-form-group
                            :id="`codigo-${index}`"
                            :label-for="`codigo-${index}`"
                          >
                            <b-form-input
                              :id="`input-${index}`"
                              :name="`input-${index}`"
                              v-model="codigo[index]"
                              type="text"
                              autocomplete="off"
                              maxlength="1"
                              minlength="1"
                              @keyup="(e) => nextInput(e)"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <div data-anima="top">
                      <a
                        href="#"
                        class="novolink"
                        :disabled="loading"
                        @click.prevent="reenviarLink"
                        v-if="!codigo_reenviado"
                      >
                        Novo token de verificação
                      </a>

                      <a
                        href="#"
                        class="novolink sucesso"
                        disabled
                        v-if="codigo_reenviado"
                      >
                        Código reenviado com sucesso! ✔️
                      </a>
                    </div>
                  </b-form>
                </div>
              </b-col>
            </b-row> -->
          </b-container>

          <footer id="confirmar" class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="loading"
              @click="enviarDados"
            >
              CONFIRMAR VOTO
            </button>
            <!-- CONTINUAR -->
            <!-- v-if="!solicita_token" -->

            <!-- <button
              v-if="solicita_token"
              type="button"
              class="btn btn-primary"
              :disabled="loading"
              @click="enviarToken"
            >
              CONFIRMAR VOTO
            </button> -->

            <Recaptcha
              :sitekey="sitekey"
              ref="recaptchaVotacao"
              @setRecaptcha="setRecaptcha"
            />
          </footer>
        </div>
      </b-modal>
    </b-container>
  </section>
</template>

<script>
import CardsPilotos from "@/components/CardsPilotos.vue";
import InformacoesEtapas from "@/components/InformacoesEtapas.vue";
import Recaptcha from "@/components/security/Recaptcha.vue";

import { api } from "@/services.js";

export default {
  name: "VotacaoPiloto",
  data() {
    return {
      solicita_token: false,
      codigo_reenviado: false,
      telefone_usuario: null,
      inputs: 4,
      codigo: [],
      usuario: null,
      nao_votar: false,
      recaptcha_code: "",
      sitekey: process.env.VUE_APP_KEY_CAPTCHA,
      loading: false,
      img_circuito: null,
      votacaoPiloto: [],
      showButton: false,
      fanpush: {
        fanpushAberto: false,
        listaPilotos: null,
      },
      idEtapaFanpush: null,
      mensagem: false,
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
      pilotosVotados: [],
      votosLocais: null,
    };
  },
  components: {
    CardsPilotos,
    InformacoesEtapas,
    Recaptcha,
  },
  methods: {
    votacao_aberta() {
      if (this.fanpush) {
        const inicio = new Date(this.fanpush.dataInicioVotacao);
        const fim = new Date(this.fanpush.dataFimVotacao);
        const agora = new Date();

        return agora >= inicio && agora <= fim;
      }
    },
    nextInput(event) {
      setTimeout(() => {
        let [input, id] = event.target.id.split("-");
        id = parseInt(id) + 1;
        let nextInput = document.getElementById(
          input + "-" + parseInt(id).toString()
        );
        if (nextInput) nextInput.focus();
      }, 20);
    },
    resetModal() {
      this.showButton = false;
      this.solicita_token = false;
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };
    },
    reenviarLink() {
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };
      const data = {
        idEtapaFanpush: this.fanpush.idEtapaFanpush,
      };
      api
        .post(`Fanpush/reenvio-token`, data)
        .then(() => {
          this.solicita_token = true;
          this.codigo_reenviado = true;
        })
        .catch((error) => {
          this.codigo_reenviado = false;
          console.log(error);

          this.notificacao_infos = {
            variant: "danger",
            mensagem: error.response
              ? error.response.data
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        });
    },
    enviarToken() {
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };
      this.loading = true;

      const data = {
        idEtapaFanpush: this.fanpush.idEtapaFanpush,
        token: this.codigo.join(""),
      };
      api
        .post(`Fanpush/token`, data)
        .then(() => {
          this.mensagem = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.$bvModal.hide("modal-1");
          localStorage.votosLocais = "";
        })
        .catch((error) => {
          console.log(error);

          this.notificacao_infos = {
            variant: "danger",
            mensagem: error.response
              ? error.response.data
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getVotosLocal() {
      this.loading = true;
      this.votosLocais = localStorage.votosLocais
        ? JSON.parse(localStorage.votosLocais)
        : null;

      if (this.votosLocais && this.votosLocais.length) {
        this.votacaoPiloto = this.votosLocais.map((item) => ({
          idPiloto: item.id,
          nome: item.nome,
          numero: item.numero,
          imagem: item.imagem,
        }));
        this.showButton = true;
        this.solicita_token = true;
        const telefone = this.usuario.phone;
        this.telefone_usuario = telefone.replace(/(\d{5})-(\d{4})/, "#####-$2");
        this.$bvModal.show("modal-1");
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    handleUpdateVotacaoPiloto(votacaoPiloto) {
      this.votacaoPiloto = null;
      this.votacaoPiloto = votacaoPiloto;
      if (this.votacaoPiloto.length === 3) {
        this.showButton = true;

        const btnContinuar = document.querySelector(".acessar");
        const botaoPosicaoY = btnContinuar.offsetTop;
        const alturaJanela = window.innerHeight;
        const pontoRolagem = botaoPosicaoY - alturaJanela / 2;
        window.scrollTo({ top: pontoRolagem, behavior: "smooth" });
      } else {
        this.showButton = false;
      }
    },
    fotoCircuito(data) {
      this.img_circuito = data;
    },

    fetchFanPush() {
      this.loading = true;
      this.pilotosVotados = [];
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };
      api
        .get(`/Fanpush`)
        .then((resp) => {
          this.fanpush = resp.data.fanpush;
          this.pilotosVotados = this.fanpush.listaPilotosVoto;
        })
        .catch((error) => {
          console.log(error);

          this.notificacao_infos = {
            variant: "danger",
            mensagem: error.response
              ? error.response.data
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setRecaptcha(token) {
      this.recaptcha_code = token;
      this.enviarDados();
    },
    enviarDados() {
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };
      // this.solicita_token = false;
      if (this.recaptcha_code) {
        this.loading = true;

        const data = {
          captcha: this.recaptcha_code,
          idEtapaFanpush: this.fanpush.idEtapaFanpush,
          piloto: this.votacaoPiloto.map((item) => ({
            id: item.idPiloto,
            nome: item.nome,
            numero: item.numero,
            imagem: item.imagem,
          })),
        };

        api
          .post(`Fanpush`, data)
          .then(() => {
            this.pilotosVotados = this.votacaoPiloto;
            this.mensagem = true;
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.$bvModal.hide("modal-1");

            // window.scrollTo({ top: 0, behavior: "smooth" });
            // localStorage.votosLocais = JSON.stringify(data.piloto);
            // this.solicita_token = true;

            // const telefone = this.usuario.phone;
            // this.telefone_usuario = telefone.replace(
            //   /(\d{5})-(\d{4})/,
            //   "#####-$2"
            // );
          })
          .catch((error) => {
            console.log(error);

            this.notificacao_infos = {
              variant: "danger",
              mensagem: error.response
                ? error.response.data
                : "Algo deu errado na requisição",
              count_timer: 10,
            };

            // if (
            //   this.erro.msg.toUpperCase() ==
            //   "É NECESSÁRIO QUE VOCÊ VALIDE SEUS VOTOS!"
            // ) {
            //   this.solicita_token = true;
            // }
            this.$refs.recaptchaVotacao.executeRecaptcha();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$refs.recaptchaVotacao.executeRecaptcha();
      }
    },
  },
  mounted() {
    this.loading = true;

    this.$nextTick(() => {
      this.usuario = localStorage.user
        ? JSON.parse(localStorage.user)
        : this.$store.state
        ? this.$store.state.user
        : null;

      this.fetchFanPush();
      // this.getVotosLocal();

      // if (this.usuario.celularAtivo && this.usuario.aceitaFluxoFanpush) {
      //   this.fetchFanPush();
      //   this.getVotosLocal();
      // } else if (!this.usuario.aceitaFluxoFanpush) {
      //   this.$router.push("/stockclub/pre-votacao");
      // } else {
      //   this.nao_votar = true;
      //   this.loading = false;
      // }
    });
  },
};
</script>

<style scoped>
section {
  min-height: 50vh;
}
.pilotos-selecionados {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}

.pilotos-selecionados img {
  position: relative;
  top: -30px;
  width: 80px;
  object-fit: contain;
}

.pilotos-selecionados p {
  font-family: "Uniwars-W00-Heavy-Italick";
  color: white;
  font-size: 45px;
  opacity: 0.8;
}

.nome-modal {
  border-top: 1px solid #ffffff52;
  margin-top: -28px;
  font-family: "Uniwars-W00-Light";
  font-size: 13px;
  color: white !important;
  font-style: italic;
  text-align: center;
  padding-top: 10px;
}

.btn-home {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  margin: 0 auto;
  margin-bottom: 80px;
}

.btn-home:hover {
  color: var(--text-color);
  border-color: var(--title-color);
  background-color: var(--title-color);
}

.my-4 {
  text-align: center;
  color: white;
}
.info-etapa {
  margin-top: -30px;
  color: #542283 !important;
}

.votacao {
  margin-top: 100px;
}

.txt-votacao {
  margin-bottom: 60px;
}

.txt-votacao span {
  color: #843dc6;
}

.txt-votacao p {
  font-size: 16px;
  color: var(--title-color);
}

h2 {
  font-family: "Uniwars-W00-Light";
  font-size: 30px;
  color: var(--title-color);
  font-style: italic;
}

h2 strong {
  font-family: "Uniwars-W00-Heavy-Italick";
}

.circuito {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -75px;
}

.circuito img {
  width: 100%;
  object-fit: contain;
}

.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
  /* width: 300px; */
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.acessar:hover {
  color: var(--text-color);
  border-color: var(--title-color);
  background-color: var(--title-color);
}

.info-etapa {
  text-align: right;
}

.loading {
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pilotosVotados {
  margin-top: 30px;
  margin-bottom: 60px;
  width: 700px;
}

@media (min-width: 990px) {
  .piloto-modal {
    width: 40%;
    height: 80px;
  }

  .btn-avancar {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 989px) {
  .pilotosVotados {
    width: 100%;
  }

  .modal-content {
    margin-top: 250px;
  }

  .pilotos-selecionados {
    justify-content: center;
    display: flex;
    gap: 20px;
  }

  .pilotos-selecionados img {
    left: 0px;
  }
  .pilotos-selecionados p {
    font-size: 32px;
  }

  .nome-modal {
    text-align: center;
    width: 100%;
  }

  .acessar {
    width: 100%;
  }

  .info-etapa {
    text-align: center;
  }

  .info-etapa {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .votacao {
    margin-top: 50px;
  }

  .txt-votacao {
    text-align: center;
    margin-bottom: 0px;
  }

  .circuito {
    top: 0px;
    margin: 0 auto !important;
    padding-bottom: 30px;
  }
}

.codigo {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}
.codigo input {
  width: 70px;
  height: 60px;
  background: transparent !important;
  border: 1px solid #e6c156 !important;
}

input:focus,
input:hover {
  box-shadow: none;
  border-color: #fff !important;
}

input:focus input:focus::placeholder {
  color: #e6c156 !important;
  background-color: #ffffff00 !important;
}

input::placeholder {
  color: #e6c156 !important;
}
input {
  color: #fff !important;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
}

input.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}

input.error:hover,
input.error:focus {
  background: rgba(255, 23, 23, 0.1) !important;
}
.novolink {
  text-align: center;
  margin: 0 auto;
  display: block;
  /* background: white; */
  max-width: 250px;
  padding: 5px;
  margin-top: 30px;
  color: #e6c156;

  text-align: center;
  color: rgb(230, 193, 86);
  background: #e6c15670;
  padding: 10px 25px;
  border-radius: 5px;
  text-decoration: none;
}
.novolink.sucesso {
  color: #fff;
  text-decoration: none;
  pointer-events: none;
}
.modal-footer {
  margin: 0 auto;
  justify-content: center;
}
.confirma {
  font-size: 18px;
}
.titulo-modal {
  max-width: 90%;
  margin: 0 auto;
  line-height: 1.5;
}
</style>