<template>
  <div style="position: relative;">
    <the-mask :type="type" :id="id" :value="value" :state="localState" :class="inputClass" :required="required" :disabled="disabled" :placeholder="placeholder" 
      @input="$emit('input', $event)" @focus="focused = true" @blur="focused = false" :mask="mask" :masked="!disabledMasked" />
    <label :for="id" :class="[labelClass, labelClassCustom]" @click="labelClick">{{ label }}</label>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";

export default {
  name: 'InputWithLabelMask',
  components: {
      TheMask,
  },
  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
    type: { type: String, default: 'text' },
    value: { type: [String, Number], default: '' },
    state: { type: Boolean, default: null },
    inputClass: { type: [String, Object], default: '' },
    labelClassCustom: { type: String, default: '' },
    required: { type: Boolean, default: false }, 
    disabled: { type: Boolean, default: false }, // Adicionado prop disabled
    disabledMasked: { type: Boolean, default: false }, // Adicionado prop disabled
    mask: { type: [Array, String], required: true, default: ''},
    placeholder: { type: String },
  },
  data() {
    return {
      focused: false,
      localState: this.state
    }
  },
  computed: {
    labelClass() {
      return {
        'label-active': this.value !== '' || this.focused,
        'label-inactive': this.value === '' && !this.focused
      }
    }
  },
  watch: {
    value() {
      this.localState = this.value !== '' ? true : null
    },
    state() {
      this.localState = this.state
    },
    disabled(value) {
      this.localState = value ? null : this.state;
    }
  },
  methods: {
    labelClick() {
      const input = document.querySelector(`input[id='${this.id}']`);
      input.focus();
    }
  }
}
</script>

  
  <style scoped>
  .label-active {
    position: absolute;
    top: -10px;
    left: 0px;
    margin-left: 12px;
    font-size: 12px;
    color: var(--primary-color);
    transition: all 0.2s ease-out;
    background-color: #000000;
    padding: 0 5px;
    border-radius: 4px !important;
  }
  .datanasc .my-input-class-mask, 
  .celular .my-input-class-mask,
  .cep .my-input-class-mask,
  .cpf .my-input-class-mask {
    height: 25px !important;
    padding: 0px !important;
  }

  .datanasc .my-input-class-mask:focus, 
  .celular .my-input-class-mask:focus,
  .cep .my-input-class-mask:focus {
    border: none !important;
    outline: none !important;
  }

  #cpf:focus-visible,
  #cpf:focus {
    outline: -webkit-focus-ring-color auto 0px !important;
  }

  .my-input-class-mask {
    padding: 1.4rem 1rem;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    background-color: transparent;
    height: 56px;
    color: #FFFFFF !important;
    margin-bottom: 30px;
    width: 100%;
    position: relative;
}

.datanasc .my-input-class-mask:disabled {
    padding-left: 10px !IMPORTANT;
    margin-top: -5px;
    height: 37px !important;
    left: 0px;
    position: absolute;
}

.my-input-class-mask.menu-input + .label-active {
    background-color: #0e080d !important;
    border-radius: 4px !important;
  }
.my-input-class-mask.menu-input:focus + label {
    background-color: #0e080d !important;
    border-radius: 4px !important;
  }
.my-input-class-mask::placeholder {
    position: absolute;
    top: 14px;
    font-size: 16px;
    color: #FFFFFF00;
    transition: all 0.2s ease-out;
}

.my-input-class-mask:focus + label {
    position: absolute;
    top: -10px;
    margin-left: 12px;
    font-size: 12px;
    color: var(--primary-color);
    transition: all 0.2s ease-out;
    background-color: #000000;
    padding: 0 5px;
}

.my-input-class-mask:disabled {
    background-color: #333;
    opacity: 0.7;
}

.my-input-class-mask:focus {
    border: 1px solid #FFFFFF;
    background-color: transparent;
    outline: none !important;
    box-shadow: none !important;
}

.my-input-class-mask.is-valid {
    border: 1px solid var(--primary-color);
    background-image: none !important;
}

.my-input-class-mask.is-valid:focus {
    border: 1px solid #FFF;
    background-image: none !important;
}

.my-input-class-mask.error + label {
    color: #ff1717;
}
.my-input-class-mask.error {
    border: 1px solid #ff1717;
    color: #ff1717;
}

.my-input-class-mask:focus + label {
    color: #fff;
}
 
.label-inactive {
  position: absolute;
  top: 7px;
  left: 0px;
  margin-left: 16px;
  font-size: 16px;
  color: var(--primary-color);
  transition: all 0.2s ease-out;
  cursor: auto;
}

.my-label-class {
    z-index: 10;
}
  </style>
  