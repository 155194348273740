<template>
  <section>
    <div v-if="!loading && noticia">
      <div class="bg-noticia-unica">
        <b-container>
          <b-row>
            <b-col cols="12" lg="12">
              <div class="img-noticia-unica">
                <img :src="noticia.imagemGaleria" alt="Imagem da notícia"/>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <b-container>
        <div class="box-titulo">
          <div class="proxima-etapa">
            <div class="linha-etapa"></div>
            <h3>{{ noticia.titulo }}</h3>
            <p class="hora-etapa">{{ noticia.dataPublicacao.split(' ')[0] }}</p>
          </div>
        </div>

        <div class="txt-noticia textos">
          <div v-html="noticia.texto"></div>
        </div>
      </b-container>

      <div class="secao-tags">
        <b-container>
          <b-row>
            <b-col cols="12" lg="12" class="conteudo-tags textos">
              <hr />
              <p>Tags</p>
              <div class="tags">
                <div v-for="tag in noticia.tags" :key="tag">
                  <p>{{ tag }}</p>
                </div>
              </div>
              <hr />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <div v-if="loading">
      <div class="bg-noticia-unica">
        <b-container>
          <b-skeleton-img></b-skeleton-img>
        </b-container>
      </div>

      <b-container>
        <div class="box-titulo">
          <div class="proxima-etapa">
            <div class="linha-etapa"></div>
            <h3><b-skeleton animation="wave" width="40%"></b-skeleton></h3>
            <p class="hora-etapa">
              <b-skeleton animation="wave" width="20%"></b-skeleton>
            </p>
          </div>
        </div>
      </b-container>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  name: "NoticiaUnica",

  data() {
    return {
      noticia: null,
      loading: false,
    };
  },
  methods: {
    fetchNoticia(categoria_id, id_noticia) {
      this.loading = true;
      api
        .get(`Noticias/categoria/${categoria_id}/noticia/${id_noticia}`)
        .then((resp) => {
          this.noticia = resp.data;
          document.title = `Noticias | ${this.noticia.titulo}`;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            if (this.noticia) {
              const bg = document.querySelector(".bg-noticia-unica");
              bg.style.backgroundImage = `url('${this.noticia.imagemGaleria}')`;
            }
          }, 100);
        });
    },

    getFirstParagraph(html) {
      let match = html.match(/<p>(.*?)<\/p>/);
      if (match && match[1]) {
        return match[1]; 
      } else {
        return ""; 
      }
    },
    removeTags(html) {
      let doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    },
  },

  metaInfo() {
    const getFirstParagraph = this.getFirstParagraph;
    const removeTags = this.removeTags;
    
    if (this.noticia && this.noticia.texto) {
      const primeiroParagrafo = getFirstParagraph(this.noticia.texto);
      const primeiroParagrafoSemTags = removeTags(primeiroParagrafo);
      
      return {
        meta: [
          { property: 'og:title', content: this.noticia.titulo },
          { property: 'og:description', content: primeiroParagrafoSemTags },
        ],
      };
    } else {
      return {};
    }
  },

  mounted() {
    this.loading = true;
    this.fetchNoticia(
      mapCategorias[this.$route.params.categoria || "proseries"],
      this.$route.params.id
    );
  },
};
</script>

<style scoped>
.bg-noticia-unica {
  /* background-image: url(@/assets/img/img-noticias.jpg); */
  min-height: 700px;
  background-blend-mode: overlay;
  background-color: rgb(0 0 0 / 60%) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.img-noticia-unica img {
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 80%;
}

.box-titulo {
  background-color: var(--primary-color);
  padding: 40px 40px 100px 40px;
}

.linha-etapa {
  opacity: 1;
  border: 3px solid;
  width: 150px;
  color: var(--text-color);
}

.box-titulo p {
  color: var(--text-color);
}

.box-titulo h3 {
  margin-top: 30px;
  color: var(--text-color);
  font-size: 35px;
  font-family: "MetronicPro-Bold";
}

.txt-noticia {
  width: 75%;
  display: grid;
  margin: 0 auto;
  margin-top: -150px;
}

.txt-noticia strong {
  font-size: 19px;
}

.textos p,
.textos div {
  font-family: "MetronicPro-Regular" !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--title-color) !important;
}

.secao-tags hr {
  background-color: #fff;
  height: 2px;
  margin-top: 70px;
  margin-block: 50px;
}

.conteudo-tags {
  width: 75%;
  margin: 0 auto;
}

.tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.tags div {
  border-radius: 5px;
  background-color: #2b262a;
  padding: 10px 25px;
}

.tags p {
  color: #707070 !important;
  margin-bottom: 0px !important;
}

@media (max-width: 989px) {
  .conteudo-tags {
    width: 95%;
  }

  .bg-noticia-unica {
    background-size: contain !important;
    min-height: auto !important;
  }

  .img-noticia-unica img {
    margin-top: 35px;
  }

  .txt-noticia {
    width: 95%;
    margin-top: 30px;
  }
}

@media (min-width: 990px) {
  .box-titulo {
    position: relative;
    top: -230px;
    width: 50%;
  }
}
</style>