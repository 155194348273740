<template>
  <div>
    <section class="main-programacao" v-if="!loading && programacoes.length">
      <b-container>
        <b-row class="divisao">
          <b-col class="titulo-centralizado title-programacao">
            <hr class="linha-titulo" />
            <h2 class="titulo">
              <strong>PROGRAMAÇÃO</strong>
            </h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>
      </b-container>

      <b-container class="d-flex main-responsive">
        <b-container>
          <div
            v-for="programacao in programacoes"
            :key="programacao.idProgramacao"
            class="horarios-card card-2"
          >
            <h2>
              <i
                ><span class="">{{ programacao.diaProgramacao }}</span> -
                {{ formatarData(programacao.dataProgramacao) }}</i
              >
            </h2>

            <div
              v-html="programacao.htmlProgramacao"
              class="horarios-text"
            ></div>
          </div>
        </b-container>

        <b-container class="align-items-end">
          <div class="hist-content box-texto mt-60">
            <div class="linha-noticia"></div>
            <b-card no-body class="mb-1 camarotes" v-if="circuitoCompleto">
              <h2>
                Etapa {{ circuitoCompleto.idCircuito }} -
                {{ circuitoCompleto.circuito }}
              </h2>

              <div class="d-flex dates-space">
                <div class="flex-grow-1">
                  <h3 class="data-evento">
                    {{ proximaEtapa.dataDia }}/{{ proximaEtapa.dataMes }}
                  </h3>
                  <p class="data-text-evento">
                    {{ obterDiaDaSemana(proximaEtapa.dataEtapa) }}
                    -
                    {{ proximaEtapa.horaClassificacao }}
                  </p>
                </div>

                <div class="d-flex">
                  <a
                    :href="proximaEtapa.urlTickets"
                    target="_blank"
                    class="btn-programacao"
                    >Garanta seu ingresso!</a
                  >
                </div>
              </div>
            </b-card>
          </div>

          <div v-if="ondeAssistir" class="mt-4 txt-circuito">
            <h3 v-if="$route.params.categoria == 'formula-4'">
              {{ $t("title.onde-assistir") }}
            </h3>
            <h3 v-else>Onde assistir</h3>

            <div class="logo-assistir">
              <img
                v-for="item in ondeAssistir"
                :key="item.nomeCanal"
                :src="item.imgCanal"
                :alt="item.tag"
              />
            </div>
          </div>
        </b-container>
      </b-container>
    </section>

    <b-container v-if="loading" data-anima="bottom" class="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: var(--primary-color);
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </b-container>

    <section v-if="!loading && !programacoes.length">
      <b-container>
        <b-row class="divisao">
          <b-col class="titulo-centralizado title-programacao-not">
            <h3 style="color: #fff">Nenhuma programação encontrada!</h3>
            <h4>Por favor retorne para pagina anterior.</h4>

            <div class="btn-avancar">
              <b-button type="submit" class="acessar btn" @click="telaAnterior"
                >VOLTAR
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
    
<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  data() {
    return {
      loading: false,
      circuitoCompleto: null,
      proximaEtapa: null,
      ondeAssistir: [],
      programacoes: [],
      meses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    };
  },
  components: {},

  methods: {
    fetchProgramacao(categoria_id, circuito_id) {
      this.loading = true;
      api
        .get(`/Circuito/${circuito_id}/categoria/${categoria_id}`)
        .then((resp) => {
          this.programacoes = resp.data.programacoes;
          this.circuitoCompleto = resp.data;
          this.proximaEtapa = resp.data.proximaEtapa;
          this.ondeAssistir = resp.data.ondeAssistir;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    formatarData(dataStr) {
      const data = new Date(dataStr);
      const dia = data.getDate();
      const mes = this.meses[data.getMonth()];

      return `${dia} de ${mes}`;
    },

    telaAnterior() {
      window.history.back();
    },

    obterDiaDaSemana(dataStr) {
      const diasDaSemana = [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ];
      const data = new Date(dataStr);
      const diaDaSemana = data.getDay();
      return diasDaSemana[diaDaSemana];
    },
  },

  mounted() {
    this.fetchProgramacao(
      mapCategorias[this.$route.params.categoria || "proseries"],
      this.$route.params.idCircuito
    );
  },
};
</script>

<style scoped>

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.btn {
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
  width: 300px;
}

.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

.btn-avancar {
  display: flex;
  justify-content: center;
}

.titulo {
  background-image: none;
}

.logo-assistir {
  flex-wrap: wrap;
  padding-left: 20px;
  display: flex;
  gap: 20px;
}

.logo-assistir img {
  height: 70px;
}

.txt-circuito h3 {
  margin-top: 30px;
  padding-left: 20px;
  color: var(--primary-color);
  font-size: 35px;
  font-family: "MetronicPro-Bold";
}

h3 {
  color: var(--text-color);
  font-size: 35px;
  font-family: "MetronicPro-Bold";
}

h4 {
  margin-top: 30px;
  padding-left: 20px;
  color: var(--primary-color);
  font-size: 22px;
  font-family: "MetronicPro-Bold";
}

.data-evento {
  font-family: "Uniwars-W00-Light";
  margin-bottom: 0px;
}

.titulo-centralizado {
  padding-top: 0px !important;
}

.data-text-evento {
  font-family: "Uniwars-W00-Light";
  margin-bottom: 0px;
}

.horarios-card {
  border: var(--primary-color) 1px solid;
}

.horarios-card {
  border-radius: 12px;
  padding: 18px;
}

.mt-60 {
  margin-top: 60px;
}

.horarios-card.card-2 {
  margin-top: 60px;
}

.horarios-card h2 {
  color: var(--primary-color);
  font-family: "MetronicPro-SemiBold";
}

.horarios-text {
  color: var(--title-color);
  font-family: "MetronicPro-Regular";
  font-style: italic;
}

.space-50 {
  width: 50%;
}

.box-texto {
  padding: 40px !important;
  background-color: var(--primary-color);
}

.box-texto h2 {
  margin-bottom: 20px;
  font-family: "MetronicPro-Bold";
  font-size: 35px;
  line-height: 36px;
  color: var(--text-color);
  text-transform: capitalize;
}

.card.camarotes {
  background-color: transparent;
  border: none;
  font-family: "MetronicPro-Semibold";
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
}

.btn-programacao {
  background-color: #000000;
  font-family: "MetronicPro-Regular";
  font-size: 1rem;
  color: var(--primary-color);
  border: none;
  padding: 4px 16px;
  min-width: 200px;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
}

.title-programacao-not {
  color: var(--primary-color);
}

@media (max-width: 1199px) {
  .data-text-evento {
    font-size: 1rem;
  }

  .btn-programacao {
    font-size: 0.8rem;
  }
}

@media (max-width: 991px) {

  .horarios-card.card-2 {
    margin-top: 20px;
  }

  .data-text-evento {
    font-size: 0.8rem;
  }

  .logo-assistir {
    padding-left: 0px;
  }

  .btn-programacao {
    font-size: 0.8rem;
  }

  .box-texto h2, .linha-noticia{
    text-align: center !important;
    margin: 0 auto;
    display: table;
    margin-top: 10px;
  }

  .btn-programacao {
    margin-top: 20px;
    padding: 8px 16px;
  }

  .data-evento, .data-text-evento {
    text-align: center !important;
  }

  .dates-space {
    flex-direction: column;
    gap: 16px;
    display: block !important;
    margin: 0 auto;
  }
}

@media (max-width: 780px) {
  .main-responsive {
    flex-direction: column;
  }

  .box-texto {
    margin-top: 40px;
  }

  .dates-space {
    flex-direction: row;
  }
}

@media (max-width: 660px) {
  .hist-content {
    height: 250px;
  }

  .box-texto {
    height: auto;
  }

  .horarios-card h2 {
    font-size: 1.4rem;
  }
}
</style>