<template>
  <div>
    <div class="bg-detalhe-piloto margin-row">
      <b-container v-if="piloto && !loading" data-anima="top">
        <b-row>
          <b-col cols="12" lg="4">
            <div class="carro-piloto margin-top">
              <img :src="piloto.imagemCarro" alt="Imagem de carro"/>
            </div>
          </b-col>

          <b-col cols="12" lg="4" class="txt-piloto">
            <h3 class="nome-piloto margin-top">
              {{ piloto.nome }} <strong>#{{ piloto.numero }}</strong>
            </h3>
            <div v-html="piloto.sobre" class="sobre"></div>
          </b-col>

          <b-col cols="12" lg="4">
            <div v-if="$route.params.categoria == 'formula-4'" class="resultado-piloto">
              <p>
                {{ $t("title.piloto-idade") }}: <span>{{ piloto.idade }}</span>
              </p>
              <p>
                POLES: <span>{{ piloto.poles }}</span>
              </p>
              <p>
                {{ $t("title.piloto-vitorias") }}: <span>{{ piloto.vitorias }}</span>
              </p>
              <p>
                {{ $t("title.piloto-titulos") }}: <span>{{ piloto.titulos }}</span>
              </p>
              <p>
                {{ $t("title.piloto-pontos") }}: <span>{{ piloto.pontuacao }}</span>
              </p>
              <p>
                {{ $t("title.piloto-time") }}: <span>{{ piloto.equipe }}</span>
              </p>
            </div>
            <div v-else class="resultado-piloto">
              <p>
                IDADE: <span>{{ piloto.idade }}</span>
              </p>
              <p>
                POLES: <span>{{ piloto.poles }}</span>
              </p>
              <p>
                VITÓRIAS: <span>{{ piloto.vitorias }}</span>
              </p>
              <p>
                TÍTULOS: <span>{{ piloto.titulos }}</span>
              </p>
              <p>
                PONTOS: <span>{{ piloto.pontuacao }}</span>
              </p>
              <p>
                EQUIPE: <span>{{ piloto.equipe }}</span>
              </p>
            </div>

            <img :src="piloto.imagemCapacete" class="capacete" alt="Imagem do capacete"/>
            <div class="redes-piloto" v-if="piloto.exibeInstagram">
              <p v-if="$route.params.categoria == 'formula-4'">{{ $t("title.conecte-com") + piloto.nome }}</p>
              <p v-else>Conecte-se com {{ piloto.nome }}</p>
              <a :href="piloto.instagram" target="_blank" class="instagram"></a>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container v-if="loading" data-anima="bottom">
        <b-row>
          <b-col cols="12" lg="4">
            <div class="carro-piloto margin-top">
              <b-skeleton-img></b-skeleton-img>
            </div>
          </b-col>

          <b-col cols="12" lg="4" class="txt-piloto">
            <h3 class="nome-piloto margin-top">
              <b-skeleton
                animation="wave"
                width="60%"
                class="mt-4 mb-3"
              ></b-skeleton>
            </h3>
            <div class="sobre">
              <b-skeleton
                animation="wave"
                width="60%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                width="100%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                width="40%"
                class="mt-4 mb-3"
              ></b-skeleton>
              <b-skeleton
                animation="wave"
                width="60%"
                class="mt-4 mb-3"
              ></b-skeleton>
            </div>
          </b-col>

          <b-col cols="12" lg="4">
            <div
              class="resultado-piloto"
              style="grid-template-columns: 1fr 1fr"
            >
              <p>
                <b-skeleton
                  animation="wave"
                  width="100%"
                  class="mt-4 mb-3"
                ></b-skeleton>
              </p>
              <p>
                <b-skeleton
                  animation="wave"
                  width="100%"
                  class="mt-4 mb-3"
                ></b-skeleton>
              </p>
              <p>
                <b-skeleton
                  animation="wave"
                  width="100%"
                  class="mt-4 mb-3"
                ></b-skeleton>
              </p>
              <p>
                <b-skeleton
                  animation="wave"
                  width="100%"
                  class="mt-4 mb-3"
                ></b-skeleton>
              </p>
              <p>
                <b-skeleton
                  animation="wave"
                  width="100%"
                  class="mt-4 mb-3"
                ></b-skeleton>
              </p>
              <p>
                <b-skeleton
                  animation="wave"
                  width="100%"
                  class="mt-4 mb-3"
                ></b-skeleton>
              </p>
            </div>

            <b-skeleton-img></b-skeleton-img>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div v-if="!loading && piloto">
      <GraficoPiloto :dadosGrafico="dadosGrafico" />

      <div>
        <div class="mt-5 mb-3">
          <b-col class="titulo-noticia titulo-centralizado">
            <hr class="linha-titulo" />
            <h1 v-if="$route.params.categoria == 'formula-4'" class="titulo"><strong>{{ $t("title.ultimacorrida") }}</strong> {{ $t("title.noticia") }}</h1>
            <h1 v-else class="titulo"><strong>ÚLTIMAS</strong> NOTÍCIAS</h1>
            <hr class="linha-titulo" />
          </b-col>
        </div>
        <Noticias :sections="sections" :noticias_piloto="piloto.noticias" />
      </div>
    </div>
  </div>
</template>


<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

import Noticias from "@/components/Noticias.vue";
import GraficoPiloto from "@/components/GraficoPiloto.vue";

export default {
  name: "PilotoInterna",
  components: {
    Noticias,
    GraficoPiloto,
  },
  data() {
    return {
      piloto: null,
      dadosGrafico: null,
      loading: false,
      sections: {
        primeira: true,
        segunda: false,
        terceira: false,
      },
    };
  },

  methods: {
    graficoPiloto() {
      this.dadosGrafico = {
        labels: this.piloto.resultados.map((item) => item.circuito),
        pontos: this.piloto.resultados.map((item) => item.pontoPiloto),
      };
    },
    fetchPilotoUnico(id_categoria, id_piloto) {
      this.loading = true;
      api
        .get(`Piloto/${id_piloto}/categoria/${id_categoria}`)
        .then((resp) => {
          this.piloto = resp.data;
          document.title = `Pilotos | ${this.piloto.nome}`;
          this.graficoPiloto();
          if (this.piloto) {
            setTimeout(() => {
              const bg = document.querySelector(".bg-detalhe-piloto");
              bg.style.backgroundImage = `url('${this.piloto.background}')`;
            }, 100);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchPilotoUnico(
      mapCategorias[this.$route.params.categoria || "proseries"],
      this.$route.params.id
    );
  },
};
</script>

<style scoped>
.carro-piloto img {
  width: 350px;
}

.nome-piloto {
  font-family: "MetronicPro-SemiBold";
  font-size: 30px;
  color: var(--title-color);
}

.nome-piloto strong {
  font-style: italic;
  font-size: 60px;
  font-family: "Uniwars-W00-Heavy-Italick";
  color: var(--primary-color);
}

.bg-detalhe-piloto p {
  color: var(--title-color);
}

.txt-piloto p {
  margin-top: 30px;
}

.resultado-piloto {
  margin-top: 65px;
  display: grid;
  justify-content: center;
}

.resultado-piloto p,
.resultado-piloto span {
  font-family: "MetronicPro-Black";
  font-size: 17px;
  color: var(--title-color);
}

.resultado-piloto span {
  color: var(--primary-color);
}

.capacete {
  display: grid;
  margin: 0 auto;
  width: 300px;
}

.redes-piloto p {
  color: var(--title-color);
  text-align: center;
}

.redes-piloto img {
  margin: 0 auto;
  display: flex;
}
.sobre {
  color: var(--title-color) !important;
}
</style>