<template>
  <div v-if="proxima_etapa" data-anima="bottom">
    <h4>{{ proxima_etapa.dataDia }}/{{ proxima_etapa.dataMes }}</h4>
    <p>{{ proxima_etapa.circuito }}</p>
    <span>{{ proxima_etapa.cidade }}</span>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  name: "InformacoesEtapas",
  data() {
    return {
      proxima_etapa: null,
    };
  },
  methods: {
    fetchProximaEtapa(id_categoria) {
      const localData = sessionStorage.proximaEtapa
        ? JSON.parse(sessionStorage.proximaEtapa)
        : "";

      if (!localData || localData.id_categoria != id_categoria) {
        this.loading = true;
        api
          .get(`Etapa/proxima/categoria/${id_categoria}`)
          .then((resp) => {
            this.proxima_etapa = resp.data;
            sessionStorage.proximaEtapa = JSON.stringify({
              id_categoria: id_categoria,
              etapa: this.proxima_etapa,
            });
            this.$emit("fotoCircuito", this.proxima_etapa.imagemCircuito);
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.proxima_etapa = localData.etapa;
        this.$emit("fotoCircuito", this.proxima_etapa.imagemCircuito);
      }
    },
  },
  mounted() {
    this.fetchProximaEtapa(mapCategorias["proseries"]);
  },
};
</script>


<style scoped>
.info-etapa h4 {
  font-family: "Uniwars-W00-Heavy-Italick";
  font-size: 27px;
  text-align: right;
}

.info-etapa p {
  color: var(--title-color);
  font-size: 14px;
  text-align: right;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 1px;
  margin-bottom: 0px !important;
}

.info-etapa span {
  color: #939393;
  font-size: 13px;
}

@media (max-width: 989px) {
  .info-etapa,
  .info-etapa p,
  .info-etapa h4 {
    text-align: center;
  }
}
</style>