<template>
  <section>
    <div class="main-galeao responsive-manual margin-row container">
      <div style="z-index: 10; position: relative">
        <b-row class="row-cards galeao-content" data-anima="top">
          <b-col
            cols="0"
            offset-md="1"
            offset-xl="2"
            xl="5"
            lg="5"
            md="5"
            sm="0"
            class="col-card" style="margin-left: 0px;"
          >
            <div class="box-proxima-etapa">
              <img
                class="circuito"
                src="../assets/img/buenosaires/circuito-ba.png"
                alt="Imagem do circuito"
                style="margin-bottom: -30px;"
              />

              <div class="infoData">
                <div class="local">
                  <p class="pista">GP Buenos Aires</p>
                  <p class="estado">Argentina</p>
                </div>
              </div>
            </div>
            <div class="linha"></div>
            <div class="titulo-noticia">
              Em 2023, a Stock Car voltou a correr em Buenos Aires, e pela primeira vez na história viu um piloto argentino conquistar 
              uma vitória correndo em seu país.
            </div>
          </b-col>
          <b-col cols="12" lg="4" md="5" class="col-card galeao-content">
            <img
              class="logo-buenos"
              style="margin-left: auto; max-width: 400px;"
              src="../assets/img/buenosaires/logo-buenosaires.png"
            />
          </b-col> 
        </b-row>
      </div>

      <div class="overlay-galeao bg-buenosaires"></div>
    </div>

    <div class="responsive-mobile">
      <div>
        <div class="bg-buenosaires-mobile">
          <div class="circuito-mobile">
            <img
              class="circuito"
              src="../assets/img/buenosaires/circuito-ba.png"
              alt="Imagem do circuito"
            />
          </div>

          <div class="box-proxima-etapa">
            <div class="infoData">
              <div class="local">
                <p class="pista">GP Buenos Aires</p>
                <p class="estado">Argentina</p>
              </div>
            </div>
          </div>

          <img class="logo-mobile" src="../assets/img/buenosaires/logo-buenosaires.png" />
        </div>

        <div style="padding-left: 80px; padding-right: 80px">
          <div class="linha-branca"></div>
        </div>

        <h2 class="title-main-galeao">
          Em 2023, a Stock Car voltou a correr em Buenos Aires, e pela primeira vez na história viu um piloto argentino 
          conquistar uma vitória correndo em seu país.
        </h2>
        
      </div>
    </div>

    <b-container class="bv-row noticias" data-anima="bottom">
      <b-row>
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h2 class="titulo">
            <strong><i>HISTÓRIA</i></strong>
          </h2>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>

      <b-row class="box-historia">
        <b-col
          cols="12"
          lg="6"
          class="hist-content img-hist historia-bg"
        >
        </b-col>
        <b-col cols="12" lg="6" class="hist-content box-texto">
          <div class="linha-noticia"></div>
          <h2>Argentina: paixão pela velocidade</h2>
          <p style="color: var(--text-color); margin-bottom: 30px;">
           A Stock Car já correu sete vezes no lendário Autódromo Oscar y Juan Galvez, inaugurado em 9 de março de 1952. A categoria brasileira jamais 
           teve um vencedor repetido em terras argentinas. Depois de 6 anos, a Stock Car voltou ao Autódromo Oscar y Juan Gálvez, em Buenos Aires. 
           O circuito automobilístico mais importante da Argentina recebeu a 9ª etapa da temporada 2023.
          </p>
          <router-link to="/stockcar/proseries/historia-buenosaires" style="text-decoration: none;" class="leia-mais">
            Leia mais
          </router-link>
        </b-col>
      </b-row>
    </b-container>

    <b-row class="margin-depo">
      <b-col class="titulo-centralizado">
        <hr class="linha-titulo" />
        <h2 class="titulo">
          <strong><i>DEPOIMENTOS</i></strong>
        </h2>
        <hr class="linha-titulo" />
      </b-col>
    </b-row>

    <b-row class="bg-galeao-2 bg-depoimentos">
      <b-col cols="0" lg="7" md="7"> </b-col>
      <div>
        <b-container>
          <b-row>
            <b-col cols="12" lg="7"></b-col>
            <b-col cols="12" lg="5">
              <b-carousel
                id="carousel-2"
                :interval="10000"
                controls
                indicators
                background="#e6c156"
                img-width="1024"
                img-height="580"
                style="text-shadow: 1px 1px 2px #333"
              >
                <b-carousel-slide
                  img-src="https://emailimagesnb.s3.sa-east-1.amazonaws.com/guids/CABINET_739f4e8d235fc6f33869011f2d71c7ce4decb04f715d24f2d0f33c00c953e62f/images/bgcarrossel_g12.jpg"
                >
                  <div class="img-carrossel">
                    <img
                      class="thumb-depoimento"
                      alt="Imagem do Daniel Serra"
                      src="../assets/img/buenosaires/img-depoimento.png"
                    />
                    <p>
                      <strong>Gabriel Casagrande, </strong> <br />piloto da Stock Car Pro Series<br />
                    </p>
                  </div>

                  <div class="html" id="html">
                    "Muito feliz por ter conquistado uma vitória diante do público argentino. É muito legal correr aqui, todo mundo nos conhece, 
                    pede foto. O autódromo também estava lotado. Muito obrigado a todos pela torcida."
                  </div>
                </b-carousel-slide>
                
              </b-carousel>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-row>

    <b-row class="mb-5">
      <b-col class="titulo-centralizado">
        <hr class="linha-titulo" />
        <h2 class="titulo">
          <strong><i>BUENOS AIRES</i></strong> EM NÚMEROS
        </h2>
        <hr class="linha-titulo" />
      </b-col>
    </b-row>

    <b-container>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="5" xl="5" class="primeiracoluna">
          <h2 class="title-num-galeao">Circuito</h2>

          <img class="img-estrutura" src="../assets/img/buenosaires/img-circuito.png" />

          <div class="desc-estrutura">

            <p class="title-estrutura">NOME DO AUTÓDROMO:</p>
            <p class="p-estrutura">Autódromo Oscar y Juan Gálvez</p>
            <div class="line-estrutura"></div>

            <p class="title-estrutura">PRIMEIRO ANO DA STOCK CAR:</p>
            <p class="p-estrutura">2005</p>
            <div class="line-estrutura"></div> 

            <p class="title-estrutura">EXTENSÃO COMPLETA:</p>
            <p class="p-estrutura">3.380 metros</p>
            <div class="line-estrutura"></div>

            <p class="title-estrutura">SENTIDO:</p>
            <p class="p-estrutura">
              Horário
            </p>
            <div class="line-estrutura"></div>

            <p class="title-estrutura">CURVAS:</p>
            <p class="p-estrutura">8</p>

          </div>
        </b-col>

        <b-col cols="12" sm="12" md="12" lg="2" xl="2" class="segundacoluna">
          <div class="linha-primary"></div>
        </b-col>

        <b-col cols="12" sm="12" md="12" lg="5" xl="5" class="terceiracoluna">
          <h2 class="title-num-galeao">Vencedores</h2>

          <img class="img-estrutura" src="../assets/img/buenosaires/img-vencedor.png" />

          <div class="desc-estrutura">
            <p class="title-estrutura">2023:</p>
            <p class="p-estrutura">
              Matías Rossi e Gabriel Casagrande
            </p>
            <div class="line-estrutura"></div>

            <p class="title-estrutura">2017:</p>
            <p class="p-estrutura">
              Rubens Barrichello e Felipe Fraga
            </p>
            <div class="line-estrutura"></div>

            <p class="title-estrutura">2007:</p>
            <p class="p-estrutura">
              Cacá Bueno
            </p>
            <div class="line-estrutura"></div>

            <p class="title-estrutura">2006:</p>
            <p class="p-estrutura">
              Ingo Hoffmann
            </p>
            <div class="line-estrutura"></div>

            <p class="title-estrutura">2005:</p>
            <p class="p-estrutura">
              Giuliano Losacco
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-row class="margin-depo">
      <b-col class="titulo-centralizado">
        <hr class="linha-titulo" />
        <h2 class="titulo">
          <strong><i>EVENTOS</i></strong> ESPECIAIS
        </h2>
        <hr class="linha-titulo" />
        <p class="subtitulo-eventos">Desfile dos pilotos pelas ruas de BA, City Tour</p>
      </b-col>
    </b-row>

    <b-container class="modal-image">
      <b-carousel id="carousel" :interval="15000" controls>
        <b-carousel-slide v-for="(pair, index) in imagePairs" :key="index" class="slides-videos">
          <div class="boxes-slides-videos">
            <div class="box-slide" v-for="(image, i) in pair" :key="i">
              <img :src="require(`../assets/img/buenosaires/eventos/${image.src}`)" 
              alt="Imagem do evento" 
              class="img-evento"
              @click="openModalImage(require(`../assets/img/buenosaires/eventos/${image.src}`))" />
            </div>
          </div>        
        </b-carousel-slide>
      </b-carousel>

        <b-modal ref="modal" id="imageModal" hide-footer size="xl">
          <img :src="modalImageSrc" alt="Imagem em tamanho grande" class="img-fluid">
        </b-modal>
    </b-container>
    
  
    <b-row class="margin-depo">
      <b-col class="titulo-centralizado">
        <hr class="linha-titulo" />
        <h2 class="titulo">
          <strong><i>GALERIA</i></strong> DE VIDEOS
        </h2>
        <hr class="linha-titulo" />
      </b-col>
    </b-row>

    <b-container>
      <b-carousel id="carousel" :interval="15000" controls>
        <b-carousel-slide class="slides-videos" v-for="(pair, index) in videoPairs" :key="index">
          <div class="boxes-slides-videos">
            <!-- Primeiro vídeo -->
            <div class="box-slide" v-for="(video, videoIndex) in pair" :key="videoIndex">
              <p>{{ video.date }}</p>
              <img
                :src="video.thumbnail"
                :alt="`Imagem do video ${index * 2 + videoIndex + 1}`"
                loading="lazy"
                @click="openModal(video)"
              />
              <h2>{{ video.title }}</h2>
            </div>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </b-container>

    <b-modal v-if="selectedVideo" v-model="modalOpen" @hide="closeModal" id="modal-1">
      <iframe :src="selectedVideo.url" frameborder="0" width="560" height="315"></iframe>
    </b-modal>

    <LogosHome :show_element="show_element.rodape" />
  </section>
</template>
  
<script>
import LogosHome from "@/components/LogosHome.vue";
import { BModal } from "bootstrap-vue";

export default {
  data() {
    return {
      modalImageSrc: '',
      modalOpen: false,
      selectedVideo: null,
      images: [
        { src: '1.jpg' },
        { src: '2.jpg' },
        { src: '3.jpg' },
        { src: '4.jpg' },
        { src: '5.jpg' },
        { src: '6.jpg' },
        { src: '7.jpg' },
        { src: '8.jpg' },
        { src: '9.jpg' },
        { src: '10.jpg' },
        { src: '11.jpg' },
        { src: '12.jpg' },
        { src: '13.jpg' },
        { src: '14.jpg' },
        { src: '15.jpg' },
        { src: '16.jpg' },
        { src: '18.jpg' },
        { src: '19.jpg' },
        { src: '20.jpg' },
        { src: '21.jpg' },
        { src: '22.jpg' },
        { src: '23.jpg' },
        { src: '24.jpg' },
        { src: '25.jpg' },
        { src: '26.jpg' },
        { src: '27.jpg' },
        { src: '28.jpg' },
        { src: '29.jpg' },
        { src: '30.jpg' },
        { src: '31.jpeg' },
        { src: '32.jpeg' },
        { src: '33.jpeg' },
        { src: '34.jpeg' },
        { src: '35.jpeg' },
      ],
      videos: [
        {
          date: "17 out 2023",
          thumbnail: "https://i.ytimg.com/vi_webp/E9japImM4k4/maxresdefault.webp",
          title: "STOCK CAR 2023 | LA FIESTA EN DETALLE! O CLIP SLOW DA ETAPA 9, EM BUENOS AIRES",
          url: "https://www.youtube.com/embed/E9japImM4k4?si=NaEKpSNoNRlMreIr",
        },
        {
          date: "9 out 2023",
          thumbnail: "https://i.ytimg.com/vi_webp/VtGrci9OEs0/maxresdefault.webp",
          title: "STOCK CAR 2023 | RESUMO DA ETAPA 9, GP BUENOS AIRES",
          url: "https://www.youtube.com/embed/VtGrci9OEs0?si=62u9n3fKAsksA0BM",
        },
        {
          date: "8 out 2023",
          thumbnail: "https://i.ytimg.com/vi/k9Mr6MvniVs/maxresdefault.jpg",
          title: "STOCK CAR 2023 | HIGHLIGHTS CORRIDA 2, ETAPA 09 | GP BUENOS AIRES, ARG",
          url: "https://www.youtube.com/embed/k9Mr6MvniVs?si=LBmpH09arhdkTAjM",
        },
         {
          date: "8 out 2023",
          thumbnail: "https://i.ytimg.com/vi/rM4ZK6mYrM0/maxresdefault.jpg",
          title: "STOCK CAR 2023 | HIGHLIGHTS CORRIDA 1, ETAPA 09 | GP BUENOS AIRES, ARG",
          url: "https://www.youtube.com/embed/rM4ZK6mYrM0?si=-mRybYRWdVG4F7D6",
        },
        {
          date: "8 out 2023",
          thumbnail: "https://i.ytimg.com/vi_webp/6ZNn8c_42Jk/maxresdefault.webp",
          title: "STOCK CAR 2023 | GABRIEL CASAGRANDE E MATÍAS ROSSI VENCEM A ETAPA 09, EM BUENOS AIRES",
          url: "https://www.youtube.com/embed/6ZNn8c_42Jk?si=RTLHgqeADyfkX5TN",
        },
        {
          date: "7 out 2023",
          thumbnail: "https://i.ytimg.com/vi_webp/PmkeqiBuvQU/maxresdefault.webp",
          title: "STOCK CAR 2023 | UM TOUR POR BUENOS AIRES COM OS NOSSOS PILOTOS",
          url: "https://www.youtube.com/embed/PmkeqiBuvQU?si=rOg_9mRgdsBP7s3Q",
        },
       
      ],
      show_element: {
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
    };
  },
  components: {
    LogosHome,
    BModal,
  },
  computed: {
      videoPairs() {
        const pairs = [];
        for (let i = 0; i < this.videos.length; i += 2) {
          const pair = [this.videos[i]];
          if (this.videos[i + 1]) {
            pair.push(this.videos[i + 1]);
          }
          pairs.push(pair);
        }
        return pairs;
      },
      imagePairs() {
        const pairs = [];
        for (let i = 0; i < this.images.length; i += 2) {
          if (i + 1 < this.images.length) {
            pairs.push([this.images[i], this.images[i + 1]]);
          } else {
            pairs.push([this.images[i]]);
          }
        }
        return pairs;
      }
    }, 
  methods: {
    openModalImage(imageSrc) {
      this.modalImageSrc = imageSrc;
      this.$refs.modal.show();
    },
    openModal(video) {
      this.selectedVideo = video;
      this.modalOpen = true;
    },
    closeModal() {
      this.selectedVideo = null;
      this.modalOpen = false;
    },
  },

};
</script>

<style scoped>

.logo-buenos {
    top: 80px;
    position: absolute;
    margin-left: 0; 
    right: 0;
}

.leia-mais {
    transition: background-color 0.5s ease;
    border: 1px solid #0E080D;
    font-weight: bold;
    color: #0E080D;
    padding: 10px 70px;
    text-decoration: none;
    border-radius: 10px;
}

.leia-mais:hover {
  background-color: #0E080D;
  color: #EABB4E;
}

.img-evento {
  height: 270px !important;
  object-fit: cover !important;
}

.boxes-slides-videos {
    display: flex !important;
    justify-content: center  !important;
    width: 100%;
    margin: 0 auto  !important;
}

.box-slide {
    margin-top: 20px;
    text-align: left;
    width: 500px;
}
.box-slide img {
    width: 400px;
    cursor: pointer;
}
.box-slide p {
    color: #707070;
    font-size: 17px;
    font-family: "MetronicPro-Light";
    text-align: left;
}
.box-slide h2 {
    text-align: left;
    width: 400px;
    margin-top: 15px;
    font-size: 22px;
    color: #E6C156;
    font-family: "MetronicPro-Bold";
}
.subtitulo-eventos {
    color: #E6C156;
    font-size: 27px;
    font-family: "MetronicPro-SemiBold";
}
.overlay-galeao {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;
  background-blend-mode: overlay;
  background-color: var(--overlay);
}

.main-galeao {
  height: calc(100vh - 135px);
}

.galeao-content {
  width: 100% !important;
  height: calc(100vh - 230px);
  padding-top: 40px;
}

.titulo-centralizado {
  padding-top: 20px;
}

.hist-content {
  height: 420px;
}

.box-texto {
  padding: 40px !important;
  background-color: var(--primary-color);
}

.box-texto h2 {
  margin-bottom: 20px;
  font-family: "MetronicPro-Bold";
  font-size: 35px;
  line-height: 36px;
  color: var(--text-color);
}

.button-link {
  border: 1px solid #0e080d;
  text-decoration: none;
  border-radius: 10px;
  padding: 12px 100px 16px 100px;
  float: left;
  cursor: pointer;

  font-family: "MetronicPro-Semibold";
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
  margin-top: 20px;
}

.button-link::first-letter {
  text-transform: uppercase;
}

.depoimento {
  background: #e6c156 0% 0% no-repeat padding-box;
  opacity: 0.9;
  max-width: 460px;
  width: 100%;
  max-height: 480px;
  height: 100%;
}

.depoimento-space {
  display: flex;
  align-content: center;
  flex-wrap: inherit;
}

.depoimento-img-piloto {
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.img-depoimento-space {
  width: 50%;
  height: 100%;
  background-color: red;
  position: absolute;
  top: -50px;
  left: 22px;
}

.depoimento-titulo {
  color: #0e080d;
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  width: 40%;
  height: 90%;
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
}
.depoimento-titulo strong {
  color: #0e080d;
  font-family: "MetronicPro-Bold";
  font-size: 20px;
}

.depoimento-desc {
  height: 50%;
  padding-left: 32px;
  padding-right: 32px;
  text-align: left;
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  color: #0e080d;
}

.circuito {
  min-width: 150px;
  min-height: 100px;
}

.titulo-noticia {
  width: 450px;
  font-family: MetronicPro-SemiBold;
  font-size: 27px;
  color: var(--title-color);
  margin-bottom: 0px;
  margin-top: 20px;
  line-height: 32px;
  text-align: left;
}

.title-num-galeao {
  color: var(--primary-color);
}

.desc-estrutura {
  background: transparent linear-gradient(90deg, #2b262a 0%, #0e080d00 92%) 0%
    0% no-repeat padding-box;
  color: #ffffff;
  margin-top: 40px;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.title-estrutura {
  font-family: MetronicPro-Bold;
  letter-spacing: 0px;
  color: #707070;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.p-estrutura {
  font-family: MetronicPro-Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
}

.line-estrutura {
  border: 1px solid #707070;
  margin-bottom: 12px;
}

.img-estrutura {
  width: 100%;
}

.linha-branca {
  border: 1px solid #ffffff;
  margin-bottom: 40px;
  margin-top: 40px;
}

.title-main-galeao {
  color: #ffffff;
  font-family: MetronicPro-Bold;
  letter-spacing: 0px;
  font-size: 22px;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 40px;
}

.responsive-mobile {
  display: none;
}

.container-novidades .boxes-noticias .coluna-noticia {
  border-right: none;
  border-left: none;
}

.img-noticia {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.play-icon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.play-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 35px solid rgba(255, 255, 255, 0.9); /* Cor e transparência do ícone */
}

.boxes-noticias {
  padding-top: 30px;
  padding-bottom: 30px;
}

.boxes-noticias p {
  font: normal normal 300 17px/25px MetronicPro-Light;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 30px;
}

.boxes-noticias h2 {
  font: normal normal bold 22px/26px MetronicPro-Bold;
  letter-spacing: 0px;
  margin: 17px 0;
  color: var(--primary-color);
}

.boxes-noticias a,
.boxes-noticias a:hover {
  color: var(--primary-color);
}

.boxes-noticias img {
  margin-bottom: 10px;
  border: 1px solid var(--primary-color);
}

.boxes-noticias .coluna-noticia {
  padding-right: 20px;
  border-right: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  padding-left: 20px;
}

.modal-body {
  display: flex !important;
  justify-content: center !important;
}

.col-card {
  margin-bottom: 0px !important;
}

.circuito-mobile {
  display: flex;
  justify-content: center;
}

.mes {
    font-size: 15px;
}
.box-historia {
    margin-top: 40px;
}
.thumb-depoimento {
    width: 150px;
}
.margin-depo {
    margin-top: 60px;
    margin-bottom: 30px;
}
.bg-galeao-2 .img-carrossel {
    margin-top: 25px;
}
.subtitulo-numeros {
    color: #E6C156;
    font-size: 24px;
    font-family: "UniwarsW00Regular";
}
@media (max-width: 991px) {
    .subtitulo-numeros, .subtitulo-eventos {
        font-size: 16px;
    }
    .boxes-slides-videos {
        gap: 15px;
    }
    .box-slide img, .box-slide {
        width: 100%;
    }
    .box-slide h2 {
        width: 100% !important;
        font-size: 14px;
    }
   .margin-depo {
        margin-top: 20px;
        margin-bottom: 0px;
   }
  .linha-primary {
    border: 1px solid #e6c156;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .hist-content {
    height: auto;
  }

  .button-link {
    padding: 12px 16px;
  }

  .historia-bg {
    background-position: center;
  }

  .button-link {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .responsive-manual {
    display: none;
  }

  .responsive-mobile {
    display: initial;
  }

  .logo-mobile {
    position: absolute;
    left: 50%;
    max-width: 264px;
    transform: translate(-50%, -50%);
    bottom: -248px;
  }

  .img-hist {
    height: 244px;
  }
}

@media (max-height: 660px) {
  .main-galeao {
    height: 78vh;
  }
}

.row-cards {
    margin-left: 0px !important;
}

@media (min-width: 991px) {
  .primeiracoluna, .terceiracoluna {
    width: 45%;
  }

  .segundacoluna {
    width: 10%;
  }
}
</style>
  