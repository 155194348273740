<template>
  <section>

    <div class="loading_global" v-if="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </div>

    <div v-else>
        <div class="faixa-tempo">
            <div class="container d-flex conteudo-faixa">
                <div class="d-flex box-tempo">
                    <img alt="Ícone de relógio" src="@/assets/img/relogio.svg" />
                    <p id="countdown">{{ days }}d : {{ hours }}h : {{ minutes }}m : {{ seconds }}s</p>
                </div>
                <div class="btn-votar">   
                    <button type="button" class="votar-agora" @click="votar">VOTAR AGORA</button>
                </div>
            </div>
        </div>
        <div class="bg-votacao">
            <div class="container">
                <div class="selo esconder-desktop">
                    <img alt="Selo snapdragon" src="@/assets/img/selomobile.png" class="img-selo" />
                </div>
                <div class="d-flex conteudo-bg">
                    <div class="titulo-live d-flex">
                        <div>
                            <h2>Vivo fan push by snapdragon</h2>
                            <h3>VOTAÇÃO EM TEMPO REAL</h3>
                        </div>

                        <div class="d-flex atualizar" >
                            <img alt="Ícone de seta" src="@/assets/img/seta.svg" class="seta" @click="atualizarDataHora"/>
                            <div>
                                <p>{{ hora }}</p>
                                <p>{{ data }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="selo esconder-mobile">
                        <img alt="Selo snapdragon" src="@/assets/img/selomobile.png" class="img-selo" />
                    </div>
                </div>
            </div>
            
        </div>

        <div>
            <b-container class="primeiros">
                <!----------- OS PRIMEIROS 6 --------------->
                <b-row >
                    <b-col cols="12" lg="4" class="box-col" v-for="(item, index) in pilotos.slice(0, 6)" :key="index">
                        <div class="d-flex box-live">
                            <div class="piloto">
                                <img alt="Imagem do piloto" :src="item.imagemB" class="piloto-img" />
                                <p class="posicao-votacao">{{ index + 1 }}</p>
                            </div>

                            <div class="progresso">
                                <h4> <span>{{ item.nome.split(' ')[0] }} </span> <strong>{{ item.nome.split(' ')[1] }}</strong> </h4>
                                <p v-if="item.votos == '0'">{{ item.votos }}%</p>
                                <p v-else>{{ item.votos.toFixed(2) }}%</p>
                                <b-progress :value="item.votos" max="100" show-progress></b-progress>
                            </div>
                        </div>
                    </b-col>

                </b-row>
                
                <!------------ RESTANTE ------------>

                <b-row class="row-lista restante">
                    <b-col cols="12" lg="3" v-for="(item, index) in pilotos.slice(6, 24)" :key="index" >
                        <div class="d-flex box-live-lista">
                            <div class="piloto">
                                <p class="posicao-lista">{{ index + 7 }}</p>
                            </div>

                            <div class="progresso progresso-lista">
                                <h4 class="h4lista"><span>{{ item.nome.split(' ')[0] }} </span> <strong>{{ item.nome.split(' ')[1] }}</strong></h4>
                                <p class="pprogresso" v-if="item.votos == '0'">{{ item.votos }}%</p>
                                <p class="pprogresso" v-else>{{ item.votos.toFixed(2) }}%</p>
                                <b-progress :value="item.votos" max="100" show-progress></b-progress>
                            </div>
                        </div>
                    </b-col>
    
                </b-row>

            </b-container>
        </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "VotacaoTempoReal",
  data() {
    return {
      loading: false,
      votacao: [],
      pilotos: [],
      hora: '',
      data: '',
      countdown: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0, 
      data_votacao: "2024-05-04T11:50:00",
      carregar: false
    };
  },
  methods: {
    votar() {
        this.$router.push("/stockclub/votacao-fanpush");
    },

    fetchVotacao() {
      if(!this.carregar) {
        this.loading = true;
      }

      api
        .get('/fanpush/votacao')
        .then((resp) => {
            this.votacao = resp.data;
            this.pilotos = resp.data.pilotos;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    iniciarAtualizacaoAutomatica() {
        this.timer = setInterval(() => {
            this.carregar = true;
        this.fetchVotacao();
        this.atualizarDataHoraAutomatica();
        }, 20000); 
    },

    salvarUltimaAtualizacao() {
        localStorage.setItem('ultimaAtualizacao', JSON.stringify({ hora: this.hora, data: this.data }));
    },

    carregarUltimaAtualizacao() {
        const ultimaAtualizacao = JSON.parse(localStorage.getItem('ultimaAtualizacao'));
        if (ultimaAtualizacao) {
        this.hora = ultimaAtualizacao.hora;
        this.data = ultimaAtualizacao.data;
        }
    },

    atualizarDataHora() {
        this.loading = true;
        const agora = new Date();
        this.hora = agora.toLocaleTimeString('pt-BR');
        this.data = agora.toLocaleDateString('pt-BR');
        this.salvarUltimaAtualizacao(); 
        this.fetchVotacao(); 
    },

    atualizarDataHoraAutomatica() {
        const agora = new Date();
        this.hora = agora.toLocaleTimeString('pt-BR');
        this.data = agora.toLocaleDateString('pt-BR');
        this.salvarUltimaAtualizacao(); 
        this.fetchVotacao(); 
    },

  },
    created() {
        this.carregarUltimaAtualizacao();
        this.atualizarDataHora();

        this.countdown = setInterval(() => {
        const now = new Date().getTime();
        
        const endTime = new Date(this.votacao.dataFimVotacao).getTime();
        
        if (isNaN(endTime)) {

            clearInterval(this.countdown);
            this.days = 0;
            this.hours = 0;
            this.minutes = 0;
            this.seconds = 0;
        } else {
            const difference = endTime - now;
            
            if (difference <= 0) {
                clearInterval(this.countdown);
                this.days = 0;
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
            } else {
                this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
                this.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
            }
        }
    }, 1000);
  },
   beforeDestroy() {
    clearInterval(this.countdown);
  },
  mounted() { 
    this.fetchVotacao();
    this.iniciarAtualizacaoAutomatica();
  }
};
</script>
<style scoped>
.faixa-tempo {
    background-color: #E6C156;
    height: 36px;
    padding: 5px 0px;
}

.faixa-tempo p {
    margin-bottom: 0px;
    color: black;
    font-size: 16px;
    font-family: "MetronicPro-SemiBold";
}

.piloto {
    position: relative;
}

.row-lista {
    margin-top: 100px;
}

.box-live {
    margin-bottom: 20px;
    align-items: center;
    gap: 5px;
}

.box-live-lista {
    margin-bottom: 40px;
    align-items: baseline;
    gap: 15px;
}

.posicao-lista {
    font-family: "Uniwars-W00-SemiBold";
    font-style: italic;
    color: #F7B04D;
    font-size: 23px;
}

.posicao-votacao {
    top: 10px;
    left: -12px;
    position: absolute;
    font-family: "Uniwars-W00-SemiBold";
    font-style: italic;
    color: #F7B04D;
    font-size: 30px;
}

.piloto-img {
    width: 132px;
}

.progresso h4 {
    color: white;
    font-family: "MetronicPro-Light";
    font-size: 24px;
    margin-bottom: 0px;
}

.progresso-lista {
    width: 100%;
}

.pprogresso  {
    font-size: 23px !important;
}

.h4lista {
    font-size: 18px !important;
}

.progresso strong {
    font-family: "MetronicPro-Black";
}

.progresso p {
    margin-bottom: 0px;
    font-family: "Uniwars-W00-Heavy-Italick";
    color: #E6C156;
    font-size: 30px;
}

.seta {
    cursor: pointer;
}

.box-tempo {
    top: -2px !important;
    position: relative;
    gap: 10px;
    align-items: end;
}

.conteudo-faixa {
    justify-content: space-between;
}

.votar-agora {
    border: none;
    font-family: "MetronicPro-Bold";
    background-color: white;
    color: #542283;
    font-size: 11px;
    height: 30px;
    width: 150px;
}

.votar-agora:hover {
    background-color: #542283;
    color: #fff;
}

.btn-votar {
    margin-top: -2px;
}

.bg-votacao {
  background-image: url(../assets/img/bgvotacao.png);
  background-size: cover;
  height: 350px;
  background-repeat: no-repeat;
  background-position: top;
}

.img-selo {
    width: 188px;
}

.conteudo-bg {
    justify-content: space-between;
    align-items: end;
    height: 300px;
}

.titulo-live h2 {
    font-family: "Uniwars-W00-SemiBold";
    color: white;
    font-size: 25px;
    font-style: italic;
    margin-bottom: 0px;
}

.titulo-live h3 {
    margin-bottom: 0px;
    color: #E6C156;
    font-size: 35px;
    font-style: italic;
    font-family: "Uniwars-W00-Heavy-Italick";
}

.titulo-live {
    gap: 20px;
    align-items: end;
}

.atualizar p {
    color: white;
    font-style: italic;
    margin-bottom: 0px;
    font-size: 15px;
    font-family: "Uniwars-W00-SemiBold";
}

.atualizar {
    position: relative;
    top: -3px !important;
    gap: 10px;
}

.primeiros .progresso {
     width: 60% !important;
}

.restante .progresso {
     width: 100%!important;
}

@media (min-width: 1500px) {
    .bg-votacao {
        height: 450px;
    }

    .conteudo-bg {
        height: 350px;
    }

}

@media (max-width: 990px) {
    .votar-agora {
        height: 38px;
        padding-top: 5px;
    }

    .faixa-tempo, .conteudo-faixa {
        padding: 0px;
    }

    .box-tempo {
        gap: 10px;
        align-items: center;
        margin-left: 20px;
        margin-top: 5px;
    }

    .faixa-tempo p {
        font-size: 13px;
    }

    .esconder-mobile {
        display: none;
    }

    .bg-votacao {
        background-image: url(../assets/img/bgmobilelive.png);
        background-size: cover;
        height: 300px;
    }

    .atualizar p {
        font-size: 11px;
    }

    .progresso h4 {
        font-size: 18px;
    }

    .progresso p {
        font-size: 23px;
    }

    .titulo-live {
        padding: 0px 5px;
        gap: 5px;
    }

    .titulo-live h2 {
        font-size: 15px;
    }

    .titulo-live h3 {
        font-size: 18px;
    }

    .conteudo-bg {
        height: 150px;
    }

    .row-lista {
        margin-top: 0px;
    }

    .img-selo {
        margin-top: 20px;
    }

    .box-col {
        padding: 0px 15px 0px 25px;
    }
}

@media (min-width:991px) {
    .esconder-desktop {
        display: none;
    }
}
</style>