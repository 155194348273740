<template>
  <div>
      <TodosCircuitos />
  </div>
</template>

<script>
import TodosCircuitos from '@/components/TodosCircuitos.vue';
export default {
  components: {
    TodosCircuitos
  }
};
</script>

