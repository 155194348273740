<template>
  <div class="bg-detalhe-equipe margin-row">
    <b-container>
      <b-row v-if="!loading && equipe.idEquipe" data-anima="top">
        <b-col cols="12" lg="12">
          <div class="topo">
            <img class="img-equipe" :src="equipe.logo" alt="Logo de equipe"/>
            <div class="txtpontos" v-if="$route.params.categoria != 'stock-series'">
              <h3>{{ equipe.pontuacaoEquipe }}</h3>
              <p>pts</p>
            </div>

            <div class="carros-equipe" v-if="equipe.carros.length">
              <img alt="imagem de carri"
                v-for="item in equipe.carros"
                :key="item"
                :src="item"
                class="img-equipe"
              />
            </div>
          </div>
        </b-col>

        <b-col cols="12" lg="7" class="txt-equipe">
          <h4 v-if="$route.params.categoria == 'formula-4'">{{ $t('title.historia') }}</h4>
          <h4 v-else>História</h4>
          <div v-html="equipe.sobre" class="html"></div>
        </b-col>

        <b-col cols="12" lg="1"></b-col>

        <b-col cols="12" lg="4" class="icon-globe">
          <a :href="equipe.website" target="_blank" v-if="equipe.exibeWebsite"
            ><img src="@/assets/img/icone-globe.png" alt="Ícone de globo web" />
          </a>
        </b-col>
      </b-row>

      <b-row v-if="loading" data-anima="bottom">
        <b-col cols="12" lg="12" style="margin-top: 220px">
          <div class="topo">
            <h3><b-skeleton animation="wave" width="40%"></b-skeleton></h3>

            <div class="carros-equipe">
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
            </div>
          </div>
        </b-col>

        <b-col cols="12" lg="7" class="txt-equipe">
          <b-skeleton animation="wave" width="40%" class="mt-4"></b-skeleton>
          <b-skeleton animation="wave" width="80%" class="mt-4"></b-skeleton>
          <b-skeleton animation="wave" width="40%" class="mt-4"></b-skeleton>
          <b-skeleton animation="wave" width="100%" class="mt-4"></b-skeleton>
        </b-col>

        <b-col cols="12" lg="1"></b-col>

        <b-col cols="12" lg="4" class="icon-globe d-flex gap-4">
          <b-skeleton type="avatar"></b-skeleton>

          <b-skeleton type="avatar"></b-skeleton>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "EquipeInterna",
  props: {
    equipe: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    document.title = `Equipes | ${this.equipe.nomeEquipe}`;
  },
};
</script>

<style scoped>
.txt-equipe h4 {
  font-family: "MetronicPro-Regular";
  font-size: 30px;
  color: var(--title-color);
  margin-bottom: 20px;
}

.txt-equipe p,
.txt-equipe .html {
  color: var(--title-color) !important;
  font-size: 17px;
  line-height: 27px;
}

.icon-globe {
  padding-top: 120px;
}

.topo h3 {
  font-size: 75px;
  font-family: "Uniwars-W00-Black";
  text-align: center;
  color: var(--primary-color);
  margin-bottom: -10px;
}

.topo p {
  text-align: center;
  font-family: "MetronicPro-Regular";
  font-style: italic;
  color: var(--title-color);
  font-size: 20px;
}

.carros-equipe {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

.txt-equipe {
  padding-top: 100px;
}

.img-equipe {
  display: flex;
  margin: 0 auto;
  max-width: 300px;
}

.topo {
  margin-top: 50px;
}
</style>