<template>
  <div>
    <div v-if="!loading">
      <EquipeInterna :equipe="equipe" :loading="loading" />
    </div>

    <b-container v-if="!loading" data-anima="top">
      <b-row>
        <b-col class="titulo-pilotos titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 v-if="$route.params.categoria == 'formula-4'" class="titulo"><strong>{{ $t('title.pilotos') }}</strong></h1>
          <h1 v-else class="titulo"><strong>pilotos</strong></h1>
          <hr class="linha-titulo" />
        </b-col>
        <TodosPilotos :pilotosExterno="equipe.pilotosEquipe" />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

import EquipeInterna from "@/components/EquipeInterna.vue";
import TodosPilotos from "@/components/TodosPilotos.vue";
export default {
  name: "DetalheEquipe",
  components: {
    EquipeInterna,
    TodosPilotos,
  },

  data() {
    return {
      equipe: {
        pilotosEquipe: [],
      },
      loading: false,
    };
  },
  methods: {
    fetchEquipe(categoria_id, id_equipe) {
      this.loading = true;
      api
        .get(`Equipe/${id_equipe}/categoria/${categoria_id}`)
        .then((resp) => {
          this.equipe = resp.data;
          if (this.equipe) {
            setTimeout(() => {
              const bg = document.querySelector(".bg-detalhe-equipe");
              bg.style.backgroundImage = `url('${this.equipe.background}')`;
            }, 100);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.fetchEquipe(
      mapCategorias[this.$route.params.categoria || "proseries"],
      this.$route.params.id
    );
  },
};
</script>

