<template>
  <section>
    <section class="page-start">
        <div v-if="usuario.cpf" class="div-quiz">
          <div class="d-flex conteudo-quiz container">
              <img src="@/assets/img/quiz/logo.png" alt="Logo stock quiz" class="img-logoquiz" />
              <div>
                <h2>SABE TUDO SOBRE A STOCK CAR? </h2>
                <h3>Participe e prove que seu conhecimento é campeão!</h3>
              </div>
               <router-link to="/stockcar/proseries/hall-of-fame/stock-quiz" class="btn-comecar">COMEÇAR</router-link>
          </div>

        </div>

        <div v-else class="div-quiz">
          <div class="d-flex conteudo-quiz container">
            <img src="@/assets/img/quiz/logo.png" alt="Logo stock quiz" class="img-logoquiz" />
            <div>
              <h2>SABE TUDO SOBRE A STOCK CAR? </h2>
              <h3>Faça seu login para responder o quiz.</h3>
            </div>
            <router-link to="/stockclub/login" class="btn-comecar">LOGAR</router-link>
          </div>      
        </div>
      </section>

    <div class="titulo-hall">
      <div class="container">
        <img src="@/assets/img/halldafama.png" alt="Imagem de hall da fama" class="halldafama" />
        <p>Relembre os pilotos que se destacaram na história da categoria</p>
        <img src="@/assets/img/titulo-campeos.png" alt="Imagem do titulo de campeos" class="campeoes viewdesktop" />
        <img src="@/assets/img/campeoesmob.png" alt="Imagem do titulo de campeos" class="campeoes viewmobile" />
      </div>
    </div>

    <div class="bg-podio">

      <div class="container podio-halldafama">
        <b-row class="podio-pilotos ">
          <b-col cols="4" lg="4" class="box-podio">
            <div class="podio">
              <img class="img-vencedor" src="@/assets/img/podio2.png" alt="Imagem do piloto" />
              <p>2</p>
            </div>
            <div class="info-piloto top-podio">
              <h3>
                CACÁ <br> <strong>BUENO</strong>
              </h3>
              <span class="nome">
                <strong>5x CAMPEÃO </strong><br>2006, 2007, 2009, 2011, 2012
              </span>
            </div>
          </b-col>

          <b-col cols="4" lg="4" class="vencedor box-podio">
            <div class="podio top1">
              <img class="img-vencedor" src="@/assets/img/podio1.png" alt="Imagem do piloto" />
              <p>1</p>
            </div>
            <div class="info-piloto">
              <h3>
                INGO<br> <strong>HOFFMANN</strong>
              </h3>
              <span class="nome">
                <strong>12x CAMPEÃO </strong><br>1980, 1985, 1989, 1990, 1991, 1992, 1993, 1994, 1996, 1997, 1998, 2002
              </span>
            </div>
          </b-col>

          <b-col cols="4" lg="4" class="box-podio">
            <div class="podio">
              <img class="img-vencedor" src="@/assets/img/podio3.png" alt="Imagem do piloto" />
              <p>3</p>
            </div>
            <div class="info-piloto top-podio">
              <h3>
                PAULO <br> <strong>GOMES</strong>
              </h3>
              <span class="nome">
                <strong>4x CAMPEÃO </strong><br>1979, 1983, 1984, 1995
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-------------- CONTAINER DE CARD DOS GANHADORES -------------->
    <div class="container viewdesktop">
      <img src="@/assets/img/ganhadores.png" alt="Imagem do titulo de ganhadores" class="ganhadores viewdesktop" />
      <img src="@/assets/img/ganhadoresmob.png" alt="Imagem do titulo de ganhadores" class="ganhadores viewmobile" />
      <div class="d-flex cards-ganhadores">
        <img src="@/assets/img/campeoes/2023.png" alt="Imagem de card do ganhador" />
        <img src="@/assets/img/campeoes/2022.png" alt="Imagem de card do ganhador" />
        <img src="@/assets/img/campeoes/2021.png" alt="Imagem de card do ganhador" />
      </div>
      <div class="container">
        <b-collapse id="pilotos" class="cards-collapse">
          <img src="@/assets/img/campeoes/2020.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2019.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2018.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2017.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2016.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2015.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2014.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2013.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2012.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2011.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2010.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2009.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2008.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2007.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2006.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2005.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2004.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2003.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2002.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2001.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2000.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1999.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1998.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1997.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1996.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1995.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1994.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1993.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1992.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1991.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1990.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1989.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1988.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1987.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1986.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1985.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1984.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1983.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1982.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1981.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1980.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/1979.png" alt="Imagem de card do ganhador" />
        </b-collapse>

        <div class="mb-3">
          <a v-b-toggle href="#pilotos" @click.prevent="toggleExpanded" class="btnmais" :aria-expanded="isExpanded">
            <img :src="imageSrc" alt="Imagem de abrir mais" class="mais" />
          </a>
        </div>
      </div>
    </div>

    <div class="container viewmobile">
      <img src="@/assets/img/ganhadores.png" alt="Imagem do titulo de ganhadores" class="ganhadores viewdesktop" />
      <img src="@/assets/img/ganhadoresmob.png" alt="Imagem do titulo de ganhadores" class="ganhadores viewmobile" />
      <div class="cards-ganhadores">
        <div class="ganhadores-mobile">
          <img src="@/assets/img/campeoes/2023.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2022.png" alt="Imagem de card do ganhador" />
        </div>
        <div class="ganhadores-mobile">
          <img src="@/assets/img/campeoes/2021.png" alt="Imagem de card do ganhador" />
          <img src="@/assets/img/campeoes/2020.png" alt="Imagem de card do ganhador" />
        </div>
      </div>

      <div class="container">
        <b-collapse id="pilotos" class="cards-collapse">
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2019.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2018.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2017.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2016.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2015.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2014.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2013.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2012.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2011.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2010.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2009.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2008.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2007.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2006.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2005.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2004.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2003.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2002.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/2001.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/2000.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1999.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1998.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1997.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1996.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1995.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1994.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1993.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1992.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1991.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1990.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1989.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1988.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1987.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1986.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1985.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1984.png" alt="Imagem de card do ganhador" />
          </div>

          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1983.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1982.png" alt="Imagem de card do ganhador" />
          </div>
          <div class="ganhadores-mobile">

          </div>
          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1981.png" alt="Imagem de card do ganhador" />
            <img src="@/assets/img/campeoes/1980.png" alt="Imagem de card do ganhador" />
          </div>

          <div class="ganhadores-mobile">
            <img src="@/assets/img/campeoes/1979.png" alt="Imagem de card do ganhador" />
          </div>

        </b-collapse>

        <div class="mb-3">
          <a v-b-toggle href="#pilotos" @click.prevent="toggleExpanded" class="btnmais" :aria-expanded="isExpanded">
            <img :src="imageSrc" alt="Imagem de abrir mais" class="mais" />
          </a>
        </div>
      </div>
    </div>

    <!-------------- CONTAINER DE CARTAZES -------------->
    <div class="cartazes viewdesktop">
      <div class="container">
        <div class="d-flex box-cartazes">
          <img src="@/assets/img/Piloto-novo.png" alt="Imagem de informacoes do piloto" />
          <img src="@/assets/img/Piloto-velho.png" alt="Imagem de informacoes do piloto" class="img-velho" />
        </div>

        <div class="d-flex box-cartazes camp-cartaz">
          <img src="@/assets/img/Camp-Novo.png" alt="Imagem de informacoes do piloto" />
          <img src="@/assets/img/Camp-Velho.png" alt="Imagem de informacoes do piloto" class="img-velho-camp" />
        </div>
      </div>
    </div>

    <div class="viewmobile">
      <div>
        <img src="@/assets/img/bg-cartaz.png" alt="Imagem dos cartazes" class="cartaz-mobile" />
      </div>
    </div>

    <!-------------- CONTAINER DO TOP10 -------------->
    <div class="top10 container">
      <h2><strong>TOP 10 PILOTOS</strong> COM MAIS VITÓRIAS EM CORRIDAS</h2>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>01</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 77<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao"> Ingo Hoffmann </p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>02</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 40<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao"> Paulo Gomes </p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>03</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 40<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao"> Thiago Camilo </p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>04</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 37<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao"> Cacá Bueno </p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>05</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 37<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao"> Ricardo Maurício </p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>06</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 33<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao"> Chico Serra </p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>07</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 26<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao"> Daniel Serra </p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>08</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 22<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao"> Xandy Negrão </p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>09</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 20<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao">Rubens Barrichello </p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>10</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 19<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao">Felipe Fraga</p>
          </div>

        </div>
      </div>

      <div class="bg-linha">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>11</p>
          </div>
          <div class="div-pontos">
            <p class="pontos"> 19<span class="pts">vitórias</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao">Átila Abreu</p>
          </div>

        </div>
      </div>
    </div>

    <!-------------- CONTAINER DE CORRIDAS -------------->
    <div class="container top-corridas">
      <h2 class="viewdesktop">PILOTOS QUE ATINGIRAM A MARCA DE<br> <strong>300 E 150 CORRIDAS PELA STOCK CAR</strong>
      </h2>
      <h2 class="viewmobile">PILOTOS QUE ATINGIRAM A MARCA DE <strong>300 E 150 CORRIDAS PELA STOCK CAR</strong></h2>

      <div class="bg-linha" v-for="(corrida, index) in corridasOrdenadas" :key="index">
        <div class="classificacao-pilotos no-scroll" data-anima="bottom">
          <div class="posicao">
            <p>{{ index + 1 }}</p>
          </div>
          <div class="div-pontos corridasmobile">
            <p class="pontos"> {{ corrida.numeroCorridas }} <span class="pts">corridas</span></p>
          </div>

          <div class="info-classificacao">
            <p class="nome-classificacao"> {{ corrida.nomePiloto }} </p>
          </div>

        </div>
      </div>

      <img src="@/assets/img/300races.png" alt="Imagem de 300 corridas" class="corridas300 viewdesktop" />
      <img src="@/assets/img/300mob.png" alt="Imagem de 300 corridas" class="corridas300 viewmobile" />

      <div>
        <div class="bg-linha" v-for="(corrida, index) in corridasOrdenadasMenores" :key="index">
          <div class="classificacao-pilotos no-scroll" data-anima="bottom">
            <div class="posicao">
              <p>{{ index + 1 }}</p>
            </div>
            <div class="div-pontos corridasmobile">
              <p class="pontos"> {{ corrida.numeroCorridas }} <span class="pts">corridas</span></p>
            </div>

            <div class="info-classificacao">
              <p class="nome-classificacao"> {{ corrida.nomePiloto }} </p>
            </div>

          </div>
        </div>
      </div>

      <img src="@/assets/img/150races.png" alt="Imagem de 300 corridas" class="corridas300 viewdesktop" />
      <img src="@/assets/img/150mob.png" alt="Imagem de 300 corridas" class="corridas300 viewmobile" />
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "HallDaFama",
  data() {
    return {
      isExpanded: false,
      corridas: [],
      usuario: '',
    };
  },
  computed: {
    imageSrc() {
      return this.isExpanded ? require('@/assets/img/btnmenos.png') : require('@/assets/img/btnmais.png');
    },
    corridasFiltradas() {
      return this.corridas.filter(corrida => corrida.numeroCorridas >= 300);
    },
    corridasFiltradasMenores() {
      return this.corridas.filter(corrida => corrida.numeroCorridas < 300);
    },

    corridasOrdenadasMenores() {
      return [...this.corridasFiltradasMenores].sort((a, b) => b.numeroCorridas - a.numeroCorridas);
    },

    corridasOrdenadas() {
      return [...this.corridasFiltradas].sort((a, b) => b.numeroCorridas - a.numeroCorridas);
    },
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },

    fetchHall() {
      this.loading = true;
      api
        .get("/HallOfFame")
        .then((resp) => {
          this.corridas = resp.data;
          console.log(this.corridas)
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchHall();
    this.usuario = localStorage.user
      ? JSON.parse(localStorage.user)
      : this.$store.state
        ? this.$store.state.user
        : null;

  },
};
</script>

<style scoped>
.halldafama {
  margin: auto;
  display: block;
  padding: 50px 0px;
}

.titulo-hall p {
  color: #FFFFFF;
  font-size: 24px;
  font-family: "MetronicPro-Regular";
  text-align: center;
  margin-top: 20px;
}

.campeoes {
  margin: auto;
  display: block;
  padding: 50px 0px 130px 0px;
}

.ganhadores {
  margin: auto;
  display: block;
  padding: 0px;
}

.img-podio {
  width: 120px;
}

.podio {
  display: flex;
}

.podio p {
  font-size: 72px;
  color: var(--primary-color);
  font-family: "Uniwars-W00-Black";
  margin-left: -80px;
}

.top1 p {
  margin-left: -60px !important;
}

.podio-pilotos h3 {
  font-size: 28px;
  color: var(--primary-color);
  font-family: "MetronicPro-SemiBold";
}

.podio-pilotos h3 strong {
  font-family: "MetronicPro-Black" !important;
}

.info-piloto {
  position: relative;
  top: -50px;
  padding: 0px 20px;
}

.top-podio {
  top: -125px !important;
}

.podio-pilotos .nome {
  font-size: 24px;
  line-height: 28px;
  font-family: "MetronicPro-Regular";
  color: var(--title-color);
}

.podio-pilotos .nome strong {
  font-family: "MetronicPro-Black" !important;
}

.bg-podio {
  background-image: url(../assets/img/bg-hall.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.cartazes {
  background-image: url(../assets/img/bg-jovem_velho.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}
/*
.titulo-hall {
  background-image: url(../assets/img/bg-titulo-hall.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}*/

.podio-halldafama {
  width: 1000px;
}

.cards-ganhadores {
  gap: 10px 0px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px 0px 10px 0px;
}

.cards-collapse {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.cards-collapse img {
  margin-bottom: 10px;
}

.mais {
  margin: auto;
  display: block;
  cursor: pointer;
  padding-top: 30px;
}

.box-cartazes {
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.box-cartazes img {
  width: 450px;
}

.img-velho {
  margin-top: 80px;
}

.camp-cartaz {
  margin-top: -70px !important;
}

.img-velho-camp {
  margin-top: 160px;
}

.top10 h2,
.top-corridas h2 {
  color: #FFFFFF;
  font-size: 33px;
  font-family: "Uniwars-W00-Black";
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.top10 strong,
.top-corridas strong {
  font-family: "Uniwars-W00-Black";
  color: #EABB4E;
}

.top-corridas h2 {
  margin: 80px 0px 30px 0px;
  font-family: "UniwarsW00Regular";
  font-size: 36px;
}

.pts {
  font-family: "Uniwars-W00-Black";
  font-size: 24px;
}

.pontos {
  margin-bottom: 0px;
}

.posicao p {
  font-family: "Uniwars-W00-Black";
  text-align: center;
  color: #707070;
  font-size: 22px;
  margin-bottom: 0px !important;
  width: 40px;
}

.top-corridas .div-pontos {
  width: 380px;
}

.nome-classificacao {
  font-size: 28px;
  text-transform: uppercase;
  font-family: "UniwarsW00Regular";
}

.div-pontos {
  width: 300px;
}

.corridas300 {
  width: 100%;
  margin: 60px 0px 60px 0px;
}

@media (min-width: 991px) {
  .viewmobile {
    display: none;
  }
}

@media (max-width: 1400px) {
  .page-start h2 {
    letter-spacing: 3px !important;
    font-size: 22px !important;
  }

  .page-start h3 {
    font-size: 19px !important;
  }
}

@media (max-width: 990px) {

  .page-start h2, .conteudo-quiz h2 {
    letter-spacing: 2px !important;
    font-size: 18px !important;
    margin-top: 0px !important;
    text-align: center !important;
    margin-bottom: 10px !important;
  }

  .page-start h3, .conteudo-quiz h3{
    text-align: center !important;
    font-size: 17px !important;
    line-height: 22px;
  }

  .conteudo-quiz {
    justify-content: center !important;
  }

  .btn-comecar {
    margin-bottom: 20px !important;
    margin: auto;
    padding: 10px 0px !important;
    font-size: 18px !important;
    letter-spacing: 3px !important;
    width: 100% !important;
  }

   .conteudo-quiz {
    display: grid !important;
  }

  .img-logoquiz {
    margin: auto !important;
    padding: 20px !important;
  }

  .corridasmobile {
    width: 160px !important;
  }

  .top-corridas h2 {
    margin: 50px 0px 30px 0px;
  }

  .mais {
    width: 50px;
  }

  .cartaz-mobile {
    width: 100%;
  }

  .ganhadores-mobile {
    justify-content: center;
    gap: 10px;
    display: flex;
    margin-bottom: 10px;
  }

  .cards-ganhadores img,
  .ganhadores-mobile img {
    width: 160px;
    height: 100%;
  }

  .top-corridas h2,
  .top-corridas strong {
    font-size: 15px;
    font-family: "UniwarsW00Regular";
  }

  .corridas300 {
    width: 100%;
    margin: 30px 0px 30px 0px;
  }

  .pts {
    font-size: 13px;
  }

  .posicao p {
    font-size: 14px;
  }

  .div-pontos {
    width: 130px;
  }

  .nome-classificacao {
    font-size: 12px;
  }

  .pontos {
    margin-bottom: 0px;
  }

  .classificacao-pilotos {
    margin-left: 0px;
  }

  .top10 h2 {
    font-family: "Uniwars-W00-SemiBold";
    font-size: 24px;
  }

  .viewdesktop {
    display: none;
  }

  .podio-halldafama,
  .halldafama,
  .campeoes,
  .ganhadores {
    width: 100%;
  }

  .halldafama {
    padding: 20px 0px;
  }

  .titulo-hall p {
    font-size: 20px;
    line-height: 23px;
  }

  .campeoes {
    padding: 20px 0px 20px 0px;
  }

  .podio p {
    font-size: 30px;
    margin-left: -45px;
  }

  .podio-pilotos .nome {
    text-align: left !important;
    font-size: 14px;
    line-height: 18px;
  }

  .podio-pilotos h3 {
    font-size: 17px;
    text-align: left !important;
  }

  .top-podio {
    text-align: left;
    padding: 0px 10px 0px 10px !important;
    top: -45px !important;
  }

  .top1 p {
    margin-left: 0px !important;
  }

  .podio-pilotos .podio {
    margin-left: 0px !important;
  }

  .info-piloto {
    position: relative;
    top: -45px;
    padding: 0px 10px;
    text-align: leftc;
  }

}


.page-start {
    background-image: url('@/assets/img/quiz/fundo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.page-start h2 {
    font-family: "MetronicPro-Bold";
    letter-spacing: 4.8px;
    font-size: 24px;
    color: #F5A437;
    text-transform: uppercase;
    text-align: left;
    margin: 20px 0px 0px 0px;
}

.page-start h3 {
    font-family: "MetronicPro-Light";
    color: #ffffff;
    font-size: 20px;
    text-align: left;
    text-transform: uppercase;
}

.img-logoquiz {
  padding: 50px 0px;
  width: 230px;
}

.conteudo-quiz {
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.btn-comecar {
    font-family: "MetronicPro-Bold";
    padding: 15px 0px;
    text-align: center;
    width: 320px;
    letter-spacing: 4.8px;
    border-radius: 7px;
    color: #FFFFFF;
    text-transform: uppercase;
    border: 2px solid #F7B04D;
    text-decoration: none;
    font-size: 23px;
}

.btn-comecar:hover {
    background-color: #F7B04D;
}

</style>