<template>
    <section>
        <b-container v-if="!loading" data-anima="top">
            <div v-if="this.categoriaRelacionada">
                <img class="linha-curvada linha-cor" :src="require(`@/assets/img/linha/${getLinha()}`)"
                    :class="getClass()">
                <img class="linha-curvada-mob linha-cor" :src="require(`@/assets/img/linha/${getLinhaMob()}`)"
                    :class="getClass()">
                <div class="titulo-noticias">
                    <img :src="getLogo()" />
                    <h3> {{ getTitulo() }}</h3>
                </div>
                <b-row class="box-noticia" :class="getClass()">
                    <b-col v-if="$route.name != 'Novidades'" cols="12" lg="6" class="coluna-noticia noticias-linha">
                        <router-link v-for="item in noticias" :key="item.id"
                            :to="`/stockcar/${categoriaRelacionada || 'proseries'}/noticia/${item.id}`">

                            <div class="d-flex noticias-direita flex-column" style="padding:5px">
                                <img alt="imagem da notícia" class="img-noticia img-lateral" :src="item.imagemNoticia"
                                    loading="lazy" width="235" />

                                <div class="info-noticias-laterais">
                                    <h3>{{ item.titulo }}</h3>
                                </div>
                            </div>
                        </router-link>
                    </b-col>
                </b-row>
                <div class="link-router">
                    <router-link :to="`/stockcar/${this.categoriaRelacionada}/novidade`" class="btn-stock"
                        :class="getClass()">Todas
                        as notícias</router-link>
                </div>
            </div>

            <Notificacao :notificacao="notificacao_infos" v-if="notificacao_infos.count_timer"
                @atualizaNotificacao="notificacao_infos.count_timer = 0" />
        </b-container>

        <b-container v-if="loading" data-anima="bottom">
            <div>
                <b-row class="box-noticia">
                    <b-col cols="12" lg="7" class="box-img">
                        <b-skeleton-img></b-skeleton-img>
                    </b-col>
                    <b-col cols="12" lg="5" class="box-texto">
                        <div class="linha-noticia"></div>
                        <h2><b-skeleton animation="wave" width="70%"></b-skeleton></h2>
                        <p><b-skeleton animation="wave" width="20%"></b-skeleton></p>
                        <p>
                            <b-skeleton animation="wave" width="40%"></b-skeleton>
                            <b-skeleton animation="wave" width="100%"></b-skeleton>
                            <b-skeleton animation="wave" width="80%"></b-skeleton>
                        </p>
                    </b-col>
                </b-row>
            </div>

            <div>
                <b-row class="boxes-noticias">
                    <b-col cols="12" lg="4" class="coluna-noticia" data-anima="bottom">
                        <p><b-skeleton animation="wave" width="20%"></b-skeleton></p>
                        <b-skeleton-img></b-skeleton-img>
                        <h2><b-skeleton animation="wave" width="70%"></b-skeleton></h2>
                    </b-col>
                    <b-col cols="12" lg="4" class="coluna-noticia" data-anima="bottom">
                        <p><b-skeleton animation="wave" width="20%"></b-skeleton></p>
                        <b-skeleton-img></b-skeleton-img>
                        <h2><b-skeleton animation="wave" width="70%"></b-skeleton></h2>
                    </b-col>
                    <b-col cols="12" lg="4" class="coluna-noticia" data-anima="bottom">
                        <p><b-skeleton animation="wave" width="20%"></b-skeleton></p>
                        <b-skeleton-img></b-skeleton-img>
                        <h2><b-skeleton animation="wave" width="70%"></b-skeleton></h2>
                    </b-col>
                </b-row>
            </div>
        </b-container>
    </section>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";
import { EventBus } from "@/main.js";

export default {
    name: "NoticiasRelacionadas",
    data() {
        return {
            tagSelecionada: '',
            banners: [],
            disabledBtn: false,
            paginacao: {
                tamanhoPagina: 10,
                numeroPagina: 0,
            },
            categoriaPesquisa: "",
            categoriaRelacionada: "",
            loading: false,
            noticias: [],
            noticia_principal: null,
            noticia_quatro: null,
            notificacao_infos: {
                variant: "primary",
                mensagem: "",
                count_timer: 0,
            },
        };
    },
    props: {
        sections: {
            type: Object,
            default: () => { },
        },
        ano_selecionado: {
            type: [String][Number],
            default: new Date().getFullYear().toString(),
        },
        bloco: {
            type: Number,
            default: 1
        }
    },
    filters: {
        dateFormat(date) {
            return new Date(date).toLocaleDateString();
        },
    },

    methods: {
        getTagSelecionada() {
            switch (this.categoriaRelacionada) {
                case "stockseries": this.tagSelecionada = 'RumoàStockCar'; break;
                case "turismo-nacional": this.tagSelecionada = 'DaTNparaAStockSeries'; break;
                case "estock": this.tagSelecionada = 'StockCarVirtual'; break;
                default: ''
            }

        },
        getLinha() {
            switch (this.categoriaRelacionada) {
                case "stockseries": return 'azul-desk.svg'
                case "turismo-nacional": return 'verde-desk.svg'
                case "estock": return 'roxo-desk.svg'
                case "proseries": return 'azul-desk.svg'
                default: return 'azul-desk.svg'
            }
        },
        getLinhaMob() {
            switch (this.categoriaRelacionada) {
                case "stockseries": return 'azul-mob.svg'
                case "turismo-nacional": return 'verde-mob.svg'
                case "estock": return 'roxo-mob.png'
                case "proseries": return 'azul-mob.svg'
                default: return 'azul-mob.svg'
            }
        },
        getLogo() {
            switch (this.categoriaRelacionada) {
                case "stockseries": return 'https://imgstock.stockproseries.com.br/categoria/cbfa67fa-972d-4fd3-896e-13385a26e185_stockcar_series_negativo.png'
                case "turismo-nacional": return 'https://imgstock.stockproseries.com.br/categoria/3857701f-e081-447d-9d3a-f53dcea39879_Turismo_Nacional_negativo.png'
                case "estock": return "https://imgstock.stockproseries.com.br/categoria/da2df084-443e-4dbd-8804-f621346d1736_estock-logo-180px.png"
                case "proseries": return 'https://imgstock.stockproseries.com.br/categoria/bfed52ff-4961-4430-885a-d54fa5edc4bc_brb_stock180x70.png'
                default: return 'https://imgstock.stockproseries.com.br/categoria/bfed52ff-4961-4430-885a-d54fa5edc4bc_brb_stock180x70.png'
            }
        },
        getTitulo() {
            switch (this.categoriaRelacionada) {
                case "stockseries": return 'RUMO À PRO SERIES'
                case "turismo-nacional": return 'RUMO À STOCK SERIES'
                case "estock": return 'CORRIDA VIRTUAL'
                case "proseries": return 'NOTÍCIAS RELACIONADAS'
                default: return 'NOTÍCIAS RELACIONADAS'
            }
        },
        getClass() {
            switch (this.categoriaRelacionada) {
                case "stockseries": return 'series'
                case "turismo-nacional": return 'turismo'
                case "estock": return 'estock'
                case "proseries": return 'proseries'
                default: return 'proseries'
            }
        },
        quebraTexto(texto) {
            if (this.$route.params.categoria == "formula-4") {
                return (
                    texto.substr(0, 200) +
                    `... <br> <br> <p>${this.$t("button.ver-mais")}</p>`
                );
            } else {
                return texto.substr(0, 200) + "... <br> <br> Ver mais";
            }
        },
        checkRoute(categoria) {
            this.noticias = [];

            switch (categoria) {
                case "proseries": this.categoriaRelacionada = (this.bloco == 1 ? "stockseries" : "estock"); break;
                case "stock-series": this.categoriaRelacionada = "turismo-nacional"; break;
                default: this.categoriaRelacionada = "proseries"; break;
            }
            this.getTagSelecionada();
            this.fetchNoticias(mapCategorias[this.categoriaRelacionada || "proseries"]);
        },
        fetchNoticias(id_categoria) {
            this.categoriaPesquisa = id_categoria;
            if (id_categoria) {
                const query = `?tamanhoPagina=${4}&numeroPagina=${1}&busca=${this.tagSelecionada}`;
                this.loading = true;
                api
                    .get(`/Noticias/categoria/${id_categoria}${query}`)
                    .then((resp) => {
                        if (resp.data.resultado.length) {
                            this.disabledBtn = false;
                            resp.data.resultado.forEach((item) => {
                                this.noticias.push(item);
                            });
                            const noticias_array = JSON.parse(JSON.stringify(this.noticias[0]));

                            this.noticia_principal = noticias_array;
                            if (this.$route.name == "Home") {
                                this.noticia_quatro = this.noticias[4];

                                this.noticias = this.noticias.filter((item, index) => {
                                    if (index < 4) {
                                        return {
                                            ...item,
                                        };
                                    }
                                });
                            }

                            if (this.noticias.length >= 1) {
                                //this.noticias.splice(0, 1);
                            }

                            this.paginacao = {
                                tamanhoPagina: resp.data.tamanhoPagina,
                                numeroPagina: resp.data.numeroPagina,
                            };
                            this.$emit("verificaNoticia", this.noticias.length);
                        } else {
                            this.notificacao_infos = {
                                variant: "primary",
                                mensagem:
                                    "Nenhuma noticia encontrada, por favor faça outra pesquisa. ",
                                count_timer: 10000,
                            };
                            this.disabledBtn = true;
                        }

                        if (resp.data.totalPaginas <= 1) {
                            this.disabledBtn = true;
                        }
                    })
                    .catch(() => {
                        this.notificacao_infos = {
                            variant: "primary",
                            mensagem:
                                "Nenhuma noticia encontrada, por favor faça outra pesquisa.",
                            count_timer: 10000,
                        };
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },

    watch: {
        pesquisa: function () {
            // watch it
            let page = {
                tamanhoPagina: 10,
                numeroPagina: 1,
            };
            let id_categoria = this.categoriaPesquisa;
            this.noticias = [];
            this.fetchNoticias(id_categoria, page);
        },
        ano_selecionado() {
            let page = {
                tamanhoPagina: 10,
                numeroPagina: 1,
            };
            this.noticias = [];
            this.fetchNoticias(this.categoriaPesquisa, page);
        },
    },

    mounted() {
        this.checkRoute(this.$route.params.categoria);
    },

    created() {
        EventBus.$on("updateRouter", (data) => {
            this.checkRoute(data);
        });
    },
};
</script>

<style scoped>
.linha-curvada {
    position: relative;
    right: calc(604px - 100%);
    top: 440px;
    margin-top: -400px;
    z-index: -9
}

.linha-curvada-mob {
    display: none;
}

.titulo-noticias {
    display: flex;
    color: #ffffff;
    margin-top: 60px;
    align-items: center;
    align-content: center;
}

.titulo-noticias img {
    margin-right: 20px;
}

.noticias-linha {
    display: flex;
    flex-direction: row;
}

a {
    text-decoration: none !important;
}

.info-noticias-laterais {
    display: grid;
    align-items: baseline;
}

.box-conferir {
    display: flex;
    margin-top: 20px;
    gap: 15px;
    align-items: center;
}

.box-conferir-lateral {
    display: flex;
    margin-top: 20px;
    gap: 15px;
    align-items: center;
}

.box-conferir-lateral img {
    margin-top: 5px !important;
    width: 20px !important;
    border: none !important;
    height: auto !important;
}

.box-conferir img {
    margin-top: -7px !important;
    width: 22px !important;
    border: none !important;
}

.conferir-lateral {
    color: var(--title-color) !important;
    font-size: 20px !important;
    text-decoration: underline !important;
    margin-bottom: 0px !important;
}

.conferir {
    color: var(--title-color) !important;
    font-size: 28px !important;
    text-decoration: underline !important;
}

.container-novidades .boxes-noticias .coluna-noticia {
    border-right: none;
    border-left: none;
}

.container-novidades .titulo-novidade {
    text-align: center;
    padding-top: 80px;
}

.img-noticia {
    height: 156px !important;
    object-fit: cover;
    width: 100%;
}

.box-img {
    padding-right: 0px !important;
    padding-left: 0px !important;
    align-items: center;
    display: flex;
}

.box-texto {
    padding: 40px !important;
    background-color: var(--primary-color);
}

.titulo-box-noticias {
    align-items: baseline;
    gap: 30px;
    margin-bottom: 10px;
}

.titulo-box-noticias p {
    margin-bottom: 0px;
    color: #707070 !important;
    font-size: 17px !important;
}

.box-noticia img {
    border: 1px solid var(--primary-color);
}

.btn-stock.series {
    border: 1px solid #00a3e1;
    color: #00a3e1;
}

.linha-cor.estock {
    filter: brightness(0) saturate(100%) invert(17%) sepia(93%) saturate(5187%) hue-rotate(308deg) brightness(80%) contrast(101%);
}

.btn-stock.estock {
    border: 1px solid #c4088b;
    color: #c4088b;
}

.btn-stock.turismo {
    border: 1px solid #00ac46;
    color: #00ac46;
}

.btn-stock.proseries {
    border: 1px solid #e6c156;
    color: #e6c156;
}

.btn-stock.series:hover {
    border: 1px solid #00a3e1;
    background-color: #00a3e1;
    color: #000000;
}

.btn-stock.estock:hover {
    border: 1px solid #c4088b;
    background-color: #c4088b;
    color: #000000;
}

.btn-stock.turismo:hover {
    border: 1px solid #00ac46;
    background-color: #00ac46;
    color: #000000;
}

.btn-stock.proseries:hover {
    border: 1px solid #e6c156;
    background-color: #e6c156;
    color: #000000;
}

.box-noticia.series img {
    border: 1px solid #00a3e1;
}

.box-noticia.estock img {
    border: 1px solid #c4088b;
}

.box-noticia.turismo img {
    border: 1px solid #00ac46;
}

.box-noticia.proseries img {
    border: 1px solid #e6c156;
}

.box-destaque-novidade {
    gap: 20px;
}

.novidade-destaque {
    width: 500px;
    height: 300px !important;
    object-fit: cover !important;

}

.img-novidades img {
    height: 200px;
    width: 300px;
}

.box-noticia {
    /* padding-top: 40px; */
    padding-bottom: 0px;
}

.box-noticia h3 {
    margin-bottom: 0px;
    color: #CFCFCF;
    font-size: 18px;
}

.titulo-f4 {
    color: #000 !important;
}

.box-noticia h2 {
    /* font-size: 28px; */
    color: var(--primary-color);
    ;
}

.box-noticia.series h3 {
    /* font-size: 28px; */
    color: #00a3e1 !important;
    ;
}

.box-noticia.turismo h3 {
    /* font-size: 28px; */
    color: #00ac46 !important;
    ;
}

.box-noticia.proseries h3 {
    /* font-size: 28px; */
    color: #e6c156 !important;
    ;
}

.box-texto h2 {
    margin-bottom: 40px;
    font-family: "MetronicPro-Bold";
    font-size: 30px;
    line-height: 36px;
    color: var(--text-color);
}

.box-texto a,
.box-texto a:hover {
    color: var(--text-color);
}

.boxes-noticias {
    padding-top: 30px;
    padding-bottom: 30px;
}

.boxes-noticias p {
    font: normal normal 300 17px/25px MetronicPro-Light;
    letter-spacing: 0px;
    color: #707070;
    margin-bottom: 30px;
}

.boxes-noticias h2 {
    font: normal normal bold 22px/26px MetronicPro-Bold;
    letter-spacing: 0px;
    margin: 17px 0;
    color: var(--primary-color);
}

.boxes-noticias a,
.boxes-noticias a:hover {
    color: var(--primary-color);
}

.boxes-noticias img {
    margin-bottom: 10px;
    border: 1px solid var(--primary-color);
    min-height: 150px;
    min-width: 100%;
}

.noticias-direita {
    gap: 20px;
    margin-bottom: 30px !important;
}

.noticias-direita p {
    margin-bottom: 30px;
    color: #707070;
    font-size: 17px;
}

.noticias-direita h2 {
    font-size: 22px;
}

.boxes-noticias .coluna-noticia {
    padding-right: 50px;
    border-right: 1px solid var(--primary-color);
    border-left: 1px solid var(--primary-color);
    padding-left: 50px;
}

.coluna-noticia.col-lg-6.col-12 {
    /* padding-top: 40px !important; */
}

.img-lateral {
    width: 235px !important;
    height: auto;
    object-fit: cover;
}

.link-router {
    display: flex;
    justify-content: center;
}

.img-grande {
    height: 560px !important;
    height: 100%;
    object-fit: cover;
}

.carregar {
    margin: 0 auto !important;
}

.box-destaque-novidade h2 {
    margin-top: 30px;
}

@media (max-width: 990px) {
    .img-noticia {
        height: 132px !important;
        object-fit: cover;
        width: 100%;
    }

    .noticias-direita {
        flex-direction: row !important;
    }

    .titulo-noticias {
        flex-direction: column;
        /* justify-content: left; */
        align-items: flex-start;
    }

    .noticias-linha {
        display: flex;
        flex-direction: column !important;
    }

    .info-noticias-laterais {
        margin-right: 30px;
    }

    .linha-curvada {
        display: none;
    }

    .linha-curvada-mob {
        display: unset;
        position: absolute;
        right: calc(-10px);
        top: 709px;
        margin-top: -709px;
        height: 100%;
    }

    .box-noticia {
        flex-direction: column !important;
    }

    .box-destaque-novidade {
        display: block !important;
    }

    .box-destaque-novidade h2 {
        margin-bottom: 40px !important;
        margin-top: 10px;
    }


    .novidade-destaque {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        margin-bottom: 20px !important;
    }

    .titulo-box-noticias {
        justify-content: space-between !important;
    }

    .box-noticia .col-lg-12.col-12 {
        padding: 0px !important;
    }

    .coluna-noticia img {
        width: 188px;
    }

    .box-conferir-lateral img {
        margin-top: -2px !important;
    }

    .img-lateral {
        width: 200px !important;
    }

    .box-conferir {
        display: flex;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .noticias-direita p {
        margin-bottom: 10px !important;
    }

    .img-grande {
        height: auto !important;
        margin-bottom: 20px;
    }

    .box-noticia h2 {
        line-height: 24px;
        font-size: 22px;
    }

    .noticias-direita h2 {
        font-size: 18px !important;
        line-height: 21px !important;
    }

    .noticias .col-lg-6.col-12,
    .noticias .container,
    .bv-row.noticias.container {
        padding: 0px !important;
    }

    .box-noticia {
        padding-top: 0px;
    }

    .btn-stock {
        padding: 10px 30px !important;
        font-size: 20px;
        margin: 0 auto;
    }
}
</style>