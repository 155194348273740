<template>
  <div class="bg-recuperacao-senha">
    <b-container>
      <b-row>
        <b-col>
          <h3>Esqueceu sua senha? Tudo bem!</h3>
          <p>
            Preencha o campo abaixo e enviaremos um e-mail para você criar uma
            nova.
          </p>

          <b-form @submit.prevent="onSubmit" v-show="!loading">
            <Notificacao
              :notificacao="notificacao_infos"
              v-if="notificacao_infos.count_timer"
              @atualizaNotificacao="notificacao_infos.count_timer = 0"
            />
            <b-form-group id="input-group-1" label-for="input-1">
              <b-form-input
                id="input-1"
                type="email"
                placeholder="Email"
                required
                v-model="form.email"
                :disabled="loading"
                :class="{ error: error }"
              ></b-form-input>

              <!-- <p v-if="error" class="msgErro">{{ errorMessage }}</p> -->

              <b-button type="submit" class="enviar btn" :disabled="loading"
                >ENVIAR</b-button
              >
            </b-form-group>
          </b-form>

          <div v-if="loading" class="loading">
            <b-spinner
              style="
                width: 3rem;
                height: 3rem;
                border-color: #e6c156;
                border-right-color: transparent;
                --bs-spinner-animation-speed: 0.5s;
              "
              label="Large Spinner"
              variant="warning"
            ></b-spinner>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { api } from "@/services.js";

export default {
  name: "EsqueciMinhaSenha",
  data() {
    return {
      form: { email: "" },
      loading: false,
      error: false,
      errorMessage: "",
      notificacao_infos: {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      },
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.notificacao_infos = {
        variant: "primary",
        mensagem: "",
        count_timer: 0,
      };

      api
        .post(`/Registro/esqueceu-senha`, this.form)
        .then(() => {
          this.$router.push("/stockclub/redefinir-senha");
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          this.loading = false;
          if (error.response) {
            if (error.response.data === "Usuário não encontrado") {
              this.errorMessage = "E-mail incorreto, ou não cadastrado";
            } else {
              this.errorMessage = error.response.data;
            }
          } else {
            this.errorMessage = "Algo deu errado na requisição";
          }

          this.notificacao_infos = {
            variant: "danger",
            mensagem: this.errorMessage,
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (localStorage.token) {
      this.$router.push("/stockclub/atualizar-senha");
    }
  },
};
</script>

<style scoped>
.bg-recuperacao-senha h3 {
  font-family: "Uniwars-W00-Heavy-Italick";
  color: var(--primary-color);
  font-size: 30px;
  text-align: center;
  margin-top: 100px;
}

.bg-recuperacao-senha p {
  font-size: 16px;
  color: var(--title-color);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
form {
  max-width: 400px;
  margin: 0 auto;
}
.form-control {
  color: var(--title-color);
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  margin-block: 20px;
  margin: 0 auto;
}

.enviar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.enviar:hover {
  color: var(--primary-color) !important;
  border-color: var(--title-color) !important;
  background-color: var(--title-color) !important;
}

input::placeholder {
  color: var(--primary-color);
}

.btn {
  width: 400px;
  margin: 0 auto;
  padding: 18px;
  font-size: 16px;
  font-family: "Uniwars-W00-Heavy-Italick";
  letter-spacing: 0;
  display: block;
  margin-top: 30px;
  text-align: center;
}

input:focus,
select:focus,
input:focus::placeholder,
select:focus::placeholder {
  color: var(--primary-color) !important;
  background-color: #ffffff00 !important;
}

input.error,
textarea.error,
select.error {
  border-color: #ff1717 !important;
  color: #ff1717 !important;
}
.msgErro {
  color: #ff1717 !important;
  text-align: center;
  margin-top: 50px;
}
.btn:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  color: var(--text-color);
}

@media (max-width: 989px) {
  .btn,
  .form-control {
    width: 100%;
  }

  .bg-recuperacao-senha h3 {
    font-size: 20px;
    margin-top: 70px;
  }

  .bg-recuperacao-senha p {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .bg-recuperacao-senha {
    height: auto;
  }
}
.loading {
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>