<template>
  <div>
    <div class="container-classificacao classificacao-interna margin-row">
      <b-container>
        <b-row v-if="$route.params.categoria != 'turismo-nacional' && $route.params.categoria != 'e-stock'">
          <b-col class="titulo-classificacao titulo-centralizado">
            <hr class="linha-titulo" />
            <h1 v-if="$route.params.categoria == 'formula-4'" class="titulo"><strong>{{ $t("title.classificageral1") }}</strong> {{ $t("title.classificageral2") }}</h1>
            <h1 v-else class="titulo"><strong>CLASSIFICAÇÃO</strong> GERAL</h1>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>

        <ClassificacaoGeral />
      </b-container>
    </div>
  </div>
</template>

<script>
import ClassificacaoGeral from "@/components/ClassificacaoGeral.vue";
export default {
  components: {
    ClassificacaoGeral,
  },
};
</script>

<style scoped>
.container{
  max-width: 1500px;
}
.classificacao-pilotos:nth-of-type(odd) {
  background-color: var(--menu-color);
}

.posicao p {
  font-family: "Uniwars-W00-Black";
  text-align: center;
  color: #707070;
  font-size: 22px;
  margin-bottom: 0px !important;
}

.div-pontos {
  text-align: left;
  width: 170px;
}

.info-classificacao {
  width: 110px;
  text-transform: capitalize;
}

.logo-equipe {
  width: 80px;
  text-align: center;
}

.tresd {
  margin-top: 20px;
}
</style>