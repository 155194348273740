<template>
  <div class="sticky-top">
    <!-- updade -->

    <header class="header">
      <div :class="$store.state.login ? `menu-login` : `menu-bar`" data-anima="top" class="menu-black"
        style="z-index: 2; position: relative">
        <div class="container menu-topo-item">
          <div class="simbols">
            <router-link to="/stockcar/proseries"><img src="@/assets/img/simbolo-proseries.png"
                alt="Logo símbolo Pro series" /></router-link>
            <router-link to="/stockcar/stock-series"><img src="@/assets/img/simbolo-stock-series.png"
                alt="Logo símbolo Stock series" /></router-link>
            <router-link to="/stockcar/e-stock"><img src="@/assets/img/simbolo-e-stock.png" alt="Logo símbolo E-stock"
                style="width: 35px;" /></router-link>
            <router-link to="/stockcar/turismo-nacional"><img src="@/assets/img/simbolo-turismo-nacional.png"
                alt="Logo símbolo Turismo nacional" /></router-link>
            <router-link to="/stockcar/formula-4"><img src="@/assets/img/simbolo-formula-4.png"
                alt="Logo símbolo Fórmula 4" /></router-link>
          </div>

          <div class="links-menu">
            <div class="links-menu aux">
              <div v-if="
                lives.length &&
                hasLive &&
                (usuario_logado ? usuario.cadastroCompleto : true)
              " @click.prevent="openLive()">
                <a class="link-menu-bar ao-vivo">AO VIVO</a>
              </div>
              <div v-for="item in menuSuperior" :key="item.titulo">
                <a class="link-menu-bar" v-show="item.ativo" :target="item.target" :class="hasCompraIngresso(item)"
                  :href="item.url" @click.prevent="openLink(item)">{{ item.titulo }}</a>
              </div>
            </div>

            <div class="stock-club" v-if="$route.params.categoria == 'proseries'">
              <router-link to="/stockclub/login"><img src="@/assets/img/stock-club.png" alt="Logo Stock club"
                  class="logo-club" /></router-link>
            </div>

            <div>
              <b-dropdown v-if="$route.params.categoria == 'formula-4'" id="dropdown-idiomas"
                :text="$i18n.locale == 'pt' ? '🌐 Português' : '🌐 English'" class="m-md-2" variant="outline-light ">
                <b-dropdown-item @click="changeLanguage('en-us')" :disabled="$i18n.locale == 'en'">
                  <img src="https://hatscripts.github.io/circle-flags/flags/us.svg" alt="" />
                  {{ $t("selectlocale.ingles") }}</b-dropdown-item>
                <b-dropdown-item @click="changeLanguage('pt-br')" :disabled="$i18n.locale == 'pt'">
                  <img src="https://hatscripts.github.io/circle-flags/flags/br.svg" alt="" />
                  {{ $t("selectlocale.portugues") }}</b-dropdown-item>
              </b-dropdown>
            </div>

            <div class="meu-perfil" v-if="usuario_logado" data-anima="right">
              <div class="meu-perfil" data-anima="right">
                <div>
                  <router-link to="/stockcar/proseries/?stockclub=true">Meu perfil
                  </router-link>
                </div>
                <div>
                  <router-link to="/stockcar/proseries/?stockclub=true"><img
                      v-if="$store.state.user && !$store.state.user.foto" src="@/assets/img/foto-default.jpg"
                      alt="Imagem de ícone de um usuário" class="img-default" />
                    <img v-if="$store.state.user && $store.state.user.foto"
                      :src="`data:image/;base64,${$store.state.user.foto}`" alt="Imagem de ícone de um usuário"
                      class="img-default" />
                  </router-link>
                </div>

                <a href="#" @click.prevent="deslogarUsuario">
                  <img src="@/assets/img/cross.png" alt="Imagem de ícone para deslogar" class="sair" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <b-modal id="modal-ao-vivo" :title="`🔴 AO VIVO | ${live_item.title}`" size="lg" variant="dark"
          @show="openLiveModal">
          <div>
            <p class="subtitle-aovivo">
              {{ live_item.subtitle }}
            </p>

            <iframe v-if="!loading_modal && live_item.link" width="100%" height="400"
              :src="`${live_item.link}?autoplay=1&mute=1`" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>

            <div v-if="loading_modal" class="loading-modal">
              <b-spinner style="
                  width: 3rem;
                  height: 3rem;
                  border-color: #e6c156;
                  border-right-color: transparent;
                  --bs-spinner-animation-speed: 0.5s;
                " label="Large Spinner" variant="warning"></b-spinner>
            </div>

            <div class="action-live" v-if="lives.length > 1">
              <a class="item-live" href="#" v-for="live in lives" :key="live.idLive"
                @click.prevent="changeLive($event.target, live)">
                <img src="@/assets/icons/play-live.svg" alt="icon play live" />{{ live.categoria }}</a>
            </div>
          </div>
        </b-modal>

        <b-navbar style="z-index: 1" toggleable="lg" type="dark" variant="info" data-anima="bottom">
          <div class="container">



            <div class="acoes-menu">

              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

              <b-navbar-brand :to="`/stockcar/${$route.params.categoria || 'proseries'}`">
                <img :src="logo" alt="Logo" class="logo-img" />
              </b-navbar-brand>

              <div v-if="
                lives.length &&
                hasLive &&
                (usuario_logado ? usuario.cadastroCompleto : true)
              " @click.prevent="openLive()">
                <b-nav-item to="/stockcar/proseries/?stockclub=true" class="link-menu-bar ao-vivo mobile-ao-vivo">
                  AO VIVO
                </b-nav-item>
              </div>

              <div>
                <b-nav-item to="/stockcar/proseries/?stockclub=true" v-if="usuario_logado"
                  class="foto-piloto-mobile"><img v-if="$store.state.user && !$store.state.user.foto"
                    src="@/assets/img/foto-default.jpg" alt="Imagem de ícone de um usuário" class="img-default" />
                  <img v-if="$store.state.user && $store.state.user.foto"
                    :src="`data:image/;base64,${$store.state.user.foto}`" alt="Imagem de ícone de um usuário"
                    class="img-default" />
                </b-nav-item>
              </div>
            </div>

            <b-collapse id="nav-collapse" is-nav v-model="menu_aberto">
              <b-navbar-nav>
                <div class="itens-menu-sup">
                  <div class="simbols">

                    <b-nav-item to="/stockcar/proseries"><img src="@/assets/img/simbolo-proseries.png"
                        alt="Logo símbolo Pro series" /></b-nav-item>
                    <b-nav-item to="/stockcar/stock-series"><img src="@/assets/img/simbolo-stock-series.png"
                        alt="Logo símbolo Stock series" /></b-nav-item>
                    <b-nav-item to="/stockcar/e-stock"><img src="@/assets/img/simbolo-e-stock.png"
                        alt="Logo símbolo E-stock" /></b-nav-item>

                    <b-nav-item to="/stockcar/turismo-nacional"><img src="@/assets/img/simbolo-turismo-nacional.png"
                        alt="Logo símbolo E-stock" /></b-nav-item>
                    <b-nav-item to="/stockcar/formula-4"><img src="@/assets/img/simbolo-formula-4.png"
                        alt="Logo símbolo Fórmula 4" /></b-nav-item>

                  </div>


                  <div class="actions-ingresso-live">
                    <a class="link-menu-bar comprar-ingresso btn-live-mobile" href="#" target="_blank" v-if="
                      lives.length &&
                      hasLive &&
                      (usuario_logado ? usuario.cadastroCompleto : true)
                    " @click.prevent="openLive()">AO VIVO</a>
                    <!-- <a
                    class="link-menu-bar comprar-ingresso"
                    href="https://bileto.sympla.com.br/event/82631"
                    target="_blank"
                    >Comprar ingresso</a
                  > -->
                  </div>
                  <div class="stock-club" v-if="$route.params.categoria == 'proseries'">
                    <b-nav-item to="/stockclub/login"><img src="@/assets/img/stock-club.png"
                        alt="Logo Stock club" /></b-nav-item>
                  </div>
                  <b-nav-item to="/stockcar/proseries/?stockclub=true" v-if="$store.state.login" class="meu-perfil">
                    <strong>Meu Perfil</strong></b-nav-item>
                </div>

                <b-nav-item class="item-mob d-block d-lg-none" @click.prevent="deslogarUsuario" v-if="usuario_logado">
                  <img src="@/assets/img/cross.png" alt="Imagem de ícone para deslogar" class="sair" />

                  <strong class="ml-2">Deslogar do Stock Club</strong>
                </b-nav-item>

                <b-nav-item to="/stockclub/login" v-if="
                  $route.params.categoria == 'proseries' &&
                  !$store.state.login
                " class="d-block d-lg-none" v-b-tooltip.hover.bottom="'Realize o login no Stockclub'">
                  <strong>Entrar</strong></b-nav-item>

                <b-nav-item :class="{
                  esconder:
                    item.titulo === 'Stock Car Channel'
                }" v-for="item in menuPrincipal" :key="item.titulo" :to="item.titulo === 'Stock Car Channel'
                  ? '/stockclub/stockchannel'
                  : `/stockcar/${$route.params.categoria || 'proseries'}/${item.url
                  }`
                  ">


                  <b-nav-item-dropdown class="dropdown-submenu" v-if="item.submenu && item.submenu.length > 0"
                    text="Dropdown">
                    <template #button-content>
                      <div>{{ item.titulo }}</div>
                    </template>
                    <b-dropdown-item v-for="submenuItem in item.submenu" :key="submenuItem.titulo" :to="`/stockcar/${$route.params.categoria || 'proseries'
                      }/${submenuItem.url}`">
                      {{ submenuItem.titulo }}
                    </b-dropdown-item>
                  </b-nav-item-dropdown>

                  <div class="testando" v-if="item.submenu && item.submenu.length > 0">
                    <!-- Renderizar as opções do submenu como b-nav-item diretamente -->
                    <b-nav-item class="submenu-mobile" :class="{ 'primary-submenu-mobile': index === 0 }"
                      v-for="(submenuItem, index) in item.submenu" :key="submenuItem.titulo" :to="`/stockcar/${$route.params.categoria || 'proseries'
                        }/${submenuItem.url}`">
                      {{ submenuItem.titulo }}
                    </b-nav-item>
                  </div>

                  <div v-else-if="item.ativo">
                    <div v-if="item.titulo !== 'Stock Car Channel'">
                      {{ item.titulo }}
                    </div>

                    <div v-else-if="
                      item.titulo === 'Stock Car Channel' && usuario_logado
                    ">
                      {{ item.titulo }}
                    </div>
                  </div>
                </b-nav-item>

                <b-nav-item v-if="usuario_logado && !votacao.encerrado" to="/stockclub/votacao-fanpush-live">Fan Push
                </b-nav-item>

                <b-nav-item to="/stockclub/login" v-if="
                  $route.params.categoria == 'proseries' &&
                  !$store.state.login
                " class="d-none d-lg-block" v-b-tooltip.hover.bottom="'Realize o login no Stockclub'">
                  <strong>Entrar</strong></b-nav-item>

                <b-nav-item class="item-mob" v-for="item in menuSuperior" :key="item.titulo"><a v-show="item.ativo"
                    :class="hasCompraIngresso(item)" :target="item.target" :href="item.url"
                    @click.prevent="openLink(item)">{{
                      item.titulo }}</a></b-nav-item>
              </b-navbar-nav>

              <div class="idiomas-mobile d-none d-sm-flex d-md-none">
                <b-dropdown v-if="$route.params.categoria == 'formula-4'" id="dropdown-idiomas"
                  :text="$i18n.locale == 'pt' ? '🌐 Português' : '🌐 English'" class="m-md-2" variant="outline-light ">
                  <b-dropdown-item @click="changeLanguage('en-us')" :disabled="$i18n.locale == 'en'">
                    <img src="https://hatscripts.github.io/circle-flags/flags/us.svg" alt="" />
                    {{ $t("selectlocale.ingles") }}</b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('pt-br')" :disabled="$i18n.locale == 'pt'">
                    <img src="https://hatscripts.github.io/circle-flags/flags/br.svg" alt="" />
                    {{ $t("selectlocale.portugues") }}</b-dropdown-item>
                </b-dropdown>

              </div>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
    </header>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

export default {
  name: "BaseMenu",
  props: {
    logo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      votacao: [],
      usuario: {
        cadastroCompleto: false,
      },
      responseMenu: null,
      menuSuperior: [],
      menuPrincipal: [],
      loading: false,
      categoria_atual: null,
      menu_aberto: false,
      link_live: "",
      selectedLanguage: null,
      lives: [],
      live_item: {
        title: "",
        subtitle: "",
        link: "",
      },
      loading_modal: false,
    };
  },

  computed: {
    usuario_logado() {
      return this.$store.state.login || window.localStorage.token;
    },
    logo_atual() {
      return this.logo || this.$store.state.categoria
        ? this.$store.state.categoria.imgLogo
        : sessionStorage.categoria
          ? JSON.parse(sessionStorage.categoria).imgLogo
          : "";
    },
    hasLive() {
      const ativo = this.lives.find((item) => {
        if (item.online && item.linkVinculado) return item;
      });
      return ativo || false;
    },
  },
  methods: {
    changeLanguage(lang) {
      const splitLang = lang.split("-")[0];
      if (splitLang != this.selectedLanguage) {
        EventBus.$emit("loadingGlobal", true);
        this.selectedLanguage = lang.split("-")[0];
        this.$i18n.locale = this.selectedLanguage;

        this.$store.dispatch("alterarIdioma", lang);

        setTimeout(() => {
          // window.location.reload();
          this.$router.go(0);
        }, 50);
      }
    },

    openLink(item) {
      window.open(item.url, item.target);
    },

    deslogarUsuario() {
      this.$store.dispatch("deslogarUsuario");
      this.$router.push("/stockclub/login");
    },
    fetchVotacao() {
      this.loading = true;

      api
        .get('/fanpush/votacao')
        .then((resp) => {
          this.votacao = resp.data;
          console.log(this.votacao)
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchMenu(id_categoria) {
      if (!this.responseMenu || this.$route.params.categoria == "formula-4") {
        this.loading = true;
        api
          .get("/Categoria")
          .then((resp) => {
            this.responseMenu = resp.data;
            sessionStorage.responseMenu = JSON.stringify({
              id_categoria: id_categoria,
              menu: resp.data,
            });

            this.menuSuperior = resp.data.menuSuperior.filter(
              (item) => item.idCategoria == id_categoria
            );
            this.menuPrincipal = resp.data.menuPrincipal.filter(
              (item) => item.idCategoria == id_categoria
            );
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.menuSuperior = this.responseMenu.menuSuperior.filter(
          (item) => item.idCategoria == id_categoria
        );
        this.menuPrincipal = this.responseMenu.menuPrincipal.filter(
          (item) => item.idCategoria == id_categoria
        );
      }
    },
    fetchLives() {
      this.loading = true;

      // reset infos
      this.live_item = {
        title: "",
        subtitle: "",
        link: "",
      };
      api
        .get("/AoVivo")
        .then((resp) => {
          this.lives = resp.data;
          this.lives.forEach((item) => {
            item.categoria =
              item.categoria == "SITE"
                ? "TRANSMISSÃO ABERTA"
                : "STOCK CAR CHANNEL";
          });

          const liveExiste = this.hasLive;

          if (
            this.lives.length &&
            liveExiste &&
            (this.usuario_logado ? this.usuario.cadastroCompleto : true)
          ) {
            this.changeLive(document.querySelector(".item-live"), liveExiste);
            this.openLive();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    openLiveModal() {
      setTimeout(() => {
        if (this.lives.length)
          this.changeLive(document.querySelector(".item-live"), this.lives[0]);
      }, 200);
    },
    changeLive(element, live) {
      this.loading_modal = true;
      const itensLive = document.querySelectorAll(".item-live");
      itensLive.forEach((item) => item.classList.remove("ativo"));

      if (live.online && live.linkVinculado) {
        this.live_item = {
          title: live.categoria,
          subtitle: live.nome,
          link: `https://www.youtube.com/embed/${this.getYouTubeParam(
            live.link
          )}`,
        };
      } else {
        this.live_item = {
          title: "⚠️ ESSA LIVE ESTÁ OFFLINE NO MOMENTO",
          subtitle: "⚠️ ESSA LIVE ESTÁ OFFLINE NO MOMENTO",
          link: `https://www.youtube.com/embed/${this.getYouTubeParam("aaa")}`,
        };
      }

      if (element) element.classList.add("ativo");

      setTimeout(() => {
        this.loading_modal = false;
      }, 1000);
    },
    openLive() {
      this.$bvModal.show("modal-ao-vivo");
    },
    getYouTubeParam(url) {
      // Extrai a parte do URL após o "?"
      const paramsString = url.split("?")[1];
      if (!paramsString) return null;

      // Cria um objeto com todos os parâmetros do URL
      const params = new URLSearchParams(paramsString);

      // Retorna o valor do parâmetro "v" se existir, caso contrário retorna null
      return params.get("v");
    },
    hasCompraIngresso(item) {
      let r = false;
      if (item.titulo) {
        const title = item.titulo.toUpperCase();
        if (title.includes("INGRESSO")) {
          this.$store.dispatch("mostrarBtnIngresso", {
            show: true,
            link: item.url,
          });
          r = "comprar-ingresso";
        }
      }

      return r;
    },
  },
  mounted() {
    this.fetchVotacao();
    this.loading = true;
    this.usuario = localStorage.user
      ? JSON.parse(localStorage.user)
      : this.$store.state
        ? this.$store.state.user
        : null;

    const langSplit = sessionStorage.idioma
      ? sessionStorage.idioma.split("-")[0]
      : "pt";
    this.$i18n.locale = langSplit;

    this.$store.dispatch("alterarIdioma", sessionStorage.idioma);

    // resetando compra ingresso
    this.$store.dispatch("mostrarBtnIngresso", {
      show: false,
      link: "",
    });

    const id_categoria =
      mapCategorias[
      sessionStorage.categoria
        ? JSON.parse(sessionStorage.categoria).tag
        : "stockcarproseries" || "stockcarproseries"
      ];

    this.responseMenu = sessionStorage.responseMenu
      ? JSON.parse(sessionStorage.responseMenu)
      : null;

    if (this.responseMenu) {
      if (id_categoria != this.responseMenu.id_categoria) {
        this.responseMenu = null;
      } else {
        this.responseMenu = this.responseMenu.menu;
      }
    }
    this.fetchMenu(id_categoria);
    this.fetchLives();
  },

  created() {
    EventBus.$on("updateRouter", (data) => {
      this.fetchMenu(mapCategorias[data || "proseries"]);
    });

    EventBus.$on("liveLogado", () => {
      if (this.usuario.cadastroCompleto) {
        this.openLive();
      }
    });
  },
};
</script>

<style scoped>
header * {
  font-family: "MetronicPro-Light";
}

.stockchannel {
  margin-right: 20px;
}

.bg-info {
  background-color: var(--menu-color) !important;
}

.dropdown-turismo,
.box-turismo-mob {
  background-image: url('@/assets/img/simbolo-turismo-nacional.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
}

.box-turismo-mob {
  background-position: top !important;
}

.dropdown-turismo a,
.turismo-mobile a {
  color: var(--title-color) !important;
  text-decoration: none !important;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: end;
}

.nav-item a {
  color: var(--title-color);
  font-size: 15px;
  text-decoration: none;
}

.nav-item a:hover {
  color: var(--primary-color);
}

.simbols {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0;
}

.links-menu {
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 30px;
}

.links-menu.aux {
  padding: 0;
}

.links-menu a {
  color: var(--primary-color);
  font-size: 15px;
  text-decoration: none;
  margin-top: 3px;
}

.links-menu a:hover {
  color: grey;
}

.nav-link strong {
  color: var(--primary-color);
}

.comprar-ingresso {
  background-color: var(--primary-color);
  color: var(--text-color) !important;
  border-radius: 5px;
  padding: 2px 20px;
}

.sticky-top {
  z-index: 9999999;
}

.img-default {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #eabb4d;
}

.sair {
  cursor: pointer;
}

.meu-perfil {
  display: flex;
  align-items: center;
  gap: 15px;
}

.meu-perfil a {
  color: #eabb4d;
}

.itens-menu-sup,
.foto-piloto-mobile,
.mobile-ao-vivo {
  display: none !important;
}

@media (max-width: 1400px) {
  .nav-item a {
    font-size: 13px;
  }
}

@media (max-width: 1200px) {
  .nav-item a {
    font-size: 15px;
  }

  .logo-img,
  .logo-club {
    max-width: 120px;
  }

  .simbols a img {
    max-width: 25px;
  }
}

@media (max-width: 989px) {
  .bg-info {
    max-height: 100vh;
    overflow: auto;
  }

  .logo-img,
  .logo-club {
    max-width: 100%;
  }

  .meu-perfil {
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .links-menu {
    display: grid;
    gap: 0px;
    justify-content: center;
    margin-top: -10px;
  }

  .menu-black .menu-topo-item {
    display: none;
  }

  .logo-img {
    max-height: 60px;
  }

  .simbols {
    justify-content: center;
  }

  .stock-club .nav-item a,
  .meu-perfil a {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
  }

  .stock-club {
    margin: 20px auto;
  }

  .itens-menu-sup,
  .foto-piloto-mobile {
    display: block !important;
  }

  .mobile-ao-vivo {
    display: flex !important;
  }

  .navbar-expand-lg .navbar-collapse {
    padding-bottom: 20px;
  }

  .nav-item a {
    padding: 7px;
  }

  .nav-item+.nav-item {
    margin-top: 10px;
  }

  .simbols .nav-item+.nav-item {
    margin-top: 0;
  }

  .acoes-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* gap: 15px; */
  }

  .img-default {
    width: 35px;
    height: 35px;
    margin-left: auto
  }

  .idiomas-mobile {
    display: flex !important;
  }
}

@media (min-width: 990px) and (max-width: 1299px) {
  .navbar-nav {
    gap: 5px;
  }
}

.loading-modal {
  /* height: 15vh; */
  min-height: 400px;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-menu-bar,
.item-mob {
  cursor: pointer;
}

.link-menu-bar.ao-vivo,
.item-mob .ao-vivo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #f9423a !important;
}

.mobile-ao-vivo {
  flex-direction: column;
  gap: 5px !important;
}

.mobile-ao-vivo .nav-link {
  color: #f9423a;
  padding: 0;
  font-size: 11px;
}

.link-menu-bar.ao-vivo::before,
.item-mob .ao-vivo::before {
  content: "";
  width: 15px;
  height: 15px;
  background: #f9423a;
  display: block;
  border-radius: 50%;
  margin-top: -2px;
  box-shadow: 0 0 0 rgba(255, 23, 23, 0.4);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 23, 23, 0.4);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(255, 23, 23, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 23, 23, 0);
  }
}

#dropdown-idiomas .dropdown-item img {
  max-width: 20px;
}

/* live */
.action-live {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin: 30px auto;
}

.action-live a {
  text-decoration: none;
  text-align: center;
  /* color: #fff; */
  color: #717171;
  padding: 10px 20px;
  transition: 0.3s;
  font-size: 18px;
  text-transform: uppercase;
}

.action-live a:hover,
.action-live a.ativo {
  text-decoration: underline;
  color: #fff;
}

.action-live a img {
  margin-right: 15px;
}

@media (max-width: 989px) {
  .action-live {
    flex-direction: column;
    gap: 20px;
  }

  .action-live a {
    border: 1px solid #e6c156;
    border-radius: 5px;
    padding: 7px 30px;
    min-width: 70%;
    font-size: 14px;
  }

  .actions-ingresso-live {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .actions-ingresso-live a {
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
  }

  .actions-ingresso-live .btn-live-mobile {
    background-color: #f9423a;
    color: #fff !important;
  }
}

.esconder {
  display: none;
}

.item-mob .sair {
  margin-right: 7px;
}
</style>