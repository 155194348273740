<template>
  <section>
    <b-container class="titulo-galeria">
      <b-row>
        <b-col class="titulo-centralizado">
          <hr class="linha-titulo" />
          <h1 class="titulo"><strong>GALERIA</strong></h1>
          <hr class="linha-titulo" />
        </b-col>
      </b-row>
    </b-container>

    <b-container class="galeria">
      <b-row v-if="!loading" data-anima="top">
        <b-col
          cols="12"
          lg="4"
          class="box-galeria"
          v-for="(item, index) in imgs"
          :key="item"
        >
          <img
            @click="showMultiple(index)"
            :src="item"
            alt="Imagem da galeria"
          />
        </b-col>
      </b-row>

      <b-row v-if="loading" data-anima="bottom">
        <b-col cols="12" lg="4" class="box-galeria">
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col cols="12" lg="4" class="box-galeria">
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col cols="12" lg="4" class="box-galeria">
          <b-skeleton-img></b-skeleton-img>
        </b-col>
      </b-row>
    </b-container>

    <div>
      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
        :loop="true"
      ></vue-easy-lightbox>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      imgs: "",
      visible: false,
      index: 0,
      loading: false,
    };
  },
  methods: {
    showMultiple(index) {
      this.index = index;
      this.show();
      // document.querySelector('body').style.overflow = 
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    fetchGaleria(id_categoria, id_galeria) {
      this.loading = true;
      api
        .get(`/Noticias/categoria/${id_categoria}/galeria/${id_galeria}`)
        .then((resp) => {
          this.imgs = resp.data.imagemGaleria;
          document.title = `Galeria | ${resp.data.titulo}`;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.loading = true;
    this.fetchGaleria(
      mapCategorias[this.$route.params.categoria || "proseries"],
      this.$route.params.id
    );
  },
};
</script>