<template>
  <div>
    <div class="bg-corrida">
      <b-container class="ultima-corrida">
        <div
          class="tabs-corrida"
          v-if="ultimasCorridas && ultimasCorridas.Corrida1.podio.length && !loading">
          <b-tabs content-class="mt-3">
            <b-tab :title="$route.params.categoria === 'proseries' ? 'Corrida Sprint' : ($route.params.categoria === 'e-stock' ? 'PRO' : 'CORRIDA 1')" v-if="ultimasCorridas.Corrida1" active>
              <b-row class="container-podio">
                <b-col cols="12" lg="6">
                  <b-row class="podio-pilotos">
                    <b-col cols="12" lg="4" class="segundo-box-podio">
                      <div class="podio">
                        <img
                          class="img-vencedor"
                          :src="ultimasCorridas.Corrida1.podio[1]?.imagemC"
                          alt="Imagem do piloto"
                        />
                        <p>2</p>
                      </div>
                      <div class="info-piloto">
                        <img
                          class="carro"
                          :src="ultimasCorridas.Corrida1.podio[1]?.carro"
                          alt="Imagem do carro"
                        />
                        <h3>
                          #{{ ultimasCorridas.Corrida1.podio[1]?.numero }}
                        </h3>
                        <span class="nome"
                          >{{
                            primeiroNome(
                              ultimasCorridas.Corrida1.podio[1]?.nome
                            )
                          }}
                          <strong class="fontStrong">{{
                            ultimoNome(ultimasCorridas.Corrida1.podio[1]?.nome)
                          }}</strong></span
                        ><br />
                        <span class="equipe">{{
                          ultimasCorridas.Corrida1.podio[1]?.nomeEquipe
                        }}</span
                        ><br />
                        <span
                          v-if="$route.params.categoria != 'formula-4' && $route.params.categoria != 'e-stock'"
                          class="montadora fontStrong"
                          >{{
                            ultimasCorridas.Corrida1.podio[1]?.montadora
                          }}</span
                        >
                      </div>
                    </b-col>

                    <b-col cols="12" lg="4" class="primeiro-box-podio">
                      <div class="podio">
                        <img
                          class="img-vencedor"
                          :src="ultimasCorridas.Corrida1.podio[0]?.imagemC"
                          alt="Imagem do piloto"
                        />
                        <p>1</p>
                      </div>
                      <div class="info-piloto">
                        <img
                          class="carro"
                          :src="ultimasCorridas.Corrida1.podio[0]?.carro"
                          alt="Imagem do carro"
                        />
                        <h3>
                          #{{ ultimasCorridas.Corrida1.podio[0]?.numero }}
                        </h3>
                        <span class="nome"
                          >{{
                            primeiroNome(
                              ultimasCorridas.Corrida1.podio[0]?.nome
                            )
                          }}
                          <strong class="fontStrong">{{
                            ultimoNome(ultimasCorridas.Corrida1.podio[0]?.nome)
                          }}</strong></span
                        ><br />
                        <span class="equipe">{{
                          ultimasCorridas.Corrida1.podio[0]?.nomeEquipe
                        }}</span
                        ><br />
                        <span
                          v-if="$route.params.categoria != 'formula-4'  && $route.params.categoria != 'e-stock'"
                          class="montadora fontStrong"
                          >{{
                            ultimasCorridas.Corrida1.podio[0]?.montadora
                          }}</span
                        >
                      </div>
                    </b-col>

                    <b-col cols="12" lg="4" class="terceiro-box-podio">
                      <div class="podio">
                        <img
                          class="img-vencedor"
                          :src="ultimasCorridas.Corrida1.podio[2]?.imagemC"
                          alt="Imagem do piloto"
                        />
                        <p>3</p>
                      </div>
                      <div class="info-piloto">
                        <img
                          class="carro"
                          :src="ultimasCorridas.Corrida1.podio[2]?.carro"
                          alt="Imagem do carro"
                        />
                        <h3>
                          #{{ ultimasCorridas.Corrida1.podio[2]?.numero }}
                        </h3>
                        <span class="nome"
                          >{{
                            primeiroNome(
                              ultimasCorridas.Corrida1.podio[2]?.nome
                            )
                          }}
                          <strong class="fontStrong">{{
                            ultimoNome(ultimasCorridas.Corrida1.podio[2]?.nome)
                          }}</strong></span
                        ><br />
                        <span class="equipe">{{
                          ultimasCorridas.Corrida1.podio[2]?.nomeEquipe
                        }}</span
                        ><br />
                        <span
                          v-if="$route.params.categoria != 'formula-4'  && $route.params.categoria != 'e-stock'"
                          class="montadora fontStrong"
                          >{{
                            ultimasCorridas.Corrida1.podio[2]?.montadora
                          }}</span
                        >
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="6">
                  <div class="tabela-corrida">
                    <b-table-simple hover small caption-top stacked>
                      <b-tbody v-if="ultimasCorridas.Corrida1">
                        <b-tr>
                          <b-td :stacked-heading="etapaTranslate"
                            ><div class="resultado">
                              {{
                                ultimasCorridas.Corrida1.dataEtapa | dateFormat
                              }}
                            </div></b-td
                          >
                          <b-td :stacked-heading="circuitoTranslate"
                            ><div class="resultado">
                              {{ ultimasCorridas.Corrida1.circuito }}
                            </div></b-td
                          >
                          <b-td stacked-heading="POLE POSITION"
                            ><div class="resultado">
                              #{{ ultimasCorridas.Corrida1.numeroPiloto }}
                              {{ ultimasCorridas.Corrida1.polePosition }}
                            </div></b-td
                          >
                          <b-td
                            stacked-heading="MAN OF THE RACE"
                            v-if="$route.params.categoria != 'formula-4'  && $route.params.categoria != 'e-stock'"
                            ><div class="resultado">
                              {{ ultimasCorridas.Corrida1.manOfTheRace }} -
                              {{ ultimasCorridas.Corrida1.manOfTheRacePontos }}
                              pts
                            </div></b-td
                          >
                          <b-td :stacked-heading="voltaMaisRapidaTranslate"
                            ><div class="resultado">
                              {{
                                ultimasCorridas.Corrida1.pilotoVoltaMaisRapida
                              }}
                              - {{ ultimasCorridas.Corrida1.voltaMaisRapida }}
                            </div></b-td
                          >
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab :title="$route.params.categoria === 'proseries' ? 'Corrida Principal' : ($route.params.categoria === 'e-stock' ? 'AM' : 'CORRIDA 2') " v-if="ultimasCorridas.Corrida2 && ultimasCorridas.Corrida2.podio.length > 0">
              <b-row class="container-podio2">
                <b-col cols="12" lg="6">
                  <b-row class="podio-pilotos">
                    <b-col cols="12" lg="4" class="segundo-box-podio">
                      <div class="podio">
                        <img
                          class="img-vencedor"
                          :src="ultimasCorridas.Corrida2.podio[1].imagemC"
                          alt="Imagem do piloto"
                        />
                        <p>2</p>
                      </div>
                      <div class="info-piloto">
                        <img
                          class="carro"
                          :src="ultimasCorridas.Corrida2.podio[1].carro"
                          alt="Imagem do carro"
                        />
                        <h3>
                          #{{
                            primeiroNome(
                              ultimasCorridas.Corrida2.podio[1].numero
                            )
                          }}
                        </h3>
                        <span class="nome"
                          >{{
                            primeiroNome(ultimasCorridas.Corrida2.podio[1].nome)
                          }}
                          <strong class="fontStrong">{{
                            ultimoNome(ultimasCorridas.Corrida2.podio[1].nome)
                          }}</strong></span
                        ><br />
                        <span class="equipe">{{
                          ultimasCorridas.Corrida2.podio[1].nomeEquipe
                        }}</span
                        ><br />
                        <span
                          v-if="$route.params.categoria != 'formula-4'  && $route.params.categoria != 'e-stock'"
                          class="montadora fontStrong"
                          >{{
                            ultimasCorridas.Corrida2.podio[1].montadora
                          }}</span
                        >
                      </div>
                    </b-col>

                    <b-col cols="12" lg="4" class="primeiro-box-podio">
                      <div class="podio">
                        <img
                          class="img-vencedor"
                          :src="ultimasCorridas.Corrida2.podio[0]?.imagemC"
                          alt="Imagem do piloto"
                        />
                        <p>1</p>
                      </div>
                      <div class="info-piloto">
                        <img
                          class="carro"
                          :src="ultimasCorridas.Corrida2.podio[0]?.carro"
                          alt="Imagem do carro"
                        />
                        <h3>
                          #{{ ultimasCorridas.Corrida2.podio[0]?.numero }}
                        </h3>
                        <span class="nome"
                          >{{
                            primeiroNome(
                              ultimasCorridas.Corrida2.podio[0]?.nome
                            )
                          }}
                          <strong class="fontStrong">{{
                            ultimoNome(ultimasCorridas.Corrida2.podio[0]?.nome)
                          }}</strong></span
                        ><br />
                        <span class="equipe">{{
                          ultimasCorridas.Corrida2.podio[0]?.nomeEquipe
                        }}</span
                        ><br />
                        <span
                          v-if="$route.params.categoria != 'formula-4'  && $route.params.categoria != 'e-stock'"
                          class="montadora fontStrong"
                          >{{
                            ultimasCorridas.Corrida2.podio[0]?.montadora
                          }}</span
                        >
                      </div>
                    </b-col>

                    <b-col cols="12" lg="4" class="terceiro-box-podio">
                      <div class="podio">
                        <img
                          class="img-vencedor"
                          :src="ultimasCorridas.Corrida2.podio[2]?.imagemC"
                          alt="Imagem do piloto"
                        />
                        <p>3</p>
                      </div>
                      <div class="info-piloto">
                        <img
                          class="carro"
                          :src="ultimasCorridas.Corrida2.podio[2]?.carro"
                          alt="Imagem do carro"
                        />
                        <h3>
                          #{{ ultimasCorridas.Corrida2.podio[2]?.numero }}
                        </h3>
                        <span class="nome"
                          >{{
                            primeiroNome(
                              ultimasCorridas.Corrida2.podio[2]?.nome
                            )
                          }}
                          <strong class="fontStrong">{{
                            ultimoNome(ultimasCorridas.Corrida2.podio[2]?.nome)
                          }}</strong></span
                        ><br />
                        <span class="equipe">{{
                          ultimasCorridas.Corrida2.podio[2]?.nomeEquipe
                        }}</span
                        ><br />
                        <span
                          v-if="$route.params.categoria != 'formula-4'  && $route.params.categoria != 'e-stock'"
                          class="montadora fontStrong"
                          >{{
                            ultimasCorridas.Corrida2.podio[2]?.montadora
                          }}</span
                        >
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="6">
                  <div class="tabela-corrida">
                    <b-table-simple hover small caption-top stacked>
                      <b-tbody>
                        <b-tr>
                          <b-td :stacked-heading="etapaTranslate"
                            ><div class="resultado">
                              {{
                                ultimasCorridas.Corrida2.dataEtapa | dateFormat
                              }}
                            </div></b-td
                          >
                          <b-td :stacked-heading="circuitoTranslate"
                            ><div class="resultado">
                              {{ ultimasCorridas.Corrida2.circuito }}
                            </div></b-td
                          >
                          <b-td stacked-heading="POLE POSITION"
                            ><div class="resultado">
                              #{{ ultimasCorridas.Corrida2.numeroPiloto }}
                              {{ ultimasCorridas.Corrida2.polePosition }}
                            </div></b-td
                          >
                          <b-td
                            stacked-heading="MAN OF THE RACE"
                            v-if="$route.params.categoria != 'formula-4'  && $route.params.categoria != 'e-stock'"
                            ><div class="resultado">
                              {{ ultimasCorridas.Corrida2.manOfTheRace }} -
                              {{ ultimasCorridas.Corrida2.manOfTheRacePontos }}
                              pts
                            </div></b-td
                          >
                          <b-td :stacked-heading="voltaMaisRapidaTranslate"
                            ><div class="resultado">
                              {{
                                ultimasCorridas.Corrida2.pilotoVoltaMaisRapida
                              }}
                              - {{ ultimasCorridas.Corrida2.voltaMaisRapida }}
                            </div></b-td
                          >
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab :title="corrida3" v-if="ultimasCorridas.Corrida3 && ultimasCorridas.Corrida3.podio.length > 0">
              <b-row class="container-podio3">
                <b-col cols="12" lg="6">
                  <b-row class="podio-pilotos">
                    <b-col cols="12" lg="4" class="segundo-box-podio">
                      <div class="podio">
                        <img
                          class="img-vencedor"
                          :src="ultimasCorridas.Corrida3.podio[1]?.imagemC"
                          alt="Imagem do piloto"
                        />
                        <p>2</p>
                      </div>
                      <div class="info-piloto">
                        <img
                          class="carro"
                          :src="ultimasCorridas.Corrida3.podio[1]?.carro"
                          alt="Imagem do carro"
                        />
                        <h3>
                          #{{ ultimasCorridas.Corrida3.podio[1]?.numero }}
                        </h3>
                        <span class="nome"
                          >{{
                            primeiroNome(
                              ultimasCorridas.Corrida3.podio[1]?.nome
                            )
                          }}
                          <strong class="fontStrong">{{
                            ultimoNome(ultimasCorridas.Corrida3.podio[1]?.nome)
                          }}</strong></span
                        ><br />
                        <span class="equipe">{{
                          ultimasCorridas.Corrida3.podio[1]?.nomeEquipe
                        }}</span
                        ><br />
                        <span
                          v-if="$route.params.categoria != 'formula-4'"
                          class="montadora fontStrong"
                          >{{
                            ultimasCorridas.Corrida3.podio[1]?.montadora
                          }}</span
                        >
                      </div>
                    </b-col>

                    <b-col cols="12" lg="4" class="primeiro-box-podio">
                      <div class="podio">
                        <img
                          class="img-vencedor"
                          :src="ultimasCorridas.Corrida3.podio[0]?.imagemC"
                          alt="Imagem do piloto"
                        />
                        <p>1</p>
                      </div>
                      <div class="info-piloto">
                        <img
                          class="carro"
                          :src="ultimasCorridas.Corrida3.podio[0]?.carro"
                          alt="Imagem do carro"
                        />
                        <h3>
                          #{{ ultimasCorridas.Corrida3.podio[0]?.numero }}
                        </h3>
                        <span class="nome"
                          >{{
                            primeiroNome(
                              ultimasCorridas.Corrida3.podio[0]?.nome
                            )
                          }}
                          <strong class="fontStrong">{{
                            ultimoNome(ultimasCorridas.Corrida3.podio[0]?.nome)
                          }}</strong></span
                        ><br />
                        <span class="equipe">{{
                          ultimasCorridas.Corrida3.podio[0]?.nomeEquipe
                        }}</span
                        ><br />
                        <span
                          v-if="$route.params.categoria != 'formula-4'"
                          class="montadora fontStrong"
                          >{{
                            ultimasCorridas.Corrida3.podio[0]?.montadora
                          }}</span
                        >
                      </div>
                    </b-col>

                    <b-col cols="12" lg="4" class="terceiro-box-podio">
                      <div class="podio">
                        <img
                          class="img-vencedor"
                          :src="ultimasCorridas.Corrida3.podio[2]?.imagemC"
                          alt="Imagem do piloto"
                        />
                        <p>3</p>
                      </div>
                      <div class="info-piloto">
                        <img
                          class="carro"
                          :src="ultimasCorridas.Corrida3.podio[2]?.carro"
                          alt="Imagem do carro"
                        />
                        <h3>
                          #{{ ultimasCorridas.Corrida3.podio[2]?.numero }}
                        </h3>
                        <span class="nome"
                          >{{
                            primeiroNome(
                              ultimasCorridas.Corrida3.podio[2]?.nome
                            )
                          }}
                          <strong class="fontStrong">{{
                            ultimoNome(ultimasCorridas.Corrida3.podio[2]?.nome)
                          }}</strong></span
                        ><br />
                        <span class="equipe">{{
                          ultimasCorridas.Corrida3.podio[2]?.nomeEquipe
                        }}</span
                        ><br />
                        <span
                          v-if="$route.params.categoria != 'formula-4'"
                          class="montadora fontStrong"
                          >{{
                            ultimasCorridas.Corrida3.podio[2]?.montadora
                          }}</span
                        >
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="6">
                  <div class="tabela-corrida">
                    <b-table-simple hover small caption-top stacked>
                      <b-tbody>
                        <b-tr>
                          <b-td :stacked-heading="etapaTranslate"
                            ><div class="resultado">
                              {{
                                ultimasCorridas.Corrida3.dataEtapa | dateFormat
                              }}
                            </div></b-td
                          >
                          <b-td :stacked-heading="circuitoTranslate"
                            ><div class="resultado">
                              {{ ultimasCorridas.Corrida3.circuito }}
                            </div></b-td
                          >
                          <b-td stacked-heading="POLE POSITION"
                            ><div class="resultado">
                              #{{ ultimasCorridas.Corrida3.numeroPiloto }}
                              {{ ultimasCorridas.Corrida3.polePosition }}
                            </div></b-td
                          >
                          <b-td
                            stacked-heading="MAN OF THE RACE"
                            v-if="$route.params.categoria != 'formula-4'"
                            ><div class="resultado">
                              {{ ultimasCorridas.Corrida3.manOfTheRace }} -
                              {{ ultimasCorridas.Corrida3.manOfTheRacePontos }}
                              pts
                            </div></b-td
                          >
                          <b-td :stacked-heading="voltaMaisRapidaTranslate"
                            ><div class="resultado">
                              {{
                                ultimasCorridas.Corrida3.pilotoVoltaMaisRapida
                              }}
                              - {{ ultimasCorridas.Corrida3.voltaMaisRapida }}
                            </div></b-td
                          >
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";

import { EventBus } from "@/main.js";

export default {
  name: "UltimasCorridas",
  data() {
    return {
      loading: false,
      ultimasCorridas: {
        Corrida1: {
          podio: [],
        },
        Corrida2: {
          podio: [],
        },
        Corrida3: {
          podio: [],
        },
      },
      corrida1: "CORRIDA 1",
      corrida2: "CORRIDA 2",
      corrida3: "CORRIDA 3",
      etapaTranslate: "Etapa",
      circuitoTranslate: "Circuito",
      voltaMaisRapidaTranslate: "Volta mais rapida",
    };
  },
  filters: {
    dateFormat(date) {
      return new Date(date).toLocaleDateString();
    },
  },
  methods: {
    primeiroNome(nome) {
      return nome ? nome.replace(/ .*/, "") : "";
    },
    ultimoNome(nome) {
      if (nome) {
        let splitNome = nome.split(" ");
        let sobrenome = splitNome.slice(1).join(" ");
        return sobrenome;
      } else return "";
    },
    fetchUltimaCorrida(id_categoria) {
      this.loading = true;
      api
        .get(`Corrida/categoria/${id_categoria}/ultimas`)
        .then((resp) => {
          this.ultimasCorridas = resp.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    corridaTraducao() {
      if (this.$route.params.categoria == "formula-4") {
        this.corrida1 = this.$t("title.corrida1");
        this.corrida2 = this.$t("title.corrida2");
        this.corrida3 = this.$t("title.corrida3");

        this.etapaTranslate = this.$t("title.etapa");
        this.circuitoTranslate = this.$t("title.circuito");
        this.voltaMaisRapidaTranslate = this.$t("title.volta-mais-rapida");
      }
    },
  },
  mounted() {
    this.fetchUltimaCorrida(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
    this.corridaTraducao();
  },
  created() {
    EventBus.$on("updateRouter", (data) => {
      this.fetchUltimaCorrida(mapCategorias[data || "proseries"]);
    });
  },
};
</script>

<style scoped>
.img-podio {
  width: 120px;
}

.podio {
  display: flex;
}

.podio p {
  font-size: 40px;
  color: var(--primary-color);
  font-family: "Uniwars-W00-Black";
  margin-left: -40px;
}

.podio-pilotos h3 {
  font-size: 22px;
  color: var(--primary-color);
  font-family: "Uniwars-W00-Black";
}

.info-piloto {
  position: relative;
  top: -50px;
}

.podio-pilotos .nome,
.vencedores-fanpush p {
  font-size: 17px;
  line-height: 20px;
  font-family: "MetronicPro-Regular";
  color: var(--title-color);
}

.fontStrong {
  font-family: "MetronicPro-Black";
}

.equipe {
  font-family: "MetronicPro-SemiBold";
}

.equipe,
.montadora {
  color: #707070;
  font-style: italic;
  font-size: 14px;
}

.img-vencedor {
  width: 150px;
}

.vencedor .podio p {
  font-size: 65px;
  margin-left: -35px;
}

.resultado {
  color: var(--title-color);
  border-bottom: 1px solid var(--title-color);
  margin-left: 30px;
  padding-bottom: 10px !important;
}

@media (max-width: 990px) {
  .container-podio, .container-podio2, .container-podio3 {
    flex-direction: column-reverse;
  }
}

.podio-pilotos{
  display: flex;
}


@media (max-width: 990px) {
  .podio-pilotos {
    flex-direction: column;
  }
  .podio-pilotos .primeiro-box-podio {
    order: 1;
  }
  .podio-pilotos .segundo-box-podio {
    order: 2;
  }
  .podio-pilotos .terceiro-box-podio {
    order: 3;
  }
}


@media (max-width: 990px) {
  .segundo-box-podio, .primeiro-box-podio, .terceiro-box-podio {
    display: flex !important;
    flex-direction: row; 
    text-align: left;    
  }

  .podio {
    margin-right: 50px; 
    flex: 0 0 auto; 
  }

  .info-piloto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 343.1px;
  width: 150px;
  top: 0;
  }

  .info-piloto > * {
    margin-bottom: 5px; 
    margin-top: auto;
  }
}

</style>