<template>
  <section>
    <div class="home" v-if="!loading">
    <!-- <Banners v-if="show_element.banners" />-->

     <EtapaHome/>

      <b-modal
        ref="banner-modal"
        hide-footer
        hide-header
        class="modal-banner"
        id="modal-popup"
      >
        <div
          class="bg-modal"
          :style="{
            background: mobile
              ? 'url(' + popups.urlMobile + ')'
              : 'url(' + popups.url + ')',
          }"
        >
          <b-row class="flex-md-column-reverse">
            <b-col class="col-chamada">
              <div class="box-titulo">
                <div class="linha-titulo"></div>
                <h3>{{ popups.titulo }}</h3>
                <a
                  :href="popups.link"
                  style="text-decoration: none !important"
                  :target="popups.abrirNovaAba ? '_blank' : '_self'"
                >
                  <div class="saiba-mais">SAIBA MAIS</div>
                </a>
              </div>
            </b-col>
            <b-col class="col-close">
              <button
                type="button"
                aria-label="Close"
                @click="hideModal"
                class="close"
              >
                ×
              </button>
            </b-col>
          </b-row>
        </div>
      </b-modal>

      <b-container
        class="bv-row noticias"
        data-anima="bottom"
        v-show="has_noticias && show_element.noticias"
      >

        <Noticias :sections="sections" @verificaNoticia="verificaNoticia" />
      </b-container>

      <!--<Programacao v-if="$route.params.categoria != 'e-stock'"/>-->

      <b-container class="bv-row noticias" data-anima="bottom"
        v-if="$route.params.categoria == 'proseries' || $route.params.categoria == 'stock-series'">
        <NoticiasRelacionadas :sections="sections" @verificaNoticia="verificaNoticia" />
      </b-container>

      <b-container class="bv-row noticias" data-anima="bottom" v-if="$route.params.categoria == 'proseries'">
        <NoticiasRelacionadas :sections="sections" @verificaNoticia="verificaNoticia" :bloco="2" />
      </b-container>

      <div v-if="show_element.ultimasCorridas">
        <b-container class="box-corrida">
          <b-row>
            <b-col class="titulo-corrida titulo-centralizado">
              <hr class="linha-titulo" />
              <h2 v-if="$route.params.categoria == 'formula-4'" class="titulo title-f4">
                <strong>{{ $t("title.ultima") }}</strong>
                {{ $t("title.corrida") }}
              </h2>
              <h2 v-else class="titulo"><strong>ÚLTIMA</strong> CORRIDA</h2>
              <hr class="linha-titulo" />
            </b-col>
          </b-row>
        </b-container>
        <UltimasCorridas />
      </div>

      <div
        class="container-classificacao margin-row"
        v-if="show_element.classificacaoGeral"
      >
         <b-container>
          <b-row>
            <b-col class="titulo-classificacao titulo-centralizado" v-if="$route.params.categoria == 'formula-4'">
              <hr class="linha-titulo" />
              <h2  class="titulo title-f4" >
                <strong>{{ $t("title.classificageral1") }}</strong>
                {{ $t("title.classificageral2") }}
              </h2>
              <hr class="linha-titulo" />
            </b-col>
            <b-col v-else-if="$route.params.categoria == 'turismo-nacional'"> </b-col>
            <b-col class="titulo-classificacao titulo-centralizado" v-else>
              <hr class="linha-titulo" />
              <h2 class="titulo">
                <strong>CLASSIFICAÇÃO</strong> GERAL
              </h2>
              <hr class="linha-titulo" />
            </b-col>
          </b-row>
          <ClassificacaoGeral :limit="5" />
        </b-container>
      </div>

      <div v-if="show_element.fanpush">
        <div class="container-fanpush margin-row">
          <b-row>
            <b-col class="titulo-fanpush titulo-centralizado">
              <hr class="linha-titulo" />
              <h2 class="titulo">
                <strong>VIVO </strong> FAN PUSH BY SNAPDRAGON
              </h2>
              <hr class="linha-titulo" />
            </b-col>
          </b-row>
        </div>

        <Fanpush />
      </div>

      <LogosHome :show_element="show_element.rodape" />
    </div>
  </section>
</template>

<script>
import Noticias from "@/components/Noticias.vue";
import UltimasCorridas from "@/components/UltimasCorridas.vue";
import ClassificacaoGeral from "@/components/ClassificacaoGeral.vue";
import Fanpush from "@/components/Fanpush.vue";
import LogosHome from "@/components/LogosHome.vue";
import EtapaHome from "@/components/EtapaHome.vue";
import NoticiasRelacionadas from "@/components/NoticiasRelacionadas.vue";
import { api } from "@/services.js";
import { mapCategorias } from "@/helpers.js";
import { EventBus } from "@/main.js";

export default {
  components: {
    Noticias,
    UltimasCorridas,
    ClassificacaoGeral,
    Fanpush,
    LogosHome,
    EtapaHome,
    NoticiasRelacionadas
  },
  data() {
    return {
       vencedores: {
        votacaoAberta: false,
        ultimosVencedores: [],
      },
      mobile: false,
      popups: [],
      loading: false,
      has_noticias: 1,
      sections: {
        primeira: true,
        segunda: true,
        terceira: true,
      },
      show_element: {
        banners: false,
        noticias: false,
        ultimasCorridas: false,
        classificacaoGeral: false,
        fanpush: false,
        maquinas: false,
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      },
    };
  },
  methods: {
    fetchFanPushVencedores() {
      this.loading = true;
      api
        .get("Fanpush/ultimos-vencedores")
        .then((resp) => {
          this.vencedores = resp.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    hideModal() {
      this.$refs["banner-modal"].hide();
    },

    fetchPopups(idCategoria) {
      if (sessionStorage.live == "false" || !sessionStorage.live) {
        if (idCategoria) {
          this.loading = true;

          api
            .get(`/Banner/Popup/${idCategoria}`)
            .then((resp) => {
              this.popups = resp.data;
              if (window.innerWidth <= 768) {
                this.mobile = true;
              }
              if (this.popups.id) {
                setTimeout(() => {
                  this.$bvModal.show("modal-popup");
                }, 3000);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    verificaNoticia(e) {
      this.has_noticias = e;
    },
    verificaElemento(data = null) {
      const categoria = data || this.$route.params.categoria;

      this.show_element = {
        banners: false,
        noticias: false,
        ultimasCorridas: false,
        classificacaoGeral: false,
        fanpush: false,
        maquinas: false,
        rodape: {
          montadoras: false,
          patrocinadores: false,
          fornecedores: false,
          mediaPartners: false,
        },
      };
      switch (categoria) {
        case "proseries": {
          this.show_element = {
            banners: true,
            noticias: true,
            ultimasCorridas: true,
            classificacaoGeral: true,
            fanpush: true,
            maquinas: true,
            rodape: {
              montadoras: true,
              patrocinadores: true,
              fornecedores: true,
              mediaPartners: true,
            },
          };
          break;
        }

        case "stock-series": {
          this.show_element = {
            banners: true,
            noticias: true,
            ultimasCorridas: true,
            classificacaoGeral: true,
            fanpush: false,
            maquinas: false,
            rodape: {
              montadoras: true,
              patrocinadores: true,
              fornecedores: true,
              mediaPartners: true,
            },
          };
          break;
        }

        case "e-stock": {
          this.show_element = {
            banners: true,
            noticias: true,
            ultimasCorridas: true,
            classificacaoGeral: false,
            fanpush: false,
            maquinas: false,
            rodape: {
              montadoras: false,
              patrocinadores: false,
              fornecedores: false,
              mediaPartners: false,
            },
          };
          break;
        }
        case "turismo-nacional": {
          this.show_element = {
            banners: true,
            noticias: true,
            ultimasCorridas: false,
            classificacaoGeral: true,
            fanpush: false,
            maquinas: false,
            rodape: {
              montadoras: false,
              patrocinadores: true,
              fornecedores: false,
              mediaPartners: false,
            },
          };
          break;
        }

        case "formula-4": {
          this.show_element = {
            banners: true,
            noticias: true,
            ultimasCorridas: true,
            classificacaoGeral: true,
            fanpush: false,
            maquinas: false,
            rodape: {
              montadoras: false,
              patrocinadores: true,
              fornecedores: true,
              mediaPartners: true,
            },
          };
          break;
        }
      }

      this.loading = false;
    },
  },
  mounted() {
    this.loading = true;
    this.verificaElemento();
    this.fetchFanPushVencedores();
    this.fetchPopups(
      mapCategorias[this.$route.params.categoria || "proseries"]
    );
  },

  created() {
    EventBus.$on("updateRouter", (data) => {
      this.verificaElemento(data);
      this.fetchPopups(mapCategorias[data || "proseries"]);
    });
  },
};
</script>

<style scoped>

.title-f4 strong {
  font-family: "Venera-900" !important;
}

.title-f4 {
  font-family: "Venera-300" !important;
}

.box-titulo {
  padding: 30px 40px;
  background: transparent linear-gradient(90deg, #383337 0%, #38333700 100%) 0%
    0% no-repeat padding-box;
}
.modal-banner .modal-content {
  z-index: 9999;
}
.close {
  background-color: transparent;
  border: none;
  font-size: 40px;
  color: black;
  margin-right: 20px;
}
.col-close {
  display: flex;
  justify-content: end;
  align-items: baseline;
}

.bg-modal .row {
  height: 500px;
}

.bg-modal h3 {
  font-family: "MetronicPro-Bold";
  color: white;
  font-size: 35px;
  width: 550px;
}

.saiba-mais {
  border: 1px solid white;
  width: 290px;
  border-radius: 5px;
  padding: 15px 10px;
  text-align: center;
  color: white;
  font-size: 20px;
}

.saiba-mais:hover {
  background-color: white;
  color: black !important;
}

.linha-titulo {
  background-color: white;
  height: 4px;
  width: 135px;
  opacity: 1;
  margin-bottom: 15px;
}

.col-chamada {
  display: flex;
  align-items: end;
}

.bg-modal {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 500px !important;
}

div#modal-popup___BV_modal_outer_ {
  z-index: 99999999 !important;
}
#modal-popup .saiba-mais {
  padding: 10px;
  margin-top: 40px;
  font-size: 18px;
}

@media (max-width: 989px) {
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .bg-modal h3 {
    width: 100%;
    font-size: 20px;
  }

  .col-close {
    align-items: center;
    margin-top: 30px;
    padding: 0px;
  }

  #modal-1 .close,
  .modal .close {
    margin-top: -200px !important;
  }

  .box-titulo {
    width: 100%;
    padding: 30px 30px;
  }

  .saiba-mais {
    width: 100%;
  }
}
</style>