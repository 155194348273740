import { render, staticRenderFns } from "./EsqueciMinhaSenha.vue?vue&type=template&id=50ed9dac&scoped=true&"
import script from "./EsqueciMinhaSenha.vue?vue&type=script&lang=js&"
export * from "./EsqueciMinhaSenha.vue?vue&type=script&lang=js&"
import style0 from "./EsqueciMinhaSenha.vue?vue&type=style&index=0&id=50ed9dac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50ed9dac",
  null
  
)

export default component.exports