<template>
  <div style="position: relative">
    <b-form-input
      :type="type"
      :id="id"
      :value="value"
      :state="localState"
      :class="inputClass"
      :disabled="disabled"
      placeholder=" "
      @input="$emit('input', $event)"
      @focus="focused = true"
      @blur="focused = false"
      :required="required"
    />
    <label
      :for="id"
      :class="[labelClass, labelClassCustom]"
      @click="labelClick"
      >{{ label }}</label
    >
  </div>
</template>
  
  <script>
export default {
  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
    type: { type: String, default: "text" },
    value: { type: [String, Number], default: "" },
    state: { type: Boolean, default: null },
    inputClass: { type: [String, Object], default: "" },
    labelClassCustom: { type: String, default: "" },
    disabled: { type: Boolean, default: false }, // Adicionado prop disabled
    required: { type: Boolean, default: false }, // Adicionado prop disabled
  },
  data() {
    return {
      focused: false,
      localState: this.state,
    };
  },
  computed: {
    labelClass() {
      return {
        "label-active": this.value !== "" || this.focused,
        "label-inactive": this.value === "" && !this.focused,
      };
    },
  },
  watch: {
    value() {
      this.localState = this.value !== "" ? true : null;
    },
    state() {
      this.localState = this.state;
    },
  },
  methods: {
    labelClick() {
      const input = document.querySelector(`input[id='${this.id}']`);
      input.focus();
    },
  },
};
</script>
  
  
  <style scoped>

.nome .label-inactive, .email .label-inactive,
.confirmarEmail .label-inactive {
    top: 6px !important;
    margin-left: 5px !important;
}

.nome .my-input-class, .email .my-input-class,
.confirmarEmail .my-input-class {
  padding: 0px !important;
  height: 25px  !important;
}

.nome .my-input-class:focus, .email .my-input-class:focus,
.confirmarEmail .my-input-class:focus {
  border: none !important;
  outline: none !important;
}


.label-active {
  position: absolute;
  top: -10px;
  margin-left: 12px;
  font-size: 12px;
  color: var(--primary-color);
  transition: all 0.2s ease-out;
  background-color: #000000;
  padding: 0 5px;
  border-radius: 4px !important;
}

.my-input-class.menu-input + .label-active {
  background-color: #0e080d !important;
  border-radius: 4px !important;
}
.my-input-class {
  padding: 1.4rem 1rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  height: 56px;
  color: #ffffff !important;
  margin-bottom: 30px;
}

.my-input-class:disabled {
  background-color: #333;
  opacity: 0.7;
}

.my-input-class:focus {
  border: 1px solid #ffffff;
  background-color: transparent;
  outline: none !important;
  box-shadow: none !important;
}

.my-input-class.is-valid {
  border: 1px solid var(--primary-color);
  background-image: none !important;
}

.my-input-class.is-valid:focus {
  border: 1px solid #fff;
  background-image: none !important;
}

.my-input-class.error + label {
  color: #ff1717;
}
.my-input-class.error {
  border: 1px solid #ff1717;
  color: #ff1717;
}

.my-input-class:focus + label {
  color: #fff;
}

.label-inactive {
  position: absolute;
  top: 14px;
  margin-left: 16px;
  font-size: 16px;
  color: var(--primary-color);
  transition: all 0.2s ease-out;
  cursor: auto;
}

.my-label-class {
  z-index: 10;
}
</style>
  