<template>
  <div>
    <section v-if="!loading">
      <div class="main-patrocinador">
        <b-container class="align-items-end">
          <b-col cols="12" lg="9" class="hist-content box-texto">
            <div class="linha-noticia"></div>
            <b-card no-body class="mb-1 camarotes">
              <h2>
                Venha fazer parte da maior plataforma de entretenimento e
                automobilismo da América do Sul
              </h2>
            </b-card>
          </b-col>
        </b-container>

        <b-container class="space-icons-banner" data-anima="bottom">
          <div class="center-images">
            <img src="../assets/img/patrocinador/STOCK.png" alt="" />
            <img src="../assets/img/patrocinador/Stock_Series.png" alt="" />
          </div>
          <div class="center-images">
            <img src="../assets/img/patrocinador/F4.png" alt="" />
            <img src="../assets/img/patrocinador/Turismo_nacional.png" alt="" />
            <img
              style="height: 40px"
              src="../assets/img/patrocinador/TCR_BRASIL.png"
              alt=""
            />
          </div>
        </b-container>
      </div>

      <div class="main-patrocinador-mobile">
        <b-container class="flex-1">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h2 class="titulo">
              <strong
                >venha fazer parte da maior plataforma de entretenimento e
                automobilismo da América do Sul</strong
              >
            </h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-container>

        <b-container style="margin-bottom: 60px">
          <div class="center-images imgs-1">
            <img src="../assets/img/patrocinador/STOCK.png" alt="" />
            <img src="../assets/img/patrocinador/Stock_Series.png" alt="" />
          </div>
          <div class="center-images imgs-2">
            <img src="../assets/img/patrocinador/Turismo_nacional.png" alt="" />
            <img
              style="height: 40px"
              src="../assets/img/patrocinador/TCR_BRASIL.png"
              alt=""
            />
          </div>
        </b-container>
      </div>

      <b-container>
        <b-row class="">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h2 class="titulo">
              <strong>SOMOS UMA PLATAFORMA</strong> QUE CONTÉM:
            </h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>
      </b-container>

      <b-container>
        <b-row class="space-cards">
          <div class="card-plataforma">
            <img class="icons-cards" src="../assets/img/megafone.svg" alt="" />

            <h2 class="cards-title">Exposição de marca:</h2>

            <p class="p-cards flex-1">
              TV aberta, fechada, Gaulês, Galvão Bueno, Youtube, Twitch e mais
              de 151 países.
            </p>
          </div>

          <div class="card-plataforma">
            <img class="icons-cards" src="../assets/img/maos.svg" alt="" />

            <h2 class="cards-title">Relacionamento B2B:</h2>

            <p class="p-cards flex-1">
              Universo de mais de 200 marcas no evento, gerando negócios entre
              elas. Camarotes corporativos, visitação aos boxes, torcida
              personalizada, volta rápida, entrega de troféus, tour guiado pelos
              bastidores etc.
            </p>
          </div>

          <div class="card-plataforma">
            <img class="icons-cards" src="../assets/img/lampada.svg" alt="" />

            <h2 class="cards-title">Brand experience:</h2>

            <p class="p-cards flex-1">
              Nós pensamos, cocriamos e executamos para vocês. Ativações,
              sampling, degustação de produtos, test drive, experiência de marca
              com o público etc.
            </p>
          </div>
        </b-row>
      </b-container>

      <b-container class="mb-4 divisao">
        <h2 class="titulo-patrocinadores">
          <strong>
            Venha impulsionar o seu negócio e construir relacionamentos
            duradouros com uma base de clientes com alto potencial de
            engajamento.
          </strong>
        </h2>
      </b-container>

      <b-container class="mt-4 mb-4">
        <b-row class="space-cards">
          <div
            v-b-modal.modal-exposicao
            class="card-img justify-content-end bg-exposicao"
          >
            <p class="titulo card-img-p">
              <strong>Exposição</strong>
            </p>
          </div>

          <div
            v-b-modal.modal-atv-marca
            class="card-img justify-content-end bg-ativacao"
          >
            <p class="titulo card-img-p">
              <strong>Ativação da marca</strong>
            </p>
          </div>

          <div
            v-b-modal.modal-relacionamento
            class="card-img justify-content-end bg-relacionamento"
          >
            <p class="titulo card-img-p">
              <strong>relacionamento</strong>
            </p>
          </div>

          <div v-b-modal.modal-esg class="card-img justify-content-end bg-esg">
            <p class="titulo card-img-p">
              <strong>ESG</strong>
            </p>
          </div>
        </b-row>
      </b-container>

      <b-container class="espaco-camarotes divisao">
        <b-row class="">
          <b-col cols="12" lg="6" class="hist-content img-trofeu trofeu-bg">
          </b-col>
          <b-col cols="12" lg="6" class="hist-content box-texto">
            <div class="linha-noticia"></div>
            <h2>QUEM SOMOS?</h2>
            <p style="color: var(--text-color)">
              Nossa missão é produzir os maiores eventos aliando performance,
              relacionamento B2B, tecnologia, inovação, sustentabilidade e
              entreterimento para nossos fãs.
            </p>

            <b-button
              :to="`/stockcar/${
                $route.params.categoria || 'proseries'
              }/quero-ser-patrocinador`"
              class="button-contact-camarote float-start"
              ><strong>Entre em </strong>contato</b-button
            >
          </b-col>
        </b-row>
      </b-container>

      <b-container>
        <b-row>
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h2 class="titulo">
              <strong>Faça uma visita guiada</strong><br />
              ao nosso escritório
            </h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>

        <div class="d-flex justify-content-center align-content-center">
          <iframe
            width="80%"
            height="500"
            :src="embedUrl(videoId)"
            frameborder="0"
          ></iframe>
        </div>
      </b-container>

      <div
        class="bg-patrocinador2 d-flex justify-content-center align-content-center"
      >
        <b-container>
          <h2 class="titulo-patrocinadores">
            <strong> Quer acelerar conosco? </strong>
          </h2>
        </b-container>
        <b-button
          :to="`/stockcar/${
            $route.params.categoria || 'proseries'
          }/quero-ser-patrocinador`"
          class="acessar btn"
          >Entre em contato
        </b-button>
      </div>

      <b-container data-anima="top">
        <b-row class="divisao">
          <b-col class="titulo-centralizado">
            <hr class="linha-titulo" />
            <h2 class="titulo">
              <strong>PATROCINADORES</strong> do grupo Vicar
            </h2>
            <hr class="linha-titulo" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="imgs-rodape">
              <div
                v-for="(item, index) in patrocinadores"
                :key="`${item.nome}-${index}`"
              >
                <img :src="item.imagem" alt="Logo do patrocinador" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <b-modal class="modal-patrocinador" id="modal-exposicao">
      <img width="100%" src="../assets/img/modal-exposicao.png" alt="" />
      <p class="mt-4 p-modal">Exposição de Marca</p>
    </b-modal>

    <b-modal class="modal-patrocinador" id="modal-atv-marca">
      <img width="100%" src="../assets/img/modal-atv-marca.png" alt="" />
      <p class="mt-4 p-modal">Ativação da Marca</p>
    </b-modal>

    <b-modal class="modal-patrocinador" id="modal-relacionamento">
      <img width="100%" src="../assets/img/modal-relacionamento.png" alt="" />
      <p class="mt-4 p-modal">Relacionamento</p>
    </b-modal>

    <b-modal class="modal-patrocinador" id="modal-esg">
      <img width="100%" src="../assets/img/modal-esg.png" alt="" />
      <p class="mt-4 title-modal">ESG</p>
      <p class="mt-4 p-modal">
        Evento carbono neutro; refinamento de óleo usado pelas squipes da Stock
        Car, receita gerada por ações em parceria com a lay For a Cause que são
        revertidas para instituições parceiras.
      </p>
    </b-modal>

    <b-container v-if="loading" data-anima="bottom" class="loading">
      <b-spinner
        style="
          width: 3rem;
          height: 3rem;
          border-color: #e6c156;
          border-right-color: transparent;
          --bs-spinner-animation-speed: 0.5s;
        "
        label="Large Spinner"
        variant="warning"
      ></b-spinner>
    </b-container>
  </div>
</template>

<script>
import { api } from "@/services.js";

export default {
  data() {
    return {
      videoId: "4c603CwmMGs",
      loading: false,
      patrocinadores: {},
    };
  },
  components: {},

  methods: {
    fetchPatrocinadores() {
      this.loading = true;
      api
        .get(`/Footer/categoria/1`)
        .then((resp) => {
          this.patrocinadores = resp.data.patrocinadores;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    embedUrl(videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    },
  },

  mounted() {
    this.fetchPatrocinadores();
  },

  created() {},
};
</script>
  
<style scoped>
.main-patrocinador {
  height: 80vh;
  display: flex;
  flex-direction: initial;
  background-image: linear-gradient(transparent, var(--bg-color)),
    var(--bg-seja-patrocinador);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.main-patrocinador-mobile {
  height: calc(100vh - 300px);
  display: none;
  flex-direction: column;
  background-image: linear-gradient(transparent, #0e080d),
    var(--bg-seja-patrocinador);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.main-patrocinador .titulo {
  background-image: none;
  font-size: 34px;
}

.loading {
  min-height: 37vh;
  padding: 40px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-estrutura {
  font-family: MetronicPro-Light;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.card-plataforma {
  border: 1px solid var(--primary-color);
  max-width: 300px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 24px;
}

.acessar {
  color: var(--text-color);
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  max-width: 250px;
  width: 100%;
  font-family: "MetronicPro-Bold";
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 30px 0px 30px 0px;
  margin-top: 40px;
  text-transform: uppercase;
}

.bg-patrocinador2 {
  height: 60vh;
  background-image: linear-gradient(transparent, var(--bg-color)),
    url(../assets/img/bg-patrocinador-2.png);
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.card-img {
  border: 1px solid var(--primary-color);
  max-width: 250px;
  background-repeat: no-repeat;
  height: 320px;
  background-size: cover;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 24px;
}

.titulo.card-img-p {
  font-size: 1rem;
  background-image: none;
}

.title-modal {
  font-family: "MetronicPro-Bold";
  color: #ffffff !important;
  text-align: center;
  display: flex;
  align-content: center;
  font-size: 1.5rem;
  flex-wrap: wrap;
}

.img-trofeu {
  background-image: url(../assets/img/trofeu2x.png);
  background-size: cover;
}

.bg-exposicao {
  background-image: linear-gradient(transparent, var(--bg-color)),
    url(../assets/img/exposicao.png);
}

.bg-ativacao {
  background-image: linear-gradient(transparent, var(--bg-color)),
    url(../assets/img/Aticacao.png);
}

.bg-relacionamento {
  background-image: linear-gradient(transparent, var(--bg-color)),
    url(../assets/img/Relacionamento.png);
}

.bg-esg {
  background-image: linear-gradient(transparent, var(--bg-color)),
    url(../assets/img/ESG.png);
}

.titulo-patrocinadores {
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 30px;
  font-family: "Uniwars-W00-Light";
  text-align: center;
  background-image: none;
}

.cards-title {
  font-family: "Uniwars-W00-SemiBold";
  font-size: 1.2rem;
  color: var(--primary-color);
  text-align: center;
}

.p-cards {
  font-family: "MetronicPro-Regular";
  color: var(--title-color);
  text-align: center;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.space-cards {
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}

.center-images {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-items-end {
  justify-content: center;
  display: flex;
  align-items: flex-end;
}

.img-hist {
  background-size: cover;
  background-position: center;
}

.flex-1 {
  flex-grow: 1;
}

.box-camarote {
  width: 100%;
  background-color: red;
  max-height: 500px;
  height: 100%;
  margin-bottom: 40px;
}

.hist-content {
  height: 420px;
}

.icons-cards {
  max-width: 150px;
  height: 150px;
  fill: var(--title-color);
}

.box-texto {
  padding: 40px !important;
  background-color: var(--primary-color);
}

.box-texto h2 {
  margin-bottom: 20px;
  font-family: "MetronicPro-Bold";
  font-size: 35px;
  line-height: 36px;
  color: var(--text-color);
}

.button-camarote-more {
  background-color: transparent;
  text-decoration: underline;
  color: var(--text-color);
  border: none;
  font-size: 20px;
  padding: 0px !important;
  text-align: initial;
}

.button-camarote-more:hover,
.button-camarote-more:active {
  background-color: transparent !important;
}

.card-header {
  background-color: transparent;
  border: none;
  font-family: "MetronicPro-Semibold";
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
}

.space-icons-banner {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.button-contact-camarote {
  width: 226px;
  font-family: "MetronicPro-Light";
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid var(--text-color);
  color: var(--text-color);
}

.button-contact-camarote:hover,
.button-contact-camarote:active {
  filter: brightness(0.5);
  background-color: transparent !important;
  border: 1px solid var(--text-color) !important;
  color: var(--text-color) !important;
}

.button-contact-camarote:focus {
  opacity: 1 !important;
}

.card.camarotes {
  background-color: transparent;
  border: none;
  font-family: "MetronicPro-Semibold";
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
}

.card-text {
  font-family: "MetronicPro-Semibold";
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: var(--text-color);
}

.espaco-camarotes {
  margin-top: 60px;
}

.card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (max-width: 1638px) {
  .box-texto h2 {
    font-size: 1.6rem !important;
    line-height: 26px;
  }

  .hist-content {
    height: auto;
  }
}

@media (max-width: 1600px) {
  .box-texto h2 {
    font-size: 1.6rem !important;
    line-height: 26px;
  }

  .center-images {
    /* margin-top: 58% !important; */
  }

  .img-trofeu {
    height: auto;
    min-height: 450px;
    background-position: center;
  }
}

@media (max-width: 1382px) {
  .main-patrocinador-mobile h2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 1382px) {
  .box-texto h2 {
    font-size: 1.2rem !important;
    line-height: 20px;
  }
}

@media (max-width: 800px) {
  .titulo-patrocinadores {
    font-size: 1.2rem;
  }

  .main-patrocinador-mobile {
    display: flex;
    height: 80vh;
  }

  .main-patrocinador {
    display: none;
  }

  .center-images.imgs-1 img {
    height: 60px;
  }

  .center-images.imgs-2 img {
    height: 30px;
  }
}

@media (max-width: 1382px) {
  .img-trofeu {
    min-height: 400px;
  }
}

@media (max-width: 660px) {
  .main-patrocinador,
  .main-patrocinador-mobile {
    height: 44vh;
  }

  .hist-content {
    height: 250px;
  }

  .box-texto {
    height: auto;
  }

  .main-patrocinador-mobile h2 {
    font-size: 1rem;
  }

  .titulo-patrocinadores {
    font-size: 1rem;
  }
}
</style>
    