<template>
  <div class="bg-rodape">
    <b-container class="rodape">
      <b-row class="icones">
        <b-col cols="12" lg="7">
          <hr />
        </b-col>

        <b-col cols="12" lg="4">
          <div class="rede-social box-centralizado">
            <a
              :href="links_social.facebook"
              v-if="links_social.facebook"
              target="_blank"
              ><img
                src="@/assets/img/footer/social_face.png"
                alt="Ícone do facebook"
            /></a>
            <a
              :href="links_social.instagram"
              v-if="links_social.instagram"
              target="_blank"
              ><img
                src="@/assets/img/footer/social_insta.png"
                alt="Ícone do instagram"
            /></a>
            <a
              :href="links_social.youtube"
              v-if="links_social.youtube"
              target="_blank"
              ><img
                src="@/assets/img/footer/social_youtube.png"
                alt="Ícone do youtube"
            /></a>
            <a
              :href="links_social.twitter"
              v-if="links_social.twitter"
              target="_blank"
              ><img
                src="@/assets/img/footer/social_twitter.png"
                alt="Ícone do twitter"
            /></a>
            <a
              :href="links_social.tiktok"
              v-if="links_social.tiktok"
              target="_blank"
              alt="Ícone do tiktok"
              ><img src="@/assets/img/footer/social_tiktok.png"
            /></a>
          </div>
        </b-col>

        <b-col cols="12" lg="1">
          <hr class="linha-mob" />
        </b-col>
      </b-row>

      <b-row class="icones">
        <p
          style="color: #fff; font-weight: 600"
          v-if="$route.params.categoria != 'formula-4'"
        >
          Categorias
        </p>

        <b-col
          v-if="$route.params.categoria != 'formula-4'"
          cols="12"
          lg="3"
          class="link-rodape"
        >
          <router-link to="/stockcar/proseries/categoria"
            >A Stock Car</router-link
          ><br />
          <router-link to="/stockcar/stock-series/categoria"
            >A Stock Series</router-link
          ><br />
          <router-link to="/stockcar/turismo-nacional/categoria"
            >A Turismo Nacional</router-link
          ><br />
          <router-link to="/stockcar/formula-4/categoria"
            >A F4 Brasileira</router-link
          ><br />
        </b-col>
        <b-col cols="12" lg="3" class="link-rodape">
          <router-link
            :to="`/stockcar/${
              $route.params.categoria || 'proseries'
            }/seja-patrocinador`"
            >Seja patrocinador</router-link
          >

          <br />
          <router-link
            :to="`/stockcar/${
              $route.params.categoria || 'proseries'
            }/corporativa-b2b`"
            >Ativação corporativa</router-link
          >
          <br />
          <router-link
            :to="`/stockcar/${$route.params.categoria || 'proseries'}/esg`"
            >ESG da Stock</router-link
          >

          <br />

          <a
            href="https://www.stockstoreoficial.com.br/"
            name="stock-store"
            target="_blank"
            >Stock Store</a
          ><br />
          <a
            href="https://www.youtube.com/user/stockcarchannel"
            name="youtube"
            target="_blank"
            >Youtube</a
          >
        </b-col>

        <b-col cols="12" lg="3" class="link-rodape">
          <router-link
            :to="`/stockcar/${
              $route.params.categoria || 'proseries'
            }/politica-privacidade`"
            >Política de Privacidade</router-link
          ><br />
          <router-link
            :to="`/stockcar/${
              $route.params.categoria || 'proseries'
            }/termos-de-uso`"
            >Termos de uso</router-link
          >
          <br />
          <router-link
            :to="`/stockcar/${
              $route.params.categoria || 'proseries'
            }/trabalhe-conosco`"
            >Trabalhe conosco</router-link
          >
          <br />
          <a href="mailto:faleconosco@vicarbrasil.com.br">Fale Conosco</a><br />
        </b-col>

        <b-col cols="12" lg="2"></b-col>

        <b-col cols="12" lg="4" v-if="$route.params.categoria == 'formula-4'">
          <img
            src="@/assets/img/footer/fia.png"
            alt="logo Fia"
            style="width: 120px; margin-right: 20px"
          />
          <img
            src="@/assets/img/footer/action.png"
            alt="Logo action"
            style="width: 120px"
          />
        </b-col>

        <b-col cols="12" lg="12">
          <p class="copyright textCenter">
            © Stock Car Brasil. Todos os Direitos Reservados à Vicar Promoções
            Desportivas S.A.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapCategorias } from "@/helpers.js";

import { EventBus } from "@/main.js";

export default {
  name: "Rodape",
  data() {
    return {
      loading: false,
      footer: null,
      links_social: {
        facebook: null,
        instagram: null,
        youtube: null,
        twitter: null,
        tiktok: null,
      },
    };
  },
  methods: {
    fetchFooter(id_categoria) {
      this.loading = true;
      this.categoria = null;

      this.$store.dispatch("getFooter", id_categoria).finally(() => {
        this.loading = false;
      });
    },

    verificaSocial(data = null) {
      const categoria = data || this.$route.params.categoria;

      this.links_social = {
        facebook: null,
        instagram: null,
        youtube: null,
        twitter: null,
        tiktok: null,
      };
      switch (categoria) {
        case "proseries": {
          this.links_social = {
            facebook: "https://www.facebook.com/stockcaroficial",
            instagram: "https://www.instagram.com/stock_car/",
            youtube: "https://www.youtube.com/user/stockcarchannel",
            twitter: "https://twitter.com/stock_car",
            tiktok: "https://www.tiktok.com/@stockcarbrasil",
          };
          break;
        }

        case "stock-series": {
          this.links_social = {
            facebook: "https://www.facebook.com/StockSeriesBr/",
            instagram: "https://www.instagram.com/stockseriesbr/",
          };
          break;
        }

        case "e-stock": {
          this.links_social = {
            facebook: "https://www.facebook.com/stockcaroficial",
            instagram: "https://www.instagram.com/stock_car/",
            youtube: "https://www.youtube.com/user/stockcarchannel",
            twitter: "https://twitter.com/stock_car",
            tiktok: "https://www.tiktok.com/@stockcarbrasil",
          };
          break;
        }
        case "turismo-nacional": {
          this.links_social = {
            facebook: "https://pt-br.facebook.com/turismonacionalbr/",
            instagram: "https://www.instagram.com/turismonacionalbr/",
          };
          break;
        }

        case "formula-4": {
          this.links_social = {
            facebook: "https://web.facebook.com/fiaf4brasil",
            instagram: "https://www.instagram.com/f4brasileira/",
            youtube: "https://www.youtube.com/@f4brasileira",
            twitter: "https://twitter.com/f4brasileira",
          };
          break;
        }

        default: {
          this.links_social = {
            facebook: "https://www.facebook.com/stockcaroficial",
            instagram: "https://www.instagram.com/stock_car/",
            youtube: "https://www.youtube.com/user/stockcarchannel",
            twitter: "https://twitter.com/stock_car",
            tiktok: "https://www.tiktok.com/@stockcarbrasil",
          };
          break;
        }
      }

      this.loading = false;
    },
  },

  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.verificaSocial();
      this.fetchFooter(
        mapCategorias[this.$route.params.categoria || "proseries"]
      );
    }, 1500);
  },

  created() {
    EventBus.$on("updateRouter", (data) => {
      this.verificaSocial(data);
    });
  },
};
</script>

<style scoped>
.link-rodape a:hover {
  color: grey;
}

.copyright {
  font-family: "MetronicPro-Regular";
  color: white;
  font-size: 13px;
  margin-top: 50px;
}

.icones {
  padding-bottom: 50px;
}

.link-rodape {
  line-height: 35px;
}

.link-rodape a {
  /* font-family: "MetronicPro-Regular"; */
  color: white;
  text-decoration: none;
}
@media (max-width: 989px) {
  .link-rodape a {
    font-size: 14px;
  }
}

.box-centralizado {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.rodape {
  margin-top: 120px;
}

.rodape hr {
  background-color: #fff;
  height: 2px;
  width: 100%;
}

.rede-social img {
  width: 40px;
}
</style>