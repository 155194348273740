<template>
  <div>
    <div class="box-relogio">
      <h3><strong>Fanpush</strong> is on</h3>
      <div class="relogio">
        <div class="borda-cinza">
          <strong>{{ days }}</strong
          ><br />
          dias
        </div>
        <div class="borda-cinza">
          <strong>{{ hours }} </strong><br />
          horas
        </div>
        <div class="borda-cinza">
          <strong>{{ minutes }} </strong><br />
          minutos
        </div>
        <div>
          <strong>{{ seconds }} </strong><br />
          segundos
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CountDownFanPush",
  data() {
    return {
      milliseconds_count: 999,
      countdown: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  props: {
    data_fim_fanpush: {
      type: String,
      default: "",
    },
  },
  methods: {},
  created() {
    this.countdown = setInterval(() => {
      const now = new Date().getTime();
      const difference = new Date(this.data_fim_fanpush).getTime() - now;
      this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.countdown);
  },
};
</script>

<style scoped>
.box-relogio h3 {
  color: #eabb4d;
  font-family: "Uniwars-W00-Light";
  font-style: italic;
  font-size: 16px;
  text-align: center;
}

.box-relogio strong {
  font-family: "Uniwars-W00-Heavy-Italick";
}

.relogio {
  gap: 20px;
  display: flex;
  justify-content: center;
}

.relogio strong {
  font-family: "Uniwars-W00-Heavy-Italick";
  font-size: 37px;
  color: white;
}

.relogio div {
  color: #939393;
  font-family: "Uniwars-W00-Light";
  font-size: 13px;
  text-align: center;
}
.borda-cinza {
  border-right: 1px solid #939393;
  padding-right: 20px;
}

@media (max-width: 989px) {
  .borda-cinza {
    padding-right: 10px;
  }

  .relogio {
    gap: 10px;
  }

  .relogio strong {
    font-size: 25px;
  }
}
</style>